<section>
  <div>
    <div>
      <div>
        <h1>Bienvenue</h1>
        <p>sur votre plateforme <strong>SI APA</strong></p>
        <div></div>
      </div>

      <form [formGroup]="loginForm">
        <div>
          <label for="email">Adresse e-mail</label>

          <div>
            <input type="email" id="email" formControlName="email" placeholder="Renseigner" />
          </div>

          <div
            *ngIf="
              loginForm.controls.email.invalid && (loginForm.controls.email.dirty || loginForm.controls.email.touched)
            ">
            <small>Vous devez saisir une adresse e-mail valide.</small>
          </div>
        </div>
        <div>
          <label for="password">Mot de passe</label>

          <div>
            <input
              type="password"
              name="password"
              id="exampleInputPassword1"
              placeholder="Renseigner"
              formControlName="password" />
          </div>
          <div
            *ngIf="
              loginForm.controls.password.invalid &&
              (loginForm.controls.password.dirty || loginForm.controls.password.touched)
            ">
            <small>Le mot de passe doit comporter au moins 8 caractères.</small>
          </div>
        </div>
        <div>
          <button type="submit" [disabled]="loginForm.invalid">S'identifier</button>
        </div>
      </form>
    </div>
  </div>
</section>

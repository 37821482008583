import { Observable } from 'rxjs';
import { UserDto } from '../models/user.dto';

export abstract class UserData {
  /**
   * Récupère les informations d'un Agent APA.
   *
   * @param agentId identifiant unique de l'agent à récupérer
   * @return les informations de l'agent
   */
  abstract getAgent(agentId: string): Observable<UserDto>;

  abstract getCurrentUser(): UserDto | undefined;
}

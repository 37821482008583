import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IncomingMailDto } from '../../../corbeille/models/incoming-mail.dto';
import { Router } from '@angular/router';
import { SearchGlobalService } from '../../../../shared/services/search-global.service';
import { RoutesEnum } from '../../../../shared/enums/routes.enum';
import { DocumentTypeTranslation } from '../../../../shared/mappers/document-type.mapper';
import { ApaAgentDto } from '../../../demande/models/apa-agent.dto';
import { TableMessage } from '../../../../shared/messages/table-message';
import { IncomingMailOriginEnumTranslation } from '../../../../shared/enums/incoming-mail-origin.enum';
import { LocalStorageService } from '../../../../shared/services/local-storage.service';
import { GestionCourriersEntrantsData } from '../../../courrier/data/gestion-courriers-entrants.data';
import { forkJoin, take } from 'rxjs';
import { BanetteSharingService } from '../../services/banette-sharing.service';
import { CourriersEntrantsSearchPipe } from '../../../../shared/pipes/courriers-entrants-search.pipe';
import { BannetteTypeEnum } from '../../enums/bannette-type.enum';
import { AssignedModaleTypeEnum } from '../../enums/assigned-modale-type.enum';
import { AgentTypeEnum } from '../../../../shared/enums/referentiel-person.enum';
import { ProcessingTypeEnum, TypeOfProcessEnum } from '../../../../shared/enums/referentiel-requests.enum';
import { ReferentielData } from '../../../../shared/data/referentiel-data';
import { SpecialTreatmentTypeEnum } from '../../../../shared/enums/special-treatment-type.enum';
import { EvidenceStatusEnum } from '../../../../shared/enums/referentiel-evidences.enum';
import { DocumentTypeEnum } from '../../../demande/enums/document-type.enum';
import { AttachedDocumentTypeEnum } from '../../../demande/enums/attached-document-type.enum';

/**
 * Composant responsable de l'affichage du tableau des courriers dans la banette.
 * Affiche les éléments des courriers sous forme de tableau.
 */
@Component({
  selector: 'app-banette-tableau-courriers',
  templateUrl: './banette-tableau-courriers.component.html',
  styleUrls: ['./banette-tableau-courriers.component.css'],
  providers: [CourriersEntrantsSearchPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BanetteTableauCourriersComponent implements OnInit, OnDestroy {
  /** Titre du tableau. */
  @Input() titre?: string;

  /** Les courriers à afficher dans le tableau. */
  @Input() courriers?: IncomingMailDto[];
  @Output() updateData = new EventEmitter();

  /** Liste des référents disponibles. */
  @Input() referents?: ApaAgentDto[];
  /**
   * Le nombre de resultat de rechercher.
   */
  @Output() updateCountSearch = new EventEmitter<number>();
  @Output() resetCountSearch = new EventEmitter();

  /** Enumération des types de traitement spécial. */
  protected readonly ProcessingTypeEnum = ProcessingTypeEnum;

  /** La classe Date. */
  protected readonly Date = Date;
  protected readonly BannetteTypeEnum = BannetteTypeEnum;
  protected readonly AgentTypeEnum = AgentTypeEnum;
  protected readonly AssignedModaleTypeEnum = AssignedModaleTypeEnum;

  /** Messages affichés dans le tableau. */
  public message = TableMessage;

  /** Liste del ids des emails modifiees */
  private changedEmailsIds: string[] = [];

  /** Liste del emails modifiees */
  private changedEmails: IncomingMailDto[] = [];

  /** Nombre d'éléments à afficher. */
  itemsToShow = 10;

  /** Indique si la fenêtre popup doit être affichée. */
  showPopup = false;

  /** Traductions des types de documents. */
  DocumentTypesTranslation = DocumentTypeTranslation;

  /** Types de courriers disponibles. */
  types: string[] = Object.values(TypeOfProcessEnum);

  /** Colonnes du tableau. */
  columns: string[] = ['Nom', 'Provenance', 'Type de courrier', 'Référent Administratif', "Date d'import"];

  /** Indique si toutes les lignes sont sélectionnées. */
  selectedAll = false;

  /** Élément sélectionné pour la fenêtre popup. */
  itemPop?: IncomingMailDto;

  /** Données sélectionnées dans le tableau. */
  selectedData?: any[] = [];

  /** Position et valeur de tri pour les colonnes. */
  positionValue = {
    position: 0,
    value: false,
  };

  /** Filtre de recherche. */
  filterValue = '';

  /** Types de courrier disponibles. */
  mailType: TypeOfProcessEnum[] = Object.values(TypeOfProcessEnum).filter(value => value !== TypeOfProcessEnum.UNKNOWN);

  protected readonly MailTypeEnum = TypeOfProcessEnum;

  /** Traductions des origines de courrier. */
  MailOriginTranslation = IncomingMailOriginEnumTranslation;

  isDialogOpen = false;
  currentIndex = 0;

  /**
   * Le nombre de resultat de rechercher.
   */
  public countSearch = 0;
  mailToUpdate: IncomingMailDto | undefined;

  /**
   * Constructeur du composant.
   *
   * @param {Router} router Le routeur.
   * @param {SearchGlobalService} searchServcice Le service de recherche globale.
   * @param localStorageService
   * @param gestionCourrierService
   * @param banetteSharingService
   * @param courriersEntrantsSearchPipe
   * @param referentielService
   * @param gestionCorbeilleData
   */
  constructor(
    private router: Router,
    private searchServcice: SearchGlobalService,
    private localStorageService: LocalStorageService,
    private gestionCourrierService: GestionCourriersEntrantsData,
    private banetteSharingService: BanetteSharingService,
    private courriersEntrantsSearchPipe: CourriersEntrantsSearchPipe,
    private referentielService: ReferentielData
  ) {}

  /**
   * Méthode d'initialisation du composant.
   */
  ngOnInit() {
    this.initCourrier();
  }

  initCourrier() {
    this.searchServcice.resetSearchValue();
    this.searchServcice.getSearchValue().subscribe((value) => {
      this.filterValue = value;
      /**
       * Counter de le nombre de rechechers
       */
      if (this.courriers) {
        this.countSearch = this.filterCourries(this.courriers, this.filterValue).length;
        this.updateCountSearch.emit(this.countSearch);
      }
    });
    this.banetteSharingService.getSelectedElements().subscribe((data) => {
      this.selectedData = data as IncomingMailDto[];
    });
    this.resetCountSearch.emit();
    this.banetteSharingService.getChangedEmailsIds().subscribe((data) => {
      if (data.length > 0) {
        const newIds = data.filter((id) => !this.changedEmailsIds.includes(id));
        this.changedEmailsIds.push(...newIds);
      }
    });
  }

  /**
   * Fonction pour mise à jour des courriers qui ont été modifiés quand on quitte la page
   *
   */
  ngOnDestroy() {
    this.createListOfMailsToUpdate();
    forkJoin(this.changedEmails.map((c) => this.gestionCourrierService.updateCourrier(c)))
      .pipe(take(1))
      .subscribe({
        next: () => {
          console.log('Les courriers ont été mis à jour avec succès.');
          this.banetteSharingService.setChangedEmailsIds([]);
        },
        error: (err) => {
          console.error('Erreur de mise à jour du courrier:', err);
          this.banetteSharingService.setChangedEmailsIds([]);
        },
      });

    this.banetteSharingService.setSelectedElements([]);
  }

  /**
   * Fonction pour créer la liste des courriers à mettre à jour
   *
   */
  createListOfMailsToUpdate() {
    this.changedEmails = this.changedEmailsIds
      .map((mailId) => this.courriers?.find((mail) => mail.mailId === mailId))
      .filter((mail) => mail !== undefined) as IncomingMailDto[];
  }

  /**
   * Augmente le nombre d'éléments à afficher dans le tableau
   */
  addMoreItemsToShow() {
    this.itemsToShow += 10; // Incrementa la cantidad de elementos a mostrar
  }

  /**
   * Sélectionner l'élément pour ouvrir la fenêtre POPUP
   *
   * @param {IncommingMail} item L'élément sélectionné
   */
  selectedItem(item: IncomingMailDto) {
    this.showPopup = !this.showPopup;
    this.itemPop = item;
  }

  /**
   * Sélectionner toutes les lignes du tableau
   */
  selectAllRows() {
    if (this.selectedData?.length == this.courriers?.length) {
      this.selectedData = [];
      this.selectedAll = false;
    } else {
      this.selectedData = this.courriers?.slice();
      this.selectedAll = true;
    }
    this.shareSelectedRows(this.selectedData!);
  }

  /**
   * Fonction permettant de sélectionner une ligne dans la colonne
   *
   * @param {number} index L'indice de la ligne à sélectionner
   */
  selectRow(index: number) {
    if (this.selectedData?.some(select => select.mailId == this.courriers?.[index].mailId || null)) {
      const pos = this.selectedData?.findIndex(select => select.mailId === this.courriers?.[index].mailId);
      this.selectedData?.splice(pos, 1);
      this.selectedAll = false;
    } else {
      this.selectedData?.push(this.courriers?.[index] || null);
      this.selectedAll = this.selectedData?.length == this.courriers?.length;
    }
    this.shareSelectedRows(this.selectedData!);
  }

  /**
   * Vérifier si un élément est sélectionné
   *
   * @param {number} index L'indice de l'élément sélectionné
   * @returns {boolean} True si l'élément est sélectionné, False sinon.
   */
  isSelected(index: number): boolean {
    const pos = this.selectedData?.findIndex(select => select.mailId === this.courriers?.[index].mailId);
     if(this.selectedData && pos != -1){
      if(JSON.stringify(this.selectedData?.[pos!]) !== JSON.stringify(this.courriers?.[index])){
        this.selectedData?.splice(pos!, 1);
        this.selectedData?.push(this.courriers?.[index]);
        this.shareSelectedRows(this.selectedData);
      }
     }
    return pos !== -1;
    }

  /**
   * Sauvegarde les elements sélectionnés dans le service de partage des banettes
   *
   * @param {IncomingMailDto[]} data Données des lignes sélectionnées.
   */
  shareSelectedRows(data: IncomingMailDto[]) {
    this.banetteSharingService.setSelectedElements(data);
  }

  /**
   * Trie le tableau en fonction de la colonne sélectionnée
   *
   * @param {number} index L'indice de la colonne sélectionnée
   */
  sortRows(index: number) {
    const sortOrder = (a: IncomingMailDto, b: IncomingMailDto) => {
      let fieldA: string, fieldB: string;

      switch (index) {
        case 0:
          fieldA = a.mailName;
          fieldB = b.mailName;
          break;
        case 1:
          fieldA = a.mailOriginCode;
          fieldB = b.mailOriginCode;
          break;
        case 2:
          fieldA = a.mailTypeCode;
          fieldB = b.mailTypeCode;

          break;
        case 3:
          fieldA = a.mailApaPerson.administrativeReferent?.firstName ?? '';
          fieldB = b.mailApaPerson.administrativeReferent?.firstName ?? '';
          break;
        case 4:
          fieldA = a.originCreationDate ?? '';
          fieldB = b.originCreationDate ?? '';
          break;
        default:
          return 0;
      }

      if (fieldA < fieldB) return -1;
      if (fieldA > fieldB) return 1;
      return 0;
    };

    if (this.positionValue.position === index && this.positionValue.value) {
      this.positionValue.value = false;
      this.courriers?.sort((a, b) => sortOrder(a, b));
    } else {
      this.courriers?.sort((a, b) => -sortOrder(a, b));
      this.positionValue.position = index;
      this.positionValue.value = true;
    }
    this.courriers = this.courriers?.slice();
  }

  /**
   * Fonction permettant de modifier le référent administratif pour un courrier spécifique.
   *
   * @param {any} event L'événement de changement de valeur du sélecteur.
   * @param {number} index L'indice du courrier dans la liste.
   */
  changeReferent(event: any, index: number) {
    if (event.target.value && event.target.value.trim()) {
      this.referents!.forEach((ref) => {
        if (ref.userId && ref.userId === event.target.value && this.courriers) {
          this.courriers[index].mailApaPerson.administrativeReferent = ref;
          this.courriers[index].mailApaPerson.administrativeReferent!.agentId = ref.userId;
          const idIndex = this.changedEmailsIds.indexOf(this.courriers[index].mailId) ?? -1;
          if (idIndex !== -1) this.changedEmailsIds[idIndex] = this.courriers[index].mailId;
          else this.changedEmailsIds.push(this.courriers[index].mailId);
        }
      });
    } else {
      delete this.courriers![index].mailApaPerson.administrativeReferent;
    }
  }

  /**
   * Fonction permettant de changer le type de courrier pour un courrier spécifique.
   *
   * @param event
   * @param {number} index L'indice du courrier dans la liste.
   */
  changeMailType(event: any, index: number) {
    if (this.courriers && (event as TypeOfProcessEnum)) {
      this.courriers[index].mailTypeCode = event as TypeOfProcessEnum;
      this.changedEmailsIds.push(this.courriers[index].mailId);
      const idindex = this.changedEmailsIds.indexOf(this.courriers[index].mailId) ?? -1;
      if (idindex !== -1) this.changedEmailsIds[idindex] = this.courriers[index].mailId;
      else this.changedEmailsIds.push(this.courriers[index].mailId);
    }
  }

  /**
   * Fonction permettant de modifier le type de courrier
   *
   * @param {any} event L'événement de changement de valeur du sélecteur.
   * @param {number} index L'indice du courrier dans la liste.
   */
  changeType(event: any, index: number) {
    if (this.courriers) {
      this.courriers[index].mailTypeCode = event.target.value;
    }
  }

  /**
   * Ouvre l'écran de traitement d'une demande de courrier sélectionnée.
   *
   * @param {IncommingMail} item Le courrier sélectionné pour le traitement.
   */
  openTreatDemande(item: IncomingMailDto) {
    // FIXME duplication d'information
    this.localStorageService.setObject('selectedMail', item);

    const dataToSend = [
      {
        mailId : item.mailId,
        referentAdmin: item.mailApaPerson?.administrativeReferent,
        mailOrigin: item.mailOriginCode,
        importDate: item.mailDepositDate,
        mailType: item.mailTypeCode,
        mailApaPerson: item.mailApaPerson,
        specialTreatmentType: item.specialTreatmentTypeCode,
      },
    ];
    this.searchServcice.changeData(dataToSend);
    this.router.navigate([RoutesEnum.DealWithRequest], { skipLocationChange: true });
  }

  onClickOpenAssignmentModale(index: number) {
    this.currentIndex = index;
    this.mailToUpdate = this.courriers ? this.courriers[this.currentIndex] : undefined;
    this.isDialogOpen = true;
  }

  closeAssignedPersonModale(refreshData?: boolean) {
    if (refreshData) {
      this.updateData.emit();
    }
    this.isDialogOpen = false;
  }

  updateSelectedPerson(person: ApaAgentDto) {
    if (!this.courriers) return;

    this.courriers[this.currentIndex].mailApaPerson.administrativeReferent = person;
    const idIndex = this.changedEmailsIds.indexOf(this.courriers[this.currentIndex].mailId) ?? -1;

    if (idIndex !== -1) this.changedEmailsIds[idIndex] = this.courriers[this.currentIndex].mailId;
    else this.changedEmailsIds.push(this.courriers[this.currentIndex].mailId);
  }

  /* *
   * Méthode de calcul du nombre de rechechers
   * */
  filterCourries(courriers: IncomingMailDto[], filterValue: string): IncomingMailDto[] {
    return this.courriersEntrantsSearchPipe.transform(courriers, filterValue);
  }

  findLabelEnum(codeEnum: string) {
    return this.referentielService.mapEnumItemCodeToItemLabel(codeEnum);
  }

  protected readonly SpecialTreatmentTypeEnum = SpecialTreatmentTypeEnum;
  protected readonly EvidenceStatusEnum = EvidenceStatusEnum;

  deleteCourrier(item: IncomingMailDto) {
    this.gestionCourrierService.deleteCourriers([item.mailId]).subscribe({
      next: () => {
        const pos = this.selectedData?.findIndex(select => select.mailId === item.mailId);
        if(this.selectedData && pos != -1){
          this.selectedData?.splice(pos!, 1);
          this.shareSelectedRows(this.selectedData??[]);
        }
        this.gestionCourrierService.getCourriersEntrants().subscribe((data) => {
          this.courriers = data.pageContent;
          this.initCourrier();
        });
      },
    });
  }

  castType(type: any) {
    return type as AttachedDocumentTypeEnum;
  }
  protected readonly DocumentTypeEnum = DocumentTypeEnum;
}

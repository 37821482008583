import { StorageTypeEnum } from '../enums/referentiel-evidences.enum';
import { EvidencePropertyCreationRequestDto } from './evidence-property-creation-request.dto';
import { LinkEvidenceTypeCreationRequestDto } from './link-evidence-type-creation-request.dto';
import { CnsaFile } from '../../pages/document-management/models/cnsa-file.model';

/**
 * Fonction permettant de générer un objet FormData à partir d'un objet EvidenceDto
 * @returns Objet FormData contenant les données de la preuve à télécharger
 * @param storageTypeCode - Code du type de stockage
 * @param structureCode - Identifiant unique de la structure de rattachement de la pièce
 * @param reference - Référence de la pièce
 * @param url - Url de la pièce
 * @param linkEvidenceTypeList - Types permettant de catégoriser la pièce.
 * @param evidencePropertyList - Propriété permettant d'indiquer des informations métier sur la pièce
 * @param generateThumbnail - Booleen indiquant s'il est nécessaire de générer la miniature. Vrai s'il faut la générer, faux sinon
 * @param generatePreview - Booleen indiquant s'il est nécessaire de générer la prévisualisation. Vrai s'il faut la générer, faux sinon
 */
export type EvidenceCreationRequest = {
  storageTypeCode: StorageTypeEnum;
  structureCode: string;
  reference: string;
  url: string;
  linkEvidenceTypeList: Array<LinkEvidenceTypeCreationRequestDto>;
  evidencePropertyList: Array<EvidencePropertyCreationRequestDto>;
  generateThumbnail?: boolean;
  generatePreview?: boolean;
};

export class EvidenceCreation {
  /**
   * Fonction permettant de générer un objet FormData à partir d'un objet EvidenceDto
   * @returns Objet FormData contenant les données de la preuve à télécharger
   * @param evidence {@link EvidenceCreationRequest}
   * @param file
   */
  static toUploadFile(evidence: EvidenceCreationRequest, file: CnsaFile): FormData {
    const formData = new FormData();
    const evidenceData = Object.assign({}, evidence);
    this.addGeneratePreviewAndThumbnail(evidenceData);
    formData.append('file', file);

    const jsonData = JSON.stringify(evidenceData);
    const blobEvidenceData = new Blob([jsonData], { type: 'application/json' });
    formData.append('request', blobEvidenceData);

    return formData;
  }

  /**
   * generatePreview And Thumbnail sont a true si INTERNE_StorageType
   * @param storageTypeEnum
   * @param evidenceData
   * @private
   */
  private static addGeneratePreviewAndThumbnail(evidenceData: EvidenceCreationRequest): void {
    let canGenerate = false;
    if (evidenceData.storageTypeCode === StorageTypeEnum.INTERNE_StorageType) canGenerate = true;
    evidenceData.generatePreview = canGenerate;
    evidenceData.generateThumbnail = canGenerate;
  }
}

import { Observable } from 'rxjs';
import { FilterDemande, ResponseDataDemandeDto } from '../models/response-gestion-demande.dto';
import { PageContentDto } from '../models/page-content.dto';
import {ApaRequestDto} from "../models/apa-request.dto";

/**
 * Interface représentant les données de la page de gestion des demandes.
 *
 * @export
 * @abstract
 * @class GestionDemandesData
 */
export abstract class GestionDemandesData {
  /**
   * Récupère les demandes en fonction des filtres spécifiés.
   *
   * @param filter - Filtre à appliquer pour récupérer les demandes (facultatif).
   * @returns Une Observable contenant les données de réponse des demandes
   */
  abstract getDemandes(filter?: FilterDemande): Observable<ResponseDataDemandeDto>;

  /**
   * Fonction permettant de mettre à jour une demande.
   *
   * @param demande
   * @returns {Observable<PageContentDto>} - Une Observable contenant les données de la demande mise à jour
   */
  abstract updateDemande(demande: PageContentDto): Observable<PageContentDto>;

  /**
   * Fonction permettant de récupérer una demande.
   */
  abstract getDemande(id: string): Observable<ApaRequestDto>;
}

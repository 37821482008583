import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GroupDto } from '../models/group.dto';
import { GroupData } from '../data/group.data';
import { Observable } from 'rxjs';

import { environment as envDev } from '../../../../../environments/environment';
import { environment as envProd } from '../../../../../environments/environment.prod';

/**
 * Service pour la gestion des opérations liées aux groupes.
 */
@Injectable()
export class GroupManagementService extends GroupData {
  /**
   * L'URL de l'API pour les opérations de gestion des groupes (environnement de développement).
   */
  endPoint: string;
  /**
   * L'URL de l'API complète, incluant le point de terminaison des groupes (utilisé dans l'intercommunication).
   */
  siapaIamInterRestApi: string;

  /**
   * Constructeur de la classe GroupManagementService.
   *
   * @param {HttpClient} httpClient Le service HttpClient pour effectuer des appels HTTP.
   */
  constructor(private httpClient: HttpClient) {
    super();
    if (isDevMode()) {
      this.endPoint = envDev.endPointApaUserGroups;
      this.siapaIamInterRestApi = envDev.siapaIamInterRestApi + this.endPoint;
    } else {
      this.endPoint = envProd.endPointApaUserGroups;
      this.siapaIamInterRestApi = envProd.siapaIamInterRestApi + this.endPoint;
    }
  }

  /**
   * Crée un nouveau groupe dans le système.
   *
   * @param {GroupDto} group Le groupe à créer.
   * @returns {Observable<any>} Un observable contenant la réponse de l'appel API.
   */
  createApaUserGroup(group: GroupDto): Observable<any> {
    group.attributedZipCodes = [];
    return this.httpClient.post(this.siapaIamInterRestApi, group);
  }

  /**
   * Récupère les informations d'un groupe spécifié par son ID.
   *
   * @param {string} groupId L'ID du groupe à récupérer.
   * @returns {Observable<GroupDto>} Un observable contenant les informations du groupe.
   */
  getApaUserGroup(groupId: string): Observable<GroupDto> {
    return this.httpClient.get<GroupDto>(`${this.siapaIamInterRestApi}/${groupId}`);
  }

  /**
   * Met à jour les informations d'un groupe spécifié par son ID.
   *
   * @param groupId L'ID du groupe à mettre à jour.
   * @param groupToUpdate Les nouvelles informations du groupe.
   * @returns Un observable contenant la réponse de l'appel API.
   */
  updateApaUserGroup(groupId: string, groupToUpdate: GroupDto): Observable<GroupDto> {
    return this.httpClient.put<GroupDto>(`${this.siapaIamInterRestApi}/${groupId}`, groupToUpdate);
  }

  /**
   * Supprime un groupe spécifié par son ID.
   *
   * @param groupId L'ID du groupe à supprimer.
   * @returns Un observable contenant la réponse de l'appel API.
   */
  deleteGroup(groupId: string): Observable<any> {
    return this.httpClient.delete<void>(`${this.siapaIamInterRestApi}/${groupId}`);
  }

  /**
   * Récupère la liste de tous les groupes.
   *
   * @returns Un observable contenant la liste des groupes.
   */
  getGroups(): Observable<GroupDto[]> {
    let groups: GroupDto[] = [];

    return new Observable((obs) => {
      this.httpClient.get(this.siapaIamInterRestApi).subscribe((data: any) => {
        if (data._embedded) {
          // Succès : les groupes sont récupérés
          groups = data._embedded.apaUserGroupDtoList;
          obs.next(groups);
        }
      });
    });
  }
}

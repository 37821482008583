import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { OutgoingMailDto } from '../../../courrier/models/outgoing-mail.dto';
import { DatePipe, formatDate } from '@angular/common';
import { SearchGlobalService } from '../../../../shared/services/search-global.service';
import { TableMessage } from '../../../../shared/messages/table-message';
import { DATE_LOCAL_US } from '../../../../shared/utils/globalConstants';
import { BanetteSharingService } from '../../services/banette-sharing.service';
import { BoiteSearchPipe } from '../../../../shared/pipes/boite-search.pipe';
import { BannetteTableauBoiteActions, BoiteEnvoiEnum } from './model/bannette-tableau-boite-actions.model';
import { GestionBoiteEnvoiService } from '../../../courrier/services/gestion-boite-envoi.service';

import {
  completeTypeCourrierSortant,
  imcompleteTypeCourrierSortant,
  MessageTypeEnum,
  rdvTypeCourrierSortant,
  rejetTypeCourrierSortant,
  relanceTypeCourrierSortant,
  TypeCourrierEnum,
} from '../../../../shared/enums/referentiel-communication.enum';
import { ReferentielService } from '../../../../shared/services/referentiel.service';

/**
 * Composant responsable de l'affichage du tableau de la boîte d'envoi dans la banette.
 * Affiche les éléments de la boîte d'envoi sous forme de tableau.
 */
@Component({
  selector: 'app-banette-tableau-boite',
  templateUrl: './banette-tableau-boite.component.html',
  styleUrls: ['./banette-tableau-boite.component.css'],
  providers: [BoiteSearchPipe],
})
export class BanetteTableauBoiteComponent implements OnInit, OnDestroy {
  /** Les éléments de la boîte d'envoi à afficher dans le tableau. */
  @Input() boite?: OutgoingMailDto[];
  /**
   * Le nombre de resultat de rechercher.
   */
  @Output() updateCountSearch = new EventEmitter<number>();
  @Output() resetCountSearch = new EventEmitter();
  /** Longueur du tableau (utilisé pour la pagination). */
  protected readonly length = length;

  /** Date utilisée pour l'affichage initial. */
  date = new Date('2023-01-01');

  /** Instance de DatePipe pour formater les dates. */
  datePipe = new DatePipe(DATE_LOCAL_US);

  /** Titre du tableau. */
  titre = "Boite d'envoi";

  /** Nombre d'éléments par page. */
  pageSize = 10;

  /** Numéro de la page actuelle. */
  currentPage = 1;

  /** Nombre total d'éléments. */
  totalItems? = 0;

  /** Nombre d'éléments à afficher. */
  itemsToShow = 10;

  /** Colonnes du tableau. */
  columns: string[] = ['', 'Dossier', 'Lieu de résidence', 'Type de courrier', 'Référent admin.', 'Géneré le'];

  /** Indique si toutes les lignes sont sélectionnées. */
  selectedAll = false;

  /** Données sélectionnées dans le tableau. */
  selectedData?: any[] = [];

  /** Position et valeur de tri pour les colonnes. */
  positionValue = {
    position: 0,
    value: false,
  };

  /** Valeur de filtrage. */
  filterValue = '';

  /** Messages affichés dans le tableau. */
  public message = TableMessage;

  /**
   * Le nombre de resultat de rechercher.
   */
  public countSearch = 0;

  protected model: BannetteTableauBoiteActions = {
    actions: [
      { label: BoiteEnvoiEnum.CONSULTER, lien: '' },
      { label: BoiteEnvoiEnum.TELECHARGER, lien: '' },
    ],
  };

  /**
   * Constructeur du composant.
   *
   * @param {SearchGlobalService} searchServcice Le service de recherche globale.
   * @param {string} locale La locale utilisée pour la date.
   * @param banetteSharingService
   * @param boiteSearchPipe
   * @param boiteEnvoiService
   * @param referentielService
   */
  constructor(
    private searchServcice: SearchGlobalService,
    @Inject(LOCALE_ID) private locale: string,
    private banetteSharingService: BanetteSharingService,
    private boiteSearchPipe: BoiteSearchPipe,
    private boiteEnvoiService: GestionBoiteEnvoiService,
    private referentielService: ReferentielService
  ) {}

  /** Méthode d'initialisation du composant. */
  ngOnInit() {
    this.searchServcice.resetSearchValue();
    this.searchServcice.getSearchValue().subscribe((value) => {
      this.filterValue = value;
      /**
       * Counter de le nombre de rechechers
       */
      if (this.boite) {
        this.countSearch = this.filterBoite(this.boite, this.filterValue).length;
        this.updateCountSearch.emit(this.countSearch);
      }
    });
    this.resetCountSearch.emit();
    this.banetteSharingService.getSelectedElements().subscribe((data) => {
      this.selectedData = data as OutgoingMailDto[];
    });
  }

  ngOnDestroy(): void {
    this.banetteSharingService.setSelectedElements([]);
  }

  /**
   * Augmente le nombre d'éléments à afficher dans le tableau
   */
  addMoreItemsToShow() {
    this.itemsToShow += 10;
  }

  /**
   * Sélectionner toutes les lignes du tableau
   */
  selectAllRows() {
    if (this.selectedData?.length == this.boite?.length) {
      this.selectedData = [];
      this.selectedAll = false;
    } else {
      this.selectedData = this.boite?.slice();
      this.selectedAll = true;
    }
    this.shareSelectedRows(this.selectedData!);
  }

  /**
   * Fonction permettant de sélectionner une ligne dans la colonne
   *
   * @param {number} index L'indice de la ligne à sélectionner
   */
  selectRow(index: number) {
    if (this.selectedData?.includes(this.boite?.[index] || null)) {
      this.selectedData?.splice(this.selectedData?.indexOf(this.boite?.[index] || null), 1);
      this.selectedAll = false;
    } else {
      this.selectedData?.push(this.boite?.[index] || null);
      if (this.selectedData?.length == this.boite?.length) this.selectedAll = true;
      else this.selectedAll = false;
    }
    this.shareSelectedRows(this.selectedData!);
  }

  /**
   * Sauvegarde les elements sélectionnés dans le service de partage des banettes
   *
   * @param {OutgoingMailDto[]} data Données des lignes sélectionnées.
   */
  shareSelectedRows(data: OutgoingMailDto[]) {
    this.banetteSharingService.setSelectedElements(data);
  }

  /**
   * Vérifier si un élément est sélectionné
   *
   * @param {number} index L'indice de l'élément à vérifier
   * @returns {boolean} True si l'élément est sélectionné sinon False.
   */
  isSelected(index: number) {
    return this.selectedData?.includes(this.boite?.[index] || null);
  }

  /**
   * Trie le tableau en fonction de la colonne sélectionnée
   *
   * @param {number} index L'indice de la colonne sélectionnée
   */
  sortRows(index: number) {
    const sortOrder = (a: any, b: any) => {
      let fieldA: any, fieldB: any;

      switch (index) {
        case 1:
          fieldA = a.receiverSituation.personIdentity.firstName;
          fieldB = b.receiverSituation.personIdentity.firstName;
          break;
        case 2:
          fieldA = a.receiverSituation.personCoordinates.residenceAddress?.placeName;
          fieldB = b.receiverSituation.personCoordinates.residenceAddress?.placeName;
          break;
        case 3:
          fieldA = a.mailType;
          fieldB = b.mailType;
          break;
        case 4:
          fieldA = a.receiverSituation.personIdentity.administrativeReferent?.firstName;
          fieldB = b.receiverSituation.personIdentity.administrativeReferent?.firstName;
          break;
        case 5:
          fieldA = a.generationDate;
          fieldB = b.generationDate;
          break;
        default:
          return 0;
      }

      if (fieldA < fieldB) return -1;
      if (fieldA > fieldB) return 1;
      return 0;
    };

    if (this.positionValue.position === index && this.positionValue.value) {
      this.positionValue.value = false;
      this.boite?.sort((a, b) => sortOrder(a, b));
    } else {
      this.boite?.sort((a, b) => -sortOrder(a, b));
      this.positionValue.position = index;
      this.positionValue.value = true;
    }
    this.boite = this.boite?.slice(0, this.pageSize);
    this.currentPage = 1;
    this.totalItems = this.boite?.length;
  }

  /**
   * Méthode pour formater la date d'un élément.
   *
   * @param {OutgoingMailDto} item L'élément contenant la date à formater.
   * @returns {string} La date formatée.
   */
  getDate(item: OutgoingMailDto): string {
    return item?.generationDate?.toString()
      ? formatDate(item.generationDate.toString(), 'dd/MM/yyyy', this.locale)
      : '';
  }

  getTimeSinceGeneration(item: OutgoingMailDto) {
    if (item?.generationDate) {
      const timeDifference = new Date().getTime() - item.generationDate.getTime();
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      if (hours > 24) {
        const days = Math.floor(hours / 24);
        return `Il y a ${days} jour(s)`;
      } else {
        return `Il y a ${hours} heure(s)`;
      }
    }
    return '';
  }

  isPriority(item: OutgoingMailDto) {
    if (item?.generationDate) {
      const timeDifference = new Date().getTime() - item.generationDate.getTime();
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      return hours > 48;
    }
    return false;
  }

  /* *
   * Méthode de calcul du nombre de rechechers
   * */
  filterBoite(boite: OutgoingMailDto[], filterValue: string): OutgoingMailDto[] {
    return this.boiteSearchPipe.transform(boite, filterValue);
  }

  selectAction(action: string, mail: OutgoingMailDto) {
    if (action.includes(BoiteEnvoiEnum.CONSULTER)) {
      console.error('Consulting not yet implemented');
      // @TODO Mettre en place l'ouverture de la modal de consultation dès quelle sera disponible
    } else if (action.includes(BoiteEnvoiEnum.TELECHARGER)) {
      console.log('Génération et téléchargement du pdf');
      // @TODO Mettre en place l'appel a l'api /generate dès que le backend est terminé et renvoi bien un pdf
      //this.boiteEnvoiService.downloadGeneratedPdfFromMail(mail.mailId);
    }
  }

  getNumberOfReceiver(courrier: OutgoingMailDto): number {
    let compteur = 0;
    Object.keys(courrier).forEach((_res) => {
      if (_res.includes('receiverSituation')) {
        compteur++;
      }
    });
    return compteur;
  }

  /* *
   * Méthode pour obtenir à partir de l'Enum Référentiel MessageType,
   * le MailType correspondant à afficher à l'écran
   * */
  findTypeCourrier(messageType: MessageTypeEnum): string {
    if (completeTypeCourrierSortant.includes(messageType)) {
      return TypeCourrierEnum.COMPLETUDE_TypeCourrier;
    }
    if (imcompleteTypeCourrierSortant.includes(messageType)) {
      return TypeCourrierEnum.INCOMPLETUDE_TypeCourrier;
    }
    if (relanceTypeCourrierSortant.includes(messageType)) {
      return TypeCourrierEnum.RELANCE_TypeCourrier;
    }
    if (rejetTypeCourrierSortant.includes(messageType)) {
      return TypeCourrierEnum.REJET_TypeCourrier;
    }
    if (rdvTypeCourrierSortant.includes(messageType)) {
      return TypeCourrierEnum.RDV_TypeCourrier;
    }
    return '';
  }

  mapEnumRequestStatusToLabel(value: MessageTypeEnum) {
    let label;
    if (value) {
      switch (value) {
        case MessageTypeEnum.RECEPTION_DEMANDE_COMPLETE_MessageType:
        case MessageTypeEnum.RECEPTION_REVISION_MessageType:
        case MessageTypeEnum.RECEPTION_CMI_COMPLETE_MessageType:
        case MessageTypeEnum.RECEPTION_CMI_MODIF_MessageType:
          label = 'Complétude';
          break;
        case MessageTypeEnum.RECEPTION_DEMANDE_INCOMPLETE_MessageType:
        case MessageTypeEnum.RECEPTION_CMI_INCOMPLETE_MessageType:
          label = 'Incomplétude';
          break;
        case MessageTypeEnum.RELANCE_INCOMPLET_MessageType:
          label = 'Relance';
          break;
        case MessageTypeEnum.REJET_CMI_MessageType:
        case  MessageTypeEnum.REJET_INCOMPLET_MessageType:
        case  MessageTypeEnum.REJET_ADMINISTRATIF_AGE_MessageType:
        case  MessageTypeEnum.REJET_ADMINISTRATIF_DECES_MessageType:
          label = 'Rejet';
          break;
        case MessageTypeEnum.VISITE_DOMICILE_COURRIER_MessageType:
        case MessageTypeEnum.RDV_TEL_COURRIER_MessageType:
          label = 'Rendez-vous';
          break;
      }
    }
    return label;
  }
}

<div class="notification-container" *ngIf="this.notificationService.notificationRequested$ | async as request">
  <cnsa-alert
    [variant]="request.variant"
    [closable]="request.closable"
    [duration]="request.duration"
    (cnsa-after-hide)="notificationService.closeNotification()"
    [title]="request.title"
    open>
    {{ request.message }}
  </cnsa-alert>
</div>

import { PagesRouteEnum } from '../../shared/enums/pages-route.enum';
import { UserRole } from '../../pages/home/enums/user-role.enum';

type TypeRolesAccessPages = { [key in PagesRouteEnum]: UserRole[] };

export const rolesAccessPages: TypeRolesAccessPages = {
  [PagesRouteEnum.DEMANDE]: [UserRole.COURRIER, UserRole.AUTONOMIE, UserRole.DEFAULT_ROLES_SI_APA],
  [PagesRouteEnum.RENDEZ_VOUS]: [UserRole.AUTONOMIE, UserRole.DEFAULT_ROLES_SI_APA],
  [PagesRouteEnum.COURRIER]: [UserRole.AUTONOMIE, UserRole.DEFAULT_ROLES_SI_APA],
  [PagesRouteEnum.CORBEILLE]: [UserRole.AUTONOMIE, UserRole.DEFAULT_ROLES_SI_APA],
  [PagesRouteEnum.CARD]: [UserRole.AUTONOMIE, UserRole.DEFAULT_ROLES_SI_APA],
  [PagesRouteEnum.DOCUMENT_VIEWER]: [UserRole.AUTONOMIE, UserRole.DEFAULT_ROLES_SI_APA],
  [PagesRouteEnum.GESTION_DES_GROUPES]: [UserRole.AUTONOMIE, UserRole.DEFAULT_ROLES_SI_APA],
};

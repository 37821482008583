import { Component, inject } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';

/**
 * Composant principale: en-tête
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  authService: AuthService = inject(AuthService);

  async logout(): Promise<void> {
    await this.authService.logout();
  }
}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BannetteTypeEnum } from '../enums/bannette-type.enum';
import { SearchApaAgentService } from '../../../shared/services/search-apa-agent.service';
import { ApaAgentDto } from '../../demande/models/apa-agent.dto';

/**
 * Composant générique pour afficher différentes vues de la bannette.
 */
@Component({
  selector: 'app-si-apa-banette-generique-page',
  templateUrl: './si-apa-banette-generique-page.component.html',
  styleUrls: ['./si-apa-banette-generique-page.component.css'],
})
export class SiApaBanetteGeneriquePageComponent implements OnInit {
  /** Titre de la bannette à afficher. */
  @Input() titre?: string;

  /** Données à afficher dans la bannette. */
  @Input() data?: any[];

  /** Nombre d'éléments dans la bannette. */
  @Input() count?: number;

  /** Nombre d'éléments resultat de la rechercher. */
  @Input() countSearch?: number = 0;
  @Input() showCountSearch?: boolean = false;

  /** Événement émis lors de l'ouverture de la modal de planification de rendez-vous. */
  @Output() openPlanRdvModalEvent = new EventEmitter();

  /**
   * Liste des référents administratifs.
   */
  referents: ApaAgentDto[] = [];

  /**
   * Liste des évaluateurs.
   */
  evaluateurs: ApaAgentDto[] = [];

  /**
   * refresh data
   */
  @Output() updateData = new EventEmitter();

  /**
   * Nom de la table utilisée pour afficher le type de bannette actuel.
   */
  table!: BannetteTypeEnum;

  /**
   * Indique si la fonction d'importation est activée ou désactivée.
   */
  import = false;

  /**
   * Indique si la fonction d'exportation est activée ou désactivée.
   */
  export = false;

  /** Type de la bannette, basé sur l'énumération `BannetteType`. */
  banetteType = BannetteTypeEnum;

  isDialogOpen = false;

  /**
   * Constructeur du composant.
   *
   * @param {ActivatedRoute} route Service d'accès aux paramètres de l'URL.
   * @param searchApaAgentService Service de recherche d'agent APA.
   */
  constructor(private route: ActivatedRoute, private searchApaAgentService: SearchApaAgentService) {}

  /**
   * Méthode d'initialisation du composant.
   */
  ngOnInit() {
    this.searchApaAgentService.getListVisibleEvaluators([]).subscribe((data) => {
      this.evaluateurs = data;
    });
    this.searchApaAgentService.getListVisibleReferents([]).subscribe((data) => {
      this.referents = data;
    });
    if (!this.titre) this.titre = 'demandes';
    switch (this.titre.toLowerCase()) {
      case BannetteTypeEnum.DEMANDE:
        this.table = BannetteTypeEnum.DEMANDE;
        this.titre = 'Demandes en cours';
        this.export = true;
        break;
      case BannetteTypeEnum.FORFAITSAPAENURGENCE:
        this.table = BannetteTypeEnum.FORFAITSAPAENURGENCE;
        this.titre = 'Gestion des forfaits APA en urgence';
        break;
      case BannetteTypeEnum.RENDEZVOUS:
        this.table = BannetteTypeEnum.RENDEZVOUS;
        this.titre = 'Gestion des rendez-vous';
        break;
      case BannetteTypeEnum.BOITE:
        this.table = BannetteTypeEnum.BOITE;
        this.titre = "Boite d'envoi";
        break;
      case BannetteTypeEnum.CORBEILLE:
        this.table = BannetteTypeEnum.CORBEILLE;
        this.titre = 'Corbeille';
        break;
      case BannetteTypeEnum.COURRIERS:
        this.table = BannetteTypeEnum.COURRIERS;
        this.titre = 'Courriers entrants';
        this.import = true;
        this.export = true;
        break;
    }
    if (this.data) this.count = this.data.length;
  }

  /**
   * Méthode appelée lors de l'ouverture de la modal de planification de rendez-vous.
   */
  openPlanRdvModal() {
    this.openPlanRdvModalEvent.emit();
  }

  /**
   * refresh data
   */
  refresh(): void {
    this.updateData.emit();
  }

  updateDialog(value: boolean): void {
    this.isDialogOpen = value;
  }

  updateCountSearch(value: any) {
    this.countSearch = value;
    this.showCountSearch = true;
  }

  resetCountSearch() {
    this.countSearch = 0;
    this.showCountSearch = false;
  }
}

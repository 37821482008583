export interface BannetteTableauBoiteActions {
  actions: Actions[];
}

export interface Actions {
  label: BoiteEnvoiEnum;
  lien?: string;
}

export enum BoiteEnvoiEnum {
  CONSULTER = 'Consulter',
  TELECHARGER = 'Télécharger',
}

import { CountryDtoList } from '../models/countries.dto';
import { RootRequestInterface } from '../interfaces/root-request.interface';

export const CountriesMock: RootRequestInterface<CountryDtoList> = {
  _embedded: {
    countryDtoList: [
      {
        iso3Code: 'FRO',
        label: 'FÉROÉ (ÎLES)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/FRO',
          },
        },
      },
      {
        iso3Code: 'SOM',
        label: 'SOMALIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SOM',
          },
        },
      },
      {
        iso3Code: 'IND',
        label: "RÉPUBLIQUE DE L'INDE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/IND',
          },
        },
      },
      {
        iso3Code: 'AIA',
        label: 'ANGUILLA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/AIA',
          },
        },
      },
      {
        iso3Code: 'LUX',
        label: 'GRAND-DUCHÉ DE LUXEMBOURG',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/LUX',
          },
        },
      },
      {
        iso3Code: 'BHS',
        label: 'COMMONWEALTH DES BAHAMAS',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BHS',
          },
        },
      },
      {
        iso3Code: 'ATG',
        label: 'ANTIGUA-ET-BARBUDA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ATG',
          },
        },
      },
      {
        iso3Code: 'SJM',
        label: 'SVALBARD ET ÎLE JAN MAYEN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SJM',
          },
        },
      },
      {
        iso3Code: 'FSM',
        label: 'ÉTATS FÉDÉRÉS DE MICRONÉSIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/FSM',
          },
        },
      },
      {
        iso3Code: 'MKD',
        label: 'MACÉDOINE DU NORD',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MKD',
          },
        },
      },
      {
        iso3Code: 'JOR',
        label: 'ROYAUME HACHÉMITE DE JORDANIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/JOR',
          },
        },
      },
      {
        iso3Code: 'HND',
        label: 'RÉPUBLIQUE DU HONDURAS',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/HND',
          },
        },
      },
      {
        iso3Code: 'KEN',
        label: 'RÉPUBLIQUE DU KENYA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/KEN',
          },
        },
      },
      {
        iso3Code: 'LBR',
        label: 'RÉPUBLIQUE DU LIBERIA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/LBR',
          },
        },
      },
      {
        iso3Code: 'GIN',
        label: 'RÉPUBLIQUE DE GUINÉE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GIN',
          },
        },
      },
      {
        iso3Code: 'ARE',
        label: 'ÉMIRATS ARABES UNIS',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ARE',
          },
        },
      },
      {
        iso3Code: 'GTM',
        label: 'RÉPUBLIQUE DU GUATEMALA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GTM',
          },
        },
      },
      {
        iso3Code: 'PAK',
        label: 'RÉPUBLIQUE ISLAMIQUE DU PAKISTAN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PAK',
          },
        },
      },
      {
        iso3Code: 'SLV',
        label: 'RÉPUBLIQUE DU SALVADOR',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SLV',
          },
        },
      },
      {
        iso3Code: 'GNB',
        label: 'RÉPUBLIQUE DE GUINÉE-BISSAU',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GNB',
          },
        },
      },
      {
        iso3Code: 'PRI',
        label: 'PORTO RICO',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PRI',
          },
        },
      },
      {
        iso3Code: 'JPN',
        label: 'JAPON',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/JPN',
          },
        },
      },
      {
        iso3Code: 'COG',
        label: 'RÉPUBLIQUE DU CONGO',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/COG',
          },
        },
      },
      {
        iso3Code: 'GRC',
        label: 'RÉPUBLIQUE HELLÉNIQUE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GRC',
          },
        },
      },
      {
        iso3Code: 'LVA',
        label: 'RÉPUBLIQUE DE LETTONIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/LVA',
          },
        },
      },
      {
        iso3Code: 'PCN',
        label: 'PITCAIRN (ÎLE)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PCN',
          },
        },
      },
      {
        iso3Code: 'ASM',
        label: 'SAMOA AMÉRICAINES',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ASM',
          },
        },
      },
      {
        iso3Code: 'CCK',
        label: 'COCOS OU KEELING (ÎLES)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CCK',
          },
        },
      },
      {
        iso3Code: 'SEN',
        label: 'RÉPUBLIQUE DU SÉNÉGAL',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SEN',
          },
        },
      },
      {
        iso3Code: 'BEL',
        label: 'ROYAUME DE BELGIQUE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BEL',
          },
        },
      },
      {
        iso3Code: 'VUT',
        label: 'RÉPUBLIQUE DE VANUATU',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/VUT',
          },
        },
      },
      {
        iso3Code: 'MNP',
        label: 'MARIANNES DU NORD (ÎLES)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MNP',
          },
        },
      },
      {
        iso3Code: 'FIN',
        label: 'RÉPUBLIQUE DE FINLANDE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/FIN',
          },
        },
      },
      {
        iso3Code: 'REU',
        label: 'DÉPARTEMENT DE LA RÉUNION',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/REU',
          },
        },
      },
      {
        iso3Code: 'KAZ',
        label: 'RÉPUBLIQUE DU KAZAKHSTAN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/KAZ',
          },
        },
      },
      {
        iso3Code: 'UGA',
        label: "RÉPUBLIQUE DE L'OUGANDA",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/UGA',
          },
        },
      },
      {
        iso3Code: 'MDA',
        label: 'RÉPUBLIQUE DE MOLDAVIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MDA',
          },
        },
      },
      {
        iso3Code: 'NZL',
        label: 'NOUVELLE-ZÉLANDE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/NZL',
          },
        },
      },
      {
        iso3Code: 'DEU',
        label: "RÉPUBLIQUE FÉDÉRALE D'ALLEMAGNE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/DEU',
          },
        },
      },
      {
        iso3Code: 'BLM',
        label: "COLLECTIVITÉ D'OUTRE-MER DE SAINT-BARTHÉLEMY",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BLM',
          },
        },
      },
      {
        iso3Code: 'GBR',
        label: "ROYAUME-UNI DE GRANDE-BRETAGNE ET D'IRLANDE DU NORD",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GBR',
          },
        },
      },
      {
        iso3Code: 'SHN',
        label: 'SAINTE-HÉLÈNE, ASCENSION ET TRISTAN DA CUNHA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SHN',
          },
        },
      },
      {
        iso3Code: 'MHL',
        label: 'RÉPUBLIQUE DES ÎLES MARSHALL',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MHL',
          },
        },
      },
      {
        iso3Code: 'NIC',
        label: 'RÉPUBLIQUE DU NICARAGUA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/NIC',
          },
        },
      },
      {
        iso3Code: 'PHL',
        label: 'RÉPUBLIQUE DES PHILIPPINES',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PHL',
          },
        },
      },
      {
        iso3Code: 'ATF',
        label: 'TERRES AUSTRALES FRANÇAISES',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ATF',
          },
        },
      },
      {
        iso3Code: 'AUS',
        label: 'AUSTRALIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/AUS',
          },
        },
      },
      {
        iso3Code: 'KIR',
        label: 'RÉPUBLIQUE DE KIRIBATI',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/KIR',
          },
        },
      },
      {
        iso3Code: 'OMN',
        label: "SULTANAT D'OMAN",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/OMN',
          },
        },
      },
      {
        iso3Code: 'FJI',
        label: 'RÉPUBLIQUE DES FIDJI',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/FJI',
          },
        },
      },
      {
        iso3Code: 'PSE',
        label: 'ÉTAT DE PALESTINE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PSE',
          },
        },
      },
      {
        iso3Code: 'SRB',
        label: 'RÉPUBLIQUE DE SERBIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SRB',
          },
        },
      },
      {
        iso3Code: 'ZAF',
        label: "RÉPUBLIQUE D'AFRIQUE DU SUD",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ZAF',
          },
        },
      },
      {
        iso3Code: 'NPL',
        label: 'NÉPAL',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/NPL',
          },
        },
      },
      {
        iso3Code: 'SAU',
        label: "ROYAUME D'ARABIE SAOUDITE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SAU',
          },
        },
      },
      {
        iso3Code: 'SDN',
        label: 'RÉPUBLIQUE DU SOUDAN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SDN',
          },
        },
      },
      {
        iso3Code: 'IRQ',
        label: "RÉPUBLIQUE D'IRAQ",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/IRQ',
          },
        },
      },
      {
        iso3Code: 'MMR',
        label: 'UNION DE BIRMANIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MMR',
          },
        },
      },
      {
        iso3Code: 'LTU',
        label: 'RÉPUBLIQUE DE LITUANIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/LTU',
          },
        },
      },
      {
        iso3Code: 'TZA',
        label: 'RÉPUBLIQUE UNIE DE TANZANIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TZA',
          },
        },
      },
      {
        iso3Code: 'MTQ',
        label: 'DÉPARTEMENT DE LA MARTINIQUE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MTQ',
          },
        },
      },
      {
        iso3Code: 'BRN',
        label: 'NEGARA BRUNEI DARUSSALAM',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BRN',
          },
        },
      },
      {
        iso3Code: 'MRT',
        label: 'RÉPUBLIQUE ISLAMIQUE DE MAURITANIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MRT',
          },
        },
      },
      {
        iso3Code: 'SWZ',
        label: "ROYAUME D'ESWATINI",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SWZ',
          },
        },
      },
      {
        iso3Code: 'MLI',
        label: 'RÉPUBLIQUE DU MALI',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MLI',
          },
        },
      },
      {
        iso3Code: 'ESH',
        label: 'SAHARA OCCIDENTAL',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ESH',
          },
        },
      },
      {
        iso3Code: 'PYF',
        label: 'POLYNÉSIE FRANÇAISE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PYF',
          },
        },
      },
      {
        iso3Code: 'URY',
        label: "RÉPUBLIQUE ORIENTALE DE L'URUGUAY",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/URY',
          },
        },
      },
      {
        iso3Code: 'DOM',
        label: 'RÉPUBLIQUE DOMINICAINE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/DOM',
          },
        },
      },
      {
        iso3Code: 'LSO',
        label: 'ROYAUME DU LESOTHO',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/LSO',
          },
        },
      },
      {
        iso3Code: 'RUS',
        label: 'FÉDÉRATION DE RUSSIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/RUS',
          },
        },
      },
      {
        iso3Code: 'TLS',
        label: 'RÉPUBLIQUE DÉMOCRATIQUE DU TIMOR ORIENTAL',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TLS',
          },
        },
      },
      {
        iso3Code: 'BWA',
        label: 'RÉPUBLIQUE DU BOTSWANA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BWA',
          },
        },
      },
      {
        iso3Code: 'COM',
        label: 'RÉPUBLIQUE FÉDÉRALE ISLAMIQUE DES COMORES',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/COM',
          },
        },
      },
      {
        iso3Code: 'TWN',
        label: 'TAÏWAN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TWN',
          },
        },
      },
      {
        iso3Code: 'GRD',
        label: 'GRENADE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GRD',
          },
        },
      },
      {
        iso3Code: 'GMB',
        label: 'RÉPUBLIQUE DE GAMBIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GMB',
          },
        },
      },
      {
        iso3Code: 'CMR',
        label: 'RÉPUBLIQUE DU CAMEROUN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CMR',
          },
        },
      },
      {
        iso3Code: 'LBY',
        label: 'JAMAHIRIYA ARABE LIBYENNE POPULAIRE ET SOCIALISTE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/LBY',
          },
        },
      },
      {
        iso3Code: 'ISR',
        label: "ÉTAT D'ISRAËL",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ISR',
          },
        },
      },
      {
        iso3Code: 'TTO',
        label: 'RÉPUBLIQUE DE TRINITÉ-ET-TOBAGO',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TTO',
          },
        },
      },
      {
        iso3Code: 'BTN',
        label: 'ROYAUME DU BHOUTAN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BTN',
          },
        },
      },
      {
        iso3Code: 'MAF',
        label: "COLLECTIVITÉ D'OUTRE-MER DE SAINT-MARTIN",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MAF',
          },
        },
      },
      {
        iso3Code: 'DNK',
        label: 'ROYAUME DU DANEMARK',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/DNK',
          },
        },
      },
      {
        iso3Code: 'NCL',
        label: 'NOUVELLE-CALÉDONIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/NCL',
          },
        },
      },
      {
        iso3Code: 'BLR',
        label: 'RÉPUBLIQUE DE BIÉLORUSSIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BLR',
          },
        },
      },
      {
        iso3Code: 'GUF',
        label: 'DÉPARTEMENT DE LA GUYANE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GUF',
          },
        },
      },
      {
        iso3Code: 'TCD',
        label: 'RÉPUBLIQUE DU TCHAD',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TCD',
          },
        },
      },
      {
        iso3Code: 'RWA',
        label: 'RÉPUBLIQUE RWANDAISE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/RWA',
          },
        },
      },
      {
        iso3Code: 'PLW',
        label: 'RÉPUBLIQUE DES ÎLES PALAOS',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PLW',
          },
        },
      },
      {
        iso3Code: 'SWE',
        label: 'ROYAUME DE SUÈDE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SWE',
          },
        },
      },
      {
        iso3Code: 'NGA',
        label: 'RÉPUBLIQUE FÉDÉRALE DU NIGÉRIA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/NGA',
          },
        },
      },
      {
        iso3Code: 'TUN',
        label: 'RÉPUBLIQUE TUNISIENNE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TUN',
          },
        },
      },
      {
        iso3Code: 'BDI',
        label: 'RÉPUBLIQUE DU BURUNDI',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BDI',
          },
        },
      },
      {
        iso3Code: 'HUN',
        label: 'RÉPUBLIQUE DE HONGRIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/HUN',
          },
        },
      },
      {
        iso3Code: 'PRY',
        label: 'RÉPUBLIQUE DU PARAGUAY',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PRY',
          },
        },
      },
      {
        iso3Code: 'TGO',
        label: 'RÉPUBLIQUE TOGOLAISE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TGO',
          },
        },
      },
      {
        iso3Code: 'ROU',
        label: 'ROUMANIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ROU',
          },
        },
      },
      {
        iso3Code: 'MYT',
        label: 'DÉPARTEMENT DE MAYOTTE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MYT',
          },
        },
      },
      {
        iso3Code: 'TJK',
        label: 'RÉPUBLIQUE DU TADJIKISTAN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TJK',
          },
        },
      },
      {
        iso3Code: 'CHE',
        label: 'CONFÉDÉRATION SUISSE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CHE',
          },
        },
      },
      {
        iso3Code: 'ETH',
        label: "RÉPUBLIQUE DÉMOCRATIQUE FÉDÉRALE D'ÉTHIOPIE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ETH',
          },
        },
      },
      {
        iso3Code: 'IRN',
        label: "RÉPUBLIQUE ISLAMIQUE D'IRAN",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/IRN',
          },
        },
      },
      {
        iso3Code: 'ZWE',
        label: 'RÉPUBLIQUE DU ZIMBABWE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ZWE',
          },
        },
      },
      {
        iso3Code: 'BGD',
        label: 'RÉPUBLIQUE POPULAIRE DU BANGLADESH',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BGD',
          },
        },
      },
      {
        iso3Code: 'AUT',
        label: "RÉPUBLIQUE D'AUTRICHE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/AUT',
          },
        },
      },
      {
        iso3Code: 'SLB',
        label: 'ÎLES SALOMON',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SLB',
          },
        },
      },
      {
        iso3Code: 'VGB',
        label: 'VIERGES BRITANNIQUES (ÎLES)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/VGB',
          },
        },
      },
      {
        iso3Code: 'ALB',
        label: "RÉPUBLIQUE D'ALBANIE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ALB',
          },
        },
      },
      {
        iso3Code: 'GUY',
        label: 'RÉPUBLIQUE COOPÉRATIVE DE GUYANA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GUY',
          },
        },
      },
      {
        iso3Code: 'SMR',
        label: 'RÉPUBLIQUE DE SAINT-MARIN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SMR',
          },
        },
      },
      {
        iso3Code: 'CIV',
        label: "RÉPUBLIQUE DE CÔTE D'IVOIRE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CIV',
          },
        },
      },
      {
        iso3Code: 'MCO',
        label: 'PRINCIPAUTÉ DE MONACO',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MCO',
          },
        },
      },
      {
        iso3Code: 'BES',
        label: 'BONAIRE, SAINT EUSTACHE ET SABA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BES',
          },
        },
      },
      {
        iso3Code: 'SYC',
        label: 'RÉPUBLIQUE DES SEYCHELLES',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SYC',
          },
        },
      },
      {
        iso3Code: 'DZA',
        label: 'RÉPUBLIQUE ALGÉRIENNE DÉMOCRATIQUE ET POPULAIRE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/DZA',
          },
        },
      },
      {
        iso3Code: 'ECU',
        label: "RÉPUBLIQUE DE L'ÉQUATEUR",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ECU',
          },
        },
      },
      {
        iso3Code: 'PNG',
        label: 'PAPOUASIE-NOUVELLE-GUINÉE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PNG',
          },
        },
      },
      {
        iso3Code: 'BVT',
        label: 'BOUVET (ÎLE)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BVT',
          },
        },
      },
      {
        iso3Code: 'GAB',
        label: 'RÉPUBLIQUE GABONAISE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GAB',
          },
        },
      },
      {
        iso3Code: 'SVK',
        label: 'RÉPUBLIQUE SLOVAQUE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SVK',
          },
        },
      },
      {
        iso3Code: 'SXM',
        label: 'SAINT-MARTIN (PARTIE NÉERLANDAISE)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SXM',
          },
        },
      },
      {
        iso3Code: 'BGR',
        label: 'RÉPUBLIQUE DE BULGARIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BGR',
          },
        },
      },
      {
        iso3Code: 'CPV',
        label: 'RÉPUBLIQUE DU CAP-VERT',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CPV',
          },
        },
      },
      {
        iso3Code: 'USA',
        label: "ÉTATS-UNIS D'AMÉRIQUE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/USA',
          },
        },
      },
      {
        iso3Code: 'AND',
        label: "PRINCIPAUTÉ D'ANDORRE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/AND',
          },
        },
      },
      {
        iso3Code: 'GIB',
        label: 'GIBRALTAR',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GIB',
          },
        },
      },
      {
        iso3Code: 'ITA',
        label: 'RÉPUBLIQUE ITALIENNE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ITA',
          },
        },
      },
      {
        iso3Code: 'BRB',
        label: 'BARBADE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BRB',
          },
        },
      },
      {
        iso3Code: 'COL',
        label: 'RÉPUBLIQUE DE COLOMBIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/COL',
          },
        },
      },
      {
        iso3Code: 'TUV',
        label: 'TUVALU',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TUV',
          },
        },
      },
      {
        iso3Code: 'MOZ',
        label: 'RÉPUBLIQUE DU MOZAMBIQUE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MOZ',
          },
        },
      },
      {
        iso3Code: 'AGO',
        label: "RÉPUBLIQUE D'ANGOLA",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/AGO',
          },
        },
      },
      {
        iso3Code: 'KHM',
        label: 'ROYAUME DU CAMBODGE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/KHM',
          },
        },
      },
      {
        iso3Code: 'WSM',
        label: 'ÉTAT INDÉPENDANT DES SAMOA OCCIDENTALES',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/WSM',
          },
        },
      },
      {
        iso3Code: 'THA',
        label: 'ROYAUME DE THAÏLANDE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/THA',
          },
        },
      },
      {
        iso3Code: 'MEX',
        label: 'ÉTATS-UNIS DU MEXIQUE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MEX',
          },
        },
      },
      {
        iso3Code: 'NOR',
        label: 'ROYAUME DE NORVÈGE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/NOR',
          },
        },
      },
      {
        iso3Code: 'COD',
        label: 'RÉPUBLIQUE DÉMOCRATIQUE DU CONGO',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/COD',
          },
        },
      },
      {
        iso3Code: 'DMA',
        label: 'COMMONWEALTH DE DOMINIQUE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/DMA',
          },
        },
      },
      {
        iso3Code: 'GGY',
        label: 'GUERNESEY',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GGY',
          },
        },
      },
      {
        iso3Code: 'IRL',
        label: 'IRLANDE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/IRL',
          },
        },
      },
      {
        iso3Code: 'SSD',
        label: 'RÉPUBLIQUE DU SOUDAN DU SUD',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SSD',
          },
        },
      },
      {
        iso3Code: 'NLD',
        label: 'ROYAUME DES PAYS-BAS',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/NLD',
          },
        },
      },
      {
        iso3Code: 'ABW',
        label: 'ARUBA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ABW',
          },
        },
      },
      {
        iso3Code: 'BIH',
        label: 'RÉPUBLIQUE DE BOSNIE-HERZÉGOVINE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BIH',
          },
        },
      },
      {
        iso3Code: 'BOL',
        label: 'ÉTAT PLURINATIONAL DE BOLIVIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BOL',
          },
        },
      },
      {
        iso3Code: 'MSR',
        label: 'MONTSERRAT',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MSR',
          },
        },
      },
      {
        iso3Code: 'CAF',
        label: 'RÉPUBLIQUE CENTRAFRICAINE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CAF',
          },
        },
      },
      {
        iso3Code: 'LCA',
        label: 'SAINTE-LUCIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/LCA',
          },
        },
      },
      {
        iso3Code: 'ESP',
        label: "ROYAUME D'ESPAGNE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ESP',
          },
        },
      },
      {
        iso3Code: 'GEO',
        label: 'RÉPUBLIQUE DE GÉORGIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GEO',
          },
        },
      },
      {
        iso3Code: 'CYM',
        label: 'CAÏMANES (ÎLES)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CYM',
          },
        },
      },
      {
        iso3Code: 'ISL',
        label: 'ISLANDE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ISL',
          },
        },
      },
      {
        iso3Code: 'VAT',
        label: 'ÉTAT DE LA CITÉ DU VATICAN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/VAT',
          },
        },
      },
      {
        iso3Code: 'COK',
        label: 'COOK (ÎLES)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/COK',
          },
        },
      },
      {
        iso3Code: 'BRA',
        label: 'RÉPUBLIQUE FÉDÉRATIVE DU BRÉSIL',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BRA',
          },
        },
      },
      {
        iso3Code: 'UZB',
        label: "RÉPUBLIQUE D'OUZBÉKISTAN",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/UZB',
          },
        },
      },
      {
        iso3Code: 'CUB',
        label: 'RÉPUBLIQUE DE CUBA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CUB',
          },
        },
      },
      {
        iso3Code: 'ERI',
        label: "ÉTAT D'ÉRYTHRÉE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ERI',
          },
        },
      },
      {
        iso3Code: 'GUM',
        label: 'GUAM',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GUM',
          },
        },
      },
      {
        iso3Code: 'PAN',
        label: 'RÉPUBLIQUE DU PANAMA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PAN',
          },
        },
      },
      {
        iso3Code: 'MYS',
        label: 'MALAISIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MYS',
          },
        },
      },
      {
        iso3Code: 'ZMB',
        label: 'RÉPUBLIQUE DE ZAMBIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ZMB',
          },
        },
      },
      {
        iso3Code: 'LIE',
        label: 'PRINCIPAUTÉ DE LIECHTENSTEIN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/LIE',
          },
        },
      },
      {
        iso3Code: 'IDN',
        label: "RÉPUBLIQUE D'INDONÉSIE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/IDN',
          },
        },
      },
      {
        iso3Code: 'FRA',
        label: 'RÉPUBLIQUE FRANÇAISE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/FRA',
          },
        },
      },
      {
        iso3Code: 'SGS',
        label: 'GÉORGIE DU SUD ET LES ÎLES SANDWICH DU SUD',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SGS',
          },
        },
      },
      {
        iso3Code: 'ARM',
        label: "RÉPUBLIQUE D'ARMÉNIE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ARM',
          },
        },
      },
      {
        iso3Code: 'MDV',
        label: 'RÉPUBLIQUE DES MALDIVES',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MDV',
          },
        },
      },
      {
        iso3Code: 'CYP',
        label: 'RÉPUBLIQUE DE CHYPRE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CYP',
          },
        },
      },
      {
        iso3Code: 'TUR',
        label: 'RÉPUBLIQUE TURQUE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TUR',
          },
        },
      },
      {
        iso3Code: 'SLE',
        label: 'RÉPUBLIQUE DE SIERRA LEONE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SLE',
          },
        },
      },
      {
        iso3Code: 'BEN',
        label: 'RÉPUBLIQUE DU BÉNIN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BEN',
          },
        },
      },
      {
        iso3Code: 'EST',
        label: "RÉPUBLIQUE D'ESTONIE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/EST',
          },
        },
      },
      {
        iso3Code: 'SGP',
        label: 'RÉPUBLIQUE DE SINGAPOUR',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SGP',
          },
        },
      },
      {
        iso3Code: 'FLK',
        label: 'MALOUINES, OU FALKLAND (ÎLES)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/FLK',
          },
        },
      },
      {
        iso3Code: 'JEY',
        label: 'JERSEY',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/JEY',
          },
        },
      },
      {
        iso3Code: 'NER',
        label: 'RÉPUBLIQUE DU NIGER',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/NER',
          },
        },
      },
      {
        iso3Code: 'CRI',
        label: 'RÉPUBLIQUE DU COSTA RICA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CRI',
          },
        },
      },
      {
        iso3Code: 'JAM',
        label: 'JAMAÏQUE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/JAM',
          },
        },
      },
      {
        iso3Code: 'TKM',
        label: 'TURKMÉNISTAN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TKM',
          },
        },
      },
      {
        iso3Code: 'LKA',
        label: 'RÉPUBLIQUE DÉMOCRATIQUE SOCIALISTE DU SRI LANKA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/LKA',
          },
        },
      },
      {
        iso3Code: 'LAO',
        label: 'RÉPUBLIQUE DÉMOCRATIQUE POPULAIRE LAO',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/LAO',
          },
        },
      },
      {
        iso3Code: 'GRL',
        label: 'GROENLAND',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GRL',
          },
        },
      },
      {
        iso3Code: 'AZE',
        label: 'RÉPUBLIQUE AZERBAÏDJANAISE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/AZE',
          },
        },
      },
      {
        iso3Code: 'KWT',
        label: 'ÉTAT DU KOWEÏT',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/KWT',
          },
        },
      },
      {
        iso3Code: 'SYR',
        label: 'RÉPUBLIQUE ARABE SYRIENNE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SYR',
          },
        },
      },
      {
        iso3Code: 'MNG',
        label: 'MONGOLIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MNG',
          },
        },
      },
      {
        iso3Code: 'TKL',
        label: 'TOKELAU',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TKL',
          },
        },
      },
      {
        iso3Code: 'HRV',
        label: 'RÉPUBLIQUE DE CROATIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/HRV',
          },
        },
      },
      {
        iso3Code: 'SUR',
        label: 'RÉPUBLIQUE DU SURINAME',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SUR',
          },
        },
      },
      {
        iso3Code: 'PRK',
        label: 'RÉPUBLIQUE POPULAIRE DÉMOCRATIQUE DE CORÉE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PRK',
          },
        },
      },
      {
        iso3Code: 'VIR',
        label: 'VIERGES DES ÉTATS-UNIS (ÎLES)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/VIR',
          },
        },
      },
      {
        iso3Code: 'KOR',
        label: 'RÉPUBLIQUE DE CORÉE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/KOR',
          },
        },
      },
      {
        iso3Code: 'BHR',
        label: 'ÉTAT DE BAHREÏN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BHR',
          },
        },
      },
      {
        iso3Code: 'MWI',
        label: 'RÉPUBLIQUE DU MALAWI',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MWI',
          },
        },
      },
      {
        iso3Code: 'TON',
        label: 'ROYAUME DES TONGA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TON',
          },
        },
      },
      {
        iso3Code: 'ARG',
        label: 'RÉPUBLIQUE ARGENTINE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/ARG',
          },
        },
      },
      {
        iso3Code: 'CHN',
        label: 'RÉPUBLIQUE POPULAIRE DE CHINE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CHN',
          },
        },
      },
      {
        iso3Code: 'YEM',
        label: 'RÉPUBLIQUE DU YÉMEN',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/YEM',
          },
        },
      },
      {
        iso3Code: 'CHL',
        label: 'RÉPUBLIQUE DU CHILI',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CHL',
          },
        },
      },
      {
        iso3Code: 'GNQ',
        label: 'RÉPUBLIQUE DE GUINÉE ÉQUATORIALE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GNQ',
          },
        },
      },
      {
        iso3Code: 'IMN',
        label: 'MAN (ÎLE)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/IMN',
          },
        },
      },
      {
        iso3Code: 'LBN',
        label: 'RÉPUBLIQUE LIBANAISE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/LBN',
          },
        },
      },
      {
        iso3Code: 'TCA',
        label: 'TURKS ET CAÏQUES (ÎLES)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/TCA',
          },
        },
      },
      {
        iso3Code: 'EGY',
        label: "RÉPUBLIQUE ARABE D'ÉGYPTE",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/EGY',
          },
        },
      },
      {
        iso3Code: 'CZE',
        label: 'RÉPUBLIQUE TCHÈQUE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CZE',
          },
        },
      },
      {
        iso3Code: 'NIU',
        label: 'NIUE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/NIU',
          },
        },
      },
      {
        iso3Code: 'AFG',
        label: "ÉTAT ISLAMIQUE D'AFGHANISTAN",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/AFG',
          },
        },
      },
      {
        iso3Code: 'WLF',
        label: 'WALLIS-ET-FUTUNA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/WLF',
          },
        },
      },
      {
        iso3Code: 'VCT',
        label: 'SAINT-VINCENT-ET-LES GRENADINES',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/VCT',
          },
        },
      },
      {
        iso3Code: 'MUS',
        label: 'RÉPUBLIQUE DE MAURICE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MUS',
          },
        },
      },
      {
        iso3Code: 'QAT',
        label: 'ÉTAT DU QATAR',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/QAT',
          },
        },
      },
      {
        iso3Code: 'NAM',
        label: 'RÉPUBLIQUE DE NAMIBIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/NAM',
          },
        },
      },
      {
        iso3Code: 'CUW',
        label: 'CURAÇAO',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CUW',
          },
        },
      },
      {
        iso3Code: 'DJI',
        label: 'RÉPUBLIQUE DE DJIBOUTI',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/DJI',
          },
        },
      },
      {
        iso3Code: 'KGZ',
        label: 'RÉPUBLIQUE KIRGHIZE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/KGZ',
          },
        },
      },
      {
        iso3Code: 'CAN',
        label: 'CANADA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CAN',
          },
        },
      },
      {
        iso3Code: 'HMD',
        label: 'HEARD ET MACDONALD (ÎLES)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/HMD',
          },
        },
      },
      {
        iso3Code: 'IOT',
        label: "OCÉAN INDIEN (TERRITOIRE BRITANNIQUE DE L')",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/IOT',
          },
        },
      },
      {
        iso3Code: 'NRU',
        label: 'RÉPUBLIQUE DE NAURU',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/NRU',
          },
        },
      },
      {
        iso3Code: 'HTI',
        label: "RÉPUBLIQUE D'HAÏTI",
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/HTI',
          },
        },
      },
      {
        iso3Code: 'GLP',
        label: 'DÉPARTEMENT DE LA GUADELOUPE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GLP',
          },
        },
      },
      {
        iso3Code: 'PRT',
        label: 'RÉPUBLIQUE PORTUGAISE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PRT',
          },
        },
      },
      {
        iso3Code: 'STP',
        label: 'RÉPUBLIQUE DÉMOCRATIQUE DE SAO TOMÉ-ET-PRINCIPE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/STP',
          },
        },
      },
      {
        iso3Code: 'KNA',
        label: 'FÉDÉRATION DE SAINT-CHRISTOPHE-ET-NIÉVÈS',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/KNA',
          },
        },
      },
      {
        iso3Code: 'NFK',
        label: 'NORFOLK (ÎLE)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/NFK',
          },
        },
      },
      {
        iso3Code: 'GHA',
        label: 'RÉPUBLIQUE DU GHANA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/GHA',
          },
        },
      },
      {
        iso3Code: 'UKR',
        label: 'UKRAINE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/UKR',
          },
        },
      },
      {
        iso3Code: 'MAR',
        label: 'ROYAUME DU MAROC',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MAR',
          },
        },
      },
      {
        iso3Code: 'VEN',
        label: 'RÉPUBLIQUE BOLIVARIENNE DU VENEZUELA',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/VEN',
          },
        },
      },
      {
        iso3Code: 'PER',
        label: 'RÉPUBLIQUE DU PÉROU',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/PER',
          },
        },
      },
      {
        iso3Code: 'BFA',
        label: 'BURKINA FASO',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BFA',
          },
        },
      },
      {
        iso3Code: 'MLT',
        label: 'RÉPUBLIQUE DE MALTE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MLT',
          },
        },
      },
      {
        iso3Code: 'MNE',
        label: 'RÉPUBLIQUE DU MONTÉNÉGRO',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MNE',
          },
        },
      },
      {
        iso3Code: 'CXR',
        label: 'CHRISTMAS (ÎLE)',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/CXR',
          },
        },
      },
      {
        iso3Code: 'BMU',
        label: 'BERMUDES',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BMU',
          },
        },
      },
      {
        iso3Code: 'VNM',
        label: 'RÉPUBLIQUE SOCIALISTE DU VIÊT NAM',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/VNM',
          },
        },
      },
      {
        iso3Code: 'SPM',
        label: 'COLLECTIVITÉ TERRITORIALE DE SAINT-PIERRE-ET-MIQUELON',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SPM',
          },
        },
      },
      {
        iso3Code: 'MDG',
        label: 'RÉPUBLIQUE DE MADAGASCAR',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/MDG',
          },
        },
      },
      {
        iso3Code: 'POL',
        label: 'RÉPUBLIQUE DE POLOGNE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/POL',
          },
        },
      },
      {
        iso3Code: 'SVN',
        label: 'RÉPUBLIQUE DE SLOVÉNIE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/SVN',
          },
        },
      },
      {
        iso3Code: 'BLZ',
        label: 'BELIZE',
        _links: {
          self: {
            href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries/BLZ',
          },
        },
      },
    ],
  },
  _links: {
    self: {
      href: 'http://siapa-capg-dev1-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries',
    },
  },
};

/**
 * Service pour accéder au stockage local.
 *
 * @author reroux
 */
import { Injectable } from '@angular/core';

/**
 * Service pour accéder au stockage local.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /**
   * Service de gestion du stockage local basé sur l'API localStorage du navigateur.
   */
  private localStorageService;

  constructor() {
    this.localStorageService = localStorage;
  }

  /**
   * Stocke un objet dans le stockage local.
   *
   * @param {string} name Le nom de la variable à stocker.
   * @param {T} object L'objet à stocker.
   */
  setObject<T>(name: string, object: T) {
    this.localStorageService.setItem(name, JSON.stringify(object));
  }

  /**
   * Récupère un objet du stockage local.
   *
   * @param {string} name Le nom de la variable à récupérer.
   * @returns {T} L'objet récupéré ou null si l'objet n'existe pas.
   */
  getObject<T>(name: string): T | null {
    const item = this.localStorageService.getItem(name);
    return item ? (JSON.parse(item) as T) : null;
  }

  /**
   * Supprime un objet du stockage local.
   *
   * @param {string} name Le nom de la variable à supprimer.
   */
  deleteObject(name: string) {
    try {
      this.localStorageService.getItem(name)
        ? this.localStorageService.removeItem(name)
        : console.error(`The store : ${name} does not exist`);
    } catch (e) {
      console.error(e);
    }
  }
}

<!-- TODO: Ajouter aria-sort= "ascending" ou "descending" sur les headers des colonnes lorsqu'elles sont triées -->
<div class="container">
  <cnsa-visually-hidden id="banette-helper"
    >, les entêtes de colonnes avec bouton peuvent être triés.</cnsa-visually-hidden
  >
  <table class="banette" aria-describedby="banette-title banette-helper">
    <thead class="banette__header">
      <tr>
        <th scope="col">
          <div class="banette__header-cell">
            <cnsa-checkbox
              label="Sélectionner toutes les lignes"
              [(ngModel)]="selectedAll"
              ngDefaultControl
              (cnsa-change)="selectAllRows()"></cnsa-checkbox>
          </div>
        </th>
        <th scope="col" *ngFor="let column of columns; let x = index">
          <button class="banette__header-cell banette__header-cell--button" (click)="sortRows(x)">
            {{ column }}
            <cnsa-icon
              name="unfold_more"
              class="banette__sort-icon"
              aria-hidden="true"
              *ngIf="column !== ''"></cnsa-icon>
          </button>
        </th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="boite">
        <tr
          *ngFor="let item of boite.slice(0, itemsToShow) | boiteSearch : filterValue; let i = index"
          [ngClass]="isSelected(i) ? 'selected-background' : ''">
          <td><cnsa-checkbox size="small" [checked]="isSelected(i)" (cnsa-change)="selectRow(i)"></cnsa-checkbox></td>
          <td class="header__destinataire">
            <span>{{ getNumberOfReceiver(item) }}</span>
            <cnsa-icon name="email"></cnsa-icon>
          </td>
          <td>
            <div class="container-dossier">
              <div class="cell-content">
                <p class="cell-content__primary">
                  {{
                    (item.receiverSituation && item.receiverSituation.personIdentity.lastName
                      ? item.receiverSituation.personIdentity.lastName
                      : ''
                    ) | uppercase
                  }}
                  {{
                    item.receiverSituation && item.receiverSituation.personIdentity.firstName
                      ? item.receiverSituation?.personIdentity?.firstName
                      : ''
                  }}
                </p>
                <!-- a changer : par le lien de la page de consultation (Pas encore crée) -->
                <a href="#" class="cell-content__secondary">
                  {{ item.receiverSituation?.personIdentity?.nir ? item.receiverSituation?.personIdentity?.nir : '' }}
                </a>
              </div>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <p class="cell-content__primary">
                {{
                  item.receiverSituation && item.receiverSituation.personCoordinates?.residenceAddress?.placeName
                    ? item.receiverSituation?.personCoordinates?.residenceAddress?.placeName
                    : ''
                }}
              </p>
              <p class="cell-content__secondary decoration_none">
                {{
                  item.receiverSituation && item.receiverSituation?.personCoordinates?.residenceAddress?.zipCode
                    ? item.receiverSituation?.personCoordinates?.residenceAddress?.zipCode
                    : ''
                }}
              </p>
            </div>
          </td>
          <td>
            <cnsa-button slot="trigger" variant="secondary" size="small">{{
              mapEnumRequestStatusToLabel(item.mailTypeCode)
            }}</cnsa-button>
          </td>
          <td>
            {{
              (item.receiverSituation && item.receiverSituation?.personIdentity?.administrativeReferent?.lastName
                ? item.receiverSituation?.personIdentity?.administrativeReferent?.lastName
                : ''
              ) | uppercase
            }}
            {{
              item.receiverSituation && item.receiverSituation?.personIdentity?.administrativeReferent?.firstName
                ? item.receiverSituation?.personIdentity?.administrativeReferent?.firstName
                : ''
            }}
          </td>
          <td>
            <div class="cell-content">
              <p class="cell-content__primary">{{ getDate(item) }}</p>
              <p class="cell-content__secondary">{{ getTimeSinceGeneration(item) }}</p>
            </div>
            <cnsa-icon
              *ngIf="isPriority(item)"
              name="priority_high"
              [ngClass]="{ 'icon__priority-high': isPriority(item) }"></cnsa-icon>
          </td>
          <td><cnsa-button size="small" variant="tertiary">Imprimer</cnsa-button></td>
          <td>
            <cnsa-dropdown placement="left-end">
              <cnsa-icon-button name="more_vert" label="Actions sur le courrier" slot="trigger"></cnsa-icon-button>
              <cnsa-menu>
                <cnsa-menu-item
                  *ngFor="let action of model.actions"
                  [value]="action.label"
                  (click)="selectAction(action.label, item)">
                  {{ action.label }}
                </cnsa-menu-item>
              </cnsa-menu>
            </cnsa-dropdown>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <span class="table-label" *ngIf="boite && (boite.slice(0, itemsToShow) | boiteSearch : filterValue).length === 0">
    <p class="bold-text">{{ message.noResult }}</p>
    <br />
    <p>{{ message.emptySearch }}</p>
  </span>
  <div class="container-button">
    <cnsa-button
      *ngIf="boite!.length > itemsToShow && (boite! | boiteSearch : filterValue).length > itemsToShow"
      variant="primary"
      (click)="addMoreItemsToShow()">
      <cnsa-icon name="downloading" slot="prefix"></cnsa-icon> Afficher plus de résultats
    </cnsa-button>
  </div>
</div>

import { AppointmentDto } from 'src/app/modules/pages/rendez-vous/models/appointment.dto';

/**
 * Interface représentant un élément d'une liste d'actions pour un rendez-vous.
 */
export interface MenuListActionRdv {
  /**
   * Libellé de l'action.
   */
  label: LabelMenuListActionRdv;

  /**
   * Lien associé à l'action.
   */
  link: string;

  action: (rdv: AppointmentDto) => void;
}

/**
 * Énumération des libellés possibles pour les actions d'une liste de rendez-vous.
 */
export enum LabelMenuListActionRdv {
  MODIFIER_LE_RENDEZ_VOUS = 'Modifier le rendez-vous',
  ANNULER_LE_RENDEZ_VOUS = 'Annuler le rendez-vous ',
  EDITER_LE_SMS_DE_RAPPEL = 'Editer le sms de rappel',
  EDITER_L_EMAIL_DE_RAPPEL = 'Editer l’email de rappel',
  MODIFIER_LA_CARACTERISATION = 'Modifier la caractérisation',
}

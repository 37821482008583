<ng-container>
  <cnsa-dialog class="dialog" [label]="labelDialog" [open]="dialogIsOpen" (cnsa-request-close)="annuler()" #dialog>
    <cnsa-input
      class="custom-input"
      label=""
      type="search"
      help-text=""
      placeholder="Rechercher"
      error-message="Texte d'erreur"
      (input)="onSearchQueryChange($event)"
      type="search"></cnsa-input>
    <cnsa-checkbox
      *ngIf="dialogType === AssignedModaleTypeEnum.SEARCH"
      (cnsa-change)="changeIsAffected()"
      [value]="isNotAffected"
      style="margin: 10px 0"
      size="medium"
      defaultchecked="false"
      error-message="Texte d'erreur"
      >Non-affecté</cnsa-checkbox
    >
    <cnsa-tab-group activation="auto">
      <cnsa-tab slot="nav" panel="personnes" active="">Personnes ({{ personnes.length }})</cnsa-tab>
      <cnsa-tab slot="nav" panel="groupes">Groupes ({{ groupes.length }})</cnsa-tab>

      <!-- radio personnes -->
      <cnsa-tab-panel name="personnes" active>
        <fieldset class="personne-item">
          <legend class="visually-hidden">Personnes</legend>
          <cnsa-radio-group class="list" deselectable (cnsa-change)="selectionPerson($event)">
            <cnsa-radio [value]="item" class="item" *ngFor="let item of orderBy(ListTypeModaleAssignerEnum.PERSONNES)">
              {{ item.lastName.toUpperCase() }} {{ item.firstName }}
            </cnsa-radio>
          </cnsa-radio-group>
        </fieldset>
      </cnsa-tab-panel>
      <!-- radio groupes -->
      <cnsa-tab-panel name="groupes">
        <fieldset>
          <legend class="visually-hidden">Groupes</legend>
          <cnsa-radio-group deselectable="" class="list">
            <cnsa-tree selection="single">
              <cnsa-tree-item *ngFor="let group of orderBy(ListTypeModaleAssignerEnum.GROUPES)" multiple="false">
                <cnsa-radio [value]="group">{{ group.groupName }}</cnsa-radio>
                <ng-container
                  *ngFor="let member of orderBy(ListTypeModaleAssignerEnum.GROUPES_PERSONNES, group.members)">
                  <cnsa-tree-item>
                    <cnsa-radio [value]="member">{{ member.lastName.toUpperCase() }} {{ member.firstName }}</cnsa-radio>
                  </cnsa-tree-item>
                </ng-container>
              </cnsa-tree-item>
            </cnsa-tree>
          </cnsa-radio-group>
        </fieldset>
      </cnsa-tab-panel>
    </cnsa-tab-group>
    <!-- buttons -->
    <cnsa-button slot="footer" variant="navigation" (click)="annuler()">Annuler</cnsa-button>
    <cnsa-button *ngIf="currentUserCanBeAssigned" slot="footer" variant="tertiary" (click)="meAssigner()"
      >Me l'assigner</cnsa-button
    >
    <cnsa-button slot="footer" variant="primary" (click)="validerAssignation()">Valider</cnsa-button>
  </cnsa-dialog>
</ng-container>

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApaAgentDto } from '../../pages/demande/models/apa-agent.dto';
import { searchApaAgentData } from '../data/search-apa-agent.data';
import { ReferentsMock } from './referents-mock';
import { EvaluatorsMock } from './evaluators-mock';
import { ApaUserProfileEnum } from '../enums/apa-user-profile.enum';

/**
 * Mock service pour la recherche des agents APA
 *
 * @export
 * @class SearchApaAgentsMockService
 * @extends {SearchApaAgentsService}
 */
@Injectable({
  providedIn: 'root',
})
export class SearchApaAgentsMockService extends searchApaAgentData {
  /**
   * Liste simulée des référents APA.
   */
  referents: ApaAgentDto[] = ReferentsMock;
  /**
   * Liste simulée des évaluateurs APA.
   */
  evaluators: ApaAgentDto[] = EvaluatorsMock;

  /**
   * Recherche des agents APA
   *
   * @param profile
   * @returns {Observable<ApaAgentDto[]>}
   */
  searchApaAgents(profile: string): Observable<ApaAgentDto[]> {
    switch (profile) {
      case ApaUserProfileEnum.ADMINISTRATIVE:
        return of(this.referents);
      case ApaUserProfileEnum.EVALUATION:
        return of(this.evaluators);
      default:
        return of([]);
    }
  }

  /**
   * Recherche des agents APA (Referents)
   *
   * @param ids
   * @returns {Observable<ApaAgentDto[]>}
   */
  getListVisibleReferents(ids: string[]): Observable<ApaAgentDto[]> {
    return of(this.referents);
  }

  /**
   * Recherche des agents APA (Evaluators)
   *
   * @param ids
   * @returns {Observable<ApaAgentDto[]>}
   */
  getListVisibleEvaluators(ids: string[]): Observable<ApaAgentDto[]> {
    return of(this.evaluators);
  }
}

import { ApaUserGroupProfile } from '../models/apa-user-group-profile';
import { GroupDto } from '../models/group.dto';
import { UserImplements } from '../models/user.model';
import { UserRole } from '../../home/enums/user-role.enum';

const user1: UserImplements = {
  userId: '3',
  profile: UserRole.AUTONOMIE,
  firstName: 'Jérome',
  lastName: 'Doucinard',
  departmentNumber: '84000',
  departmentName: '8400',
};

const user2: UserImplements = {
  userId: '2',
  profile: UserRole.AUTONOMIE,
  firstName: 'Lucie',
  lastName: 'Laboulangère',
  departmentNumber: '4000',
  departmentName: '400',
};

export const group1: GroupDto = {
  groupId: '1',
  groupName: 'A',
  description: 'desc2',
  profile: ApaUserGroupProfile.EFFECTIVENESS_AND_PAYMENT,
  attributedZipCodes: ['100000', '81000'],
  members: [user1, user2],
};

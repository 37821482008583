/**
 * Enumération représentant l'origine d'un courrier entrant.
 */
// TODO #referentiel à supprimer quand debouchonnage referentiel fini -> nouveaux fichier referentiel-requests.enum.ts

export enum IncomingMailOriginEnum {
  /**
   * Origine : TS DAA.
   */
  TS_DAA = 'TS_DAA',

  /**
   * Origine inconnue.
   */
  UNKNOWN = 'UNKNOWN',
}

/**
 * Traductions pour l'énumération IncomingMailOriginEnum.
 */
export const IncomingMailOriginEnumTranslation = {
  [IncomingMailOriginEnum.TS_DAA]: 'TS DAA',
  [IncomingMailOriginEnum.UNKNOWN]: 'Inconnu',
};

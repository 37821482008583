import { RootRequestInterface } from '../../shared/interfaces/root-request.interface';
import { EnumItemDtoList } from '../../shared/models/enum-item.dto';

export const EnumData: RootRequestInterface<EnumItemDtoList> = {
  _embedded: {
    enumItemDtoList: [
      {
        enumItemCode: 'ASH-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: "Aide Sociale à l'Hébergement",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ASH-Benefit',
          },
        },
      },
      {
        enumItemCode: 'MANDATAIRE-LinkedPersonRole',
        enumItemType: 'LinkedPersonRole',
        enumItemLabel: 'Mandataire',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/MANDATAIRE-LinkedPersonRole',
          },
        },
      },
      {
        enumItemCode: 'AMAS-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: "Aide à domicile au titre de l'aide sociale départementale",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/AMAS-Benefit',
          },
        },
      },
      {
        enumItemCode: 'REVISION-EvidenceType\t',
        enumItemType: 'EvidenceType',
        enumItemLabel: 'Courrier de demande de révision',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/REVISION-EvidenceType%09',
          },
        },
      },
      {
        enumItemCode: 'DEGRADATION-CriticalityReason',
        enumItemType: 'CriticalityReason',
        enumItemLabel: "Dégradation de l'état de santé ou de l'autonomie",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DEGRADATION-CriticalityReason',
          },
        },
      },
      {
        enumItemCode: 'REPIT-PersonEventType',
        enumItemType: 'PersonEventType',
        enumItemLabel: 'Aide au répit',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/REPIT-PersonEventType',
          },
        },
      },
      {
        enumItemCode: 'RECEPTION_CMI_COMPLETE-MessageType',
        enumItemType: 'MessageType',
        enumItemLabel: 'Accusé de réception - Demande CMI complète',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RECEPTION_CMI_COMPLETE-MessageType',
          },
        },
      },
      {
        enumItemCode: 'SOCIALE-RelationshipCategory',
        enumItemType: 'RelationshipCategory',
        enumItemLabel: 'Relation sociale',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/SOCIALE-RelationshipCategory',
          },
        },
      },
      {
        enumItemCode: 'REFERENT-RelationshipType',
        enumItemType: 'RelationshipType',
        enumItemLabel: 'Référent',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/REFERENT-RelationshipType',
          },
        },
      },
      {
        enumItemCode: 'NON_APPLICABLE-Sex',
        enumItemType: 'Sex',
        enumItemLabel: 'Non applicable',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/NON_APPLICABLE-Sex',
          },
        },
      },
      {
        enumItemCode: 'QUOTIDIEN-BenefitFrequency',
        enumItemType: 'BenefitFrequency',
        enumItemLabel: 'Quotidienne',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/QUOTIDIEN-BenefitFrequency',
          },
        },
      },
      {
        enumItemCode: 'EN_SOMMEIL-PersonStatus',
        enumItemType: 'PersonStatus',
        enumItemLabel: 'En sommeil',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/EN_SOMMEIL-PersonStatus',
          },
        },
      },
      {
        enumItemCode: 'AIDANT-LinkedPersonRole',
        enumItemType: 'LinkedPersonRole',
        enumItemLabel: 'Aidant',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/AIDANT-LinkedPersonRole',
          },
        },
      },
      {
        enumItemCode: 'IDENTITE-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: "Justificatif d'identité",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/IDENTITE-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'PROCHE-LinkedPersonRole',
        enumItemType: 'LinkedPersonRole',
        enumItemLabel: 'Proche à contacter',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/PROCHE-LinkedPersonRole',
          },
        },
      },
      {
        enumItemCode: 'TELEPHONE-CommunicationChannel',
        enumItemType: 'CommunicationChannel',
        enumItemLabel: 'Téléphone',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/TELEPHONE-CommunicationChannel',
          },
        },
      },
      {
        enumItemCode: 'REVISION-PersonEventType',
        enumItemType: 'PersonEventType',
        enumItemLabel: 'Révision demande',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/REVISION-PersonEventType',
          },
        },
      },
      {
        enumItemCode: 'RENONCEMENT-PersonEventType',
        enumItemType: 'PersonEventType',
        enumItemLabel: "Renoncement à l'aide",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RENONCEMENT-PersonEventType',
          },
        },
      },
      {
        enumItemCode: 'MONSIEUR-Civility',
        enumItemType: 'Civility',
        enumItemLabel: 'Monsieur',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/MONSIEUR-Civility',
          },
        },
      },
      {
        enumItemCode: 'DOSSIER_DEMANDE-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: 'Dossier de demande',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DOSSIER_DEMANDE-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'A_METTRE_EN_PLACE-BenefitPaymentStatus',
        enumItemType: 'BenefitPaymentStatus',
        enumItemLabel: 'A mettre en place',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/A_METTRE_EN_PLACE-BenefitPaymentStatus',
          },
        },
      },
      {
        enumItemCode: 'DEMANDEUR-PersonStatus',
        enumItemType: 'PersonStatus',
        enumItemLabel: 'Demandeur',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DEMANDEUR-PersonStatus',
          },
        },
      },
      {
        enumItemCode: 'INACTIF-PersonStatus',
        enumItemType: 'PersonStatus',
        enumItemLabel: 'Inactif',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/INACTIF-PersonStatus',
          },
        },
      },
      {
        enumItemCode: 'RELANCE_CMI_INCOMPLETE-MessageType',
        enumItemType: 'MessageType',
        enumItemLabel: 'Courrier de relance - Demande CMI incomplète',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RELANCE_CMI_INCOMPLETE-MessageType',
          },
        },
      },
      {
        enumItemCode: 'IMPOSITION_DEMANDEUR-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: "Avis d'imposition ou de non imposition demandeur",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/IMPOSITION_DEMANDEUR-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'TAXE_FONCIERE-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: 'Avis de taxe foncière',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/TAXE_FONCIERE-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'AMI-RelationshipType',
        enumItemType: 'RelationshipType',
        enumItemLabel: 'Ami',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/AMI-RelationshipType',
          },
        },
      },
      {
        enumItemCode: 'DROIT_EN_COURS-BenefitStatus',
        enumItemType: 'BenefitStatus',
        enumItemLabel: 'Droit en cours',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DROIT_EN_COURS-BenefitStatus',
          },
        },
      },
      {
        enumItemCode: 'PALIATIFS-CriticalityReason',
        enumItemType: 'CriticalityReason',
        enumItemLabel: 'Soins palliatifs',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/PALIATIFS-CriticalityReason',
          },
        },
      },
      {
        enumItemCode: 'AUTRE-CriticalityReason',
        enumItemType: 'CriticalityReason',
        enumItemLabel: 'Autre',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/AUTRE-CriticalityReason',
          },
        },
      },
      {
        enumItemCode: 'ENFANT-RelationshipType',
        enumItemType: 'RelationshipType',
        enumItemLabel: 'Enfant',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ENFANT-RelationshipType',
          },
        },
      },
      {
        enumItemCode: 'PREMIERE-RequestNature',
        enumItemType: 'RequestNature',
        enumItemLabel: 'Première demande',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/PREMIERE-RequestNature',
          },
        },
      },
      {
        enumItemCode: 'AUTRE-PersonStatus',
        enumItemType: 'PersonStatus',
        enumItemLabel: 'Autre',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/AUTRE-PersonStatus',
          },
        },
      },
      {
        enumItemCode: 'PATRIMOINE-MeasureScopeType',
        enumItemType: 'MeasureScopeType',
        enumItemLabel: 'Gestion patrimoine',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/PATRIMOINE-MeasureScopeType',
          },
        },
      },
      {
        enumItemCode: 'ART-TypeOfProperty',
        enumItemType: 'TypeOfProperty',
        enumItemLabel: "Œuvre d'art",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ART-TypeOfProperty',
          },
        },
      },
      {
        enumItemCode: 'MADAME-Civility',
        enumItemType: 'Civility',
        enumItemLabel: 'Madame',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/MADAME-Civility',
          },
        },
      },
      {
        enumItemCode: 'REVISION-RequestNature',
        enumItemType: 'RequestNature',
        enumItemLabel: "Révision à l'initiative du demandeur",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/REVISION-RequestNature',
          },
        },
      },
      {
        enumItemCode: 'BENEFICIAIRE-PersonStatus',
        enumItemType: 'PersonStatus',
        enumItemLabel: 'Bénéficiaire',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/BENEFICIAIRE-PersonStatus',
          },
        },
      },
      {
        enumItemCode: 'FIN_HOSPITALISATION-PersonEventType',
        enumItemType: 'PersonEventType',
        enumItemLabel: "Fin d'hospitalisation",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/FIN_HOSPITALISATION-PersonEventType',
          },
        },
      },
      {
        enumItemCode: 'CAISSE_RETRAITE-StructureType',
        enumItemType: 'StructureType',
        enumItemLabel: 'Caisse de retraite',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/CAISSE_RETRAITE-StructureType',
          },
        },
      },
      {
        enumItemCode: 'CLOTUREE-BenefitStatus',
        enumItemType: 'BenefitStatus',
        enumItemLabel: 'Clôturée',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/CLOTUREE-BenefitStatus',
          },
        },
      },
      {
        enumItemCode: 'NON_CERTIFEE-IdentityStatus',
        enumItemType: 'IdentityStatus',
        enumItemLabel: 'Non certifiée',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/NON_CERTIFEE-IdentityStatus',
          },
        },
      },
      {
        enumItemCode: 'REJETEE-BenefitStatus',
        enumItemType: 'BenefitStatus',
        enumItemLabel: 'Rejetée',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/REJETEE-BenefitStatus',
          },
        },
      },
      {
        enumItemCode: 'CONJOINT-RelationshipType',
        enumItemType: 'RelationshipType',
        enumItemLabel: 'Conjoint',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/CONJOINT-RelationshipType',
          },
        },
      },
      {
        enumItemCode: 'APA_D-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: 'APA D',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/APA_D-Benefit',
          },
        },
      },
      {
        enumItemCode: 'FAMILLE-RelationshipCategory',
        enumItemType: 'RelationshipCategory',
        enumItemLabel: 'Famille',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/FAMILLE-RelationshipCategory',
          },
        },
      },
      {
        enumItemCode: 'DOSSIER_INDIVIDU-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: 'Dossier individu',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DOSSIER_INDIVIDU-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'NOTIFICATION_SOCIALE-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: 'Notification action sociale (TS DAA)',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/NOTIFICATION_SOCIALE-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'REJET_ADMINISTRATIF-MessageType',
        enumItemType: 'MessageType',
        enumItemLabel: 'Courrier de rejet administratif (âge et résidence)',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/REJET_ADMINISTRATIF-MessageType',
          },
        },
      },
      {
        enumItemCode: 'MENSUEL-BenefitFrequency',
        enumItemType: 'BenefitFrequency',
        enumItemLabel: 'Mensuelle',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/MENSUEL-BenefitFrequency',
          },
        },
      },
      {
        enumItemCode: 'SUPPRIME-EvidenceStatus',
        enumItemType: 'EvidenceStatus',
        enumItemLabel: 'Supprimé',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/SUPPRIME-EvidenceStatus',
          },
        },
      },
      {
        enumItemCode: 'TBD-ContactFrequency',
        enumItemType: 'ContactFrequency',
        enumItemLabel: 'To be defined',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/TBD-ContactFrequency',
          },
        },
      },
      {
        enumItemCode: 'CONCUBINAGE-MaritalStatus',
        enumItemType: 'MaritalStatus',
        enumItemLabel: 'En concubinage',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/CONCUBINAGE-MaritalStatus',
          },
        },
      },
      {
        enumItemCode: 'INDEFINI-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: 'Indéfini',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/INDEFINI-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'RUPTURE-CriticalityReason',
        enumItemType: 'CriticalityReason',
        enumItemLabel: "Rupture brutale d'aidant ou entourage (décès...)",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RUPTURE-CriticalityReason',
          },
        },
      },
      {
        enumItemCode: 'MEDICAL-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: 'Certificat médical',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/MEDICAL-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'JUGEMENT-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: 'Jugement tutelles ou curatelle (= représentation TS DAA)',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/JUGEMENT-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'APF-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: 'Allocation de Placement Familial',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/APF-Benefit',
          },
        },
      },
      {
        enumItemCode: 'EVALUATION-ReferentType',
        enumItemType: 'ReferentType',
        enumItemLabel: 'Evaluation',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/EVALUATION-ReferentType',
          },
        },
      },
      {
        enumItemCode: 'REJET_CMI-MessageType',
        enumItemType: 'MessageType',
        enumItemLabel: 'Courrier de rejet - Demande CMI (Non bénéficiaire APA)',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/REJET_CMI-MessageType',
          },
        },
      },
      {
        enumItemCode: 'DOCUMENT-InputChannel',
        enumItemType: 'InputChannel',
        enumItemLabel: 'Papier/Email',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DOCUMENT-InputChannel',
          },
        },
      },
      {
        enumItemCode: 'PONCTUEL-BenefitFrequency',
        enumItemType: 'BenefitFrequency',
        enumItemLabel: 'Ponctuelle',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/PONCTUEL-BenefitFrequency',
          },
        },
      },
      {
        enumItemCode: 'ANNUEL-BenefitFrequency',
        enumItemType: 'BenefitFrequency',
        enumItemLabel: 'Annuelle',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ANNUEL-BenefitFrequency',
          },
        },
      },
      {
        enumItemCode: 'INDISPENSABLE-LinkedPersonRole',
        enumItemType: 'LinkedPersonRole',
        enumItemLabel: 'Aidant indispensable',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/INDISPENSABLE-LinkedPersonRole',
          },
        },
      },
      {
        enumItemCode: 'FINANCEMENT-StructureType',
        enumItemType: 'StructureType',
        enumItemLabel: 'Structure de financement',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/FINANCEMENT-StructureType',
          },
        },
      },
      {
        enumItemCode: 'MIS_EN_PLACE-BenefitPaymentStatus',
        enumItemType: 'BenefitPaymentStatus',
        enumItemLabel: 'Mis en place',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/MIS_EN_PLACE-BenefitPaymentStatus',
          },
        },
      },
      {
        enumItemCode: 'SURSIS-BenefitStatus',
        enumItemType: 'BenefitStatus',
        enumItemLabel: 'Sursis à statuer',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/SURSIS-BenefitStatus',
          },
        },
      },
      {
        enumItemCode: 'BIJOUX-TypeOfProperty',
        enumItemType: 'TypeOfProperty',
        enumItemLabel: 'Bijoux',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/BIJOUX-TypeOfProperty',
          },
        },
      },
      {
        enumItemCode: 'ACTION_SOCIALE-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: 'Action sociale interministérielle (TS DAA)',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ACTION_SOCIALE-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'DEBUT_HOSPITALISATION-PersonEventType',
        enumItemType: 'PersonEventType',
        enumItemLabel: "Début d'hospitalisation",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DEBUT_HOSPITALISATION-PersonEventType',
          },
        },
      },
      {
        enumItemCode: 'FAMILLE-AddressType',
        enumItemType: 'AddressType',
        enumItemLabel: "Domicile d'un membre de la famille du demandeur",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/FAMILLE-AddressType',
          },
        },
      },
      {
        enumItemCode: 'HEBDO-BenefitFrequency',
        enumItemType: 'BenefitFrequency',
        enumItemLabel: 'Hebdomadaire',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/HEBDO-BenefitFrequency',
          },
        },
      },
      {
        enumItemCode: 'MTP-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: 'MTP',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/MTP-Benefit',
          },
        },
      },
      {
        enumItemCode: 'HOMME-Sex',
        enumItemType: 'Sex',
        enumItemLabel: 'Homme',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/HOMME-Sex',
          },
        },
      },
      {
        enumItemCode: 'ALIMENTAIRE-CriticalityReason',
        enumItemType: 'CriticalityReason',
        enumItemLabel: 'Besoin alimentaire',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ALIMENTAIRE-CriticalityReason',
          },
        },
      },
      {
        enumItemCode: 'GECCO-GedType',
        enumItemType: 'GedType',
        enumItemLabel: 'Gecco',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/GECCO-GedType',
          },
        },
      },
      {
        enumItemCode: 'INCONNU-OccupationStatus',
        enumItemType: 'OccupationStatus',
        enumItemLabel: 'Non renseigné',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/INCONNU-OccupationStatus',
          },
        },
      },
      {
        enumItemCode: 'NON_VERIFIEE-IdentityStatus',
        enumItemType: 'IdentityStatus',
        enumItemLabel: 'Non vérifiée',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/NON_VERIFIEE-IdentityStatus',
          },
        },
      },
      {
        enumItemCode: 'ISOLEMENT-CriticalityReason',
        enumItemType: 'CriticalityReason',
        enumItemLabel: "Absence d'aidant/isolement social",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ISOLEMENT-CriticalityReason',
          },
        },
      },
      {
        enumItemCode: 'AUTRE-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: 'Autre',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/AUTRE-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'CERTIFIEE-IdentityStatus',
        enumItemType: 'IdentityStatus',
        enumItemLabel: 'Certifiée',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/CERTIFIEE-IdentityStatus',
          },
        },
      },
      {
        enumItemCode: 'HOSPITALISATION-RequestNature',
        enumItemType: 'RequestNature',
        enumItemLabel: "Module relais en cas d'hospitalisation",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/HOSPITALISATION-RequestNature',
          },
        },
      },
      {
        enumItemCode: 'RNCPS-BenefitOrigin',
        enumItemType: 'BenefitOrigin',
        enumItemLabel: 'RNCPS',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RNCPS-BenefitOrigin',
          },
        },
      },
      {
        enumItemCode: 'DEMENAGEMENT_DPT-PersonEventType',
        enumItemType: 'PersonEventType',
        enumItemLabel: 'Déménagement (dans département)',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DEMENAGEMENT_DPT-PersonEventType',
          },
        },
      },
      {
        enumItemCode: 'APA_E-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: 'APA en établissement',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/APA_E-Benefit',
          },
        },
      },
      {
        enumItemCode: 'EFFECTIVITE-ReferentType',
        enumItemType: 'ReferentType',
        enumItemLabel: 'Effectivité et paiement',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/EFFECTIVITE-ReferentType',
          },
        },
      },
      {
        enumItemCode: 'TRAITEMENT-StructureType',
        enumItemType: 'StructureType',
        enumItemLabel: 'Structure de traitement',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/TRAITEMENT-StructureType',
          },
        },
      },
      {
        enumItemCode: 'ALFRESCO-GedType',
        enumItemType: 'GedType',
        enumItemLabel: 'Alfresco',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ALFRESCO-GedType',
          },
        },
      },
      {
        enumItemCode: 'CURATELLE_SIMPLE-ProtectiveMeasureType',
        enumItemType: 'ProtectiveMeasureType',
        enumItemLabel: 'Curatelle simple',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/CURATELLE_SIMPLE-ProtectiveMeasureType',
          },
        },
      },
      {
        enumItemCode: 'CLASSOTHEQUE-StorageType',
        enumItemType: 'StorageType',
        enumItemLabel: 'Classothèque',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/CLASSOTHEQUE-StorageType',
          },
        },
      },
      {
        enumItemCode: 'DEMANDEUR-AddressType',
        enumItemType: 'AddressType',
        enumItemLabel: 'Domicile du demandeur',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DEMANDEUR-AddressType',
          },
        },
      },
      {
        enumItemCode: 'TS_DAA-BenefitOrigin',
        enumItemType: 'BenefitOrigin',
        enumItemLabel: 'TS DAA',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/TS_DAA-BenefitOrigin',
          },
        },
      },
      {
        enumItemCode: 'NON_SPECIFIE-Sex',
        enumItemType: 'Sex',
        enumItemLabel: 'Non spécifié',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/NON_SPECIFIE-Sex',
          },
        },
      },
      {
        enumItemCode: 'MULTIGEST-GedType',
        enumItemType: 'GedType',
        enumItemLabel: 'Multigest',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/MULTIGEST-GedType',
          },
        },
      },
      {
        enumItemCode: 'RETIRE-PersonStatus',
        enumItemType: 'PersonStatus',
        enumItemLabel: 'Agent retiré',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RETIRE-PersonStatus',
          },
        },
      },
      {
        enumItemCode: 'LOUE-OccupationStatus',
        enumItemType: 'OccupationStatus',
        enumItemLabel: 'Loué',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/LOUE-OccupationStatus',
          },
        },
      },
      {
        enumItemCode: 'HOSPITALISATION-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: "Courrier de module relais en cas d'hospitalisation",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/HOSPITALISATION-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'ERREUR-IdentityStatus',
        enumItemType: 'IdentityStatus',
        enumItemLabel: 'Erreur technique',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ERREUR-IdentityStatus',
          },
        },
      },
      {
        enumItemCode: 'ARCHIVE-EvidenceStatus',
        enumItemType: 'EvidenceStatus',
        enumItemLabel: 'Archivé',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ARCHIVE-EvidenceStatus',
          },
        },
      },
      {
        enumItemCode: 'AUTRE-GedType',
        enumItemType: 'GedType',
        enumItemLabel: 'Autre GED métier non supportée',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/AUTRE-GedType',
          },
        },
      },
      {
        enumItemCode: 'DECLARATION_SI_APA-BenefitOrigin',
        enumItemType: 'BenefitOrigin',
        enumItemLabel: 'Déclaration SI APA',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DECLARATION_SI_APA-BenefitOrigin',
          },
        },
      },
      {
        enumItemCode: 'AUTRE-RelationshipType',
        enumItemType: 'RelationshipType',
        enumItemLabel: 'Autre',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/AUTRE-RelationshipType',
          },
        },
      },
      {
        enumItemCode: 'TUTELLE-ProtectiveMeasureType',
        enumItemType: 'ProtectiveMeasureType',
        enumItemLabel: 'Tutelle',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/TUTELLE-ProtectiveMeasureType',
          },
        },
      },
      {
        enumItemCode: 'JUSTICE-RelationshipCategory',
        enumItemType: 'RelationshipCategory',
        enumItemLabel: 'Relation judiciaire',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/JUSTICE-RelationshipCategory',
          },
        },
      },
      {
        enumItemCode: 'TBD-ContactNature',
        enumItemType: 'ContactNature',
        enumItemLabel: 'To be defined',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/TBD-ContactNature',
          },
        },
      },
      {
        enumItemCode: 'HOSPITALISATION-CriticalityReason',
        enumItemType: 'CriticalityReason',
        enumItemLabel: "Sortie d'hospitalisation",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/HOSPITALISATION-CriticalityReason',
          },
        },
      },
      {
        enumItemCode: 'SI_AIDES_SOCIALES-BenefitOrigin',
        enumItemType: 'BenefitOrigin',
        enumItemLabel: 'SI Aides sociales',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/SI_AIDES_SOCIALES-BenefitOrigin',
          },
        },
      },
      {
        enumItemCode: 'ACCUEILLANT-AddressType',
        enumItemType: 'AddressType',
        enumItemLabel: "Domicile d'un acceuillant familial",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ACCUEILLANT-AddressType',
          },
        },
      },
      {
        enumItemCode: 'CMI_STA-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: 'CMI stationnement',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/CMI_STA-Benefit',
          },
        },
      },
      {
        enumItemCode: 'PERSONNE-MeasureScopeType',
        enumItemType: 'MeasureScopeType',
        enumItemLabel: 'Protection personne',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/PERSONNE-MeasureScopeType',
          },
        },
      },
      {
        enumItemCode: 'FAMILLIALE-ProtectiveMeasureType',
        enumItemType: 'ProtectiveMeasureType',
        enumItemLabel: 'Habilitation familiale',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/FAMILLIALE-ProtectiveMeasureType',
          },
        },
      },
      {
        enumItemCode: 'NON_LOUE-OccupationStatus',
        enumItemType: 'OccupationStatus',
        enumItemLabel: 'Non loué',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/NON_LOUE-OccupationStatus',
          },
        },
      },
      {
        enumItemCode: 'PROFESSIONNEL-RelationshipCategory',
        enumItemType: 'RelationshipCategory',
        enumItemLabel: 'Professionnel',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/PROFESSIONNEL-RelationshipCategory',
          },
        },
      },
      {
        enumItemCode: 'RIB-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: 'RIB',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RIB-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'JUSTICE-ProtectiveMeasureType',
        enumItemType: 'ProtectiveMeasureType',
        enumItemLabel: 'Sauvegarde de justice',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/JUSTICE-ProtectiveMeasureType',
          },
        },
      },
      {
        enumItemCode: 'SUSPENDU-BenefitPaymentStatus',
        enumItemType: 'BenefitPaymentStatus',
        enumItemLabel: 'Suspendu',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/SUSPENDU-BenefitPaymentStatus',
          },
        },
      },
      {
        enumItemCode: 'PROPRIETAIRE-ApplicantStatus',
        enumItemType: 'ApplicantStatus',
        enumItemLabel: 'Propriétaire',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/PROPRIETAIRE-ApplicantStatus',
          },
        },
      },
      {
        enumItemCode: 'RENOUVELLEMENT-RequestNature',
        enumItemType: 'RequestNature',
        enumItemLabel: 'Renouvellement',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RENOUVELLEMENT-RequestNature',
          },
        },
      },
      {
        enumItemCode: 'OCCUPE-OccupationStatus',
        enumItemType: 'OccupationStatus',
        enumItemLabel: 'Occupé par le demandeur',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/OCCUPE-OccupationStatus',
          },
        },
      },
      {
        enumItemCode: 'DEMANDE-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: 'Formulaire de demande APA',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DEMANDE-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'HEBERGE-ApplicantStatus',
        enumItemType: 'ApplicantStatus',
        enumItemLabel: 'Hébergé à titre gratuit',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/HEBERGE-ApplicantStatus',
          },
        },
      },
      {
        enumItemCode: 'DISPONIBLE-EvidenceStatus',
        enumItemType: 'EvidenceStatus',
        enumItemLabel: 'Disponible',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DISPONIBLE-EvidenceStatus',
          },
        },
      },
      {
        enumItemCode: 'NOUVELLE-RequestNature',
        enumItemType: 'RequestNature',
        enumItemLabel: 'Nouvelle demande',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/NOUVELLE-RequestNature',
          },
        },
      },
      {
        enumItemCode: 'DANGER-CriticalityReason',
        enumItemType: 'CriticalityReason',
        enumItemLabel: 'Mise en danger de la personne',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DANGER-CriticalityReason',
          },
        },
      },
      {
        enumItemCode: 'LOCAITAIRE-ApplicantStatus',
        enumItemType: 'ApplicantStatus',
        enumItemLabel: 'Locataire',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/LOCAITAIRE-ApplicantStatus',
          },
        },
      },
      {
        enumItemCode: 'SUSPENDUE-BenefitStatus',
        enumItemType: 'BenefitStatus',
        enumItemLabel: 'Interrompue',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/SUSPENDUE-BenefitStatus',
          },
        },
      },
      {
        enumItemCode: 'RESIDENCE-AddressType',
        enumItemType: 'AddressType',
        enumItemLabel: "Résidence d'autonomie",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RESIDENCE-AddressType',
          },
        },
      },
      {
        enumItemCode: 'AUTRE-AddressType',
        enumItemType: 'AddressType',
        enumItemLabel: 'Autre',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/AUTRE-AddressType',
          },
        },
      },
      {
        enumItemCode: 'TS_DAA-InputChannel',
        enumItemType: 'InputChannel',
        enumItemLabel: 'TS DAA',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/TS_DAA-InputChannel',
          },
        },
      },
      {
        enumItemCode: 'PACSE-MaritalStatus',
        enumItemType: 'MaritalStatus',
        enumItemLabel: 'Pacsé',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/PACSE-MaritalStatus',
          },
        },
      },
      {
        enumItemCode: 'INTERNE-StorageType',
        enumItemType: 'StorageType',
        enumItemLabel: 'Interne',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/INTERNE-StorageType',
          },
        },
      },
      {
        enumItemCode: 'FORMULAIRE-MessageType',
        enumItemType: 'MessageType',
        enumItemLabel: 'Formulaire de demande',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/FORMULAIRE-MessageType',
          },
        },
      },
      {
        enumItemCode: 'DECES-PersonEventType',
        enumItemType: 'PersonEventType',
        enumItemLabel: 'Décès',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DECES-PersonEventType',
          },
        },
      },
      {
        enumItemCode: 'RECEPTION_DEMANDE_COMPLETE-MessageType',
        enumItemType: 'MessageType',
        enumItemLabel: 'Accusé de réception - Demande complète',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RECEPTION_DEMANDE_COMPLETE-MessageType',
          },
        },
      },
      {
        enumItemCode: 'TIERS-LinkedPersonRole',
        enumItemType: 'LinkedPersonRole',
        enumItemLabel: 'Tiers rencontrés ou contactés',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/TIERS-LinkedPersonRole',
          },
        },
      },
      {
        enumItemCode: 'COURRIER_POSTAL-CommunicationChannel',
        enumItemType: 'CommunicationChannel',
        enumItemLabel: 'Courrier postal',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/COURRIER_POSTAL-CommunicationChannel',
          },
        },
      },
      {
        enumItemCode: 'VOITURE-TypeOfProperty',
        enumItemType: 'TypeOfProperty',
        enumItemLabel: 'Voiture de luxe',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/VOITURE-TypeOfProperty',
          },
        },
      },
      {
        enumItemCode: 'NON_CONCLUANTE-IdentityStatus',
        enumItemType: 'IdentityStatus',
        enumItemLabel: 'Vérification non concluante',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/NON_CONCLUANTE-IdentityStatus',
          },
        },
      },
      {
        enumItemCode: 'EMAIL-CommunicationChannel',
        enumItemType: 'CommunicationChannel',
        enumItemLabel: 'Email',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/EMAIL-CommunicationChannel',
          },
        },
      },
      {
        enumItemCode: 'EN_TRAITEMENT-BenefitStatus',
        enumItemType: 'BenefitStatus',
        enumItemLabel: 'En traitement',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/EN_TRAITEMENT-BenefitStatus',
          },
        },
      },
      {
        enumItemCode: 'ORGANISME_PROTECTION-StructureType',
        enumItemType: 'StructureType',
        enumItemLabel: 'Organisme de protection',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ORGANISME_PROTECTION-StructureType',
          },
        },
      },
      {
        enumItemCode: 'CURATELLE_RENFORCEE-ProtectiveMeasureType',
        enumItemType: 'ProtectiveMeasureType',
        enumItemLabel: 'Curatelle renforcée',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/CURATELLE_RENFORCEE-ProtectiveMeasureType',
          },
        },
      },
      {
        enumItemCode: 'RELANCE_INCOMPLET-MessageType',
        enumItemType: 'MessageType',
        enumItemLabel: 'Courrier de relance - Demande incomplète',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RELANCE_INCOMPLET-MessageType',
          },
        },
      },
      {
        enumItemCode: 'MARIE-MaritalStatus',
        enumItemType: 'MaritalStatus',
        enumItemLabel: 'Marié',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/MARIE-MaritalStatus',
          },
        },
      },
      {
        enumItemCode: 'PCH-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: 'PCH',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/PCH-Benefit',
          },
        },
      },
      {
        enumItemCode: 'PROFESSIONNEL-LinkedPersonRole',
        enumItemType: 'LinkedPersonRole',
        enumItemLabel: 'Contact professionnel',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/PROFESSIONNEL-LinkedPersonRole',
          },
        },
      },
      {
        enumItemCode: 'AGENT-PersonStatus',
        enumItemType: 'PersonStatus',
        enumItemLabel: 'Agent',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/AGENT-PersonStatus',
          },
        },
      },
      {
        enumItemCode: 'REJET_INCOMPLET-MessageType',
        enumItemType: 'MessageType',
        enumItemLabel: 'Courrier de rejet - Demande incomplète',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/REJET_INCOMPLET-MessageType',
          },
        },
      },
      {
        enumItemCode: 'RECEPTION_REVISION-MessageType',
        enumItemType: 'MessageType',
        enumItemLabel: 'Accusé de réception - Demande révision',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RECEPTION_REVISION-MessageType',
          },
        },
      },
      {
        enumItemCode: 'IMPOSITION_CONJOINT-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: "Avis d'imposition ou de non imposition conjoint",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/IMPOSITION_CONJOINT-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'SMS-CommunicationChannel',
        enumItemType: 'CommunicationChannel',
        enumItemLabel: 'SMS',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/SMS-CommunicationChannel',
          },
        },
      },
      {
        enumItemCode: 'VEUF-MaritalStatus',
        enumItemType: 'MaritalStatus',
        enumItemLabel: 'Veuf',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/VEUF-MaritalStatus',
          },
        },
      },
      {
        enumItemCode: 'PCRTP-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: 'PCRTP',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/PCRTP-Benefit',
          },
        },
      },
      {
        enumItemCode: 'RECEPTION_DEMANDE_INCOMPLETE-MessageType',
        enumItemType: 'MessageType',
        enumItemLabel: 'Accusé de réception - Demande incomplète',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RECEPTION_DEMANDE_INCOMPLETE-MessageType',
          },
        },
      },
      {
        enumItemCode: 'AUTRE-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: 'Autre',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/AUTRE-Benefit',
          },
        },
      },
      {
        enumItemCode: 'RECEPTION_CMI_MODIF-MessageType',
        enumItemType: 'MessageType',
        enumItemLabel: 'Accusé de réception - Demande CMI modif mention',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RECEPTION_CMI_MODIF-MessageType',
          },
        },
      },
      {
        enumItemCode: 'REPAS-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: 'Aide pour le portage de repas',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/REPAS-Benefit',
          },
        },
      },
      {
        enumItemCode: 'SEPARE-MaritalStatus',
        enumItemType: 'MaritalStatus',
        enumItemLabel: 'Divorcé ou séparé',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/SEPARE-MaritalStatus',
          },
        },
      },
      {
        enumItemCode: 'MANDAT-ProtectiveMeasureType',
        enumItemType: 'ProtectiveMeasureType',
        enumItemLabel: 'Mandat de protection future',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/MANDAT-ProtectiveMeasureType',
          },
        },
      },
      {
        enumItemCode: 'ACTP-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: 'ACTP',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ACTP-Benefit',
          },
        },
      },
      {
        enumItemCode: 'ATTESTATION-EvidenceType',
        enumItemType: 'EvidenceType',
        enumItemLabel: "Attestation d'hospitalisation",
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ATTESTATION-EvidenceType',
          },
        },
      },
      {
        enumItemCode: 'RECEPTION_CMI_INCOMPLETE-MessageType',
        enumItemType: 'MessageType',
        enumItemLabel: 'Accusé de réception - Demande CMI incomplète',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/RECEPTION_CMI_INCOMPLETE-MessageType',
          },
        },
      },
      {
        enumItemCode: 'FEMME-Sex',
        enumItemType: 'Sex',
        enumItemLabel: 'Femme',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/FEMME-Sex',
          },
        },
      },
      {
        enumItemCode: 'ADMINISTRATIF-ReferentType',
        enumItemType: 'ReferentType',
        enumItemLabel: 'Administratif',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ADMINISTRATIF-ReferentType',
          },
        },
      },
      {
        enumItemCode: 'DECEDE-PersonStatus',
        enumItemType: 'PersonStatus',
        enumItemLabel: 'Décédé',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DECEDE-PersonStatus',
          },
        },
      },
      {
        enumItemCode: 'GED-StorageType',
        enumItemType: 'StorageType',
        enumItemLabel: 'GED',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/GED-StorageType',
          },
        },
      },
      {
        enumItemCode: 'VOISIN-RelationshipType',
        enumItemType: 'RelationshipType',
        enumItemLabel: 'Voisin',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/VOISIN-RelationshipType',
          },
        },
      },
      {
        enumItemCode: 'AUTRE-TypeOfProperty',
        enumItemType: 'TypeOfProperty',
        enumItemLabel: 'Autre',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/AUTRE-TypeOfProperty',
          },
        },
      },
      {
        enumItemCode: 'CELIBATAIRE-MaritalStatus',
        enumItemType: 'MaritalStatus',
        enumItemLabel: 'Célibataire',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/CELIBATAIRE-MaritalStatus',
          },
        },
      },
      {
        enumItemCode: 'CMI_PRI_INV-Benefit',
        enumItemType: 'Benefit',
        enumItemLabel: 'CMI priorité ou invalidité',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/CMI_PRI_INV-Benefit',
          },
        },
      },
      {
        enumItemCode: 'DEMENAGEMENT_HORS_DPT-PersonEventType',
        enumItemType: 'PersonEventType',
        enumItemLabel: 'Déménagement (hors département)',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/DEMENAGEMENT_HORS_DPT-PersonEventType',
          },
        },
      },
      {
        enumItemCode: 'ENTREE_ETABLISSEMENT-PersonEventType',
        enumItemType: 'PersonEventType',
        enumItemLabel: 'Entrée en établissement',
        _links: {
          self: {
            href: 'http://localhost:8080/api/v1/enum-items/ENTREE_ETABLISSEMENT-PersonEventType',
          },
        },
      },
    ],
  },
  _links: {
    self: {
      href: 'http://localhost:8080/api/v1/countries',
    },
  },
};

<cnsa-drawer
  id="leftDrawer"
  class="drawer-placement-start"
  [open]="isOpen"
  placement="start"
  (cnsa-after-hide)="onDrawerClose()">
  <app-menu (closeDialog)="onDrawerClose()" />
</cnsa-drawer>
<cnsa-button class="menu-button" (click)="handleDrawer()" variant="tertiary">
  <cnsa-icon name="list" slot="prefix"></cnsa-icon>
  Menu
</cnsa-button>

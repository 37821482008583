import { AttachedDocumentTypeEnum } from '../../demande/enums/attached-document-type.enum';
import { DocumentStatusEnum } from '../../../shared/enums/document-status.enum';
import { CivilityEnum, IdentityStatusEnum, PersonStatusEnum } from '../../../shared/enums/referentiel-person.enum';
import { ProcessingTypeEnum, TypeOfProcessEnum } from '../../../shared/enums/referentiel-requests.enum';
import { CommunicationChannelEnum } from '../../../shared/enums/referentiel-communication.enum';
import { StorageTypeEnum } from '../../../shared/enums/referentiel-evidences.enum';

/**
 * Données de test pour la corbeille des courriers entrants
 */
export const IncomingMailCorbeilleMock = [
  {
    mailId: '1342323456789',
    mailName: 'Dossier 1',
    specialTreatmentTypeCode: ProcessingTypeEnum.APA_URGENCE_ProcessingType,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-20',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: true,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Document 1',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Document 2',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Document 3',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '123454234324326789',
    mailName: 'Document 1',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-20',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-06',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '1234564444444789',
    mailName: 'Document 2',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-15',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-06',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '1234562342342789',
    mailName: 'Document 3',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-16',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-10',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '1234567232233323289',
    mailName: 'Document 4',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.REVISION_PERSONNE_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-210',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-15',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '1234556566789',
    mailName: 'Document 5',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-05-22',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-14',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '123456789555',
    mailName: 'Document 6',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-05-12',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-04-29',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '123444456789',
    mailName: 'Document 7',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.UNKNOWN,
    mailTypeCode: TypeOfProcessEnum.RENOUVELLEMENT_CMI_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-27',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '123222456789',
    mailName: 'Document 8',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-23',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '123456754453489',
    mailName: 'Document 9',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-26',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '12345444444444444444444444446789',
    mailName: 'Document 10',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.RELAIS_HOSPITALISATION_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-04-20',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '1234777756789',
    mailName: 'Document 11',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-21',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '128883456789',
    mailName: 'Document 12',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-03-20',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '123456789',
    mailName: 'Document 13',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-22',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '12344444434456789',
    mailName: 'Document 14',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-05',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '1234567877787809',
    mailName: 'Document 15',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-09',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '1234000056789',
    mailName: 'Document 16',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-25',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '102003456789',
    mailName: 'Document 17',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-20',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '1234567800000009',
    mailName: 'Document 18',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.TS_CommunicationChannel,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',

      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-20',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
  {
    mailId: '1275645453456789',
    mailName: 'Document 19',
    specialTreatmentTypeCode: ProcessingTypeEnum.NO,
    mailOriginCode: CommunicationChannelEnum.UNKNOWN,
    mailTypeCode: TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess,
    mailApaPerson: {
      personId: '987654321',
      nir: '1234567890123',
      civility: CivilityEnum.MONSIEUR_Civility,
      personType: PersonStatusEnum.DEMANDEUR_PersonStatus,
      sngiCertificationStatus: {
        value: IdentityStatusEnum.CERTIFIEE_IdentityStatus,
        comment: 'Certificado correctamente',
      },
      firstName: 'John',
      lastName: 'Doe',
      useName: 'John Doe',
      otherFirstNames: [],
      birthDate: new Date('1990-01-01'),
      birthCountryName: 'Country',
      birthPlaceName: 'Place',
      birthDepartmentNumber: '123',
      birthDepartmentName: 'Department',
      administrativeReferent: {
        agentId: '2',
        firstName: 'Marcos',
        lastName: 'Miguel',
        departmentNumber: '75',
        departmentName: 'Valence',
        workPhoneNumber: '01 02 03 04 05',
        workEmailAddress: 'marcos@marcos.com',
      },
    },
    mailDepositDate: '2023-06-20',
    deleteBy: {
      agentId: '12345',
      firstName: 'Jean',
      lastName: 'Martin',
      departmentNumber: '75',
      departmentName: 'Paris',
      workPhoneNumber: '+33 123456789',
      workEmailAddress: 'jean.martin@example.com',
    },
    deletionDatetime: '2023-06-20',
    isFolder: false,
    documents: [
      {
        attachedDocumentId: '123456789',
        storageType: StorageTypeEnum.INTERNE_StorageType,
        types: [AttachedDocumentTypeEnum.OTHER],
        otherType: '',
        reference: 'DOC001',
        libraryNumber: 'LIB001',
        url: 'https://example.com/document',
        document: {
          documentId: '987654321',
          documentName: 'Formulario de solicitud APA',
          mimeType: 'application/pdf',
          contentLength: 1024,
          hash: 'abcdef123456',
          status: DocumentStatusEnum.AVAILABLE,
        },
      },
    ],
  },
];

/**
 * Classe contenant les messages relatifs aux notifications.
 */
export class NotificationMessage {
  /**
   * Message affiché lorsqu'un chargement est en cours, demandant à l'utilisateur de patienter.
   */
  static chargementMessage = 'Chargement en cours, veuillez patientez.';
  /**
   * Message affiché lorsqu'une erreur technique est survenue, demandant à l'utilisateur de réessayer ultérieurement.
   */
  static errorMessage = 'Une erreur technique est survenue, Veuillez réessayer ultérieurement.';

  static errorMessageApaBlock =
    'Action verrouillée par un autre utilisateur. L’action sera à nouveau possible lorsque les modifications seront terminées.';
}

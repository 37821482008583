<header class="main-header" role="banner">
  <div class="header-left-region">
    <div class="logo-class">
      <img src="assets/logo_SI-APA_Signature_Couleur.svg" alt="Logo du APA" />
    </div>
    <app-side-dialog />
  </div>

  <div class="header-right-region">
    <div class="search-input">
      <app-search-bar />
    </div>
    <div class="logout-button">
      <cnsa-icon name="logout" (click)="logout()"></cnsa-icon>
    </div>
  </div>
</header>

/**
 * Énumération représentant les différentes variantes de notification de l'application.
 */
export enum NotificationVariant {
  ERROR = 'danger',
  INFO = 'primary',
  SUCCESS = 'success',
  WARNING = 'warning',
  NEUTRAL = 'neutral',
}

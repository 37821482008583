import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { NotificationService } from '../../shared/services/notification.service';
import { NotificationVariant } from '../../shared/enums/NotificationVariant.enum';
import { NotificationMessage } from '../../shared/messages/notifcation-message';
import { environment } from '../../../../environments/environment.prod';
import { Location } from '@angular/common';

/**
 * Numéro représentant un intervalle d'une seconde
 */
const oneSecond = 1000;

/**
 * Intercepteur HTTP pour gérer les erreurs techniques
 */
@Injectable({
  providedIn: 'root',
})
export class HttpProcessTechnicalInterceptor implements HttpInterceptor {
  /**
   * Injection du service de notification
   */
  private notificationService: NotificationService = inject(NotificationService);
  private location: Location = inject(Location);

  /**
   * Intercepteur HTTP pour gérer les erreurs techniques
   *
   * @param req requête HTTP
   * @param next gestionnaire HTTP
   * @returns Observable de l'événement HTTP
   */
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const started = Date.now();
    const timer = setInterval(() => {
      const elapsed = Date.now() - started;
      if (elapsed > environment.warningShowingChargementNotification) {
        this.notificationService.showNotification(NotificationMessage.chargementMessage, {
          variant: NotificationVariant.WARNING,
          duration: NaN,
        });
        clearInterval(timer);
      }
    }, oneSecond);

    return next.handle(req).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          this.notificationService.closeNotification();
          clearInterval(timer);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        clearInterval(timer);
        // Cette solution est temporaire et doit etre modifiée avec une vrai gestion d'erreur des retours back
        if (error.status == 400 && error.error.message?.includes('Le RIB saisi')) {
          return throwError(() => error);
        } else {
          this.notificationService.showNotification(NotificationMessage.errorMessage, {
            variant: NotificationVariant.ERROR,
          });
          console.error(`Error in HttpProcessTechnicalInterceptor [${error}]`);
          return throwError(() => error);
        }
      })
    );
  }
}

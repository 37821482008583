import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BanetteSharingService } from '../../services/banette-sharing.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { SearchGlobalService } from '../../../../shared/services/search-global.service';
import { RoutesEnum } from '../../../../shared/enums/routes.enum';
import { ApaAgentDto } from '../../../demande/models/apa-agent.dto';
import { COUNTDOWN_IN_DAYS, DATE_LOCAL_US } from '../../../../shared/utils/globalConstants';
import { TableMessage } from '../../../../shared/messages/table-message';
import { ApaRequestStatusValueEnum } from '../../../demande/enums/apa-request-status-value.enum';
import { searchApaAgentData } from '../../../../shared/data/search-apa-agent.data';
import { GestionDemandesData } from '../../../demande/data/gestion-demandes.data';
import { differenceInDays } from 'date-fns';
import { DemandeEnCoursSearchPipe } from '../../../../shared/pipes/demande-en-cours-search.pipe';
import { GroupDto } from '../../../management-group/models/group.dto';
import { BannetteTypeEnum } from '../../enums/bannette-type.enum';
import { AssignedModaleTypeEnum } from '../../enums/assigned-modale-type.enum';
import { SpecialTreatmentTypeEnum } from 'src/app/modules/shared/enums/special-treatment-type.enum';
import { ReferentielData } from '../../../../shared/data/referentiel-data';
import { AgentTypeEnum } from '../../../../shared/enums/referentiel-person.enum';
import { PageContentDto } from '../../../demande/models/page-content.dto';
import { DemandStatusEnum, TypeOfProcessEnum } from '../../../../shared/enums/referentiel-requests.enum';
import { LocalStorageService } from '../../../../shared/services/local-storage.service';
import { ApaRequestDto } from '../../../demande/models/apa-request.dto';

/**
 * Composant pour afficher le tableau des demandes en cours dans la banette.
 * Affiche les éléments des demandes en cours sous forme de tableau.
 */
@Component({
  selector: 'app-banette-tableau-demandes',
  templateUrl: './banette-tableau-demandes.component.html',
  styleUrls: ['./banette-tableau-demandes.component.css'],
  providers: [DemandeEnCoursSearchPipe],
})
export class BanetteTableauDemandesComponent implements OnInit, OnDestroy {
  protected readonly COUNTDOWN_IN_DAYS = COUNTDOWN_IN_DAYS;
  protected readonly Math = Math;

  protected readonly BannetteTypeEnum = BannetteTypeEnum;
  protected readonly AgentTypeEnum = AgentTypeEnum;
  protected readonly AssignedModaleTypeEnum = AssignedModaleTypeEnum;

  isDialogOpen = false;
  currentIndex = 0;
  selectedDemande: ApaRequestDto | undefined;

  @Input() set demandes(value: PageContentDto[] | undefined) {
    if (value) {
      this.demandesValue = value;

      this.demandesValue.forEach((demande) => {
        if (demande.apaRequest.initialisationDateTime) {
          demande.apaRequest.daysLeft =
            COUNTDOWN_IN_DAYS - differenceInDays(new Date(), new Date(demande.apaRequest.initialisationDateTime));
        } else {
          demande.apaRequest.daysLeft = COUNTDOWN_IN_DAYS;
        }
      });
    }
  }
  @Output() updateData = new EventEmitter();

  /** Liste des référents disponibles. */
  @Input() referents?: ApaAgentDto[];

  /**
   * Le nombre de resultat de rechercher.
   */
  @Output() updateCountSearch = new EventEmitter<number>();
  @Output() resetCountSearch = new EventEmitter();
  /** Date utilisée pour initialiser les dates dans le tableau. */
  date = new Date('2023-01-01');

  /** Pipe de date utilisé pour formater les dates dans le tableau. */
  datePipe = new DatePipe(DATE_LOCAL_US);

  /** Titre affiché au-dessus du tableau. */
  titre = 'Demandes en cours';

  /** Nombre d'éléments à afficher dans le tableau. */
  itemsToShow = 10;
  personnes: ApaAgentDto[] = [];
  groupes: GroupDto[] = [];

  /** Colonnes du tableau. */
  columns: string[] = ['Dossier', 'Nature de la demande', 'Statut', 'Référent Administratif', 'Date de dépôt'];

  /** Indique si toutes les demandes sont sélectionnées. */
  selectedAll = false;

  /** Liste des demandes sélectionnées dans le tableau. */
  selectedData?: PageContentDto[] = [];

  /** Informations sur la position de tri dans le tableau. */
  positionValue = {
    position: 0,
    value: false,
  };

  /** Messages utilisés dans le tableau. */
  public message = TableMessage;

  /** Valeur de filtrage pour la recherche. */
  filterValue = '';

  demandesValue: PageContentDto[] = [];

  /**
   * Le nombre de resultat de rechercher.
   */
  public countSearch = 0;

  /** Enumération des types de traitement spécial. */
  protected readonly SpecialTreatmentType = SpecialTreatmentTypeEnum;

  /** Enumération des request status. */
  protected readonly RequestStatusValue = ApaRequestStatusValueEnum;

  /**
   * Constructeur du composant.
   *
   * @param {Router} router Le routeur.
   * @param searchService
   * @param {searchApaAgentData} searchApaAgentService Le service de recherche d'agents APA.
   * @param {BanetteSharingService} banetteSharingService Le service partagé des banettes.
   * @param demandeService
   * @param localStorage
   */
  constructor(
    private router: Router,
    private searchService: SearchGlobalService,
    private searchApaAgentService: searchApaAgentData,
    private banetteSharingService: BanetteSharingService,
    private demandeService: GestionDemandesData,
    private demandeEnCoursSearchPipe: DemandeEnCoursSearchPipe,
    private referentielService: ReferentielData,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * Méthode d'initialisation du composant.
   */
  ngOnInit() {
    this.searchService.resetSearchValue();
    this.searchService.getSearchValue().subscribe((value) => {
      this.filterValue = value;
      /**
       * Counter de le nombre de rechechers
       */
      this.countSearch = this.filterDemands(this.demandesValue, this.filterValue).length;
      this.updateCountSearch.emit(this.countSearch);
    });

    this.searchApaAgentService.getListVisibleReferents([]).subscribe((data) => {
      this.referents = data;
    });

    this.banetteSharingService.getSelectedElements().subscribe((data) => {
      this.selectedData = data as PageContentDto[];
    });
    this.resetCountSearch.emit();
  }

  /**
   * Méthode appelée lors de la destruction du composant.
   */
  ngOnDestroy(): void {
    this.banetteSharingService.setSelectedElements([]);
  }

  /**
   * Augmente le nombre d'éléments à afficher dans le tableau
   */
  addMoreItemsToShow() {
    this.itemsToShow += 10;
  }

  /**
   * Sélectionner toutes les lignes du tableau
   */
  selectAllRows() {
    if (this.selectedData?.length == this.demandesValue?.length) {
      this.selectedData = [];
      this.selectedAll = false;
    } else {
      this.selectedData = this.demandesValue?.slice();
      this.selectedAll = true;
    }

    this.shareSelectedRows(this.selectedData!);
  }

  /**
   * Fonction permettant de sélectionner une ligne dans la colonne
   *
   * @param {number} index L'indice de la colonne sélectionnée
   */
  selectRow(index: number) {
    if (this.demandesValue) {
      if (this.selectedData?.includes(this.demandesValue && this.demandesValue[index])) {
        this.selectedData?.splice(this.selectedData?.indexOf(this.demandesValue && this.demandesValue[index]), 1);
        this.selectedAll = false;
      } else {
        this.selectedData?.push(this.demandesValue && this.demandesValue[index]);
        if (this.selectedData?.length == this.demandesValue?.length) this.selectedAll = true;
        else this.selectedAll = false;
      }
    }

    this.shareSelectedRows(this.selectedData!);
  }

  /**
   * Vérifier si un élément est sélectionné dans le tableau.
   *
   * @param {number} index L'indice de la demande dans la liste.
   * @returns {boolean} True si la demande est sélectionnée, sinon False.
   */
  isSelected(index: number) {
    return this.demandesValue ? this.selectedData?.includes(this.demandesValue && this.demandesValue[index]) : false;
  }

  /**
   * Sauvegarde les elements sélectionnés dans le service de partage des banettes
   *
   * @param {PageContentDto[]} data Les données à partager.
   */
  shareSelectedRows(data: PageContentDto[]) {
    this.banetteSharingService.setSelectedElements(data);
  }

  /**
   * Trie le tableau en fonction de la colonne sélectionnée.
   *
   * @param {number} index L'indice de la colonne sélectionnée.
   */
  sortRows(index: number) {
    const sortOrder = (a: PageContentDto, b: PageContentDto) => {
      let fieldA: any, fieldB: any;

      switch (index) {
        case 0:
          fieldA = a.apaRequest.requesterSituation.personIdentity.firstName.toUpperCase();
          fieldB = b.apaRequest.requesterSituation.personIdentity.firstName.toUpperCase();
          break;
        case 1:
          fieldA = a.apaRequest.requestType;
          fieldB = b.apaRequest.requestType;
          break;
        case 2:
          fieldA = a.apaRequest.requestStatus?.value;
          fieldB = b.apaRequest.requestStatus?.value;
          break;
        case 3:
          fieldA = a.apaRequest.requesterSituation.personIdentity.administrativeReferent?.firstName;
          fieldB = b.apaRequest.requesterSituation.personIdentity.administrativeReferent?.firstName;
          break;
        case 4:
          fieldA = a.apaRequest.initialisationDateTime;
          fieldB = b.apaRequest.initialisationDateTime;
          break;
        default:
          return 0;
      }

      if (fieldA < fieldB) return -1;
      if (fieldA > fieldB) return 1;
      return 0;
    };

    if (this.positionValue.position === index && this.positionValue.value) {
      this.positionValue.value = false;
      this.demandesValue?.sort((a, b) => sortOrder(a, b));
    } else {
      this.demandesValue?.sort((a, b) => -sortOrder(a, b));
      this.positionValue.position = index;
      this.positionValue.value = true;
    }
    this.demandesValue = this.demandesValue?.slice();
  }

  /**
   * Fonction permettant de modifier le référent administratif pour un courrier spécifique.
   *
   * @param {any} event L'événement de changement de valeur du sélecteur.
   * @param {number} index L'indice du courrier dans la liste.
   * @author nitouhla
   */
  changeReferent(event: any, index: number) {
    if (event.target.value && event.target.value.trim()) {
      this.referents!.forEach((ref) => {
        if (ref.userId && ref.userId === event.target.value && this.demandesValue) {
          this.demandesValue[index].apaRequest.requesterSituation.personIdentity.administrativeReferent = ref;
          this.demandesValue[index].apaRequest.requesterSituation.personIdentity.administrativeReferent!.agentId =
            ref.userId;
        }
      });
    }
    if (this.demandesValue) {
      this.demandeService.updateDemande(this.demandesValue[index]);
    }
  }

  /**
   * Ouvre le formulaire de demande pour une demande spécifique.
   *
   * @param {PageContentDto} demande La demande à afficher dans le formulaire.
   */
  openDemandeForm(demande: PageContentDto) {
    switch (demande.apaRequest.requestType) {
      case TypeOfProcessEnum.RELAIS_HOSPITALISATION_TypeOfProcess:
        this.router.navigate([
          RoutesEnum.RelaisHospitalization + '/' + demande.apaRequest.requestId + '/' + demande.taskList[0].id,
        ]);
        break;
      case TypeOfProcessEnum.PREMIERE_DEMANDE_CMI_TypeOfProcess:
      case TypeOfProcessEnum.RENOUVELLEMENT_CMI_TypeOfProcess:
        this.router.navigate([
          RoutesEnum.ApaRequestCMI + '/' + demande.apaRequest.requestId + '/' + demande.taskList[0].id,
        ]);
        break;
      default:
        this.router.navigate(
          [RoutesEnum.RequestForm + '/' + demande.apaRequest.requestId + '/' + demande.taskList[0].id],
          {
            skipLocationChange: true,
          }
        );
        break;
    }
  }

  onClickOpenAssignmentModale(index: number) {
    // event.preventDefault();
    this.currentIndex = index;
    this.selectedDemande = this.demandesValue[this.currentIndex].apaRequest;
    this.isDialogOpen = true;
  }

  closeAssignedPersonModale(refreshData?: boolean) {
    if (refreshData) {
      this.updateData.emit();
    }
    this.isDialogOpen = false;
  }

  updateSelectedPerson(person: ApaAgentDto) {
    this.demandesValue[this.currentIndex].apaRequest.requesterSituation.personIdentity.administrativeReferent = person;

    if (this.demandesValue) {
      //this.demandeService.updateDemande(this.demandesValue[this.currentIndex]);
    }
  }

  /**
   * Méthode pour afficher plus de résultats dans le tableau.
   *
   */
  canShowMoreResults(): boolean {
    if (this.demandesValue) {
      const filteredDemandes = this.demandeEnCoursSearchPipe.transform(this.demandesValue, this.filterValue);
      return filteredDemandes.length > this.itemsToShow;
    }
    return false;
  }

  /**
   * Méthode pour afficher le message de résultat vide si la recherche ne retourne aucun résultat.
   *
   */
  isNoResults(): boolean {
    if (this.demandesValue) {
      const filteredDemandes = this.demandeEnCoursSearchPipe.transform(this.demandesValue, this.filterValue);
      return filteredDemandes.length === 0;
    }
    return false;
  }

  /* *
   * Méthode de calcul du nombre de rechechers
   * */
  filterDemands(demandes: PageContentDto[], filterValue: string): PageContentDto[] {
    return this.demandeEnCoursSearchPipe.transform(demandes, filterValue);
  }

  findLabelEnum(codeEnum: string) {
    return this.referentielService.mapEnumItemCodeToItemLabel(codeEnum);
  }

  mapEnumRequestStatusToLabel(value: ApaRequestStatusValueEnum | DemandStatusEnum | undefined) {
    let label;
    if (value) {
      label = this.findLabelEnum(value);
      switch (String(value)) {
        case DemandStatusEnum.DEPOSEE_DemandStatus:
          label = 'En cours';
          break;
        case DemandStatusEnum.INCOMPLETE_DemandStatus:
          label = 'Incomplet';
          break;
      }
    }
    return label;
  }
}

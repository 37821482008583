import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CNSADialog} from '@cnsa-fr/design-system';
import {ApaAgentDto} from '../../../demande/models/apa-agent.dto';
import {PAGE_SIZE} from '../../../../shared/utils/globalConstants';
import {RendezVousData} from '../../../rendez-vous/data/rendez-vous-data';
import {AppointmentPaginatedSearchQueryDto} from '../../../rendez-vous/models/appointment-paginated-search-query.dto';
import {ProcessingTypeEnum, TypeOfProcessEnum} from '../../../../shared/enums/referentiel-requests.enum';
import {AppointmentStatusEnum, EvaluationModalityEnum} from '../../../../shared/enums/referentiel-evaluation.enum';
import {AgentTypeEnum} from '../../../../shared/enums/referentiel-person.enum';

/**
 * Composant pour afficher les filtres des rendez-vous et les utiliser pour filtrer les rendez-vous.
 */
@Component({
  selector: 'app-banette-filtres-rdv',
  templateUrl: './banette-filtres-rdv.component.html',
  styleUrls: ['./banette-filtres-rdv.component.css'],
})
export class BanetteFiltresRdvComponent implements OnChanges {
  /**
   * Titre du composant (optionnel).
   */
  @Input() titre?: string;

  /**
   * Indicateur pour afficher ou masquer la boîte de dialogue.
   */
  @Input() showDialog!: boolean;

  /**
   * Indicateur pour filtrer par plan APA d'urgence.
   */
  @Input() isEmergencyApaPlan = false;

  /**
   * Indicateur pour filtrer par procédure accélérée.
   */
  @Input() isAcceleratedProcedure = false;
  @Input() selectedReferent: ApaAgentDto | undefined = undefined;
  @Input() selectedEvaluator: ApaAgentDto | undefined = undefined;

  /**
   * Événement émis lorsque la boîte de dialogue est fermée.
   */
  @Output() closeDialog = new EventEmitter<string[]>();

  /**
   * Événement émis lorsque le nombre de filtres sélectionnés.
   */
  @Output() checkedCounterChange = new EventEmitter<number>();
  @Output() updateModale = new EventEmitter<boolean>();
  @Output() updateProfilName = new EventEmitter<AgentTypeEnum>();

  /**
   * Référence au dialogue (si utilisée dans le modèle).
   */
  @ViewChild('dialog') dialog?: ElementRef<CNSADialog>;

  /**
   * Liste des évaluateurs APA disponibles.
   */
  @Input() evaluateurs?: ApaAgentDto[];

  /**
   * Liste des référents APA disponibles.
   */
  @Input() referents?: ApaAgentDto[];

  /**
   * Liste des typifications sélectionnées.
   */
  selectedTypifications: ProcessingTypeEnum[] = [];

  /**
   * Forme de la date (période ou précise).
   */
  dateForm = 'periode';

  /**
   * counter pour le nombre de filtres sélectionnés.
   */
  checkedCounter = 0;

  /**
   * Filtre actuel pour les rendez-vous.
   */
  filter: AppointmentPaginatedSearchQueryDto = {
    pageNumber: 0,
    pageSize: PAGE_SIZE,
  };

  /**
   * Enumération des types de traitement spécial.
   */
  ProcessingTypeEnum = ProcessingTypeEnum;

  /**
   * Enumération des types de demandes APA.
   */
  TypeOfProcessEnum = TypeOfProcessEnum;
  /**
   * Enumération des statuts des rendez-vous.
   */
  AppointmentStatusEnum = AppointmentStatusEnum;

  /**
   * Enumération des types de rendez-vous.
   */
  EvaluationModalityEnum = EvaluationModalityEnum;
  /**
   * Liste des types de rendez-vous sélectionnés.
   */
  types: string[] = [];

  /**
   * Liste des statuts des visites sélectionnés.
   */
  visitStatus: string[] = [];

  /**
   * Liste des types de demandes sélectionnés.
   */
  requestTypes: string[] = [];

  /**
   * Liste des typifications sélectionnées.
   */
  typifications: string[] = [];

  /**
   * Référence au select des referents.
   */
  @ViewChild('referentsSelect') referentsSelect?: ElementRef;
  /**
   * Référence au select des evaluateurs.
   */
  @ViewChild('evaluateursSelect') evaluateursSelect?: ElementRef;

  /**
   * Crée une instance du composant BanetteFiltresRdvComponent.
   *
   * @param {RendezVousData} rdvService Le service pour gérer les rendez-vous.
   * @param {searchApaAgentData} searchApaAgentService Le service pour rechercher les agents APA.
   */
  constructor(private rdvService: RendezVousData) {
  }

  /**
   * Méthode appelée lorsque les entrées de ce composant changent.
   *
   * @param {SimpleChanges} { isEmergencyApaPlan, isAcceleratedProcedure } Les changements observés.
   */
  ngOnChanges({isEmergencyApaPlan, isAcceleratedProcedure}: SimpleChanges): void {
    // Emergency apa plan special treatment change
    if (isEmergencyApaPlan && !isEmergencyApaPlan.firstChange) {
      this.updateRequestTypificationSearch(this.isEmergencyApaPlan, ProcessingTypeEnum.APA_URGENCE_ProcessingType);
    }
    // Accelerated procedure special treatment change
    if (isAcceleratedProcedure && !isAcceleratedProcedure.firstChange) {
      this.updateRequestTypificationSearch(
        this.isAcceleratedProcedure,
        ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType
      );
    }
  }

  /**
   * Met à jour les filtres de recherche en fonction du type de traitement spécial.
   *
   * @param {boolean} type Le type de filtre (vrai ou faux).
   * @param {ProcessingTypeEnum} specialTreatmentType Le type de traitement spécial.
   */
  private updateRequestTypificationSearch(type: boolean, specialTreatmentType: ProcessingTypeEnum) {
    setTimeout(() => {
      // Vérifie si le type de traitement spécial existe déjà dans le tableau
      const typeExists = this.typifications.includes(specialTreatmentType);

      if (type && !typeExists) {
        // Ajoute le type de traitement spécial s'il est vrai et qu'il n'existe pas encore
        this.typifications.push(specialTreatmentType);
      } else if (!type && typeExists) {
        // Supprime le type de traitement spécial s'il est faux et qu'il existe déjà
        this.typifications = this.typifications.filter(
          (specialTreatmentFilter) => specialTreatmentFilter !== specialTreatmentType
        );
      }

      // Appelle filterRDV après avoir mis à jour this.typifications
      this.filterRDV();
    });
  }

  /**
   * Méthode appelée lorsqu'un type de demande est modifié.
   *
   * @param {Event} event L'événement de modification.
   */
  changeTypeDemande(event: Event) {
    const target = event.target as HTMLInputElement;
    if (this.requestTypes.includes(target.value)) {
      const index = this.requestTypes.indexOf(target.value);
      if (index !== -1) {
        this.requestTypes.splice(index, 1);
      }
    } else this.requestTypes.push(target.value);
  }

  /**
   * Méthode appelée lorsqu'une typification de demande est modifiée.
   *
   * @param {Event} event L'événement de modification.
   */
  changeTypificationDemande(event: Event) {
    const target = event.target as HTMLInputElement;

    // Si "Aucune" est sélectionné, désélectionner les autres deux options
    if (target.name === 'aucune') {
      if (target.checked) {
        this.typifications = [ProcessingTypeEnum.NO];
      } else {
        // Si "Aucune" est décochée, laisser le tableau vide
        this.typifications = [];
      }
    } else {
      // Si l'une des deux autres options est sélectionnée, désélectionner "Aucune"
      if (this.typifications.includes(ProcessingTypeEnum.NO)) {
        const index = this.typifications.indexOf(ProcessingTypeEnum.NO);
        if (index !== -1) {
          this.typifications.splice(index, 1);
        }
      }

      if (target.checked && !this.typifications.includes(target.value)) {
        this.typifications.push(target.value);
      } else if (!target.checked) {
        // Si elle est désélectionnée, supprimer la valeur si elle existe dans le tableau
        const index = this.typifications.indexOf(target.value);
        if (index !== -1) {
          this.typifications.splice(index, 1);
        }
      }
    }
  }

  /**
   * Méthode appelée lorsqu'un référent est modifié.
   *
   * @param {Event} event L'événement de modification.
   */
  //TODO: This should be changed when referents backend is implemented (temporal solution)!
  changeReferent(event: Event) {
    const target = event.target as HTMLInputElement;
    this.filter.administrativeReferent = {
      agentId: target.value,
      departmentName: '',
      departmentNumber: '',
      firstName: '',
      lastName: '',
      userId: '',
      workEmailAddress: '',
      workPhoneNumber: '',
    };
  }

  /**
   * Méthode appelée lorsqu'un statut de visite est modifié.
   * @param {Event} event L'événement de modification.
   */
  changeStatusVisit(event: Event) {
    const target = event.target as HTMLInputElement;
    if (this.visitStatus.includes(target.value)) {
      const index = this.visitStatus.indexOf(target.value);
      if (index !== -1) {
        this.visitStatus.splice(index, 1);
      }
    } else this.visitStatus.push(target.value);
  }

  /**
   * Méthode appelée lorsqu'un évaluateur est modifié.
   *
   * @param {Event} event L'événement de modification.
   */
  changeEvaluator(event: Event) {
    const target = event.target as HTMLInputElement;
    this.filter.evaluator = {
      agentId: target.value,
      departmentName: '',
      departmentNumber: '',
      firstName: '',
      lastName: '',
      userId: '',
      workEmailAddress: '',
      workPhoneNumber: '',
    };
  }

  /**
   * Méthode appelée lorsqu'une forme de date est modifiée.
   *
   * @param {Event} event L'événement de modification.
   */
  changeDateForm(event: Event) {
    const target = event.target as HTMLInputElement;
    this.dateForm = target.value;
    if (this.dateForm === 'periode') {
      this.filter.apaRequestExactCompletionDate = undefined;
    } else {
      this.filter.apaRequestMinCompletionDate = undefined;
      this.filter.apaRequestMaxCompletionDate = undefined;
    }
  }

  /**
   * Filtrer les rendez-vous en fonction des filtres actuels.
   * Appelle le service RendezVousData pour récupérer les rendez-vous filtrés.
   */
  filterRDV() {
    if (this.selectedReferent) {
      this.filter.administrativeReferent = this.selectedReferent;
    }
    if (this.selectedEvaluator) {
      this.filter.evaluator = this.selectedEvaluator;
    }
    let filterCount = 0;
    this.filter.apaRequestTypificationList = this.typifications;
    this.filter.apaRequestTypeList = this.requestTypes;
    this.filter.appointmentStatusList = this.visitStatus;
    this.filter.appointmentTypeList = this.types;

    this.rdvService.getRendezvous(this.filter).subscribe();
    this.toggleDialog();

    filterCount =
      this.typifications.length +
      this.requestTypes.length +
      this.visitStatus.length +
      this.types.length +
      (this.filter.apaRequestMaxCompletionDate ? 1 : 0) +
      (this.filter.apaRequestMinCompletionDate ? 1 : 0) +
      (this.filter.apaRequestExactCompletionDate ? 1 : 0);
    this.checkedCounter = filterCount;
    this.checkedCounterChange.emit(this.checkedCounter); // "counter"pour envoyer les informations au composant père avec le nombre de filtres sélectionnés.
  }

  /**
   * Réinitialise les filtres de rendez-vous.
   * Appelle le service RendezVousData pour récupérer tous les rendez-vous sans filtres.
   */
  resetFilter() {
    this.rdvService.getRendezvous().subscribe();
    this.filter = {
      pageNumber: 0,
      pageSize: PAGE_SIZE,
    };

    this.typifications = [];
    this.requestTypes = [];
    this.visitStatus = [];
    this.types = [];
    this.selectedTypifications = [];
    this.dateForm = 'periode';
    if (this.referentsSelect) {
      this.referentsSelect.nativeElement.value = '';
      delete this.filter.administrativeReferent;
    }
    if (this.evaluateursSelect) {
      this.evaluateursSelect.nativeElement.value = '';
      delete this.filter.evaluator;
    }
    this.checkedCounter = 0;
    this.selectedReferent = undefined;
    this.selectedEvaluator = undefined;
    this.checkedCounterChange.emit(this.checkedCounter); // reset "counter"  le nombre de filtres sélectionnés.
  }

  /**
   * Bascule l'affichage de la boîte de dialogue.
   */
  toggleDialog() {
    this.showDialog = false;
    this.closeDialog.emit(this.typifications);
  }

  /**
   * Méthode appelée lorsqu'un type de rendez-vous est modifié.
   *
   * @param {Event} event L'événement de modification.
   */
  changeTypeVisit(event: Event) {
    const target = event.target as HTMLInputElement;
    if (this.types.includes(target.value)) {
      const index = this.types.indexOf(target.value);
      if (index !== -1) {
        this.types.splice(index, 1);
      }
    } else this.types.push(target.value);
  }

  onClickSelectReferent(event: Event): void {
    event.preventDefault();
    this.updateModale.emit(true);
    this.updateProfilName.emit(AgentTypeEnum.ADMINISTRATIF_AgentType);
  }

  onClickSelectEvaluator(event: Event): void {
    event.preventDefault();
    this.updateModale.emit(true);
    this.updateProfilName.emit(AgentTypeEnum.EVALUATEUR_AgentType);
  }
}

/**
 * Énumération représentant les différentes routes de l'application.
 */
export enum RoutesEnum {
  /**
   * Accueil de l'application.
   */
  Home = '/',

  /**
   * Bannière du courrier entrant.
   */
  IncomingMailBanner = 'courrier/courriers-entrants',

  /**
   * Formulaire de demande.
   */
  RequestForm = '/demande/formulaireDemande',

  /**
   * Traitement des demandes.
   */
  DealWithRequest = '/demande/traiterDemande',

  /**
   * Bannière des demandes APA en cours.
   */
  ApaRequestBanner = '/demande/demandes-en-cours',

  ApaRequestCMI = '/demande/formulaireCMI',
  /**
   * Bannière des rendez-vous.
   */
  AppointmentBanner = '/rendez-vous/gestion-rdv',

  /**
   * Ajout de courrier.
   */
  AddMail = '/demande/ajouterCourrier',

  /**
   * Completer demande relais hospitalisation.
   */
  RelaisHospitalization = '/demande/relaisHospitalisation',

  /**
   * Vue individuelle d'un individu.
   */
  IndividualView = '/demande/individualView',

  /**
   * Boîte d'envoi des courriers.
   */
  MailOutbox = '/courrier/boite-envoi',

  /**
   * Forfaits APA en urgence.
   */
  ForfaitsApaUrgence = '/rendez-vous/forfaits-apa-en-urgence',

  /**
   * Page d'erreur.
   */
  error = '/error',

  /**
   * Corbeille.
   */
  trash = '/corbeille',

  /**
   * document viewer
   */
  DocumentViewer = '/document-viewer',

  /**
   * Gestion des groupes
   */
  GestionDesGroupes = '/gestion-des-groupes',

  /**
   * donné fausse à changer pour vrai chemins
   */
  DemandeCMI = '/demande/CMI',

  /**
   * donné fausse à changer pour vrai chemins
   */
  Hospitalisation = '/demande/Hospitalisation',
  /**
   * donné fausse à changer pour vrai chemins
   */
  RevisionPersonne = '/demande/RevisionPersonne',
}

/**
 * Classe contenant les messages relatifs aux tableaux (listes).
 */
export class TableMessage {
  /**
   * Message affiché lorsqu'aucun résultat n'est trouvé dans le tableau.
   */
  static noResult = 'Aucuns résultats trouvés';
  /**
   * Message affiché lorsqu'une recherche dans le tableau ne donne aucun résultat.
   */
  static emptySearch = 'Nous n’avons pas pu trouver de résultats pour votre recherche';
}

<div class="banette-header-recherche">
  <p class="banette-header-recherche__title" *ngIf="titre === 'Gestion des rendez-vous'">
    {{ showCountSearch ? countSearch : count }} rendez-vous
  </p>
  <p class="banette-header-recherche__title" *ngIf="titre === 'Demandes en cours'">
    {{ showCountSearch ? countSearch : count }} demandes
  </p>
  <p class="banette-header-recherche__title" *ngIf="titre === 'Courriers entrants' || titre?.includes('Boite')">
    {{ showCountSearch ? countSearch : count }} courriers
  </p>
  <p class="banette-header-recherche__title" *ngIf="titre === 'Gestion des forfaits APA en urgence'">
    {{ showCountSearch ? countSearch : count }} rendez-vous
  </p>

  <p class="banette-header-recherche__title" *ngIf="titre === 'Corbeille'">{{ count }} documents</p>

  <section class="banette-header-recherche__actions">
    <ng-container
      *ngIf="titre !== 'Corbeille' && titre !== 'Gestion des forfaits APA en urgence' && !titre?.includes('Boite')">
      <cnsa-checkbox size="medium" [checked]="isEmergencyApaPlan" (cnsa-change)="onEmergencyApaPlanChange($event)"
        >Forfait APA en urgence</cnsa-checkbox
      >
      <cnsa-checkbox
        size="medium"
        [checked]="isAcceleratedProcedure"
        (cnsa-change)="onAcceleratedProcedureChange($event)"
        >Procédure accélérée</cnsa-checkbox
      >
    </ng-container>

    <cnsa-button variant="tertiary" class="button-with-counter" (click)="openDialog()">
      <cnsa-icon name="filter_list" slot="prefix"></cnsa-icon>Filtrer
      <span *ngIf="checkedCounter > 0" class="counter">{{ checkedCounter }}</span>
    </cnsa-button>

    <form [formGroup]="searchForm">
      <cnsa-input
        class="search-input"
        label="Rechercher"
        placeholder="Rechercher"
        type="search"
        formControlName="searchValue"
        ngDefaultControl>
      </cnsa-input>
    </form>

    <ng-container class="container-buttons" *ngIf="titre !== 'Gestion des forfaits APA en urgence'">
      <cnsa-button variant="primary" *ngIf="import">
        <cnsa-icon name="file_download" slot="prefix"></cnsa-icon>
        Importer
      </cnsa-button>
      <cnsa-button
        variant="primary"
        *ngIf="titre !== 'Gestion des forfaits APA en urgence' && export"
        (click)="generateCsv()">
        <cnsa-icon name="ios_share" slot="prefix"></cnsa-icon>
        Exporter
      </cnsa-button>
    </ng-container>
  </section>
</div>

<app-banette-filtres-demande
  *ngIf="titre === 'Demandes en cours'"
  [titre]="titre"
  [showDialog]="showFilters"
  [referents]="referents"
  [isEmergencyApaPlan]="isEmergencyApaPlan"
  [isAcceleratedProcedure]="isAcceleratedProcedure"
  (checkedCounterChange)="updateCheckedCounter($event)"
  (closeDialog)="closeDialog()"
  [selectedPerson]="selectedReferent"
  (updateModale)="updateModale($event)"
  (updateProfilName)="updateProfileName($event)"></app-banette-filtres-demande>
<app-banette-filtres-corbeille
  *ngIf="titre === 'Corbeille'"
  [titre]="titre"
  [showDialog]="showFilters"
  [referents]="referents"
  (checkedCounterChange)="updateCheckedCounter($event)"
  (closeDialog)="closeDialog()"
  [selectedPerson]="selectedReferent"
  (updateModale)="updateModale($event)"
  (updateProfilName)="updateProfileName($event)"></app-banette-filtres-corbeille>
<app-banette-filtres-boite
  *ngIf="titre === 'Boite d\'envoi'"
  [titre]="titre"
  [showDialog]="showFilters"
  [referents]="referents"
  (checkedCounterChange)="updateCheckedCounter($event)"
  (closeDialog)="closeDialog()"
  [selectedPerson]="selectedReferent"
  (updateModale)="updateModale($event)"
  (updateProfilName)="updateProfileName($event)"></app-banette-filtres-boite>
<app-banette-filtres-rdv
  *ngIf="titre === 'Gestion des rendez-vous' || titre === 'Gestion des forfaits APA en urgence'"
  [titre]="titre"
  [showDialog]="showFilters"
  [evaluateurs]="evaluateurs"
  [referents]="referents"
  [isEmergencyApaPlan]="isEmergencyApaPlan"
  [isAcceleratedProcedure]="isAcceleratedProcedure"
  (checkedCounterChange)="updateCheckedCounter($event)"
  (closeDialog)="closeDialog($event)"
  [selectedReferent]="selectedReferent"
  [selectedEvaluator]="selectedEvaluator"
  (updateModale)="updateModale($event)"
  (updateProfilName)="updateProfileName($event)"></app-banette-filtres-rdv>
<app-banette-filtres-courriers
  *ngIf="titre === 'Courriers entrants'"
  [titre]="titre"
  [showDialog]="showFilters"
  [referents]="referents"
  [isEmergencyApaPlan]="isEmergencyApaPlan"
  [isAcceleratedProcedure]="isAcceleratedProcedure"
  (checkedCounterChange)="updateCheckedCounter($event)"
  (closeDialog)="closeDialog()"
  [selectedReferent]="selectedReferent"
  (updateModale)="updateModale($event)"
  (updateProfilName)="updateProfileName($event)"></app-banette-filtres-courriers>
<app-bannete-modale-assigner-personne
  *ngIf="isDialogOpen && profilName && bannetteType"
  [nameProfile]="profilName"
  [banetteType]="bannetteType"
  [dialogIsOpen]="isDialogOpen"
  [dialogType]="AssignedModaleTypeEnum.SEARCH"
  (closeModal)="closeAssignedPersonModale()"
  (updatePerson)="updateSelectedPerson($event)">
</app-bannete-modale-assigner-personne>

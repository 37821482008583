import { Observable } from 'rxjs';
import { GroupDto } from '../models/group.dto';

export abstract class GroupData {
  /**
   * Créé un groupe d'utilisateurs du SI-APA.
   *
   * @param group informations à utiliser pour créer le groupe
   * @return le groupe créé
   */
  abstract createApaUserGroup(group: GroupDto): Observable<any>;

  /**
   * Récupération des caractéristiques d'un groupe d'utilisateurs du SI-APA.
   *
   * @param groupId identifiant unique du groupe à récupérer
   * @return {GroupDto} le groupe correspondant
   */
  abstract getApaUserGroup(groupId: string): Observable<GroupDto>;

  /**
   * Met à jour les informations d'un groupe d'utilisateurs du SI-APA.
   *
   * @param groupId       identifiant du groupe à mettre à jour
   * @param groupToUpdate informations à utiliser pour mettre à jour le groupe
   * @return le groupe mis à jour
   */
  abstract updateApaUserGroup(groupId: string, groupToUpdate: GroupDto): Observable<GroupDto>;

  /**
   * Récupère tous les groupes consultables par un utilisateur.
   *
   * @return tous les groupes consultables par l'utilisateur
   */
  abstract getGroups(): Observable<GroupDto[]>;

  /**
   * Supprime un groupe et tous ses affectation aux demandes APA, courrier, visites,...
   *
   * @return réponse vide
   */
  abstract deleteGroup(groupId: string): Observable<any>;
}

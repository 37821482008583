import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoiteSearchPipe } from './pipes/boite-search.pipe';
import { CourriersEntrantsSearchPipe } from './pipes/courriers-entrants-search.pipe';
import { RendezVousSearchPipe } from './pipes/rendez-vous-search.pipe';
import { DemandeEnCoursSearchPipe } from './pipes/demande-en-cours-search.pipe';
import { DateAddPipe } from './pipes/date-add.pipe';
import { ForfaitApaEnUrgenceSearchPipe } from './pipes/forfait-apa-en-urgence-search.pipe';
import { searchApaAgentData } from './data/search-apa-agent.data';
import { environment } from '../../../environments/environment';
import { SearchApaAgentService } from './services/search-apa-agent.service';
import { SearchApaAgentsMockService } from './mocks/search-apa-agents-mock.service';
import { CustomLabelsTransformPipe } from './pipes/custom-labels-transform.pipe';
import { ImcCustomLabelsSynthesisPopupTransformPipe } from './pipes/imc-custom-labels-synthesis-popup-transform.pipe';
import { CorbeilleSearchPipe } from './pipes/corbeille-search.pipe';

/**
 * Fournisseurs de données utilisés pour les services de recherche d'agents APA.
 */
const DATA_PROVIDERS = [
  {
    provide: searchApaAgentData,
    useClass: environment.production ? SearchApaAgentService : SearchApaAgentsMockService,
    // useClass: environment.production ? SearchApaAgentsMockService : SearchApaAgentsMockService,
  },
];

@NgModule({
  declarations: [
    CorbeilleSearchPipe,
    BoiteSearchPipe,
    CourriersEntrantsSearchPipe,
    RendezVousSearchPipe,
    DemandeEnCoursSearchPipe,
    DateAddPipe,
    ForfaitApaEnUrgenceSearchPipe,
    CustomLabelsTransformPipe,
    ImcCustomLabelsSynthesisPopupTransformPipe,
  ],
  imports: [CommonModule],
  exports: [
    CorbeilleSearchPipe,
    BoiteSearchPipe,
    CourriersEntrantsSearchPipe,
    RendezVousSearchPipe,
    DemandeEnCoursSearchPipe,
    DateAddPipe,
    ForfaitApaEnUrgenceSearchPipe,
    CustomLabelsTransformPipe,
    ImcCustomLabelsSynthesisPopupTransformPipe,
  ],
  providers: [...DATA_PROVIDERS],
})
export class SharedModule {}

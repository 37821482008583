import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

/**
 * Composant principal: saut de contenu
 * @see https://angular.io/guide/accessibility#skip-to-content
 */
@Component({
  selector: 'app-skip-to-content',
  templateUrl: './skip-to-content.component.html',
  styleUrls: ['./skip-to-content.component.css'],
})
export class SkipToContentComponent implements OnInit {
  /**
   * Chaîne stockant le chemin de saut
   */
  skipLinkPath: string;

  /**
   * Constructeur du composant
   * @param location Injection du service de localisation
   */
  constructor(private location: Location) {
    this.skipLinkPath = '';
  }

  /**
   * NgOnInit (s'exécute une fois après le premier ngOnChanges)
   */
  ngOnInit() {
    this.skipLinkPath = `${this.location.path()}#main`;
  }
}

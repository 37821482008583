import { Injectable, isDevMode } from '@angular/core';
import Keycloak, { KeycloakLoginOptions } from 'keycloak-js';
import { Subject } from 'rxjs';
import { AuthenticatedUserInfo } from '../models/authenticated-user.info';
import { environment as envDev } from '../../../../../environments/environment';
import { environment as envProd } from '../../../../../environments/environment.prod';
import { UserRole } from '../../../pages/home/enums/user-role.enum';
import { LocalStorageService } from '../../../shared/services/local-storage.service';

const USER_INFO = 'userInfo';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly USER_INFO_BASE64 = 'userInfoBase64';
  private enableKeycloak;
  private keycloakURL;
  private redirectURL;
  private keycloakIdpHint;
  private keycloak: Keycloak;
  private userInfoSubject = new Subject<AuthenticatedUserInfo>();
  private userIdSubject = new Subject<string>();

  private rolesUser?: UserRole[];

  constructor(private localStorageService: LocalStorageService) {
    // Définition des URL en fonction de l'environnement
    const env = isDevMode() ? envDev : envProd;
    this.enableKeycloak = env.enableKeycloak;
    this.keycloakURL = env.keycloakURL;
    this.redirectURL = env.redirectURL;
    this.keycloakIdpHint = env.keycloakIdpHint;
    this.keycloak = new Keycloak({
      url: this.keycloakURL,
      realm: 'si-apa',
      clientId: 'siapa-web-agent',
    });
  }

  /**
   * Authentifie l'utilisateur auprès de Keycloak
   */
  public async authenticate(): Promise<AuthenticatedUserInfo> {
    if (!this.enableKeycloak) return this.mockAuthenticate();
    const authenticated = await this.init();
    if (!authenticated) {
      await this.login();
    }
    return this.loadUserInfo();
  }

  /**
   * Initialise le contexte du client Keycloak
   */
  public async init(): Promise<boolean> {
    if (!this.enableKeycloak) return this.mockInit();
    try {
      return await this.keycloak.init({
        redirectUri: this.redirectURL,
        checkLoginIframe: false, // https://stackoverflow.com/a/72069273
      });
    } catch (error) {
      console.error('Failed to initialize adapter:', error);
      throw error;
    }
  }

  /**
   * Redirige l'utilisateur vers la page d'authentification
   */
  public async login(): Promise<boolean> {
    if (!this.enableKeycloak) return this.mockLogin();
    try {
      const keycloakLoginOptions: KeycloakLoginOptions = {
        redirectUri: this.redirectURL,
      };
      if (this.keycloakIdpHint != '') {
        // Déléguer l'authentification à l'identity prodivder de la CNSA
        keycloakLoginOptions.idpHint = this.keycloakIdpHint;
      }
      await this.keycloak.login(keycloakLoginOptions);
      return true;
    } catch (error) {
      console.error('Failed to initialize adapter:', error);
      throw error;
    }
  }

  /**
   * Charge les informations de l'utilisateur connecté depuis Keycloak
   * @private
   */
  private async loadUserInfo(): Promise<AuthenticatedUserInfo> {
    if (!this.enableKeycloak) return this.mockLoadUserInfo();
    try {
      return this.keycloak.loadUserInfo().then((data: unknown) => {
        const userInfo: AuthenticatedUserInfo = this.getUserInfoFromJson(data);
        this.rolesUser = userInfo?.profiles as UserRole[];
        this.localStorageService.setObject(USER_INFO, userInfo);
        this.userInfoSubject.next(userInfo);
        if (userInfo == null) {
          this.userIdSubject.next('');
        } else {
          this.userIdSubject.next(userInfo.userId);
        }
        return userInfo;
      });
    } catch (error) {
      console.error('Failed to initialize adapter:', error);
      throw error;
    }
  }

  /**
   * Convertit les informations de l'utilisateur connecté de Keycloak vers l'objet UserInfo
   * @param data Informations de l'utilisateur de Keycloak
   * @private
   */
  private getUserInfoFromJson(data: any): AuthenticatedUserInfo {
    const userId: string = data['sub'];
    const username: string = data['preferred_username'];
    const profiles: string[] = data['groups'];
    const userInfo = new AuthenticatedUserInfo(userId, username, profiles);
    const userInfoObj = { userId: userId, username: username };
    this.localStorageService.setObject(this.USER_INFO_BASE64, btoa(JSON.stringify(userInfoObj)));
    userInfo.firstName = data['given_name'];
    userInfo.lastName = data['family_name'];
    userInfo.email = data['email'];
    userInfo.departmentNumber = data['department_number'];
    userInfo.departmentName = data['department_name'];
    return userInfo;
  }

  public getUserInfo(): AuthenticatedUserInfo | null {
    return this.localStorageService.getObject(USER_INFO);
  }

  public getUserInfoBase64(): string | null {
    return this.localStorageService.getObject(this.USER_INFO_BASE64);
  }

  public getToken(): string | undefined {
    if (!this.enableKeycloak) return this.mockGetToken();
    return this.keycloak.token;
  }

  public async logout(): Promise<void> {
    if (!this.enableKeycloak) return this.mockLogout();
    this.localStorageService.deleteObject(USER_INFO);
    try {
      return this.keycloak.logout({
        redirectUri: this.redirectURL,
      });
    } catch (error) {
      console.error('Failed to logout:', error);
      throw error;
    }
  }

  // MOCK

  /**
   * Authentifie l'utilisateur auprès de Keycloak
   */
  public async mockAuthenticate(): Promise<AuthenticatedUserInfo> {
    return this.mockLoadUserInfo();
  }

  /**
   * Initialise le contexte du client Keycloak
   */
  public async mockInit(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(true), 300);
    });
  }

  /**
   * Redirige l'utilisateur vers la page d'authentification
   */
  public async mockLogin(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(true), 300);
    });
  }

  /**
   * Charge les informations de l'utilisateur connecté depuis Keycloak
   * @private
   */
  private async mockLoadUserInfo(): Promise<AuthenticatedUserInfo> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const userInfo: AuthenticatedUserInfo = this.mockUserInfoDto();
        this.rolesUser = userInfo?.profiles as UserRole[];
        this.localStorageService.setObject(USER_INFO, userInfo);
        this.userInfoSubject.next(userInfo);
        this.userIdSubject.next(userInfo.userId);
        resolve(userInfo);
      }, 300);
    });
  }

  public mockGetToken(): string | undefined {
    const env = isDevMode() ? envDev : envProd;
    return env.jwtToken;
  }
  public async mockLogout(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), 300);
    });
  }

  /**
   * Utilisateur de test
   * @private
   */
  private mockUserInfoDto(): AuthenticatedUserInfo {
    const userInfoDto = new AuthenticatedUserInfo('test', 'test', [UserRole.AUTONOMIE]);
    userInfoDto.userId = '1';
    userInfoDto.firstName = 'User';
    userInfoDto.lastName = 'Mock';
    userInfoDto.departmentNumber = '01';
    userInfoDto.departmentName = 'AIN';
    this.localStorageService.setObject(USER_INFO, userInfoDto);
    return userInfoDto;
  }

  /**
   * Vérifie si l'utilisateur est autorisé à accéder aux rôles spécifiés.
   *
   * @param roles Un tableau de rôles que l'utilisateur doit avoir pour être autorisé.
   * @returns true si l'utilisateur est autorisé, faux sinon.
   */
  public isAuthorize(roles: UserRole[]): boolean {
    const env = isDevMode() ? envDev : envProd;
    return !env.enableMockProfile && env.enableKeycloak
      ? this.rolesUser?.some((userRole) => roles.includes(userRole)) ?? false
      : true;
  }

  /**
   * Vérifie si l'utilisateur n'est pas autorisé à accéder aux rôles spécifiés.
   *
   * @param roles Un tableau de rôles que l'utilisateur doit avoir pour être autorisé.
   * @returns false si l'utilisateur n'est pas autorisé, vraix sinon.
   */
  public isNotAuthorize(roles: UserRole[]): boolean {
    const env = isDevMode() ? envDev : envProd;
    return env.enableMockProfile || env.enableKeycloak
      ? !this.rolesUser?.some((userRole) => roles.includes(userRole)) ?? true
      : true;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { IncomingMailDto } from '../../corbeille/models/incoming-mail.dto';
import { IncomingMailPaginatedSearchQueryDto } from '../models/incoming-mail-paginated-search-query.dto';
import { GestionCourriersEntrantsData } from '../data/gestion-courriers-entrants.data';
import { IncomingMailsPaginatedSearchResultDto } from '../../corbeille/models/Incoming-mails-paginated-search-result.dto';
import { IncomingMailsMock } from './incoming-mails-mock';
import { ReferentUpdateRequestDto } from '../../../shared/models/referent-update-request.dto';

@Injectable()
export class GestionCourriersEntrantsMockService extends GestionCourriersEntrantsData {
  mail: IncomingMailDto[] = IncomingMailsMock;

  private courrierDataSubject: BehaviorSubject<IncomingMailsPaginatedSearchResultDto> =
    new BehaviorSubject<IncomingMailsPaginatedSearchResultDto>({
      pageNumber: 0,
      pageSize: 10,
      totalNumberOfPages: 0,
      totalNumberOfElements: 0,
      pageContent: [],
      _links: 'links',
    });

  response: IncomingMailsPaginatedSearchResultDto = {
    pageNumber: 0,
    pageSize: 10,
    totalNumberOfPages: 0,
    totalNumberOfElements: 0,
    pageContent: [],
    _links: 'links',
  };

  /**
   * Subject pour stocker la date de mise à jour des courriers.
   */
  private courrierUpdateDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());

  /**
   * Fonction qui permet de récupérer les courriers entrants en fonction des filtres
   * Si aucun filtre n'est passé en paramètre, la fonction retourne tous les courriers entrants
   *
   * @param filter : FilterCourriers
   * @returns Observable<ResponseDataIncommingMail>
   */
  getCourriersEntrants(
    filter?: IncomingMailPaginatedSearchQueryDto
  ): Observable<IncomingMailsPaginatedSearchResultDto> {
    let mailFiltered = this.mail;
    if (!filter) {
      filter = {
        specialTreatmentTypeCodeList: [],
        pageNumber: 0,
        pageSize: 10,
      };
    } else {
      mailFiltered = this.filterMails(filter, mailFiltered);
    }

    this.response.pageContent = mailFiltered;
    this.courrierDataSubject.next(this.response);
    this.courrierUpdateDate.next(new Date());
    return this.courrierDataSubject.asObservable();
  }

  /**
   *  Fonction qui permet de filtrer les courriers entrants en fonction des filtres
   *
   * @param filter
   * @param mailFiltered
   * @private
   */
  private filterMails(filter: IncomingMailPaginatedSearchQueryDto, mailFiltered: IncomingMailDto[]) {
    if (filter.mailTypeCodeList && filter.mailTypeCodeList.length > 0) {
      mailFiltered = this.mail.filter((mail) => filter?.mailTypeCodeList?.includes(mail.mailTypeCode));
    }
    if (filter.mailOriginCodeList && filter.mailOriginCodeList.length > 0) {
      mailFiltered = this.mail.filter((mail) => filter?.mailOriginCodeList?.includes(mail.mailOriginCode));
    }
    if (filter.specialTreatmentTypeCodeList && filter.specialTreatmentTypeCodeList.length > 0) {
      mailFiltered = this.mail.filter((mail) =>
        filter?.specialTreatmentTypeCodeList!.includes(mail.specialTreatmentTypeCode)
      );
    }
    if (filter.administrativeReferent) {
      mailFiltered = this.mail.filter((mail) =>
        filter?.administrativeReferent?.agentId?.includes(mail.mailApaPerson.administrativeReferent?.agentId ?? '')
      );
    }
    if (filter.mailExactImportDate) {
      mailFiltered = this.mail.filter((mail) => filter?.mailExactImportDate?.includes(mail.mailDepositDate));
    }
    if (filter.mailMinImportDate && filter.mailMaxImportDate) {
      mailFiltered = this.mail.filter(
        (mail) =>
          mail.mailDepositDate >= filter!.mailMinImportDate! && mail.mailDepositDate <= filter!.mailMaxImportDate!
      );
    }
    return mailFiltered;
  }

  /**
   * Fonction qui permet de supprimer un courrier entrant
   *
   * @param ids
   */
  deleteCourriers(ids: string[]): Observable<void> {
    this.mail = this.mail.filter((mail) => !ids.includes(mail.mailId));
    this.response.pageContent = this.mail;
    this.courrierDataSubject.next(this.response);
    return of();
  }

  /**
   * Fonction qui permet de mettre à jour un courrier entrant
   *
   * @param courrier
   * @returns Observable<IncommingMail>
   */
  updateCourrier(courrier: IncomingMailDto): Observable<IncomingMailDto> {
    const index = this.mail.findIndex((mail) => mail.mailId === courrier.mailId);
    this.mail[index] = courrier;
    this.courrierDataSubject.next(this.response);
    return of(this.courrierDataSubject.getValue().pageContent[index]);
  }

  updateIncomingMailReferentAdmin(updateInfo: ReferentUpdateRequestDto): Observable<any> {
    return EMPTY;
  }

  // Shared courrier data between components

  /**
   * Récupère la date de mise à jour des courriers.
   *
   * @returns {Observable<Date>} La date de mise à jour des courriers.
   */
  getCourrierUpdateDate(): Observable<Date> {
    return this.courrierUpdateDate.asObservable();
  }
}

import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { IncomingMailDto } from '../../../corbeille/models/incoming-mail.dto';
import { SearchGlobalService } from '../../../../shared/services/search-global.service';
import { DocumentTypeTranslation } from '../../../../shared/mappers/document-type.mapper';
import { TableMessage } from '../../../../shared/messages/table-message';
import { GestionCorbeilleData } from '../../../corbeille/data/gestion-corbeille.data';
import { searchApaAgentData } from 'src/app/modules/shared/data/search-apa-agent.data';
import { ApaAgentDto } from '../../../demande/models/apa-agent.dto';
import { BanetteSharingService } from '../../services/banette-sharing.service';
import { GestionDocumentService } from '../../../document-management/services/gestion-document.service';
import { AuthService } from '../../../../core/auth/services/auth.service';
import { EvidenceLineDto } from '../../../../shared/models/evidenceLineDto';
import { EvidencePropertyDto, InfoEvidence } from '../../../../shared/models/evidence-property.dto';
import { ReferentielService } from '../../../../shared/services/referentiel.service';
import { CorbeilleSearchPipe } from '../../../../shared/pipes/corbeille-search.pipe';

/**
 * Composant responsable de l'affichage du tableau de la corbeille dans la banette.
 * Affiche les éléments de la corbeille sous forme de tableau.
 */
@Component({
  selector: 'app-banette-tableau-corbeille',
  templateUrl: './banette-tableau-corbeille.component.html',
  styleUrls: ['./banette-tableau-corbeille.component.css'],
})
export class BanetteTableauCorbeilleComponent implements OnInit, OnDestroy {
  /** Titre du tableau. */
  @Input() titre?: string;

  /** Les éléments de la corbeille à afficher dans le tableau. */
  @Input() corbeille?: EvidenceLineDto[];
  /**
   * Le nombre de resultat de rechercher.
   */
  @Output() updateCountSearch = new EventEmitter<number>();
  @Output() resetCountSearch = new EventEmitter();
  /** Traductions des types de documents. */
  DocumentTypesTranslation = DocumentTypeTranslation;

  /** Nombre d'éléments à afficher. */
  itemsToShow = 10;

  /** Indique si la fenêtre popup doit être affichée. */
  showPopup = false;

  /** Messages affichés dans le tableau. */
  public message = TableMessage;
  /** Liste des référents. */
  referents: ApaAgentDto[] = [];
  /** Colonnes du tableau. */
  columns: string[] = ['Nom', 'Dossier', 'Supprimé par', 'Supprimé le'];

  /** Indique si toutes les lignes sont sélectionnées. */
  selectedAll = false;

  /** Élément sélectionné pour la fenêtre popup. */
  itemPop?: EvidenceLineDto;

  /** Données sélectionnées dans le tableau. */
  selectedData?: any[] = [];

  /** Position et valeur de tri pour les colonnes. */
  positionValue = {
    position: 0,
    value: false,
  };

  /** Valeur de filtrage. */
  filterValue = '';
  /**
   * Le nombre de resultat de rechercher.
   */
  public countSearch = 0;

  /**
   * Constructeur du composant.
   *
   * @param {SearchGlobalService} searchServcice Le service de recherche globale.
   * @param {GestionCorbeilleData} corbeilleService Le service de gestion de la corbeille.
   * @param {searchApaAgentData} searchApaAgentService Le service de recherche des référents APA.
   * @param banetteSharingService
   * @param corbeilleSearchPipe
   * @param gestionDocumentService
   * @param authService
   * @param referentielService
   */
  constructor(
    private searchServcice: SearchGlobalService,
    private corbeilleService: GestionCorbeilleData,
    private searchApaAgentService: searchApaAgentData,
    private banetteSharingService: BanetteSharingService,
    private corbeilleSearchPipe: CorbeilleSearchPipe,
    private gestionDocumentService: GestionDocumentService,
    private authService: AuthService,
    private referentielService: ReferentielService
  ) {}

  /** Méthode d'initialisation du composant. */
  ngOnInit() {
    this.searchServcice.resetSearchValue();
    this.searchServcice.getSearchValue().subscribe((value) => {
      this.filterValue = value;
      /**
       * Counter de le nombre de rechechers
       */
      if (this.corbeille?.length) {
        this.countSearch = this.filterCorbeille(this.corbeille, this.filterValue).length;
        this.updateCountSearch.emit(this.countSearch);
      }
    });
    this.searchApaAgentService.getListVisibleReferents([]).subscribe((data) => {
      this.referents = data;
    });
    this.resetCountSearch.emit();
    this.banetteSharingService.getSelectedElements().subscribe((data) => {
      this.selectedData = data as IncomingMailDto[];
    });
  }

  ngOnDestroy(): void {
    this.banetteSharingService.setSelectedElements([]);
  }

  /**
   * Augmente le nombre d'éléments à afficher dans le tableau
   */
  addMoreItemsToShow() {
    this.itemsToShow += 10; // Incrementa la cantidad de elementos a mostrar
  }

  /**
   * Sélectionner l'élément pour ouvrir la fenêtre POPUP
   *
   * @param {IncommingMail} item L'élément sélectionné
   */
  selecTedItem(item: EvidenceLineDto) {
    this.showPopup = !this.showPopup;
    this.itemPop = item;
  }

  /**
   * Sélectionner toutes les lignes du tableau
   */
  selectAllRows() {
    if (this.selectedData?.length == this.corbeille?.length) {
      this.selectedData = [];
      this.selectedAll = false;
    } else {
      this.selectedData = this.corbeille?.slice();
      this.selectedAll = true;
    }
    this.shareSelectedRows(this.selectedData!);
  }

  /**
   * Fonction permettant de sélectionner une ligne dans la colonne
   *
   * @param {number} index L'indice de la ligne à sélectionner
   */
  selectRow(index: number) {
    if (this.selectedData?.includes(this.corbeille?.[index] || null)) {
      this.selectedData?.splice(this.selectedData?.indexOf(this.corbeille?.[index] || null), 1);
      this.selectedAll = false;
    } else {
      this.selectedData?.push(this.corbeille?.[index] || null);
      if (this.selectedData?.length == this.corbeille?.length) this.selectedAll = true;
      else this.selectedAll = false;
    }
    this.shareSelectedRows(this.selectedData!);
  }

  /**
   * Sauvegarde les elements sélectionnés dans le service de partage des banettes
   *
   * @param {IncomingMailDto[]} data Données des lignes sélectionnées.
   */
  shareSelectedRows(data: IncomingMailDto[]) {
    this.banetteSharingService.setSelectedElements(data);
  }

  /**
   * Vérifier si un élément est sélectionné
   *
   * @param {number} index L'indice de l'élément sélectionné
   * @returns {boolean} True si l'élément est sélectionné, False sinon.
   */
  isSelected(index: number): boolean {
    return this.selectedData?.includes(this.corbeille?.[index] || null) ?? false;
  }

  /**
   * Trie le tableau en fonction de la colonne sélectionnée
   *
   * @param {number} index l'index de la colonne sélectionnée
   */
  sortRows(index: number) {
    const sortOrder = (a: EvidenceLineDto, b: EvidenceLineDto) => {
      let fieldA: any, fieldB: any;

      switch (index) {
        case 0:
          fieldA = a.lineName.toUpperCase();
          fieldB = b.lineName.toUpperCase();
          break;
        case 1:
          fieldA = a.individuDto.name.toUpperCase();
          fieldB = b.individuDto.name.toUpperCase();
          break;
        case 2:
          fieldA = a.deleterUserName.toUpperCase();
          fieldB = b.deleterUserName.toUpperCase();
          break;
        case 3:
          fieldA = a.deleteDate;
          fieldB = b.deleteDate;
          break;
        default:
          return 0;
      }

      if (fieldA < fieldB) return -1;
      if (fieldA > fieldB) return 1;
      return 0;
    };

    if (this.positionValue.position === index && this.positionValue.value) {
      this.positionValue.value = false;
      this.corbeille?.sort((a, b) => sortOrder(a, b));
    } else {
      this.corbeille?.sort((a, b) => -sortOrder(a, b));
      this.positionValue.position = index;
      this.positionValue.value = true;
    }
  }

  /**
   * Supprime un document de la corbeille.
   *
   * @param {string} id L'identifiant du document à supprimer.
   */
  deleteDoc(id?: string) {
    if (id) {
      this.corbeilleService.deleteDoc(id);
      this.gestionDocumentService.deleteEvidence(id ?? '', `cd-${this.authService.getUserInfo()?.departmentNumber}`);
    }
  }

  /* *
   * Méthode de calcul du nombre de rechechers
   */
  filterCorbeille(corbeille: EvidenceLineDto[], filterValue: string): EvidenceLineDto[] {
    return this.corbeilleSearchPipe.transform(corbeille, filterValue);
  }

  /**
   * Transformer la date au format correct: JJ/MM/AAAA
   *
   * @param date string avec la date
   * @returns string avec le format de date correct
   */
  formatDate(date: string) {
    const d = date.split('-');
    return d[2] + '/' + d[1] + '/' + d[0];
  }

  mapEvidenceProperty(evidencePropertyDto: EvidencePropertyDto[]): InfoEvidence {
    let contentType = '';
    let filename = '';
    let extension = '';
    evidencePropertyDto.forEach((value) => {
      switch (value.propertyKey) {
        case 'content-type':
          contentType = value.propertyValue;
          break;
        case 'filename':
          filename = value.propertyValue;
          break;
        case 'extension':
          extension = value.propertyValue;
          break;
      }
    });
    return {
      contentType,
      extension,
      filename,
    };
  }

  findLabelEnum(codeEnum?: string) {
    return this.referentielService.mapEnumItemCodeToItemLabel(codeEnum);
  }

  formatName(deleteUser?:string){
    let result = '';
    if(deleteUser){
      const parts = deleteUser.split(' ')
      if(parts.length === 2){
        result = parts[1].toUpperCase() + ' ' + parts[0]
      }
      if(parts.length === 1){
        result = parts[0];
      }    
    }
    return result
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppointmentDto } from '../../../rendez-vous/models/appointment.dto';
import { LabelMenuListActionRdv, MenuListActionRdv } from '../banette-tableau-rendez-vous/model/MenuListActionRdv';
import { SearchGlobalService } from '../../../../shared/services/search-global.service';
import { CNSAMenu, CNSASelect } from '@cnsa-fr/design-system';
import { ApaAgentDto } from '../../../demande/models/apa-agent.dto';
import { searchApaAgentData } from '../../../../shared/data/search-apa-agent.data';
import { ForfaitApaEnUrgenceSearchPipe } from '../../../../shared/pipes/forfait-apa-en-urgence-search.pipe';
import { ApaRequestTypeTranslation } from '../../../demande/enums/apa-request.type.enum';
import { ProcessingTypeEnum } from '../../../../shared/enums/referentiel-requests.enum';
import { SpecialTreatmentTypeEnum } from '../../../../shared/enums/special-treatment-type.enum';

/**
 * Composant pour afficher le tableau des forfaits APA en urgence.
 */
@Component({
  selector: 'app-banette-forfaits-apa-en-urgence',
  templateUrl: './banette-forfaits-apa-en-urgence.component.html',
  styleUrls: ['./banette-forfaits-apa-en-urgence.component.css'],
  providers: [ForfaitApaEnUrgenceSearchPipe],
})
export class BanetteForfaitsApaEnUrgenceComponent implements OnInit {
  /** Titre affiché au-dessus du tableau. */
  @Input() titre?: string;

  /** Liste des rendez-vous pour les forfaits APA en urgence à afficher dans le tableau. */
  @Input() rendezvous?: AppointmentDto[];
  /**
   * Le nombre de resultat de rechercher.
   */
  @Output() updateCountSearch = new EventEmitter<number>();
  @Output() resetCountSearch = new EventEmitter();
  /** Liste des évaluateurs pour les rendez-vous. */
  @Input() evaluateurs?: ApaAgentDto[];

  /** Liste des référents administratifs pour les rendez-vous. */
  @Input() referents?: ApaAgentDto[];

  protected readonly Boolean = Boolean;

  /** Constante pour le type de traitement spécial. */
  protected readonly DemandeRDV = SpecialTreatmentTypeEnum;

  /** Traductions des types de courrier. */
  RequestTypeTranslation = ApaRequestTypeTranslation;

  //protected readonly StatusRDV = StatusRDV;

  /** Référence pour la sélection du référent. */
  @ViewChild('selectReferent')
  readonly selectReferent?: ElementRef<CNSASelect>;

  @ViewChild('selectEvaluateur')
  readonly selectEvaluateur?: ElementRef<CNSASelect>;

  /** Référence pour le menu de rendez-vous. */
  @ViewChild('menuRdv')
  readonly menuRdv?: ElementRef<CNSAMenu>;

  /** Nombre d'éléments à afficher dans le tableau. */
  itemsToShow = 10;

  /** Actions du menu pour les rendez-vous. */
  listActionRdvs: MenuListActionRdv[] = [
    {
      label: LabelMenuListActionRdv.MODIFIER_LE_RENDEZ_VOUS,
      link: '',
      action: (rdv) => {
        // TODO Implement open modifier popup
      },
    },
    {
      label: LabelMenuListActionRdv.ANNULER_LE_RENDEZ_VOUS,
      link: '',
      action: (rdv) => {
        // TODO Implement open annuler rdv
      },
    },
    {
      label: LabelMenuListActionRdv.EDITER_LE_SMS_DE_RAPPEL,
      link: '',
      action: (rdv) => {
        // TODO Implement open editer sms
      },
    },
    {
      label: LabelMenuListActionRdv.EDITER_L_EMAIL_DE_RAPPEL,
      link: '',
      action: (rdv) => {
        // TODO Implement open editer email
      },
    },
    {
      label: LabelMenuListActionRdv.MODIFIER_LA_CARACTERISATION,
      link: '',
      action: (rdv) => {
        // TODO Implement open modifier caracterisation
        if (rdv.apaRequestSpecialTreatment.specialTreatmentType === SpecialTreatmentTypeEnum.EMERGENCY_APA_PLAN) return;
      },
    },
  ];

  /** Colonnes du tableau. */
  columns: string[] = ['Nom', 'Ville/Code postal', 'Référent admin.', 'Évaluateur', "Échéance plan d'aide"];

  /** Indique si toutes les lignes du tableau sont sélectionnées. */
  selectedAll = false;

  /** Liste des éléments sélectionnés dans le tableau. */
  selectedData?: any[] = [];

  /** Position et valeur de tri pour les colonnes. */
  positionValue = {
    position: 0,
    value: false,
  };

  /** Valeur de filtrage. */
  filterValue = '';

  /** Liste des rendez-vous de visites. */
  visites: AppointmentDto[] = [];
  /**
   * Le nombre de resultat de rechercher.
   */
  public countSearch = 0;

  /**
   * Constructeur du composant.
   *
   * @param {SearchGlobalService} searchServcice Service de recherche global.
   */
  constructor(
    private searchServcice: SearchGlobalService,
    private searchApaAgentService: searchApaAgentData,
    private forfaitApaEnUrgenceSearchPipe: ForfaitApaEnUrgenceSearchPipe
  ) {}

  /**
   * Méthode d'initialisation du composant.
   */
  ngOnInit() {
    this.searchApaAgentService.getListVisibleReferents([]).subscribe((data) => {
      this.referents = data;
    });

    this.searchServcice.getSearchValue().subscribe((value) => {
      this.filterValue = value;
      /**
       * Counter de le nombre de rechechers
       */
      if (this.rendezvous) {
        this.countSearch = this.filterForfaits(this.rendezvous, this.filterValue).length;
        this.updateCountSearch.emit(this.countSearch);
      }
    });
    this.resetCountSearch.emit();
  }

  /**
   * Augmente le nombre d'éléments à afficher dans le tableau
   */
  addMoreItemsToShow() {
    this.itemsToShow += 10; // Incrementa la cantidad de elementos a mostrar
  }

  /**
   * Sélectionner toutes les lignes du tableau
   */
  selectAllRows() {
    if (this.selectedData?.length == this.rendezvous?.length) {
      this.selectedData = [];
      this.selectedAll = false;
    } else {
      this.selectedData = this.rendezvous?.slice();
      this.selectedAll = true;
    }
  }

  /**
   * Fonction permettant de sélectionner une ligne dans la colonne
   *
   * @param {number} index L'indice de la ligne à sélectionner
   */
  selectRow(index: number) {
    if (this.selectedData?.includes(this.rendezvous?.[index])) {
      this.selectedData?.splice(this.selectedData?.indexOf(this.rendezvous?.[index]), 1);
      this.selectedAll = false;
    } else {
      this.selectedData?.push(this.rendezvous?.[index]);
      this.selectedAll = this.selectedData?.length === this.rendezvous?.length;
    }
  }

  /**
   * Vérifier si un rendez-vous est sélectionné
   *
   * @param {number} index L'indice de l'élément à vérifier
   * @returns {boolean} True si l'élément est sélectionné sinon False.
   */
  isSelected(index: number) {
    return this.selectedData?.includes(this.rendezvous?.[index]);
  }

  /**
   * Trie le tableau en fonction de la colonne sélectionnée
   *
   * @param {number} index L'indice de la colonne sélectionnée
   */
  sortRows(index: number) {
    const sortOrder = (a: any, b: any) => {
      let fieldA: any, fieldB: any;

      switch (index) {
        case 0:
          fieldA = a.requesterSituation.requesterIdentity.lastName.toUpperCase();
          fieldB = b.requesterSituation.requesterIdentity.lastName.toUpperCase();
          break;
        case 1:
          fieldA = a.requesterSituation.requesterCoordinates.currentResidenceAddress.placeName;
          fieldB = b.requesterSituation.requesterCoordinates.currentResidenceAddress.placeName;
          break;
        case 2:
          fieldA = a.requesterSituation.requesterIdentity.administrativeReferent.firstName;
          fieldB = b.requesterSituation.requesterIdentity.administrativeReferent.firstName;
          break;
        case 3:
          fieldA = a.evaluator.firstName;
          fieldB = b.evaluator.firstName;
          break;
        case 4:
          fieldA = a.visitDate;
          fieldB = b.visitDate;
          break;
        case 5:
          fieldA = a.apaRequestCompletionDate;
          fieldB = b.apaRequestCompletionDate;
          break;
        default:
          return 0;
      }

      if (fieldA < fieldB) return -1;
      if (fieldA > fieldB) return 1;
      return 0;
    };

    if (this.positionValue.position === index && this.positionValue.value) {
      this.positionValue.value = false;
      this.rendezvous?.sort((a, b) => sortOrder(a, b));
    } else {
      this.rendezvous?.sort((a, b) => -sortOrder(a, b));
      this.positionValue.position = index;
      this.positionValue.value = true;
    }
    this.rendezvous = this.rendezvous?.slice();
  }

  /**
   * Fonction permettant de modifier le référent administratif pour un courrier spécifique.
   *
   * @param {any} referent valeur du sélecteur.
   * @param {number} index L'indice du rendez-vous dans la liste.
   */
  changeReferent(referent: ApaAgentDto, index: any) {
    this.rendezvous![index].requesterSituation.requesterIdentity.administrativeReferent!.firstName = referent.firstName;
    this.rendezvous![index].requesterSituation.requesterIdentity.administrativeReferent!.lastName = referent.lastName;
  }

  /**
   * Fonction permettant de modifier l'évaluateur pour un courrier spécifique.
   *
   * @param {any} event L'événement de changement de valeur de l'évaluateur.
   * @param {any} index L'indice du renddz-vous dans la liste.
   */
  changeEvaluateur(event: any, index: any) {
    if (this.rendezvous) {
      this.rendezvous[index].evaluator.firstName = event.target.value.split(' ')[0];
      this.rendezvous[index].evaluator.lastName = event.target.value.split(' ')[1];
    }
  }

  //TODO will be implemented when the modal SFD E2-UC07 : Affectation d’un dossier
  /**
   * Méthode pour ouvrir le modal d'affectation d'un dossier au référent.
   *
   * @param {MouseEvent} $event L'événement de clic de la souris.
   * @param {Rendezvous} item L'objet Rendezvous associé au modal.
   */
  openModalAffectationDossierReferent($event: MouseEvent, item: AppointmentDto) {
    this.selectReferent?.nativeElement.hide();
  }

  //TODO will be implemented when the modal SFD E2-UC07 : Affectation d’un dossier

  /**
   * Méthode pour ouvrir le modal d'affectation d'un dossier à l'évaluateur.
   *
   * @param {MouseEvent} $event L'événement de clic de la souris.
   * @param {number} i
   */
  openModalAffectationDossierEvaluateurs($event: MouseEvent, i: number) {
    this.selectEvaluateur?.nativeElement.hide();
  }

  selectDefaultAction($event: any) {
    //TODO will be implemented with the form
  }

  /**
   * Énumération des libellés d'actions possibles dans le menu contextuel des rendez-vous.
   */
  protected readonly LabelMenuListActionRdv = LabelMenuListActionRdv;

  /**
   * Méthode pour soustraire les dates et renvoyer la différence en jours.
   *
   * @param {string} date La date cible sous forme de chaîne ou null.
   * @returns {string} La différence entre la date cible et aujourd'hui en jours.
   */
  subtractDates(date: string | null): string {
    if (!date) {
      return '';
    }

    const today = new Date().getTime();
    const targetDate = new Date(date).getTime();
    const differenceInMilliseconds = targetDate - today;

    // Convert the difference to days
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    // Return the difference as a string
    return differenceInDays.toString();
  }

  /**
   * Type spécial de traitement.
   */
  protected readonly ProcessingTypeEnum = ProcessingTypeEnum;

  /* *
   * Méthode de calcul du nombre de rechechers
   * */
  filterForfaits(rendezvous: AppointmentDto[], filterValue: string): AppointmentDto[] {
    return this.forfaitApaEnUrgenceSearchPipe.transform(rendezvous, filterValue);
  }
}

import { Component } from '@angular/core';

/**
 * Composant principale: pied de page
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {}

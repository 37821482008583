import { NotificationVariant } from '../enums/NotificationVariant.enum';
import { NotificationRequestOptions } from '../interfaces/NotificationRequestOptions.interface';
import { environment } from '../../../../environments/environment.prod';

/**
 * Une classe représentant une demande de notification affichée à l'utilisateur.
 */
export class NotificationRequest {
  /**
   * Variante de la notification.
   */
  variant: NotificationVariant;
  /**
   * Durée d'affichage de la notification en millisecondes.
   */
  duration: number;
  /**
   * Indique si la notification peut être fermée par l'utilisateur.
   */
  closable: boolean;
  /**
   * Message de la notification.
   */
  message?: string;

  /**
   * Constructeur du type NotificationRequest
   *
   * @param {string} title Le titre de la notification.
   * @param {Partial<NotificationRequestOptions>} options Options de la demande de notification.
   */
  constructor(public title: string, options?: Partial<NotificationRequestOptions>) {
    this.variant = options?.variant ?? NotificationVariant.INFO;
    this.duration = options?.duration ?? environment.warningShowingChargementNotification;
    this.closable = options?.closable ?? true;
    this.message = options?.message;
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SiApaBanetteGeneriquePageComponent } from './si-apa-banette-generique-page/si-apa-banette-generique-page.component';

const routes: Routes = [
  {
    path: ':titre',
    component: SiApaBanetteGeneriquePageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BanetteRoutingModule {}

// TODO #referentiel - Old Enum: IncomingMailOriginEnum
export enum CommunicationChannelEnum {
  /**
   *   Téléphone
   */
  TELEPHONE_CommunicationChannel = 'TELEPHONE-CommunicationChannel',
  /**
   *  Email
   */
  EMAIL_CommunicationChannel = 'EMAIL-CommunicationChannel',
  /**
   *   Courrier
   */
  COURRIER_CommunicationChannel = 'COURRIER-CommunicationChannel',
  /**
   *   SMS
   */
  SMS_CommunicationChannel = 'SMS-CommunicationChannel',
  /**
   *   Téléservice DAA
   */
  TS_CommunicationChannel = 'TS-CommunicationChannel',
  //  TODO #referentiel: Old Enum
  UNKNOWN = 'UNKNOWN',
}

// TODO #referentiel - Old Enum: OutgoingMailTypeEnum
export enum MessageTypeEnum {
  /**
   *   Accusé de réception - Demande complète - Type de courrier : Complétude
   */
  RECEPTION_DEMANDE_COMPLETE_MessageType = 'RECEPTION_DEMANDE_COMPLETE-MessageType',
  /**
   *   Accusé de réception - Demande incomplète - Type de courrier : Incomplétude
   */
  RECEPTION_DEMANDE_INCOMPLETE_MessageType = 'RECEPTION_DEMANDE_INCOMPLETE-MessageType',
  /**
   *   Courrier de relance - Demande incomplète - Type de courrier : Incomplétude
   */
  RELANCE_INCOMPLET_MessageType = 'RELANCE_INCOMPLET-MessageType',
  /**
   *   Courrier de rejet - Demande incomplète  - Type de courrier : Incomplétude
   */
  REJET_INCOMPLET_MessageType = 'REJET_INCOMPLET-MessageType',
  /**
   *   Accusé de réception - Demande révision - Type de courrier : Complétude
   */
  RECEPTION_REVISION_MessageType = 'RECEPTION_REVISION-MessageType',
  /**
   *   Accusé de réception - Demande CMI complète - Type de courrier : Complétude
   */
  RECEPTION_CMI_COMPLETE_MessageType = 'RECEPTION_CMI_COMPLETE-MessageType',
  /**
   *  Accusé de réception - Demande CMI modif mention - Type de courrier : Complétude
   */
  RECEPTION_CMI_MODIF_MessageType = 'RECEPTION_CMI_MODIF-MessageType',
  /**
   *  Accusé de réception - Demande CMI incomplète - Type de courrier : Incomplétude
   */
  RECEPTION_CMI_INCOMPLETE_MessageType = 'RECEPTION_CMI_INCOMPLETE-MessageType',
  /**
   *  Courrier de relance - Demande CMI incomplète - Type de courrier : Relance
   */
  RELANCE_CMI_INCOMPLETE_MessageType = 'RELANCE_CMI_INCOMPLETE-MessageType',
  /**
   *   Courrier de rejet - Demande CMI (Non bénéficiaire APA) - Type de courrier : Rejet
   */
  REJET_CMI_MessageType = 'REJET_CMI-MessageType',
  /**
   *  Courrier de rejet administratif (âge et résidence) - Type de courrier : Rejet
   */
  REJET_ADMINISTRATIF_AGE_MessageType = 'REJET_ADMINISTRATIF_AGE-MessageType',
  /**
   *  Courrier de rejet administratif (cause décès) - Type de courrier : Rejet
   */
  REJET_ADMINISTRATIF_DECES_MessageType = 'REJET_ADMINISTRATIF_DECES-MessageType',
  /**
   *  Courrier de rejet demande de révision (ancien bénéficiaire ou demandeur GIR 5/6)  - Type de courrier : Rejet
   */
  REJET_REVISION_MessageType = 'REJET_REVISION-MessageType',
  /**
   *   Visite à domicile : courrier de visite à domicile - Type de courrier : Rendez-vous
   */
  VISITE_DOMICILE_COURRIER_MessageType = 'VISITE_DOMICILE_COURRIER-MessageType',
  /**
   *   RDV téléphonique : Courrier de rendez-vous téléphonique Type de courrier : Rendez-vous
   */
  RDV_TEL_COURRIER_MessageType = 'RDV_TEL_COURRIER-MessageType',
  /**
   *   Echange
   */
  ECHANGE_MessageType = 'ECHANGE-MessageType',
  /**
   *   Courrier entrant
   */
  COURRIER_ENTRANT_MessageType = 'COURRIER_ENTRANT-MessageType',
  /**
   *   Rappel de rendez-vous (SMS/E-mail)
   */
  RAPPEL_RDV_MessageType = 'RAPPEL_RDV-MessageType',
}

// TODO #referentiel - N'existe pas dans référentiel
export enum TypeCourrierEnum {
  COMPLETUDE_TypeCourrier = 'Complétude',
  INCOMPLETUDE_TypeCourrier = 'Incomplétude',
  RELANCE_TypeCourrier = 'Relance',
  REJET_TypeCourrier = 'Rejet',
  RDV_TypeCourrier = 'RV téléphonique',
  VISITE_typeCourrier = 'Visite à domicile'
}

/**
 * Liste des codes MessageTypes correspondant à Complétude pour le filtre.
 */
export const completeTypeCourrierSortant: MessageTypeEnum[] = [
  MessageTypeEnum.RECEPTION_DEMANDE_COMPLETE_MessageType,
  MessageTypeEnum.RECEPTION_REVISION_MessageType,
  MessageTypeEnum.RECEPTION_CMI_COMPLETE_MessageType,
  MessageTypeEnum.RECEPTION_CMI_MODIF_MessageType,
];
/**
 * Liste des codes MessageTypes correspondant à Incomplétude pour le filtre.
 */
export const imcompleteTypeCourrierSortant: MessageTypeEnum[] = [
  MessageTypeEnum.RECEPTION_DEMANDE_INCOMPLETE_MessageType,
  MessageTypeEnum.RELANCE_INCOMPLET_MessageType,
  MessageTypeEnum.REJET_INCOMPLET_MessageType,
  MessageTypeEnum.RECEPTION_CMI_INCOMPLETE_MessageType,
];
/**
 * Liste des codes MessageTypes correspondant à Relance pour le filtre.
 */
export const relanceTypeCourrierSortant: MessageTypeEnum[] = [MessageTypeEnum.RELANCE_CMI_INCOMPLETE_MessageType];
/**
 * Liste des codes MessageTypes correspondant à Rejet pour le filtre.
 */
export const rejetTypeCourrierSortant: MessageTypeEnum[] = [
  MessageTypeEnum.REJET_CMI_MessageType,
  MessageTypeEnum.REJET_ADMINISTRATIF_AGE_MessageType,
  MessageTypeEnum.REJET_ADMINISTRATIF_DECES_MessageType,
  MessageTypeEnum.REJET_REVISION_MessageType,
];
/**
 * Liste des codes MessageTypes correspondant à Rendez-vous pour le filtre.
 */
export const rdvTypeCourrierSortant: MessageTypeEnum[] = [
  MessageTypeEnum.RDV_TEL_COURRIER_MessageType,
];
export const visiteTypeCourrierSortant: MessageTypeEnum[] = [
  MessageTypeEnum.VISITE_DOMICILE_COURRIER_MessageType,
];

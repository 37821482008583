/**
 * Énumération des types de documents.
 */
export enum DocumentTypeEnum {
  /**
   * Coordonnées bancaires.
   */
  BANK_DETAILS = 'BANK_DETAILS',
  /**
   * Avis de taxation ou de non-imposition du demandeur.
   */
  NOTICE_TAXATION_OR_NON_TAXATION_OF_APPLICANT = 'NOTICE_TAXATION_OR_NON_TAXATION_OF_APPLICANT',
  /**
   * Avis de taxation ou de non-imposition du conjoint.
   */
  NOTICE_TAXATION_OR_NON_TAXATION_OF_SPOUSE = 'NOTICE_TAXATION_OR_NON_TAXATION_OF_SPOUSE',
  /**
   * Formulaire.
   */
  FORM = 'FORM',
  /**
   * Document d'identité.
   */
  IDENTITY_DOCUMENT = 'IDENTITY_DOCUMENT',
  /**
   * Certificat médical.
   */
  MEDICAL_CERTIFICATE = 'MEDICAL_CERTIFICATE',
  /**
   * Avis de taxe foncière.
   */
  NOTICE_PROPERTY_TAX = 'NOTICE_PROPERTY_TAX',
  /**
   * Décision de mesure de protection.
   */
  PROTECTIVE_MEASURE_DECISION = 'PROTECTIVE_MEASURE_DECISION',
  /**
   * Autre type de document.
   */
  OTHER = 'OTHER',
}

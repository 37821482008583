import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Service de recherche globale qui permet de stocker et récupérer la valeur de recherche dans toute l'application.
 */
@Injectable({
  providedIn: 'root',
})
export class SearchGlobalService {
  /**
   * Le sujet BehaviorSubject pour stocker la valeur à rechercher
   */
  private searchValueSubject = new BehaviorSubject<string>('');
  private courrierData = new BehaviorSubject<any>([]);

  currentData = this.courrierData.asObservable();

  changeData(data: any) {
    this.courrierData.next(data);
  }

  /**
   * Stocke la valeur à rechercher.
   *
   * @param {string} value la valeur à stocker.
   */
  setSearchValue(value: string): void {
    this.searchValueSubject.next(value);
  }

  /**
   * Récupère la valeur recherchée.
   *
   * @returns {Observable<string>} la valeur récupérée.
   */
  getSearchValue(): Observable<string> {
    return this.searchValueSubject.asObservable();
  }
  resetSearchValue() {
    this.searchValueSubject.next('');
  }
}

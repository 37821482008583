import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CNSADialog } from '@cnsa-fr/design-system';
import { ApaAgentDto } from '../../../demande/models/apa-agent.dto';
import { PAGE_SIZE } from '../../../../shared/utils/globalConstants';
import { GestionCourriersEntrantsData } from '../../../courrier/data/gestion-courriers-entrants.data';
import { IncomingMailPaginatedSearchQueryDto } from '../../../courrier/models/incoming-mail-paginated-search-query.dto';
import { ProcessingTypeEnum, TypeOfProcessEnum } from '../../../../shared/enums/referentiel-requests.enum';
import { AgentTypeEnum } from '../../../../shared/enums/referentiel-person.enum';
import { CommunicationChannelEnum } from '../../../../shared/enums/referentiel-communication.enum';
/**
 * Composant pour afficher les filtres des courriers et les utiliser pour filtrer les courriers entrants.
 */
@Component({
  selector: 'app-banette-filtres-courriers',
  templateUrl: './banette-filtres-courriers.component.html',
  styleUrls: ['./banette-filtres-courriers.component.css'],
})
export class BanetteFiltresCourriersComponent implements OnChanges {
  /**
   * Titre du composant (optionnel).
   */
  @Input() titre?: string;

  /**
   * Indicateur pour afficher ou masquer la boîte de dialogue.
   */
  @Input() showDialog = false;

  /**
   * Indicateur pour filtrer par plan APA d'urgence.
   */
  @Input() isEmergencyApaPlan = false;

  /**
   * Indicateur pour filtrer par procédure accélérée.
   */
  @Input() isAcceleratedProcedure = false;

  /**
   * Liste des référents APA disponibles.
   */
  @Input() referents?: ApaAgentDto[];

  /**
   * Événement émis lorsque la boîte de dialogue est fermée.
   */
  @Output() closeDialog = new EventEmitter();
  @Input() selectedReferent: ApaAgentDto | undefined = undefined;

  /**
   * Événement émis lorsque le nombre de filtres sélectionnés.
   */
  @Output() checkedCounterChange = new EventEmitter<number | boolean>();

  @Output() updateModale = new EventEmitter<boolean>();

  @Output() updateProfilName = new EventEmitter<AgentTypeEnum>();

  /**
   * Référence au dialogue (si utilisée dans le modèle).
   */
  @ViewChild('dialog') dialog?: ElementRef<CNSADialog>;

  /**
   * Filtre actuel pour les courriers.
   */
  filterCourriers: IncomingMailPaginatedSearchQueryDto = {
    pageNumber: 0,
    pageSize: PAGE_SIZE,
  };

  /**
   * Liste des origines des courriers.
   */
  origins: CommunicationChannelEnum[] = [];

  /**
   * Liste des types de courriers.
   */
  types: TypeOfProcessEnum[] = [];

  /**
   * Liste des typifications des courriers.
   */
  typifications: ProcessingTypeEnum[] = [];

  /**
   * Enumération des types de courriers.
   */
  TypeOfProcessEnum = TypeOfProcessEnum;

  /**
   * Enumération des origines des courriers.
   */
  CommunicationChannelEnum = CommunicationChannelEnum;

  /**
   * Enumération des typifications des courriers.
   */
  ProcessingTypeEnum = ProcessingTypeEnum;

  /**
   * Format actuel pour la date (par défaut 'precise').
   */
  dateForm = 'periode';

  /**
   * counter pour le nombre de filtres sélectionnés.
   */
  checkedCounter = 0;
  filterCount = 0;

  /**
   * Crée une instance du composant BanetteFiltresCourriersComponent.
   *
   * @param {GestionCourriersEntrantsData} gestionCourrierEntrantsData Le service pour gérer les courriers entrants.
   */
  constructor(private gestionCourrierEntrantsData: GestionCourriersEntrantsData) {}

  /**
   * Méthode appelée lorsque les entrées de ce composant changent.
   *
   * @param {SimpleChanges} { isEmergencyApaPlan, isAcceleratedProcedure } Les changements observés.
   */
  ngOnChanges({ isEmergencyApaPlan, isAcceleratedProcedure }: SimpleChanges): void {
    // Emergency apa plan special treatment change
    if (isEmergencyApaPlan && !isEmergencyApaPlan.firstChange) {
      this.updateSpecialTreatmentTypeSearch(this.isEmergencyApaPlan, ProcessingTypeEnum.APA_URGENCE_ProcessingType);
    }
    // Accelerated procedure special treatment change
    if (isAcceleratedProcedure && !isAcceleratedProcedure.firstChange) {
      this.updateSpecialTreatmentTypeSearch(
        this.isAcceleratedProcedure,
        ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType
      );
    }
  }

  /**
   * Met à jour les filtres de recherche en fonction de la typification du courrier.
   *
   * @param {boolean} type Le type de filtre (vrai ou faux).
   * @param {ProcessingTypeEnu} specialTreatmentType La typification du courrier.
   */
  private updateSpecialTreatmentTypeSearch(type: boolean, specialTreatmentType: ProcessingTypeEnum) {
    setTimeout(() => {
      // if type is true add it to search filter
      if (type) {
        this.typifications.push(specialTreatmentType);
      } else {
        // if type is false remove it from search filter
        this.typifications = this.typifications.filter(
          (specialTreatmentTypeFilter) => specialTreatmentTypeFilter !== specialTreatmentType
        );
      }
      this.filtrerCourrier();
    });
  }

  /**
   * Change la caractérisation du courrier en fonction de l'événement fourni.
   *
   * @param {Event} event L'événement de changement de caractérisation du courrier.
   */
  changeCaracterisationCourrier(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (this.typifications.includes(checkbox.value as ProcessingTypeEnum)) {
      const index = this.typifications.indexOf(checkbox.value as ProcessingTypeEnum);
      if (index !== -1) {
        this.typifications.splice(index, 1);
      }
    } else this.typifications.push(checkbox.value as ProcessingTypeEnum);
  }

  /**
   * Change la provenance du courrier en fonction de l'événement fourni.
   *
   * @param {Event} event L'événement de changement de provenance du courrier.
   */
  changeProvenanceCourrier(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    if (this.origins.includes(checkbox.value as CommunicationChannelEnum)) {
      const index = this.origins.indexOf(checkbox.value as CommunicationChannelEnum);
      if (index !== -1) {
        this.origins.splice(index, 1);
      }
    } else this.origins.push(checkbox.value as CommunicationChannelEnum);
  }

  /**
   * Change le type du courier en fonction de l'événement fourni.
   *
   * @param {Event} event L'événement déclenché pour le changement du type de courrier.
   */
  changeTypeCourrier(event: Event) {
    const target = event.target as HTMLInputElement;
    if (this.types.includes(target.value as TypeOfProcessEnum)) {
      const index = this.types.indexOf(target.value as TypeOfProcessEnum);
      if (index !== -1) {
        this.types.splice(index, 1);
      }
    } else this.types.push(target.value as TypeOfProcessEnum);
  }

  /**
   * Change le référent qui a supprimé le courrier en fonction de l'événement fourni.
   *
   * @param {Event} event L'événement déclenché pour le changement de référent.
   */
  changeReferent(event: Event) {
    const target = event.target as HTMLInputElement;
    this.filterCourriers.administrativeReferent = {
      agentId: target.value,
      departmentName: '',
      departmentNumber: '',
      firstName: '',
      lastName: '',
      userId: '',
      workEmailAddress: '',
      workPhoneNumber: '',
    };
  }

  /**
   * Méthode appelée lorsqu'une forme de date est modifiée.
   *
   * @param {Event} event L'événement de modification.
   */
  changeDateForm(event: Event) {
    const target = event.target as HTMLInputElement;
    this.dateForm = target.value;   
  }

  /**
   * Bascule l'affichage de la boîte de dialogue.
   * Cache la boîte de dialogue en émettant un événement closeDialog.
   */
  toggleDialog() {
    this.showDialog = false;
    this.closeDialog.emit();
  }

  /**
   * Réinitialise les filtres des courriers.
   * Appelle le service gestionCourrierEntrantsData pour récupérer tous les courriers entrants sans filtres.
   */
  resetFiltersCourriers() {
    this.gestionCourrierEntrantsData.getCourriersEntrants().subscribe();
    this.checkedCounterChange.emit(false);
    this.filterCourriers = {
      pageNumber: 0,
      pageSize: PAGE_SIZE,
    };
    this.filterCourriers.administrativeReferent = {
      agentId: '',
      departmentName: '',
      departmentNumber: '',
      firstName: '',
      lastName: '',
      userId: '',
      workEmailAddress: '',
      workPhoneNumber: '',
    };

    this.origins = [];
    this.types = [];
    this.typifications = [];
    this.dateForm = 'periode';
    this.filterCourriers.mailMinImportDate = undefined;
    this.filterCourriers.mailMaxImportDate = undefined;
    this.filterCourriers.mailExactImportDate = undefined;

    this.filterCount = 0;
    this.checkedCounter = this.filterCount;
    this.selectedReferent = undefined;
    this.checkedCounterChange.emit(this.checkedCounter); // "counter"pour envoyer les informations au composant père avec le nombre de filtres sélectionnés.
  }

  /**
   * Filtre les courriers entrants en fonction des filtres actuels.
   * Appelle le service gestionCourrierEntrantsData pour récupérer les courriers filtrés.
   */

  filtrerCourrier() {
    if (this.selectedReferent) {
      this.filterCourriers.administrativeReferent = this.selectedReferent;
    }
    // Initialise un compteur temporaire
    this.filterCount = 0;
    if (this.origins.length > 0) {
      this.filterCourriers.mailOriginCodeList = this.origins;
      this.filterCount += this.origins.length; // Incrémente le compteur temporaire
    } else {
      delete this.filterCourriers.mailOriginCodeList;
    }

    if (this.types.length > 0) {
      this.filterCourriers.mailTypeCodeList = this.types;
      this.filterCount += this.types.length; // Incrémente le compteur temporaire
    } else {
      delete this.filterCourriers.mailTypeCodeList;
    }

    if (this.typifications.length > 0) {
      this.filterCourriers.specialTreatmentTypeCodeList = this.typifications;
      this.filterCount += this.typifications.length; // Incrémente le compteur temporaire
    } else {
      delete this.filterCourriers.specialTreatmentTypeCodeList;
    }

    if (this.filterCourriers.administrativeReferent && this.filterCourriers.administrativeReferent.agentId) {
      this.filterCount++; // Incrémente le compteur temporaire si agentId a une valeur
    }
    if (
      this.filterCourriers.mailMinImportDate !== undefined ||
      this.filterCourriers.mailMaxImportDate !== undefined ||
      this.filterCourriers.mailExactImportDate !== undefined
    ) {
      if(this.dateForm == 'periode'){
        this.filterCourriers.mailExactImportDate = undefined;
      }else{
        this.filterCourriers.mailMinImportDate = undefined;
        this.filterCourriers.mailMaxImportDate = undefined;
      }
      this.filterCount++;
    }

    // Met à jour le compteur réel seulement après l'avoir calculé
    this.checkedCounter = this.filterCount;
    if (this.checkedCounter === 0) {
      this.gestionCourrierEntrantsData.getCourriersEntrants().subscribe();
      this.toggleDialog();

      this.checkedCounterChange.emit(this.checkedCounter);
    } else {
      this.gestionCourrierEntrantsData.getCourriersEntrants(this.filterCourriers).subscribe();
      this.toggleDialog();

      this.checkedCounterChange.emit(this.checkedCounter);
    }
  }

  onClickSelect(): void {
    // event.preventDefault();
    this.updateModale.emit(true);
    this.updateProfilName.emit(AgentTypeEnum.ADMINISTRATIF_AgentType);
  }
}

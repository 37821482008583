import { Pipe, PipeTransform } from '@angular/core';
import { AppointmentDto } from '../../pages/rendez-vous/models/appointment.dto';
import { AppointmentWithTaskDto } from '../../pages/rendez-vous/models/Appointment-with-task.dto';

/**
 * Pipe personnalisée pour filtrer une liste de rendez-vous en fonction de la valeur de filtrage fournie.
 */
@Pipe({
  name: 'rendezVousSearch',
})
export class RendezVousSearchPipe implements PipeTransform {
  /**
   * Filtrer une liste de rendez-vous en fonction de la valeur de filtrage fournie.
   *
   * @param {AppointmentDto[]} rendezvous La liste de rendez-vous à filtrer.
   * @param {string} filterValue La valeur de filtrage utilisée pour sélectionner les rendez-vous.
   * @returns {AppointmentDto[]} Une nouvelle liste de rendez-vous filtrée en fonction de la valeur de filtrage.
   */
  transform(rendezvous: AppointmentWithTaskDto[], filterValue: string): AppointmentWithTaskDto[] {
    if (!rendezvous || !filterValue) {
      return rendezvous; // Retourne la liste non filtrée si la liste ou la valeur de filtrage est vide
    }

    filterValue = filterValue.toLowerCase(); // Convertit la valeur de filtrage en minuscules

    return rendezvous.filter((rdv: AppointmentWithTaskDto) => {
      const lastName = rdv.appointment.requesterSituation.requesterIdentity?.lastName
        ?.toLowerCase()
        .includes(filterValue);
      const firstName = rdv.appointment.requesterSituation.requesterIdentity?.firstName
        ?.toLowerCase()
        .includes(filterValue);
      const nir = rdv.appointment.requesterSituation.requesterIdentity?.nir?.toLowerCase().includes(filterValue);
      const requestType = rdv.appointment.apaRequestType?.toLowerCase().includes(filterValue);
      const placeName = rdv.appointment.requesterSituation.requesterCoordinates?.currentResidenceAddress?.placeName
        ?.toLowerCase()
        .includes(filterValue);
      const zipCode = rdv.appointment.requesterSituation.requesterCoordinates?.currentResidenceAddress?.zipCode
        ?.toLowerCase()
        .includes(filterValue);
      const dateComplet = rdv.appointment.apaRequestCompletionDate?.toLowerCase().includes(filterValue);
      const administrativeFirstName =
        rdv.appointment.requesterSituation.requesterIdentity?.administrativeReferent?.firstName
          ?.toLowerCase()
          .includes(filterValue);
      const administrativeLastName =
        rdv.appointment.requesterSituation.requesterIdentity?.administrativeReferent?.lastName
          ?.toLowerCase()
          .includes(filterValue);
      const evaluatorFirstName = rdv.appointment.evaluator?.firstName?.toLowerCase().includes(filterValue);
      const evaluatorLastName = rdv.appointment.evaluator?.lastName?.toLowerCase().includes(filterValue);

      return (
        lastName ||
        firstName ||
        nir ||
        requestType ||
        placeName ||
        zipCode ||
        dateComplet ||
        administrativeFirstName ||
        administrativeLastName ||
        evaluatorFirstName ||
        evaluatorLastName
      );
    });
  }
}

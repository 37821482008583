<!-- Liens d'accès rapides -->

<app-skip-to-content></app-skip-to-content>
<ng-template [ngIf]="isAuthenticate">
  <!-- Entête de l'application -->
  <app-header></app-header>

  <!-- Contenu principal -->
  <main role="main" id="main" class="main">
    <div class="main-container">
      <router-outlet></router-outlet>
    </div>
  </main>

  <!-- Footer de l'application -->
  <app-footer />

  <!--Container des notifications en bas de page-->
  <app-notification />
</ng-template>

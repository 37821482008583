import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe personnalisée pour ajouter un nombre de jours à une date donnée.
 */
@Pipe({
  name: 'dateAdd',
})
export class DateAddPipe implements PipeTransform {
  /**
   * Ajoute un nombre spécifié de jours à une date donnée.
   *
   * @param {string} date La date au format de chaîne de caractères (au format 'yyyy-MM-dd') ou null.
   * @param {number} days Le nombre de jours à ajouter.
   * @returns {Date} Une nouvelle date après l'ajout des jours spécifiés ou null si la date d'entrée est également null ou invalide.
   */
  transform(date: string | null, days: number): Date | null {
    if (date === null) {
      return null;
    }

    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return null;
    }

    const result = new Date(parsedDate);
    result.setDate(result.getDate() + days);
    return result;
  }
}

import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { IncomingMailsPaginatedSearchResultDto } from '../models/Incoming-mails-paginated-search-result.dto';
import { environment as envDev } from '../../../../../environments/environment';
import { environment as envProd } from '../../../../../environments/environment.prod';
import { PAGE_SIZE } from '../../../shared/utils/globalConstants';
import { GestionCorbeilleData } from '../data/gestion-corbeille.data';
import { IncomingMailPaginatedSearchQueryDto } from '../../courrier/models/incoming-mail-paginated-search-query.dto';
import { EvidenceDto } from '../../../shared/models/evidence.dto';
import { EvidencePaginatedSearchQueryDto } from '../models/EvidencePaginatedSearchQueryDto';
import { EvidenceLineDto, ListEvidenceLine } from '../../../shared/models/evidenceLineDto';

/**
 * Service de gestion des courriers entrants supprimées (Corbeille).
 */
@Injectable()
export class GestionCorbeilleService extends GestionCorbeilleData {
  /**
   * Endpoint de l'API Siapa pour la récupération des courriers entrants.
   */
  private readonly siapaPieceInterRestApi;

  /**
   * Endpoint de l'API Siapa pour la gestion des courriers entrants.
   */
  private readonly endpoint;

  /**
   * Constructeur du service de gestion des courriers entrants supprimées (Corbeille).
   *
   * @param httpClient - Injection du service HTTPClient pour les appels API REST.
   */
  constructor(private httpClient: HttpClient) {
    super();
    if (isDevMode()) {
      this.endpoint = envDev.endPointCorbeille;
      this.siapaPieceInterRestApi = envDev.siapaPieceInterRestApi + this.endpoint;
    } else {
      this.endpoint = envProd.endPointCorbeille;
      this.siapaPieceInterRestApi = envProd.siapaPieceInterRestApi + this.endpoint;
    }
  }

  /**
   * Récupère les courriers entrants supprimées (Corbeille).
   *
   * @param filter - (Facultatif) Filtre pour la récupération des courriers.
   * @returns Un Observable contenant la réponse de gestion des boîtes de réception.
   * @author Carlos Poveda
   */
  getCorbeille(
    filter: EvidencePaginatedSearchQueryDto = {
      statusCodeList: ['SUPPRIME-EvidenceStatus'],
      pageSize: PAGE_SIZE,
      pageNumber: 0,
    }
  ): Observable<ListEvidenceLine> {
    const requestUrl = this.siapaPieceInterRestApi + '/search';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpClient.post<ListEvidenceLine>(requestUrl, filter, { headers });
  }

  /**
   * Supprime un courrier entrant avec l'ID spécifié (Corbeille).
   *
   * @param id - ID du courrier entrant à supprimer.
   */
  deleteDoc(id: string) {
    throw new Error('Method not implemented.');
  }
}

<cnsa-input
  type="search"
  #searchInput
  (input)="onInputChange(searchInput.value)"
  placeholder="Rechercher un nom, n° de dossier"
  title="Rechercher">
</cnsa-input>
<ul class="search-results" *ngIf="searchResults.length > 0">
  <li *ngFor="let result of searchResults" class="search-result">{{ result }}</li>
</ul>

import { RequestReferentiel } from '../../core/service/request-referentiel.service';
import { LocalStorageService } from '../services/local-storage.service';
import { EnumItemDto } from '../models/enum-item.dto';
import { StructureDto } from '../models/structure.dto';
import { Observable } from 'rxjs';
import { DepartmentsDto } from '../models/departmentsDto';
import { CountryDto } from '../models/countries.dto';
import { Localities } from '../models/localities';

/**
 * Classe abstraite représentant un service de gestion du référentiel.
 *
 */
export abstract class ReferentielData {
  /**
   * Service de gestion des enum items.
   */
  abstract requestReferentiel: Readonly<RequestReferentiel>;

  /**
   * Service de gestion du stockage local.
   */
  abstract localStorageService: Readonly<LocalStorageService>;

  /**
   * Demande le référentiel à l'API et les stocke dans le stockage local.
   */
  abstract saveReferentielEnumItemInLocalStorage(): void;

  /**
   * Mappe le code d'un item à son libellé.
   *
   * @param code Le code de l'item.
   * @returns Le libellé de l'item, ou undefined si l'item n'existe pas.
   */
  abstract mapEnumItemCodeToItemLabel(code: string | undefined): string | undefined;

  /**
   * Récupère les référentiels d'un type donné.
   *
   * @param code Le code du type de référentiel.
   * @returns Les référentiels du type donné, ou undefined si aucun référentiel n'existe pour ce type.
   */
  abstract getReferentielsEnumItemByType(code: string): EnumItemDto[] | undefined;

  /**
   * Récupère les informations de la structure
   *
   * @param structureCode - code la structure
   */
  abstract structureByCode(structureCode: string): Observable<StructureDto> | undefined;

  /**
   * Récupère tous les départments du référentiel socle .
   * @returns {Observable<DepartmentsDto>} Un Observable qui renvoie un DepartmentsDto du référentiel socle, ou undefined si le référentiel socle n'existe pas.
   *
   */
  abstract allDepartments(): Observable<DepartmentsDto[] | undefined>;

  /**
   * Récupère le départment du référentiel socle pour un code donné.
   * @param {string} inseeCode Le code du référentiel socle.
   * @returns {Observable<DepartmentsDto>} Un Observable qui émet la structure du référentiel socle, ou undefined si le référentiel socle n'existe pas.
   *
   */
  abstract departmentByInseeCode(inseeCode: string): Observable<DepartmentsDto | undefined>;

  /**
   * Récupère tous les pays du référentiel socle .
   * @returns {Observable<DepartmentsDto>} Un Observable qui renvoie la liste des pays par ordre alphabétique du référentiel socle, ou undefined si le référentiel socle n'existe pas.
   *
   */
  abstract allCountries(): Observable<CountryDto[] | undefined>;

  /**
   * Récupère le pay du référentiel socle pour un code donné.
   * @param {string} iso3Code Le code du pays référentiel socle.
   * @returns {Observable<DepartmentsDto>} Un Observable qui émet le pay du référentiel socle, ou undefined si le référentiel socle n'existe pas.
   *
   */
  abstract countryByIsoCode(iso3Code: string): Observable<CountryDto | undefined>;

  /**
   * Récupère la commune du référentiel socle pour le code le code insee donée
   * @param {string} inseeCode Le code insee de la commune.
   * @returns {Observable<Localities>} Un Observable qui émet la commune, ou undefined si la commune socle n'existe pas.
   *
   */
  abstract localitiesByInseeCode(inseeCode: string): Observable<Localities | undefined>;
}

<section>
  <div>
    <div>
      <h2><span>Error</span>404</h2>
      <p>Désolé, cette page n'a pas été trouvée.</p>
      <p>Mais ne vous inquiétez pas, vous trouverez bien d'autres choses sur notre page d'accueil.</p>
      <a rel="noopener noreferrer" href="#">Retour à la page d'accueil</a>
    </div>
  </div>
</section>

import { Observable } from 'rxjs';
import { AppointmentPaginatedSearchResultDto } from '../models/appointment-paginated-search-result.dto';
import { AppointmentDto } from '../models/appointment.dto';
import { ExchangeDto } from '../models/exchange.dto';
import { AppointmentPaginatedSearchQueryDto } from '../models/appointment-paginated-search-query.dto';
import { ReferentUpdateRequestDto } from '../../../shared/models/referent-update-request.dto';

export abstract class RendezVousData {
  /**
   * Récupération des rendez-vous avec filtre
   *
   * @param filter - Pour filtrer les rendez-vous
   * @returns Observable<ResponseDataVisite>
   */
  abstract getRendezvous(filter?: AppointmentPaginatedSearchQueryDto): Observable<AppointmentPaginatedSearchResultDto>;

  /**
   * Mis à jour d'un rendez-vous
   *
   * @param rdv a mettre à jour
   * @returns Observable<AppointmentDto>
   */
  abstract updateRendezvous(rdv: AppointmentDto, taskId: string): Observable<AppointmentDto>;

  /**
   * Mise à jour des échanges d'un rendez-vous
   *
   * @param rdvId - Id du rendez-vous mis à jour
   * @param exchange - Echange à ajouter
   * @returns Observable<any>
   */
  abstract setExchanges(rdvId: string, exchange: ExchangeDto): Observable<any>;

  /**
   * Modifier référent admin et évaluateur pour une liste de rendez-vous.
   *
   * @param updateInfo
   */
  abstract updateAppointmentResponsable(updateInfo: ReferentUpdateRequestDto): Observable<any>;

  abstract getRendezVousFromProcess(url: string): Observable<AppointmentDto>;
}

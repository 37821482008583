import { Pipe, PipeTransform } from '@angular/core';
import { EvidenceLineDto } from '../models/evidenceLineDto';

/**
 * Pipe personnalisée pour filtrer une liste d'e-mails reçus (dans la corbeille) en fonction d'une valeur de filtre.
 */
@Pipe({
  name: 'corbeilleSearch',
})
export class CorbeilleSearchPipe implements PipeTransform {
  /**
   * Filtrer une liste d'e-mails reçus de la corbeille en fonction de la valeur de filtrage fournie.
   *
   * @param {EvidenceLineDto[]} mailList - La liste d'e-mails reçus à filtrer.
   * @param {string} filterValue - La valeur de filtre à appliquer.
   * @returns {EvidenceLineDto[]} Une nouvelle liste d'e-mails reçus filtrée.
   */
  transform(mailList: EvidenceLineDto[], filterValue: string): EvidenceLineDto[] {
    if (!mailList || !filterValue) {
      return mailList; // Retourne la liste non filtrée si la liste ou la valeur de filtrage est vide
    }

    filterValue = filterValue.toLowerCase(); // Convertit la valeur de filtrage en minuscules

    return mailList.filter((mail: EvidenceLineDto) => {
      const mailNameMatches = mail.lineName.toLowerCase().includes(filterValue);
      const deleteByMatches = mail.deleterUserName.toLowerCase().includes(filterValue);
      const deletedate = mail.deleteDate.toLowerCase().includes(filterValue);
      const firstName = mail.deleterUserName.toLowerCase().includes(filterValue);
      return mailNameMatches || deleteByMatches || deletedate || firstName;
    });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PAGE_SIZE } from '../../../shared/utils/globalConstants';
import { GestionCorbeilleData } from '../data/gestion-corbeille.data';
import { IncomingMailCorbeilleMock } from './incoming-mail-corbeille-mock';
import { IncomingMailsPaginatedSearchResultDto } from '../models/Incoming-mails-paginated-search-result.dto';
import { IncomingMailDto } from '../models/incoming-mail.dto';
import { IncomingMailPaginatedSearchQueryDto } from '../../courrier/models/incoming-mail-paginated-search-query.dto';
import { EvidenceDto } from '../../../shared/models/evidence.dto';
import { id } from 'date-fns/locale';
import { EvidenceLineDto, ListEvidenceLine } from '../../../shared/models/evidenceLineDto';

/**
 * Service de gestion des courriers entrants supprimées (Corbeille).
 */
@Injectable()
export class GestionCorbeilleMockService extends GestionCorbeilleData {
  /**
   * Mocked liste des courriers entrants supprimées (Corbeille).
   */
  mail: IncomingMailDto[] = IncomingMailCorbeilleMock;

  /**
   * Observable per le liste des courriers entrants supprimées (Corbeille).
   */
  private corbeilleDataSubject: BehaviorSubject<IncomingMailsPaginatedSearchResultDto> =
    new BehaviorSubject<IncomingMailsPaginatedSearchResultDto>({
      pageNumber: 0,
      pageSize: PAGE_SIZE,
      totalNumberOfPages: 0,
      totalNumberOfElements: 0,
      pageContent: [],
      _links: 'links',
    });

  /**
   * Réponse de la requête de récupération des courriers entrants supprimées (Corbeille).
   */
  response: IncomingMailsPaginatedSearchResultDto = {
    pageNumber: 0,
    pageSize: PAGE_SIZE,
    totalNumberOfPages: 0,
    totalNumberOfElements: 0,
    pageContent: [],
    _links: 'links',
  };

  /**
   * Récupère les courriers entrants supprimées (Corbeille).
   *
   * @param filter - (Facultatif) Filtre pour la récupération des courriers.
   * @returns Un Observable contenant la réponse de gestion des boîtes de réception.
   * @author Carlos Poveda
   */
  getCorbeille(filter?: IncomingMailPaginatedSearchQueryDto): Observable<ListEvidenceLine> {
    let mailFiltered = this.mail;
    if (!filter) {
      filter = {
        pageNumber: 0,
        pageSize: PAGE_SIZE,
      };
    } else {
      if (filter.isFolder != null) {
        mailFiltered = mailFiltered.filter((mail) => mail.isFolder == filter?.isFolder);
      }
    }

    this.response.pageContent = mailFiltered;
    this.corbeilleDataSubject.next(this.response);
    return of();
  }

  /**
   * Supprime un courrier entrant avec l'ID spécifié (Corbeille).
   * @param id - ID du courrier entrant à supprimer.
   */
  deleteDoc(id: string) {
    this.mail = this.mail.filter((mail) => mail.mailId != id);
    this.response.pageContent = this.mail;
    this.corbeilleDataSubject.next(this.response);
  }
}

import { BanetteSharingService } from './../../services/banette-sharing.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GestionDemandeService } from '../../../demande/services/gestion-demande.service';
import { BanetteTableauDemandesComponent } from '../banette-tableau-demandes/banette-tableau-demandes.component';
import { ApaAgentDto } from '../../../demande/models/apa-agent.dto';
import { ApaRequestDto } from '../../../demande/models/apa-request.dto';
import { GroupDto } from '../../../management-group/models/group.dto';
import { GroupManagementService } from '../../../management-group/service/group-management.service';
import { SearchApaAgentService } from '../../../../shared/services/search-apa-agent.service';
import { UserImplements } from '../../../management-group/models/user.model';
import { ProfileTypeEnum, ProfileTypeEnumTranslation } from '../../enums/profile-type.enum';
import { ListTypeModaleAssignerEnum } from '../../enums/list-type-modale-assigner.enum';
import { ReferentUpdateRequestDto } from 'src/app/modules/shared/models/referent-update-request.dto';
import { AppointmentDto } from '../../../rendez-vous/models/appointment.dto';
import { IncomingMailDto } from '../../../corbeille/models/incoming-mail.dto';
import { PersonnesGroupesData } from '../../data/personnes-groupes.data';
import { UserData } from '../../../home/data/user.data';
import { BannetteTypeEnum } from '../../enums/bannette-type.enum';
import { GestionDemandesData } from '../../../demande/data/gestion-demandes.data';
import { GestionCourriersEntrantsData } from '../../../courrier/data/gestion-courriers-entrants.data';
import { RendezVousData } from '../../../rendez-vous/data/rendez-vous-data';
import { CNSADialog, CNSARadioGroup } from '@cnsa-fr/design-system';
import { AssignedModaleTypeEnum } from '../../enums/assigned-modale-type.enum';
import { AuthService } from '../../../../core/auth/services/auth.service';
import { AuthenticatedUserInfo, toApaAgentDto } from '../../../../core/auth/models/authenticated-user.info';
import { OutgoingMailDto } from '../../../courrier/models/outgoing-mail.dto';
import { AgentTypeEnum } from '../../../../shared/enums/referentiel-person.enum';
import { ReferentielData } from '../../../../shared/data/referentiel-data';
import { PageContentDto } from '../../../demande/models/page-content.dto';
import { AppointmentWithTaskDto } from '../../../rendez-vous/models/Appointment-with-task.dto';

@Component({
  selector: 'app-bannete-modale-assigner-personne',
  templateUrl: './banette-modale-assigner-personne.component.html',
  styleUrls: ['./banette-modale-assigner-personne.component.css'],
})
export class BanetteModaleAssignerPersonneComponent implements OnInit, OnDestroy, AfterViewInit {
  protected readonly AssignedModaleTypeEnum = AssignedModaleTypeEnum;
  protected readonly ListTypeModaleAssignerEnum = ListTypeModaleAssignerEnum;

  @Input() dialogIsOpen!: boolean;
  @Input() banetteType!: BannetteTypeEnum;
  @Input() nameProfile!: ProfileTypeEnum | AgentTypeEnum;

  @Output() closeModal = new EventEmitter();

  @Input() dialogType?: AssignedModaleTypeEnum = AssignedModaleTypeEnum.ASSIGNMENT_MULTIPLE;

  @Input() dataToChangeRefAdmin: ApaRequestDto | AppointmentDto | IncomingMailDto | OutgoingMailDto | undefined;

  @Output() updatePerson = new EventEmitter<ApaAgentDto>();

  personnes: ApaAgentDto[] = [];
  groupes: GroupDto[] = [];
  selectedPerson: ApaAgentDto | undefined = undefined;
  demandes: ApaRequestDto[] = [];
  authenticatedUser: AuthenticatedUserInfo | null = null;
  labelDialog = '';
  groupExpanded: string | undefined = '';
  isNotAffected = false;
  currentUserCanBeAssigned = false;
  private selectedData: (
    | PageContentDto
    | AppointmentDto
    | IncomingMailDto
    | OutgoingMailDto
    | AppointmentWithTaskDto
  )[] = [];
  @ViewChild(BanetteTableauDemandesComponent) banetteTableauDemandesComponent!: BanetteTableauDemandesComponent;

  @ViewChild('radio') radio?: ElementRef<CNSARadioGroup>;
  @ViewChild('dialog') dialog?: ElementRef<CNSADialog>;

  constructor(
    private banetteTableauDemandesService: GestionDemandeService,
    private groupManagementService: GroupManagementService,
    private searchApaAgentService: SearchApaAgentService,
    private userService: UserData,
    private personneGroupesData: PersonnesGroupesData,
    private gestionDemandesData: GestionDemandesData,
    private gestionCourriersEntrantsData: GestionCourriersEntrantsData,
    private rendezVousData: RendezVousData,
    private banetteSharingService: BanetteSharingService,
    private authService: AuthService,
    private referentielService: ReferentielData
  ) {}

  ngOnInit() {
    this.authenticatedUser = this.authService.getUserInfo();
    this.currentUserCanBeAssigned = this.authenticatedUser?.profiles.includes(this.nameProfile.toString()) ?? false;
    this.getDataInit();
    if (
      this.nameProfile === AgentTypeEnum.ADMINISTRATIF_AgentType ||
      this.nameProfile === AgentTypeEnum.EVALUATEUR_AgentType
    )
      this.labelDialog = 'Sélection du ' + this.findLabelEnum(this.nameProfile);
    if (this.nameProfile === ProfileTypeEnum.REFERENT_ADMIN || this.nameProfile === ProfileTypeEnum.EVALUATOR)
      this.labelDialog = 'Sélection du ' + ProfileTypeEnumTranslation[this.nameProfile];
    this.banetteSharingService.getSelectedElements().subscribe((data) => {
      this.selectedData = data;
    });
  }

  ngAfterViewInit(): void {
    this.radio?.nativeElement.addEventListener('cnsa-focus', () => {
      if (this.radio) this.groupExpanded = this.radio.nativeElement.value;
    });

    this.dialog?.nativeElement.addEventListener('cnsa-request-close', () => this.annuler());
  }

  setGroupExpended(new_group: string | undefined): void {
    if (!new_group) return;
    if (this.groupExpanded !== new_group) this.groupExpanded = new_group;
    else this.groupExpanded = '';
  }

  annuler() {
    // output qui permet de fermer la popup
    this.closeModal.emit();
  }

  getDataInit() {
    if (
      this.nameProfile === ProfileTypeEnum.REFERENT_ADMIN ||
      this.nameProfile === AgentTypeEnum.ADMINISTRATIF_AgentType
    ) {
      this.searchApaAgentService.getListVisibleReferents([]).subscribe((data) => {
        this.personnes = data;
      });
    }
    if (this.nameProfile === ProfileTypeEnum.EVALUATOR || this.nameProfile === AgentTypeEnum.EVALUATEUR_AgentType) {
      this.searchApaAgentService.getListVisibleEvaluators([]).subscribe((data) => {
        this.personnes = data;
      });
    }

    this.groupManagementService.getGroups().subscribe((data) => {
      this.groupes = data;
    });
  }

  ngOnDestroy() {
    this.closeModal.emit();
  }

  /**
   * Réagit à la sélection d'une personne et met à jour la personne sélectionnée.
   *
   * @param event - L'événement de sélection qui déclenche cette fonction.
   * @returns Aucune valeur de retour explicite, mais met à jour la personne sélectionnée.
   */
  selectionPerson(event: Event): void {
    const selectedPerson = event.target as HTMLInputElement;
    this.selectedPerson = <ApaAgentDto>(<unknown>selectedPerson.value);
  }

  /**
   * Attribue automatiquement la personne actuellement authentifiée si possible, puis valide l'assignation.
   *
   * @returns Aucune valeur de retour explicite, mais peut mettre à jour la personne sélectionnée et exécuter la validation de l'assignation.
   */
  meAssigner() {
    if (this.currentUserCanBeAssigned && this.authenticatedUser) {
      this.selectedPerson = toApaAgentDto(this.authenticatedUser);
      this.validerAssignation();
    }
  }

  /**
   * Valide l'assignation en fonction du type de dialogue actuel.
   *
   * @returns Aucune valeur de retour explicite, mais exécute la validation appropriée en fonction du type de dialogue.
   */
  validerAssignation() {
    switch (this.dialogType) {
      case AssignedModaleTypeEnum.SEARCH:
        this.validateSearch();
        break;
      case AssignedModaleTypeEnum.ASSIGNMENT_UNITARY:
        //this.validateSearch();
        //break;
      case AssignedModaleTypeEnum.ASSIGNMENT_MULTIPLE:
        this.validateAssignmentMultiple();
        break;
      default:
        break;
    }
  }

  /**
   * Valide l'assignation lorsque le type de dialogue est "Recherche".
   *
   * @returns Aucune valeur de retour explicite, mais peut mettre à jour la personne sélectionnée et émettre des événements de mise à jour et de fermeture du dialogue.
   */
  validateSearch(): void {
    if (this.isNotAffected) {
      this.updatePerson.emit(undefined);
      this.closeModal.emit();
    } else {
      if (!this.selectedPerson) {
        console.error('aucune personne sélectionnée');
      } else {
        this.selectedPerson.agentId = this.selectedPerson.username;
        this.updatePerson.emit(this.selectedPerson);
        this.closeModal.emit();
      }
    }
  }

  /**
   * Valide l'assignation lorsque le type de dialogue est "Assignation unitaire".
   *
   * @returns Aucune valeur de retour explicite, mais peut mettre à jour la personne sélectionnée et émettre des événements de mise à jour et de fermeture du dialogue.
   */
  validateAssignmentUnitary(): void {
    if (!this.selectedPerson) {
      console.error('aucune personne sélectionnée');
      return;
    }
    this.selectedPerson.agentId = this.selectedPerson.userId;
    this.updatePerson.emit(this.selectedPerson);
    this.closeModal.emit();
  }

  /**
   * Valide l'assignation lorsque le type de dialogue est "Assignation multiple".
   *
   * @returns Aucune valeur de retour explicite, mais peut mettre à jour les assignations en fonction du type de banette sélectionné.
   */
  validateAssignmentMultiple(): void {
    if (!this.selectedPerson) {
      console.error('aucune personne sélectionnée');
      return;
    }
    switch (this.banetteType) {
      case BannetteTypeEnum.DEMANDE:
        this.updateApaRequestReferentAdmin();
        break;
      case BannetteTypeEnum.COURRIERS:
        this.updateIncomingMailReferentAdmin();
        break;
      case BannetteTypeEnum.RENDEZVOUS:
        this.updateAppointmentResponsable();
        break;
      default:
        break;
    }
  }

  /**
   * Met à jour les assignations pour les demandes APA en tant qu'administrateur référent.
   *
   * @private
   * @returns Aucune valeur de retour explicite, mais effectue des mises à jour et émet des événements de fermeture du dialogue.
   */
  private updateApaRequestReferentAdmin() {
    if (!this.selectedPerson?.username) {
      console.error('aucune personne sélectionnée');
      return;
    }

    const updateInfo: ReferentUpdateRequestDto = {
      referentAdminId: this.selectedPerson.username,
      requestIds: this.dataToChangeRefAdmin
        ? [(this.dataToChangeRefAdmin as ApaRequestDto).requestId!]
        : this.selectedData.map((data) => {
            if ((data as PageContentDto).apaRequest.requestId)
              return (data as PageContentDto).apaRequest.requestId ?? '';
            return '';
          }),
    };
    this.personneGroupesData.updateApaRequestReferentAdmin(updateInfo).subscribe({
      next: () => {
        this.updatePerson.emit(this.selectedPerson);
        this.closeModal.emit(true);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  /**
   * Met à jour les assignations pour les courriers entrants en tant qu'administrateur référent.
   *
   * @private
   * @returns Aucune valeur de retour explicite, mais effectue des mises à jour et émet des événements de fermeture du dialogue.
   */
  private updateIncomingMailReferentAdmin() {
    if (!this.selectedPerson?.username) {
      console.error('aucune personne sélectionnée');
      return;
    }

    const updateInfo: any = {
      referentAdminId: this.selectedPerson.username,
      incomingMailIdList: this.dataToChangeRefAdmin
        ? [(this.dataToChangeRefAdmin as IncomingMailDto).mailId!]
        : this.selectedData.map((data) => {
            if ((data as IncomingMailDto).mailId) return (data as IncomingMailDto).mailId ?? '';
            return '';
          }),
    };
    this.gestionCourriersEntrantsData.updateIncomingMailReferentAdmin(updateInfo).subscribe({
      next: () => {
        this.closeModal.emit(true);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  /**
   * Trie et renvoie un tableau d'objets en fonction du champ spécifié.
   *
   * @param field - Le champ par lequel trier les objets.
   * @param personnesGroup - Un tableau d'objets à trier (facultatif).
   * @returns Un tableau trié en fonction du champ spécifié ou un tableau vide si le champ n'est pas valide ou si aucun tableau n'est fourni.
   */
  orderBy(field: string, personnesGroup?: UserImplements[]): any[] {
    if (field === ListTypeModaleAssignerEnum.PERSONNES) {
      return this.personnes.sort((a, b) => a.lastName.localeCompare(b.lastName));
    }
    if (field === ListTypeModaleAssignerEnum.GROUPES) {
      return this.groupes.sort((a, b) => {
        if (a.groupName && b.groupName) return a.groupName.localeCompare(b.groupName);
        if (a.groupName && !b.groupName) return -1;
        if (!a.groupName && b.groupName) return 1;
        return 0;
      });
    }
    if (field === ListTypeModaleAssignerEnum.GROUPES_PERSONNES && personnesGroup) {
      return personnesGroup.sort((a, b) => a.lastName.localeCompare(b.lastName));
    } else {
      return [];
    }
  }

  /**
   * Réagit au changement de la requête de recherche et filtre les listes de personnes et de groupes en fonction de la requête.
   *
   * @param event - L'événement de changement qui déclenche cette fonction.
   * @returns Aucune valeur de retour, la méthode filtre les listes directement en fonction de la requête.
   */
  onSearchQueryChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    const searchQueryLower = inputValue.toLowerCase();

    // Vérifiez si la requête de recherche contient au moins 3 caractères
    if (searchQueryLower.length >= 3) {
      this.personnes = this.personnes.filter((personne) => {
        const firstNameMatch = personne.firstName?.toLowerCase().includes(searchQueryLower);
        const lastNameMatch = personne.lastName?.toLowerCase().includes(searchQueryLower);
        const departmentNameMatch = personne.departmentName?.toLowerCase().includes(searchQueryLower);

        return firstNameMatch || lastNameMatch || departmentNameMatch;
      });
      this.groupes = this.groupes.filter((groupe) => {
        const nameMatch = groupe.groupName?.toLowerCase().includes(searchQueryLower);
        const postalCodeMatch = groupe.attributedZipCodes?.includes(searchQueryLower.substring(0, 5));

        return nameMatch || postalCodeMatch;
      });
    } else {
      // Réinitialisez la liste des personnes et groupes si la requête de recherche ne contient pas au moins 3 caractères
      this.getDataInit();
    }
  }

  /**
   * Met à jour les assignations pour les rendez-vous en tant que responsable.
   *
   * @private
   * @returns Aucune valeur de retour explicite, mais effectue des mises à jour et émet des événements de fermeture du dialogue.
   */
  private updateAppointmentResponsable() {
    if (!this.selectedPerson?.username) {
      console.error('aucune personne sélectionnée');
      return;
    }

    const updateInfo: any = {
      referentAdminId: this.selectedPerson.username,
      appointmentIdList: this.dataToChangeRefAdmin
        ? [(this.dataToChangeRefAdmin as AppointmentDto).appointmentId!]
        : this.selectedData.map((data) => {
            if ((data as AppointmentWithTaskDto).appointment.appointmentId)
              return (data as AppointmentWithTaskDto).appointment.appointmentId ?? '';
            return '';
          }),
      type: this.nameProfile,
      userMadeAssignment: this.personnes[0], // Temporary assignment until we add connected User
    };
    this.rendezVousData.updateAppointmentResponsable(updateInfo).subscribe({
      next: () => {
        this.closeModal.emit(true);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  /**
   * Inverse l'état de la variable 'isNotAffected'.
   *
   * Cette fonction est utilisée pour basculer entre les états "affecté" et "non affecté".
   *
   * @returns Aucune valeur de retour explicite, mais modifie l'état 'isNotAffected'.
   */
  changeIsAffected() {
    this.isNotAffected = !this.isNotAffected;
  }

  findLabelEnum(codeEnum: string) {
    return this.referentielService.mapEnumItemCodeToItemLabel(codeEnum);
  }
}

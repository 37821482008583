import { Observable } from 'rxjs';
import { OutgoingMailsPaginatedSearchQueryDto } from '../models/outgoing-mails-paginated-search-query.dto';
import { OutgoingMailsPaginatedSearchResultDto } from '../models/outgoing-mails-paginated-search-result.dto';

/**
 * Interface pour la gestion de la boite d'envoi
 *
 * @export
 * @abstract
 * @class GestionBoiteData
 */
export abstract class GestionBoiteData {
  /**
   * Retourne la liste des mails à envoyer
   *
   * @param filter
   * @return {Observable<ResponseGestionBoite>}
   */
  abstract getMailsToSend(
    filter?: OutgoingMailsPaginatedSearchQueryDto
  ): Observable<OutgoingMailsPaginatedSearchResultDto>;
}

import { Observable, of } from 'rxjs';
import { group2 } from '../../management-group/mocks/group2.mock';
import { group1 } from '../../management-group/mocks/group1.mock';
import { Injectable } from '@angular/core';
import { ApaAgentDto } from '../../demande/models/apa-agent.dto';
import { GroupDto } from '../../management-group/models/group.dto';
import { ApaUserGroupProfile } from '../../management-group/models/apa-user-group-profile';
import { PersonnesGroupesData } from '../data/personnes-groupes.data';
import { UserDto } from '../../home/models/user.dto';
import { ApaRequestDto } from '../../demande/models/apa-request.dto';
import { ReferentUpdateRequestDto } from 'src/app/modules/shared/models/referent-update-request.dto';

@Injectable({
  providedIn: 'root',
})
export class PersonnesGroupesMockService implements PersonnesGroupesData {
  personne: ApaAgentDto[] = [
    {
      agentId: '1',
      firstName: 'Valerie',
      lastName: 'Lefevre',
      departmentNumber: '123',
      departmentName: 'Département A',
      workPhoneNumber: '222222222',
      workEmailAddress: 'valerie.dupont@example.com',
    },
    {
      agentId: '2',
      firstName: 'Marc',
      lastName: 'DURAND',
      departmentNumber: '456',
      departmentName: 'Département B',
      workPhoneNumber: '222222222',
      workEmailAddress: 'marc.martin@example.com',
    },
    {
      agentId: '3',
      firstName: 'Anne',
      lastName: 'Leroy',
      departmentNumber: '789',
      departmentName: 'Département C',
      workPhoneNumber: '222222222',
      workEmailAddress: 'anne.dubois@example.com',
    },
    {
      agentId: '4',
      firstName: 'Fran',
      lastName: 'Dubois',
      departmentNumber: '789',
      departmentName: 'Département D',
      workPhoneNumber: '222222222',
      workEmailAddress: 'fran.bouquet@example.com',
    },
    {
      agentId: '5',
      firstName: 'Laurent',
      lastName: 'Pinot',
      departmentNumber: '789',
      departmentName: 'Département D',
      workPhoneNumber: '222222222',
      workEmailAddress: 'fran.bouquet@example.com',
    },
    {
      agentId: '6',
      firstName: 'Ghislaine',
      lastName: 'García',
      departmentNumber: '789',
      departmentName: 'Département D',
      workPhoneNumber: '222222222',
      workEmailAddress: 'fran.bouquet@example.com',
    },
  ];

  groupe: GroupDto[] = [
    {
      ...group1,
      profile: ApaUserGroupProfile.ADMINISTRATIVE,
    },
    {
      ...group1,
    },
    {
      ...group2,
    },
  ];

  getPersonne() {
    return this.personne;
  }

  getGroupe() {
    return this.groupe;
  }

  personneAffectee: ApaAgentDto | null = null;

  // PersonnesGroupesData implementation

  getPersonnes(): Observable<ApaAgentDto[]> {
    return of(this.personne);
  }

  getGroupes(): Observable<GroupDto[]> {
    return of(this.groupe);
  }

  getCurrentUser(): UserDto | undefined {
    return undefined;
  }

  enregistrerAffectation(personne: ApaAgentDto): Observable<any> {
    this.personneAffectee = personne;
    return of({});
  }

  ajouterReferentAdmin(personne: ApaAgentDto): Observable<any> {
    return of({});
  }

  personneAssigneeHandler(personne: ApaAgentDto): Observable<any> {
    return of({});
  }

  filterPersonnesGroupes(searchQuery: string): Observable<any> {
    return of({});
  }

  // Méthode pour récupérer les demandes
  getDemandes(): Observable<ApaRequestDto[]> {
    return of([]);
  }

  // Méthode pour ajouter un nouveau référent
  ajouterReferent(referent: ApaAgentDto): Observable<any> {
    return of({});
  }

  // Méthode pour mettre à jour les informations d'un référent
  updateApaRequestReferentAdmin(updateInfo: ReferentUpdateRequestDto): Observable<any> {
    return of({});
  }
}

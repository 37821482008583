import { Component, ElementRef, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CNSADrawer } from '@cnsa-fr/design-system';
import { RoutesEnum } from '../../../shared/enums/routes.enum';
import { AuthenticatedUserInfo } from '../../auth/models/authenticated-user.info';
import { AuthService } from '../../auth/services/auth.service';
import { PagesRouteEnum } from '../../../shared/enums/pages-route.enum';
import { rolesAccessPages } from '../../roles/rolesAccessPages';

/**
 * Composant principale: menu principal de l'application
 */
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent {
  protected readonly PagesRouteEnum = PagesRouteEnum;
  protected readonly rolesAccessPages = rolesAccessPages;
  /**
   * Emetteur d'évènement pour fermer le menu
   */
  @Output() closeDialog = new EventEmitter();

  /**
   * Référence vers le drawer
   */
  @ViewChild('drawer') drawer?: ElementRef<CNSADrawer>;
  authService: AuthService = inject(AuthService);

  /**
   * Référence verls l'enume des routes
   */
  protected readonly routes = RoutesEnum;
  /**
   * Informations sur l'utilisateur actuellement connecté.
   */
  user: AuthenticatedUserInfo | null = null;

  constructor(private router: Router) {}

  /**
   * Fonction qui gère la route à rediriger
   *
   * @param url Url à rediriger
   */
  handleClick(url: string): void {
    this.router.navigate([url], { skipLocationChange: true });
    this.closeDialog.emit();
  }

  ngOnInit(): void {
    this.user = this.authService.getUserInfo();
  }
}

// TODO #referentiel à supprimer quand debouchonnage referentiel fini -> nouveaux fichier referentiel-requests.enum.ts
/**
 * Énumération représentant différents types de traitements spéciaux des demandes APA.
 */
export enum SpecialTreatmentTypeEnum {
  NO = 'NO',
  EMERGENCY_APA_PLAN = 'EMERGENCY_APA_PLAN',
  ACCELERATED_PROCEDURE = 'ACCELERATED_PROCEDURE',
}

/**
 * Traductions pour chaque type de traitement spécial d'une demande APA.
 */
export const SpecialTreatmentTypeEnumTranslation: { [key in SpecialTreatmentTypeEnum]: string } = {
  [SpecialTreatmentTypeEnum.NO]: 'Non',
  [SpecialTreatmentTypeEnum.EMERGENCY_APA_PLAN]: 'APA en urgence',
  [SpecialTreatmentTypeEnum.ACCELERATED_PROCEDURE]: 'Procédure accélérée',
};

import { RequestReferentielData } from '../data/RequestReferentielData';
import { map, Observable, of } from 'rxjs';
import { EnumItemDto, EnumItemDtoList } from '../../shared/models/enum-item.dto';
import { EnumData } from './enum-data';
import { RootRequestInterface } from '../../shared/interfaces/root-request.interface';
import { StructureDto } from '../../shared/models/structure.dto';
import { DepartmentsDto } from '../../shared/models/departmentsDto';
import { DepartmentsMock } from '../../shared/mocks/departments-mock';
import { CountryDto } from '../../shared/models/countries.dto';
import { CountriesMock } from '../../shared/mocks/countries-mock';
import { Localities } from '../../shared/models/localities';

export class RequestReferentielDataMockService implements RequestReferentielData {
  constructor() {
    console.warn('-- You used Mock for : RequestReferentielData --');
  }

  getAllEnumItemSocle(): Observable<EnumItemDto[] | undefined> {
    return of(EnumData).pipe(
      map((rootEnumItemDto: RootRequestInterface<EnumItemDtoList>) => {
        return rootEnumItemDto._embedded?.enumItemDtoList;
      })
    );
  }

  getStructureSocleByCode(code: string): Observable<StructureDto> | undefined {
    return undefined;
  }

  getALLDepartments(): Observable<DepartmentsDto[] | undefined> {
    return of(DepartmentsMock._embedded?.departmentDtoList);
  }

  getDepartmentByInseeCode(inseeCode: string): Observable<DepartmentsDto | undefined> {
    const dept = DepartmentsMock._embedded?.departmentDtoList.find((dpt) => dpt.inseeCode === inseeCode);
    return of(dept);
  }

  getALLCountries(): Observable<CountryDto[] | undefined> {
    return of(CountriesMock._embedded?.countryDtoList);
  }

  getCountryByIsoCode(iso3Code: string): Observable<CountryDto | undefined> {
    const findCountry = CountriesMock._embedded?.countryDtoList.find((country) => country.iso3Code === iso3Code);
    return of(findCountry);
  }

  getLocalitiesByInseeCode(inseeCode: string): Observable<Localities | undefined> {
    return of({
      inseeCode: '01002',
      label: "L'Abergement-de-Varey",
      _links: {
        self: {
          href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/localities/01002',
        },
      },
    });
  }
}

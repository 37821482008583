import { Observable } from 'rxjs';
import { IncomingMailPaginatedSearchQueryDto } from '../models/incoming-mail-paginated-search-query.dto';
import { IncomingMailsPaginatedSearchResultDto } from '../../corbeille/models/Incoming-mails-paginated-search-result.dto';
import { IncomingMailDto } from '../../corbeille/models/incoming-mail.dto';
import { ReferentUpdateRequestDto } from '../../../shared/models/referent-update-request.dto';

/**
 * Interface pour la gestion des courriers entrants
 *
 * @export
 * @abstract
 * @class GestionCourriersEntrantsData
 */
export abstract class GestionCourriersEntrantsData {
  /**
   * Retourne la liste des courriers entrants
   *
   * @param filter
   * @returns {Observable<IncomingMailsPaginatedSearchResultDto>}
   */
  abstract getCourriersEntrants(
    filter?: IncomingMailPaginatedSearchQueryDto
  ): Observable<IncomingMailsPaginatedSearchResultDto>;

  /**
   * Mis à jour d'un courrier entrant
   *
   * @param courrier courrier à mettre à jour
   * @returns {Observable<IncomingMailDto>} courrier mis à jour
   */
  abstract updateCourrier(courrier: IncomingMailDto): Observable<IncomingMailDto>;

  abstract updateIncomingMailReferentAdmin(updateInfo: ReferentUpdateRequestDto): Observable<any>;

  // Shared courrier data between components

  /**
   * Récupère la date de mise à jour des courriers.
   *
   * @returns {Observable<Date>} La date de mise à jour des courriers.
   */
  abstract getCourrierUpdateDate(): Observable<Date>;

  /**
   * Suppression d'un document
   *
   * @abstract
   * @param ids
   * @returns {void}
   */
  abstract deleteCourriers(ids: string[]): Observable<void>;
}

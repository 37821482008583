import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { BanetteSharingService } from '../../services/banette-sharing.service';
import { BannetteTypeEnum } from '../../enums/bannette-type.enum';
import { AppointmentDto } from '../../../rendez-vous/models/appointment.dto';
import { IncomingMailDto } from '../../../corbeille/models/incoming-mail.dto';
import { ApaAgentDto } from '../../../demande/models/apa-agent.dto';
import { OutgoingMailDto } from '../../../courrier/models/outgoing-mail.dto';
import {
  IncomingMailTypeEnum,
  IncomingMailTypeEnumTranslation,
} from 'src/app/modules/shared/enums/incoming-mail-type.enum';
import { CNSAMenuItem } from '@cnsa-fr/design-system';
import { GestionCourriersEntrantsData } from '../../../courrier/data/gestion-courriers-entrants.data';
import { PageContentDto } from '../../../demande/models/page-content.dto';
import { TypeOfProcessEnum } from '../../../../shared/enums/referentiel-requests.enum';
import { AppointmentWithTaskDto } from '../../../rendez-vous/models/Appointment-with-task.dto';
import { AgentTypeEnum } from '../../../../shared/enums/referentiel-person.enum';

/**
 * Composant de la barre d'actions en masse des banettes
 * S'ouvre lorsqu'il y a plus de 1 élément sélectionné sur les banettes "demande" et "rendez-vous"
 */
@Component({
  selector: 'app-banette-header-barre-actions',
  templateUrl: './banette-header-barre-actions.component.html',
  styleUrls: ['./banette-header-barre-actions.component.css'],
})
export class BanetteHeaderBarreActionsComponent implements OnInit {
  /**
   * Type de la banette {@link SiApaBanetteGeneriquePageComponent.html#table}
   *
   * @type {string}
   */
  @Input() bannetteType?: BannetteTypeEnum;
  @Output() updateData = new EventEmitter();
  @Output() updateDialog = new EventEmitter();

  /**
   * Array des éléments sélectionnés dans les banettes
   */
  public selectedElements: (
    | PageContentDto
    | AppointmentDto
    | IncomingMailDto
    | OutgoingMailDto
    | AppointmentWithTaskDto
  )[] = [];

  /**
   * Texte du référent sélectionné
   */
  public selectedReferentsText = '';

  /**
   * Texte du référent sélectionné
   */
  public selectedEvaluatersText = '';

  /**
   * Booléen pour savoir si le dialog est ouvert ou non
   */
  @Input() isDialogOpen = false;

  /**
   * Enumération des types de banettes
   */
  protected readonly BannetteTypeEnum = BannetteTypeEnum;

  /**
   * String indiquant si la boîte de dialogue est ouverte avec Évaluateur ou Référent admin
   */
  nameProfile?: AgentTypeEnum;

  /** Types de courrier disponibles. */
  mailType: IncomingMailTypeEnum[] = Object.values(IncomingMailTypeEnum);

  /** Traductions des types de courrier. */
  protected readonly IncomingMailTypeEnumTranslation = IncomingMailTypeEnumTranslation;

  public changedEmailsIds: string[] = [];

  /**
   * Crée une instance du composant BanetteHeaderBarreActionsComponent.
   *
   * @param {BanetteSharingService} banetteSharingService Le service partagé des banettes.
   */
  constructor(
    private banetteSharingService: BanetteSharingService,
    private courriersService: GestionCourriersEntrantsData
  ) {}

  /**
   * Initialise le composant.
   */
  ngOnInit() {
    this.banetteSharingService.getSelectedElements().subscribe((data) => {
      this.selectedElements = data;
      this.selectedReferentsText = this.processDisplayText(this.getReferentsForProcess(), 'Référent admin.');
      this.selectedEvaluatersText = this.processDisplayText(this.getEvaluatersForProcess(), 'Evaluateur');
    });
  }

  /**
   * Fonction permettant de changer le type de courriers pour courriers séléctionnes.
   *
   * @param {CNSAMenuItem} item L'item sélectionné.
   */
  @HostListener('cnsa-select', ['$event.detail.item'])
  changeMailsType(item: CNSAMenuItem) {
    this.selectedElements.forEach((e, index: number) => {
      const element = e as IncomingMailDto;
      if (element.mailTypeCode) {
        element.mailTypeCode = TypeOfProcessEnum[item.value as keyof typeof TypeOfProcessEnum];
        this.changedEmailsIds.push((this.selectedElements[index] as IncomingMailDto).mailId);
      }
    });
    this.banetteSharingService.setChangedEmailsIds(this.changedEmailsIds);
  }

  /**
   * Change l'indicateur pour indiquer que la fenêtre de dialogue s'ouvre et avec quel profil a été ouvert
   *
   * @param value String indiquant si la boîte de dialogue est ouverte avec Évaluateur ou Référent admin
   */
  openDialog(value: AgentTypeEnum) {
    this.updateDialog.emit(true);
    this.nameProfile = value;
  }

  /**
   * Ferme le dialog de partage de la banette
   */
  closeDialog() {
    this.updateDialog.emit(false);
    this.updateData.emit();
    if (this.bannetteType !== BannetteTypeEnum.COURRIERS) {
      this.deselectAll();
    }
  }

  /**
   * Desélectionne tous les éléments sélectionnés
   */
  deselectAll() {
    this.selectedElements = [];
    this.banetteSharingService.setSelectedElements(this.selectedElements);
  }

  /**
   * Retourne le texte à afficher pour le référent sélectionné
   */
  processDisplayText(data: (ApaAgentDto | undefined)[], defaultText: string): string {
    let result = '';

    if (this.selectedElements.length <= 1) return '';

    const individualsCount: Record<string, number> = {};

    data.forEach((ref) => {
      if (ref && ref.lastName && ref.firstName) {
        const refName = ref.lastName + ' ' + ref.firstName;
        individualsCount[refName] = (individualsCount[refName] || 0) + 1;
      }
    });

    const individualsCountSorted = Object.entries(individualsCount).sort((a, b) => b[1] - a[1]);

    if (individualsCountSorted.length === 1) result = individualsCountSorted[0][0];
    // else result = 'Sélectionner';
    else result = defaultText;

    return result;
  }

  /**
   * Fonction qui retourne les référents et les groupes de référents des éléments sélectionnés
   *
   * @returns refs et refGroups
   */
  public getReferentsForProcess(): (ApaAgentDto | undefined)[] {
    let result: (ApaAgentDto | undefined)[] = [];

    if (this.bannetteType === BannetteTypeEnum.DEMANDE) {
      const selectedDemandes = this.selectedElements as PageContentDto[];

      result = selectedDemandes.map(
        (element) => element.apaRequest.requesterSituation.personIdentity.administrativeReferent
      );
    } else if (this.bannetteType === BannetteTypeEnum.RENDEZVOUS) {
      const selectedRdvs = this.selectedElements as AppointmentDto[];

      result = selectedRdvs.map((element) => element.requesterSituation.requesterIdentity.administrativeReferent);
    } else if (this.bannetteType === BannetteTypeEnum.COURRIERS) {
      const selectedMails = this.selectedElements as IncomingMailDto[];

      result = selectedMails.map((element) => element.mailApaPerson?.administrativeReferent);
    }

    return result;
  }

  /**
   * Fonction qui retourne les référents et les groupes de référents des éléments sélectionnés
   *
   * @returns refs et refGroups
   */
  public getEvaluatersForProcess(): (ApaAgentDto | undefined)[] {
    let result: (ApaAgentDto | undefined)[] = [];

    if (this.bannetteType === BannetteTypeEnum.RENDEZVOUS) {
      const selectedRdvs = this.selectedElements as AppointmentDto[];

      result = selectedRdvs.map((element) => element.evaluator);
    }

    return result;
  }
  protected readonly AgentTypeEnum = AgentTypeEnum;

  /**
   * Fonction de regroupement des dossiers et des documents
   */
  regroupCourriers() {
    if (this.bannetteType === BannetteTypeEnum.COURRIERS) {
      const idRegroup = [];

      this.selectedElements.forEach((e) => {
        const element = e as IncomingMailDto;

        idRegroup.push(element.mailId);
        // TODO: appeler le service regroupement courriers entrans
      });
    }
  }

  /**
   * Fonction de suppression d'un courrier entrants
   */
  deleteCourriers() {
    if (this.bannetteType === BannetteTypeEnum.COURRIERS) {
      const idsDelete: string[] = [];

      this.selectedElements.forEach((e) => {
        const element = e as IncomingMailDto;

        idsDelete.push(element.mailId);
        // TODO: appeler le service pour supprimer courriers entrans
      });

      this.courriersService.deleteCourriers(idsDelete);
    }
    this.deselectAll();
  }
}

<!-- TODO: Ajouter aria-sort= "ascending" ou "descending" sur les headers des colonnes lorsqu'elles sont triées -->
<div class="container">
  <cnsa-visually-hidden id="banette-helper"
    >, les entêtes de colonnes avec bouton peuvent être triés.</cnsa-visually-hidden
  >
  <table class="banette" aria-describedby="banette-title banette-helper">
    <thead class="banette__header">
      <tr>
        <th scope="col">
          <div class="banette__header-cell">
            <cnsa-checkbox
              label="Sélectionner toutes les lignes"
              [(ngModel)]="selectedAll"
              ngDefaultControl
              (cnsa-change)="selectAllRows()"></cnsa-checkbox>
          </div>
        </th>
        <th></th>
        <th></th>
        <th scope="col" *ngFor="let column of columns; let x = index">
          <button class="banette__header-cell banette__header-cell--button" (click)="sortRows(x)">
            {{ column }}
            <cnsa-icon name="unfold_more" *ngIf="column !== ''" class="banette__sort-icon"></cnsa-icon>
          </button>
        </th>
        <th></th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngIf="courriers">
        <tr
          *ngFor="let item of courriers.slice(0, itemsToShow) | courriersEntrantsSearch : filterValue; let i = index"
          [ngClass]="isSelected(i) ? 'selected-background' : ''">
          <td><cnsa-checkbox size="small" [checked]="isSelected(i)" (cnsa-change)="selectRow(i)"></cnsa-checkbox></td>
          <td>
            <cnsa-icon
              name="folder"
              class="icon-folder"
              *ngIf="item.documents && item.documents.length > 1"
              (click)="selectedItem(item)"></cnsa-icon>
            <cnsa-icon
              name="article"
              class="icon-article"
              *ngIf="item.documents.length === 1"
              (click)="selectedItem(item)"></cnsa-icon>
            <ng-container *ngIf="item.specialTreatmentTypeCode">
              <span class="red-color">
                <cnsa-icon
                  name="priority_high"
                  *ngIf="item.specialTreatmentTypeCode === ProcessingTypeEnum.APA_URGENCE_ProcessingType"></cnsa-icon
              ></span>
            </ng-container>
            <cnsa-dialog
              *ngIf="itemPop === item && item.documents.length > 1"
              classname="dialog-overview"
              label="Dossier {{ item.mailApaPerson.civility }} {{ item.mailApaPerson.lastName | uppercase }} {{
                item.mailApaPerson.firstName
              }}"
              [open]="showPopup"
              size="large">
              <div class="container">
                <table aria-label="Documents">
                  <thead class="visually-hidden">
                    <tr>
                      <th scope="col">Nom</th>
                      <th scope="col">Format</th>
                      <th scope="col">Type</th>
                      <th scope="col">Poids</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let doc of itemPop?.documents">
                      <td>
                        {{ doc.document?.documentName ?? '' }}
                      </td>
                      <td>
                        {{ doc.document?.mimeType ?? '' }}
                      </td>
                      <td>
                        <cnsa-tag variant="blue-100" *ngFor="let type of doc.types">
                          {{ type ? DocumentTypesTranslation[castType(type)] : '' }}
                        </cnsa-tag>
                      </td>
                      <td>{{ doc.document?.contentLength ?? '-' }} Mo</td>
                      <td><cnsa-icon name="more_vert" class="icon-sort"></cnsa-icon></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </cnsa-dialog>
          </td>
          <td>
            <ng-container *ngIf="item.specialTreatmentTypeCode">
              <span class="red-color">
                <cnsa-icon
                  name="priority_high"
                  *ngIf="item.specialTreatmentTypeCode === ProcessingTypeEnum.APA_URGENCE_ProcessingType"></cnsa-icon
              ></span>

              <cnsa-icon
                name="timer"
                *ngIf="
                  item.specialTreatmentTypeCode === ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType
                "></cnsa-icon>
            </ng-container>
          </td>
          <td class="cell-content">
            <strong>
              {{ item.mailApaPerson && item.mailApaPerson.lastName ? item.mailApaPerson.lastName.toUpperCase() : '' }}
              {{ item.mailApaPerson && item.mailApaPerson.firstName ? item.mailApaPerson.firstName : '' }}
            </strong>
            <p>
              {{
                item.specialTreatmentTypeCode &&
                item.specialTreatmentTypeCode === ProcessingTypeEnum.APA_URGENCE_ProcessingType
                  ? findLabelEnum(ProcessingTypeEnum.APA_URGENCE_ProcessingType)
                  : ''
              }}
            </p>
            <p class="cell-content__secondary">
              {{
                item.documents && item.documents.length > 1
                  ? 'Afficher les ' + item.documents.length + ' documents'
                  : ''
              }}
            </p>
            <p class="cell-content__secondary">
              {{ item.documents && item.documents.length === 1 ? item.documents[0].document?.mimeType : '' }}
            </p>
          </td>
          <td>
            <ng-container *ngIf="item.mailOriginCode">
              <cnsa-tag variant="orange-500">
                TS DAA
              </cnsa-tag>
            </ng-container>
          </td>
          <td>
            <ng-container *ngIf="item.mailOriginCode
                     then thenBlock;
                     else elseBlock"></ng-container>
            <ng-template #thenBlock>
              <cnsa-dropdown hoist="" caret="" name="typeCourrier" id="typeCourrier" disabled="">
                <cnsa-button slot="trigger" caret="" variant="secondary" size="small">{{
                  (item.mailTypeCode && item.mailTypeCode !== MailTypeEnum.UNKNOWN) ? findLabelEnum(item.mailTypeCode) : 'Sélectionner'
                  }}</cnsa-button>
                <cnsa-menu *ngFor="let type of mailType">
                  <cnsa-menu-item *ngIf="type !== MailTypeEnum.UNKNOWN" (click)="changeMailType(type, i)">{{ findLabelEnum(type) }}</cnsa-menu-item>
                </cnsa-menu>
              </cnsa-dropdown>
            </ng-template>
            <ng-template #elseBlock>
              <cnsa-dropdown hoist="" caret="" name="typeCourrier" id="typeCourrier">
                <cnsa-button slot="trigger" caret="" variant="secondary" size="small">{{
                  (item.mailTypeCode && item.mailTypeCode !== MailTypeEnum.UNKNOWN) ? findLabelEnum(item.mailTypeCode) : 'Sélectionner'
                  }}</cnsa-button>
                <cnsa-menu *ngFor="let type of mailType">
                  <cnsa-menu-item *ngIf="type !== MailTypeEnum.UNKNOWN" (click)="changeMailType(type, i)">{{ findLabelEnum(type) }}</cnsa-menu-item>
                </cnsa-menu>
              </cnsa-dropdown>
            </ng-template>
          </td>
          <td>
            <cnsa-button
              name="referents"
              id="referents"
              (click)="onClickOpenAssignmentModale(i)"
              title="Référent admin."
              slot="trigger"
              variant="secondary"
              size="small">
              {{
                item.mailApaPerson && item.mailApaPerson.administrativeReferent
                  ? (item.mailApaPerson.administrativeReferent.lastName | uppercase) +
                    ' ' +
                    item.mailApaPerson.administrativeReferent.firstName
                  : 'Sélectionner'
              }}
            </cnsa-button>
          </td>
          <td>
            <cnsa-icon name="calendar_today"></cnsa-icon>
            {{ item.originCreationDate ? (item.originCreationDate | date : 'dd/MM/yyyy') : '' }}
          </td>
          <td><cnsa-button (click)="openTreatDemande(item)" size="small" variant="tertiary">Traiter</cnsa-button></td>
          <td>
            <cnsa-dropdown class="document__menu">
              <cnsa-icon-button
                class="document__menu-trigger"
                name="more_vert"
                label="Actions sur le document"
                slot="trigger"></cnsa-icon-button>
              <cnsa-menu>
                <!--<cnsa-menu-item class="document__menu-item">Télécharger</cnsa-menu-item>-->
                <cnsa-menu-item class="document__menu-item document__menu-item--alert" (click)="deleteCourrier(item)"
                  >Supprimer</cnsa-menu-item
                >
              </cnsa-menu>
            </cnsa-dropdown>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <span
    class="table-label"
    *ngIf="courriers && (courriers.slice(0, itemsToShow) | courriersEntrantsSearch : filterValue).length === 0">
    <p class="bold-text">{{ message.noResult }}</p>
    <br />
    <p>{{ message.emptySearch }}</p>
  </span>

  <div class="container-button" *ngIf="courriers">
    <cnsa-button
      *ngIf="courriers.length > itemsToShow && (courriers | courriersEntrantsSearch : filterValue).length > itemsToShow"
      variant="primary"
      (click)="addMoreItemsToShow()"
      ><cnsa-icon name="expand_circle_down"></cnsa-icon> Afficher plus de résultats</cnsa-button
    >
  </div>
  <app-bannete-modale-assigner-personne
    *ngIf="isDialogOpen"
    [nameProfile]="AgentTypeEnum.ADMINISTRATIF_AgentType"
    [banetteType]="BannetteTypeEnum.COURRIERS"
    [dialogIsOpen]="isDialogOpen"
    [dialogType]="AssignedModaleTypeEnum.ASSIGNMENT_UNITARY"
    [dataToChangeRefAdmin]="mailToUpdate"
    (closeModal)="closeAssignedPersonModale($event)"
    (updatePerson)="updateSelectedPerson($event)">
  </app-bannete-modale-assigner-personne>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppointmentDto } from '../../rendez-vous/models/appointment.dto';
import { IncomingMailDto } from '../../corbeille/models/incoming-mail.dto';
import { OutgoingMailDto } from '../../courrier/models/outgoing-mail.dto';
import { PageContentDto } from '../../demande/models/page-content.dto';
import { AppointmentWithTaskDto } from '../../rendez-vous/models/Appointment-with-task.dto';

/**
 * Service pour le partage de données entre les composants de la banette.
 */
@Injectable({
  providedIn: 'root',
})
export class BanetteSharingService {
  /**
   * Observable pour les éléments sélectionnés dans les banettes
   */
  private selectedElementsSubject: BehaviorSubject<
    (PageContentDto | AppointmentDto | IncomingMailDto | OutgoingMailDto | AppointmentWithTaskDto)[]
  > = new BehaviorSubject<
    (PageContentDto | AppointmentDto | IncomingMailDto | OutgoingMailDto | AppointmentWithTaskDto)[]
  >([]);

  private changedEmailsIdsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  /**
   * Récupère les éléments sélectionnés dans les banettes sous forme d'observable.
   *
   * @returns {ApaRequestDto | Rendezvous} Un observable des éléments sélectionnés.
   */
  getSelectedElements(): Observable<
    (PageContentDto | AppointmentDto | IncomingMailDto | OutgoingMailDto | AppointmentWithTaskDto)[]
  > {
    return this.selectedElementsSubject.asObservable();
  }

  /**
   * Setter pour mettre à jour le behaviorSubject avec les éléments sélectionnés.
   *
   * @param {ApaRequestDto | Rendezvous} data Les éléments à définir comme sélectionnés.
   */
  setSelectedElements(
    data: (PageContentDto | AppointmentDto | IncomingMailDto | OutgoingMailDto | AppointmentWithTaskDto)[]
  ) {
    this.selectedElementsSubject.next(data);
  }

  /**
   * Récupère les email ids modifiees.
   *
   * @returns {Observable<string[]>} Les email ids modifiees.
   */
  getChangedEmailsIds(): Observable<string[]> {
    return this.changedEmailsIdsSubject.asObservable();
  }

  /**
   * Setter pour mettre à jour le behaviorSubject avec les email ids modifiees.
   *
   * @param {string[]} data Les email ids modifiees.
   */
  setChangedEmailsIds(data: string[]) {
    this.changedEmailsIdsSubject.next(data);
  }
}

// TODO #referentiel - Old Enum: ProtectionMeasureTypeEnum
export enum MeasureScopeTypeEnum {
  /**
   *   Protection personne
   */
  PERSONNE_MeasureScopeType = 'PERSONNE-MeasureScopeType',
  /**
   *   Gestion patrimoine
   */
  PATRIMOINE_MeasureScopeType = 'PATRIMOINE-MeasureScopeType',
}
// TODO #referentiel - Old Enum: ProtectionMeasureTypeEnum
export enum CivilityEnum {
  /**
   *   Madame
   */
  MONSIEUR_Civility = 'MONSIEUR-Civility',
  /**
   *   Monsieur
   */
  MADAME_Civility = 'MADAME-Civility',
  SIR = 'SIR', // TODO #referentiel à supprimer quand debouchonnage referentiel fini
  MISS = 'MISS', // TODO #referentiel à supprimer quand debouchonnage referentiel fini
}

/**
 * Énumération représentant les différents types de personnes dans l'application.
 */
// TODO #referentiel - Old Enum: PersonTypeEnum
export enum PersonStatusEnum {
  /**
   *   Actif Demandeur
   */
  DEMANDEUR_PersonStatus = 'DEMANDEUR-PersonStatus',
  /**
   *   Actif Bénéficiaire
   */
  BENEFICIAIRE_PersonStatus = 'BENEFICIAIRE-PersonStatus',
  /**
   *   En sommeil ancien demandeur
   */
  // SOMMEIL_DEMANDEUR_PersonStatus = 'SOMMEIL_DEMANDEUR-PersonStatus',
  /**
   *  En sommeil ancien bénéficiaire
   */
  // SOMMEIL_BENEFICIAIRE_PersonStatus = 'SOMMEIL_BENEFICIAIRE-PersonStatus',
  /**
   *   Inactif
   */
  // INACTIF_PersonStatus = 'INACTIF-PersonStatus',
  /**
   *   Décédé
   */
  // DECEDE_PersonStatus = 'DECEDE_PersonStatus',
}

// TODO #referentiel - Old Enum: ProtectionMeasureEnum
export enum ProtectiveMeasureTypeEnum {
  /**
   *   Sauvegarde de justice
   */
  JUSTICE_ProtectiveMeasureType = 'JUSTICE-ProtectiveMeasureType',
  /**
   *   Curatelle simple
   */
  CURATELLE_SIMPLE_ProtectiveMeasureType = 'CURATELLE_SIMPLE-ProtectiveMeasureType',
  /**
   *   Curatelle renforcée
   */
  CURATELLE_RENFORCEE_ProtectiveMeasureType = 'CURATELLE_RENFORCEE-ProtectiveMeasureType',
  /**
   *   Tutelle
   */
  TUTELLE_ProtectiveMeasureType = 'TUTELLE-ProtectiveMeasureType',
  /**
   *   Habilitation familiale
   */
  FAMILLIALE_ProtectiveMeasureType = 'FAMILLIALE-ProtectiveMeasureType',
  /**
   *   Mandat de protection future activée
   */
  MANDAT_ProtectiveMeasureType = 'MANDAT-ProtectiveMeasureType',
}

/**
 * Énumération des situations familiales possibles pour une demande APA.
 */
// TODO #referentiel - Old Enum: ApaRequestFamilySituationEnum
export enum MaritalStatusEnum {
  /**
   *   Célibataire
   */
  CELIBATAIRE_MaritalStatus = 'CELIBATAIRE-MaritalStatus',
  /**
   *   Marié
   */
  MARIE_MaritalStatus = 'MARIE-MaritalStatus',
  /**
   *  Pacsé
   */
  PACSE_MaritalStatus = 'PACSE-MaritalStatus',
  /**
   *   En concubinage
   */
  CONCUBINAGE_MaritalStatus = 'CONCUBINAGE-MaritalStatus',
  /**
   *   Divorcé ou séparé
   */
  SEPARE_MaritalStatus = 'SEPARE-MaritalStatus',
  /**
   *   Veuf
   */
  VEUF_MaritalStatus = 'VEUF-MaritalStatus',
}

// TODO #referentiel - Old Enum: RequestContactConnectionEnum
export enum RelationshipTypeEnum {
  /**
   *   Conjoint
   */
  CONJOINT_RelationshipType = 'CONJOINT-RelationshipType',
  /**
   *   Enfant
   */
  ENFANT_RelationshipType = 'ENFANT-RelationshipType',
  /**
   *   Ami
   */
  AMI_RelationshipType = 'AMI-RelationshipType',
  /**
   *   Voisin
   */
  VOISIN_RelationshipType = 'VOISIN-RelationshipType',
  /**
   *   Autre
   */
  AUTRE_RelationshipType = 'AUTRE-RelationshipType',

  // TODO #referentiel à supprimer quand debouchonnage referentiel fini
  PARTNER = 'PARTNER',
  CHILD = 'CHILD',
  FRIEND = 'FRIEND',
  NEIGHBOUR = 'NEIGHBOUR',
  OTHER = 'OTHER',
}

// TODO #referentiel - Old Enum: OwnedResidenceStatusEnum
export enum OccupationStatusEnum {
  /**
   *   Occupée
   */
  OCCUPE_OccupationStatusEnum = 'OCCUPE-OccupationStatus',
  /**
   *   Louée
   */
  LOUE_OccupationStatus = 'LOUE-OccupationStatus',
  /**
   *   Non louée
   */
  NON_LOUE_OccupationStatus = 'NON_LOUE-OccupationStatus',
  /**
   *   Non renseigné
   */
  INCONNU_OccupationStatus = 'INCONNU-OccupationStatus',
}

// TODO #referentiel - Old Enum: LodgingStatusEnum
export enum ApplicantStatusEnum {
  /**
   *   Propriétaire
   */
  PROPRIETAIRE_ApplicantStatus = 'PROPRIETAIRE-ApplicantStatus',
  /**
   *   Locataire
   */
  LOCATAIRE_ApplicantStatus = 'LOCATAIRE-ApplicantStatus',
  /**
   *   Hébergé à titre gratuit
   */
  HEBERGE_ApplicantStatus = 'HEBERGE-ApplicantStatus',
  // TODO #referentiel à supprimer quand debouchonnage referentiel fini (NOT_FILLED -> de l'ancienne énumération)
  NOT_FILLED = 'NOT_FILLED',
}

// TODO #referentiel - Old Enum: PersonalPropertyNatureEnum
export enum NatureOfPropertyEnum {
  /**
   *   Voiture de luxe
   */
  VOITURE_NatureOfProperty = 'VOITURE-NatureOfProperty',
  /**
   *   Œuvre d'art
   */
  ART_NatureOfProperty = 'ART-NatureOfProperty',
  /**
   *   Bijoux
   */
  BIJOUX_NatureOfProperty = 'BIJOUX-NatureOfProperty',
  /**
   *   Autre
   */
  AUTRE_NatureOfProperty = 'AUTRE-NatureOfProperty',
}

/**
 * Énumération représentant les différents états de certification SNGI.
 */
// TODO #referentiel - Old Enum: SngiCertificationStatusValueEnum
export enum IdentityStatusEnum {
  /**
   *   Non vérifié
   */
  NON_VERIFIEE_IdentityStatus = 'NON_VERIFIEE-IdentityStatus',
  /**
   *   Certifiée
   */
  CERTIFIEE_IdentityStatus = 'CERTIFIEE-IdentityStatus',
  /**
   *  Non certifiée
   */
  NON_CERTIFIEE_IdentityStatus = 'NON_CERTIFIEE-IdentityStatus',
  /**
   *   Vérification non concluante
   */
  NON_CONCLUANTE_IdentityStatus = 'NON_CONCLUANTE-IdentityStatus',
  /**
   *   Erreur technique
   */
  ERREUR_IdentityStatus = 'ERREUR-IdentityStatus',
}

/**
 * Énumération représentant les différents types de résidence pour une personne.
 */
// TODO #referentiel - Old Enum: ResidenceTypeEnum
export enum TypeOfHomeEnum {
  /**
   *   Domicile du demandeur
   */
  DEMANDEUR_TypeOfHome = 'DEMANDEUR-TypeOfHome',
  /**
   *  Domicile d'un membre de la famille
   */
  MEMBRE_FAMILLE_TypeOfHome = 'MEMBRE_FAMILLE-TypeOfHome',
  /**
   *  Domicile d'un accueillant familial
   */
  ACCUEILLANT_FAMILIAL_TypeOfHome = 'ACCUEILLANT_FAMILIAL-TypeOfHome',
  /**
   *  Résidence autonomie
   */
  AUTONOMIE_TypeOfHome = 'AUTONOMIE-TypeOfHome',
  /**
   *   EHPAD
   */
  EHPAD_TypeOfHome = 'EHPAD-TypeOfHome',
  /**
   *   Autre
   */
  AUTRE_TypeOfHome = 'AUTRE-TypeOfHome',
}

/**
 * Énumération des types possibles en la sélection du profil dans la banette header barre action.
 */
// TODO #referentiel - Old Enum: ProfileTypeEnum
export enum AgentTypeEnum {
  /**
   *   Référent administratif
   */
  ADMINISTRATIF_AgentType = 'ADMINISTRATIF-AgentType',
  /**
   *   Evaluateur
   */
  EVALUATEUR_AgentType = 'EVALUATEUR-AgentType',
  /**
   *   Effectivité et paiement
   */
  EFFECTIVITE_AgentType = 'EFFECTIVITE-AgentType',
  /**
   *   EMS
   */
  EMS_AgentType = 'EMS-AgentType',
}

/**
 * Traductions des différents types de personnes.
 */
// TODO #referentiel à supprimer quand debouchonnage referentiel fini
export const PersonTypeEnumTranslation = {
  [PersonStatusEnum.DEMANDEUR_PersonStatus]: 'Demandeur',
  [PersonStatusEnum.BENEFICIAIRE_PersonStatus]: 'Bénéficiaire',
};

// TODO #referentiel à supprimer quand debouchonnage referentiel fini
export const CivilityEnumTranslation = {
  [CivilityEnum.MONSIEUR_Civility]: 'Mr',
  [CivilityEnum.MADAME_Civility]: 'Mme',
  [CivilityEnum.SIR]: 'Mr',
  [CivilityEnum.MISS]: 'Mme',
};

// TODO #referentiel à supprimer quand debouchonnage referentiel fini
export const RelationshipTypeEnumEnumTranslation: { [key in RelationshipTypeEnum]: string } = {
  [RelationshipTypeEnum.PARTNER]: 'Conjoint',
  [RelationshipTypeEnum.CHILD]: 'Enfant',
  [RelationshipTypeEnum.FRIEND]: 'Ami',
  [RelationshipTypeEnum.NEIGHBOUR]: 'Voisin',
  [RelationshipTypeEnum.OTHER]: 'Autres',

  [RelationshipTypeEnum.CONJOINT_RelationshipType]: 'Conjoint',
  [RelationshipTypeEnum.ENFANT_RelationshipType]: 'Enfant',
  [RelationshipTypeEnum.AMI_RelationshipType]: 'Ami',
  [RelationshipTypeEnum.VOISIN_RelationshipType]: 'Voisin',
  [RelationshipTypeEnum.AUTRE_RelationshipType]: 'Autres',
};

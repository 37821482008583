import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserData } from '../data/user.data';
import { mockAutonomieUser } from './autonomieUser.mock';
import { mockCourrierUser } from './courrierUser.mock';
import { UserDto } from '../models/user.dto';
import { LocalStorageService } from '../../../shared/services/local-storage.service';

@Injectable()
export class ApaUserServiceMock extends UserData {
  localStorageService = inject(LocalStorageService);

  constructor() {
    super();
  }

  getAgent(agentId: string): Observable<UserDto> {
    let userReturn;
    if (agentId === 'serviceAutonomie') {
      userReturn = mockAutonomieUser;
    } else {
      userReturn = mockCourrierUser;
    }
    console.log('--- MOCK: authenticated user --');
    return of(userReturn);
  }

  getCurrentUser(): UserDto | undefined {
    const currentUserString = this.localStorageService.getObject<UserDto>('currentUser');
    if (currentUserString) {
      return currentUserString;
    } else {
      return {
        userId: 'string',
        profile: 'role',
        firstName: 'firstName',
        lastName: 'lastName',
        departmentNumber: 'departmentNumber',
        departmentName: 'departmentname',
      };
    }
  }
}

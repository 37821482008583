<cnsa-dialog classname="dialog-overview" label="Filtrer" [open]="showDialog" #dialog>
  <ng-container *ngIf="titre === 'Boite d\'envoi'">
    <div class="div-filtres">
      <div class="div-filtres-general">
        <div class="div-filtres-general">
          <h3 class="sub-title-filtres">Type de courrier</h3>
          <div class="div-filtres-checkbox">
            <cnsa-checkbox
              size="medium"
              [value]="imcompleteTypeCourrierSortant"
              [checked]="isChecked('imcompleteTypeCourrierSortant')"
              (cnsa-change)="changeMailType($event)"
            >{{ TypeCourrierEnum.INCOMPLETUDE_TypeCourrier }}</cnsa-checkbox
            >
            <cnsa-checkbox
              size="medium"
              [value]="completeTypeCourrierSortant"
              [checked]="isChecked('completeTypeCourrierSortant')"
              (cnsa-change)="changeMailType($event)"
            >{{ TypeCourrierEnum.COMPLETUDE_TypeCourrier }}</cnsa-checkbox
            >      
            <cnsa-checkbox
              size="medium"
              [value]="rejetTypeCourrierSortant"
              [checked]="isChecked('rejetTypeCourrierSortant')"
              (cnsa-change)="changeMailType($event)"
            >{{ TypeCourrierEnum.REJET_TypeCourrier }}</cnsa-checkbox
            >  
            <cnsa-checkbox
              size="medium"
              [value]="visiteTypeCourrierSortant"
              [checked]="isChecked('visiteTypeCourrierSortant')"
              (cnsa-change)="changeMailType($event)"
            >{{ TypeCourrierEnum.VISITE_typeCourrier }}</cnsa-checkbox
            >

            <cnsa-checkbox
              size="medium"
              [value]="rdvTypeCourrierSortant"
              [checked]="isChecked('rdvTypeCourrierSortant')"
              (cnsa-change)="changeMailType($event)"
            >{{ TypeCourrierEnum.RDV_TypeCourrier }}</cnsa-checkbox
            >

          </div>
        </div>
      </div>

      <div class="input-container input-container--20-80 ">
        <div>
          <cnsa-select
            #zipCodeListRef
            (cnsa-change)="changeZipCode($event)"
            label="Code postal"
            hoist=""
            error-message="Texte d'erreur"
            max-options-visible="0"
            label="Code postal"
            placeholder="Sélectionner"
            placement="bottom"
            size="medium"
            pill=""
            [multiple]="true">
            <cnsa-option *ngFor="let codePostal of codesPostal" value="{{ codePostal }}">{{ codePostal }}</cnsa-option>
          </cnsa-select>
        </div>
        <div>
          <cnsa-select
            #placeNameRef
            (cnsa-change)="changePlaceName($event)"
            label="Nom de la ville"
            hoist=""
            error-message="Texte d'erreur"
            max-options-visible="0"
            label="Nom de la ville"
            placeholder="Sélectionner"
            placement="bottom"
            size="medium"
            [multiple]="true">
            <cnsa-option *ngFor="let ville of villes" value="{{ ville }}">{{ ville }}</cnsa-option>
          </cnsa-select>
        </div>
      </div>

      <div class="div-filtres-date-inputs">
        <h3 class="sub-title-filtres">Référent administratif</h3>
        <cnsa-button variant="secondary" (click)="onClickSelect()">Sélectionner</cnsa-button>
      </div>

      <div class="div-filtres-date-inputs">
        <label class="sub-title-filtres-date">Généré le</label>
        <cnsa-input
          type="date"
          [(ngModel)]="filter.generationDate"
          ngDefaultControl
          help-text="Format attendu: JJ/MM/AAAA" />
      </div>
    </div>

    <cnsa-button slot="footer" variant="navigation" (click)="resetFilters()">Réinitialiser les filtres</cnsa-button>
    <cnsa-button slot="footer" (click)="filterBoite()">Filtrer</cnsa-button>
  </ng-container>
</cnsa-dialog>

import { Observable } from 'rxjs';
import { ApaAgentDto } from '../../demande/models/apa-agent.dto';
import { GroupDto } from '../../management-group/models/group.dto';
import { UserDto } from '../../home/models/user.dto';
import { ApaRequestDto } from '../../demande/models/apa-request.dto';
import { ReferentUpdateRequestDto } from 'src/app/modules/shared/models/referent-update-request.dto';

export abstract class PersonnesGroupesData {
  public abstract getPersonnes(): Observable<ApaAgentDto[]>;

  public abstract getGroupes(): Observable<GroupDto[]>;

  public abstract getCurrentUser(): UserDto | undefined;

  public abstract enregistrerAffectation(personne: ApaAgentDto): Observable<any>;

  public abstract ajouterReferentAdmin(personne: ApaAgentDto): Observable<any>;

  public abstract personneAssigneeHandler(personne: ApaAgentDto): Observable<any>;

  public abstract filterPersonnesGroupes(searchQuery: string): Observable<any>;

  public abstract getDemandes(): Observable<ApaRequestDto[]>;

  public abstract ajouterReferent(referent: ApaAgentDto): Observable<any>;

  public abstract updateApaRequestReferentAdmin(updateInfo: ReferentUpdateRequestDto): Observable<any>;
}

<ng-container *ngIf="selectedElements.length > 1">
  <div class="barre-actions">
    <span class="barre-actions__selected">{{ selectedElements.length }} éléments sélectionnés</span>

    <div class="barre-actions__actions actions">
      <ng-container *ngIf="bannetteType === BannetteTypeEnum.COURRIERS">
        <span class="actions__section">
          <cnsa-dropdown caret="true" hoist="true">
            <cnsa-button slot="trigger" variant="tertiary" floating="true" caret="true" ngDefaultControl>
              <cnsa-icon name="local_offer"></cnsa-icon>
              <span class="barre-actions__select-text">Typer</span>
            </cnsa-button>

            <cnsa-menu>
              <ng-container *ngFor="let type of mailType">
                <cnsa-menu-item [value]="type"> {{ IncomingMailTypeEnumTranslation[type] }}</cnsa-menu-item>
              </ng-container>
            </cnsa-menu>
          </cnsa-dropdown>
        </span>
      </ng-container>

      <ng-container
        *ngIf="
          bannetteType === BannetteTypeEnum.COURRIERS ||
          bannetteType === BannetteTypeEnum.DEMANDE ||
          bannetteType === BannetteTypeEnum.RENDEZVOUS
        ">
        <span class="actions__section">
          <cnsa-button
            variant="tertiary"
            floating="true"
            ngDefaultControl
            (click)="openDialog(AgentTypeEnum.ADMINISTRATIF_AgentType)">
            <cnsa-icon name="person"></cnsa-icon>
            <span class="actions__select-text--black">{{ selectedReferentsText }}</span>
            <!-- {{ selectedElements[0]?.evaluateur.prenom | uppercase }} {{ selectedElements[0]?.referent.nom }} -->
          </cnsa-button>
        </span>
      </ng-container>

      <ng-container *ngIf="bannetteType === BannetteTypeEnum.RENDEZVOUS">
        <span class="actions__section">
          <cnsa-button
            variant="tertiary"
            floating="true"
            ngDefaultControl
            (click)="openDialog(AgentTypeEnum.EVALUATEUR_AgentType)">
            <cnsa-icon name="person"></cnsa-icon>
            <span class="barre-actions__select-text">{{ selectedEvaluatersText }}</span>
            <!-- {{ selectedElements[0]?.evaluateur.prenom | uppercase }} {{ selectedElements[0]?.evaluateur.nom }}-->
          </cnsa-button>
        </span>
      </ng-container>

      <ng-container *ngIf="bannetteType === BannetteTypeEnum.COURRIERS">
        <span class="actions__section">
          <cnsa-button variant="tertiary" floating="true" ngDefaultControl (click)="regroupCourriers()">
            <cnsa-icon name="create_new_folder"></cnsa-icon>
            <span class="barre-actions__select-text">Regrouper</span>
          </cnsa-button>
        </span>
      </ng-container>

      <ng-container *ngIf="bannetteType === BannetteTypeEnum.BOITE">
        <span class="actions__section">
          <cnsa-button variant="tertiary" floating="true" ngDefaultControl>
            <cnsa-icon name="print"></cnsa-icon>
            <span class="barre-actions__select-text">Imprimer</span>
          </cnsa-button>
        </span>
      </ng-container>

      <ng-container *ngIf="bannetteType === BannetteTypeEnum.BOITE || bannetteType === BannetteTypeEnum.CORBEILLE">
        <span class="actions__section">
          <cnsa-button variant="tertiary" floating="true" ngDefaultControl>
            <cnsa-icon name="download"></cnsa-icon>
            <span class="barre-actions__select-text">Télécharger</span>
          </cnsa-button>
        </span>
      </ng-container>

      <ng-container *ngIf="bannetteType === BannetteTypeEnum.CORBEILLE || bannetteType === BannetteTypeEnum.COURRIERS">
        <span class="actions__section">
          <cnsa-button variant="danger" floating="true" ngDefaultControl (click)="deleteCourriers()">
            <cnsa-icon name="delete"></cnsa-icon>
            <span class="barre-actions__select-text">Supprimer</span>
          </cnsa-button>
        </span>
      </ng-container>
    </div>
  </div>

  <app-bannete-modale-assigner-personne
    *ngIf="isDialogOpen && nameProfile && bannetteType"
    [nameProfile]="nameProfile"
    [banetteType]="bannetteType"
    [dialogIsOpen]="isDialogOpen"
    (closeModal)="closeDialog()">
  </app-bannete-modale-assigner-personne>
</ng-container>

import { ApaAgentDto } from '../../pages/demande/models/apa-agent.dto';
import { Observable } from 'rxjs';

/**
 * Interface de recherche d'agents APA et de récupération de données associées.
 */
export abstract class searchApaAgentData {
  /**
   * Rechercher des agents APA en fonction d'un profil.
   *
   * @param {string} profile Le profil à rechercher.
   * @returns {Observable<ApaAgentDto[]>} Observable des données d'agent APA.
   */
  abstract searchApaAgents(profile: string): Observable<ApaAgentDto[]>;

  /**
   * Obtenir une liste de référents visibles en fonction des identifiants.
   *
   * @param {string[]} ids Les identifiants des référents à récupérer.
   * @returns {Observable<ApaAgentDto[]>} Observable des données de référents visibles.
   */
  abstract getListVisibleReferents(ids: string[]): Observable<ApaAgentDto[]>;

  /**
   * Obtenir une liste d'évaluateurs visibles en fonction des identifiants.
   *
   * @param {string[]} ids Les identifiants des évaluateurs à récupérer.
   * @returns {Observable<ApaAgentDto[]>} Observable des données d'évaluateurs visibles.
   */
  abstract getListVisibleEvaluators(ids: string[]): Observable<ApaAgentDto[]>;
}

/**
 * Énumération des valeurs possibles de statut d'une demande APA.
 */
// TODO #referentiel à supprimer quand debouchonnage referentiel fini
export enum ApaRequestStatusValueEnum {
  /**
   * Initialisé.
   */
  INITIALIZED = 'DEPOSEE-DemandStatus',
  /**
   * En cours.
   */
  ON_GOING = 'ON_GOING',
  /**
   * Incomplet.
   */
  INCOMPLETE = 'INCOMPLETE',
  /**
   * Complet.
   */
  COMPLETE = 'COMPLETE',
  /**
   * Rejeté.
   */
  REJECTED = 'REJECTED',
  /**
   * À rejeter.
   */
  TO_BE_REJECTED = 'TO_BE_REJECTED',
}

/**
 * Traductions des valeurs possibles de statut d'une demande APA.
 */
export const ApaRequestStatusValueEnumTranslation: { [key in ApaRequestStatusValueEnum]: string } = {
  [ApaRequestStatusValueEnum.INITIALIZED]: 'En cours', // TODO un contournement en attendant le dev backend; pour les BA pas de statut initialisé ref : https://e-3d-jira3.capgemini.com/jira3/browse/P003962-2972
  [ApaRequestStatusValueEnum.ON_GOING]: 'En cours',
  [ApaRequestStatusValueEnum.INCOMPLETE]: 'Incomplet',
  [ApaRequestStatusValueEnum.COMPLETE]: 'Complet',
  [ApaRequestStatusValueEnum.REJECTED]: 'À rejeter',
  [ApaRequestStatusValueEnum.TO_BE_REJECTED]: 'A rejeté',
};

import { ApaAgentDto } from '../../pages/demande/models/apa-agent.dto';

/**
 * Données de mock pour les référents APA.
 */
export const ReferentsMock: ApaAgentDto[] = [
  {
    userId: '1',
    firstName: 'Arturo',
    lastName: 'Ballester',
    departmentNumber: '75',
    departmentName: 'Paris',
    workPhoneNumber: '01 02 03 04 05',
    workEmailAddress: 'arturo@arturo.com',
    username: '',
  },
  {
    userId: '2',
    firstName: 'Marcos',
    lastName: 'Miguel',
    departmentNumber: '75',
    departmentName: 'Valence',
    workPhoneNumber: '01 02 03 04 05',
    workEmailAddress: 'marcos@marcos.com',
    username: '',
  },
  {
    userId: '3',
    firstName: 'Noelia',
    lastName: 'Bolumar',
    departmentNumber: '75',
    departmentName: 'Lyon',
    workPhoneNumber: '01 02 03 04 05',
    workEmailAddress: 'noelia@noelia.com',
    username: '',
  },
];

import { Component, Input, OnInit } from '@angular/core';
import { GestionCourriersEntrantsData } from '../../../courrier/data/gestion-courriers-entrants.data';

/**
 * Composant responsable du titre de l'entête de la banette.
 * Il Affiche le titre.
 */
@Component({
  selector: 'app-banette-header-titre',
  templateUrl: './banette-header-titre.component.html',
  styleUrls: ['./banette-header-titre.component.css'],
})
export class BanetteHeaderTitreComponent implements OnInit {
  /** Le titre à afficher dans l'en-tête. */
  @Input() titre?: string;

  public lastUpdateDate!: Date;

  constructor(private courrierData: GestionCourriersEntrantsData) {}

  ngOnInit(): void {
    this.courrierData.getCourrierUpdateDate().subscribe((date) => {
      this.lastUpdateDate = date;
    });
  }
}

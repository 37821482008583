import { Component, inject, OnInit } from '@angular/core';
import { AuthService } from './modules/core/auth/services/auth.service';
import { ReferentielData } from './modules/shared/data/referentiel-data';
import { pipe, tap } from 'rxjs';

/**
 * Composant principal de l'application
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  authService: AuthService = inject(AuthService);
  isAuthenticate = false;
  ReferentielData = inject(ReferentielData);
  /**
   * Titre de l'application
   */
  title = 'SI-APA-Web-Agent';

  async ngOnInit() {
    this.ReferentielData.saveReferentielEnumItemInLocalStorage();
    await this.authService
      .authenticate()
      .then(() => (this.isAuthenticate = true))
      .catch(() => (this.isAuthenticate = false));
  }
}

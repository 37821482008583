import { Component } from '@angular/core';

/**
 * Composant principale: page non trouvée (404)
 */
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css'],
})
export class PageNotFoundComponent {}

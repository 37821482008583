import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SiapaProgressBarComponent } from './components/siapa-progress-bar/siapa-progress-bar.component';
import { SiapaImportScreenComponent } from './components/siapa-import-screen/siapa-import-screen.component';

import '@cnsa-fr/design-system/dist/components/badge/badge.js';
import '@cnsa-fr/design-system/dist/components/button/button.js';
import '@cnsa-fr/design-system/dist/components/checkbox/checkbox.js';
import '@cnsa-fr/design-system/dist/components/dialog/dialog.js';
import '@cnsa-fr/design-system/dist/components/divider/divider.js';
import '@cnsa-fr/design-system/dist/components/icon-button/icon-button.js';
import '@cnsa-fr/design-system/dist/components/icon/icon.js';
import '@cnsa-fr/design-system/dist/components/input/input.js';
import '@cnsa-fr/design-system/dist/components/menu-item/menu-item.js';
import '@cnsa-fr/design-system/dist/components/menu/menu.js';
import '@cnsa-fr/design-system/dist/components/option/option.js';
import '@cnsa-fr/design-system/dist/components/popup/popup.js';
import '@cnsa-fr/design-system/dist/components/progress-bar/progress-bar.js';
import '@cnsa-fr/design-system/dist/components/radio-button/radio-button.js';
import '@cnsa-fr/design-system/dist/components/radio-group/radio-group.js';
import '@cnsa-fr/design-system/dist/components/radio/radio.js';
import '@cnsa-fr/design-system/dist/components/select/select.js';
import '@cnsa-fr/design-system/dist/components/textarea/textarea.js';
import '@cnsa-fr/design-system/dist/components/visually-hidden/visually-hidden.js';
import '@cnsa-fr/design-system/dist/components/spinner/spinner.js';
import '@cnsa-fr/design-system/dist/components/dropdown/dropdown.js';
import { SiapaDocumentViewerComponent } from './components/siapa-document-viewer/siapa-document-viewer.component';
import { SiapaDemandeDocumentsExtendedViewComponent } from './components/siapa-demande-documents-extended-view/siapa-demande-documents-extended-view.component';
import { SiapaDemandeDocumentsMinimizedViewComponent } from './components/siapa-demande-documents-minimized-view/siapa-demande-documents-minimized-view.component';

@NgModule({
  declarations: [
    SiapaProgressBarComponent,
    SiapaImportScreenComponent,
    SiapaDemandeDocumentsMinimizedViewComponent,
    SiapaDemandeDocumentsExtendedViewComponent,
    SiapaDocumentViewerComponent,
  ],
  imports: [CommonModule, NgxExtendedPdfViewerModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    SiapaImportScreenComponent,
    SiapaDemandeDocumentsMinimizedViewComponent,
    SiapaDemandeDocumentsExtendedViewComponent,
    SiapaDocumentViewerComponent,
  ],
})
export class DocumentManagementModule {}

import { Injectable, isDevMode } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApaAgentDto } from '../../demande/models/apa-agent.dto';
import { GroupDto } from '../../management-group/models/group.dto';
import { ApaRequestDto } from '../../demande/models/apa-request.dto';
import { UserDto } from '../../home/models/user.dto';
import { ReferentUpdateRequestDto } from 'src/app/modules/shared/models/referent-update-request.dto';

import { environment as envDev } from '../../../../../environments/environment';
import { environment as envProd } from '../../../../../environments/environment.prod';
import { PersonnesGroupesData } from '../data/personnes-groupes.data';
import { UserData } from '../../home/data/user.data';

@Injectable()
export class PersonnesGroupesService implements PersonnesGroupesData {
  private apiUrl = ''; // Remplacez par l'URL réelle de l'API backend
  private endPointApaRequests = '';

  /**
   * Constructeur du service GestionCourrierService.
   *
   * @param http
   * @param userService
   */
  constructor(private http: HttpClient, private userService: UserData) {
    if (isDevMode()) {
      this.endPointApaRequests = envDev.siapaDemandeInterRestApi + envDev.endPointApaRequests;
    } else {
      this.endPointApaRequests = envProd.siapaDemandeInterRestApi + envProd.endPointApaRequests;
    }
  }

  getPersonnes(): Observable<ApaAgentDto[]> {
    return this.http.get<ApaAgentDto[]>(`${this.apiUrl}`);
  }

  getGroupes(): Observable<GroupDto[]> {
    return this.http.get<GroupDto[]>(`${this.apiUrl}`);
  }

  getCurrentUser(): UserDto | undefined {
    return this.userService.getCurrentUser();
  }

  enregistrerAffectation(personne: ApaAgentDto): Observable<any> {
    return this.http.post(`${this.apiUrl}`, personne);
  }

  ajouterReferentAdmin(personne: ApaAgentDto): Observable<any> {
    return this.http.post(`${this.apiUrl}`, personne);
  }

  personneAssigneeHandler(personne: ApaAgentDto): Observable<any> {
    return this.http.post(`${this.apiUrl}`, personne);
  }

  filterPersonnesGroupes(searchQuery: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/filter?searchQuery=${searchQuery}`);
  }

  // Méthode pour récupérer les demandes
  getDemandes(): Observable<ApaRequestDto[]> {
    return this.http.get<ApaRequestDto[]>(`${this.apiUrl}`);
  }

  // Méthode pour ajouter un nouveau référent
  ajouterReferent(referent: ApaAgentDto): Observable<any> {
    return this.http.post(`${this.apiUrl}`, referent);
  }

  // Méthode pour mettre à jour les informations d'un référent
  updateApaRequestReferentAdmin(updateInfo: ReferentUpdateRequestDto): Observable<any> {
    return this.http.put(`${this.endPointApaRequests}/referent-admin`, updateInfo);
  }
}

/**
 * Énumération des types de rendez-vous.
 */
// TODO #referentiel - Old Enum: AppointmentTypeEnum
export enum EvaluationModalityEnum {
  /**
   *   Visite à domicile
   */
  VISITE_DOMICILE_EvaluationModality = 'VISITE_DOMICILE-EvaluationModality',
  /**
   *  Entretien téléphonique
   */
  ENTRETIEN_TEL_EvaluationModality = 'ENTRETIEN_TEL-EvaluationModality',
}

// TODO #referentiel - Old Enum: AppointmentStatusEnum
export enum AppointmentStatusEnum {
  /**
   *  A planifier
   */
  A_PLANIFIER_AppointmentStatus = 'A_PLANIFIER-AppointmentStatus',
  /**
   *  Planifié
   */
  PLANIFIE_AppointmentStatus = 'PLANIFIE-AppointmentStatus',
  /**
   *  Annulé
   */
  ANNULE_AppointmentStatus = 'ANNULE-AppointmentStatus',
  /**
   *  Réalisé
   */
  REALISE_AppointmentStatus = 'REALISE-AppointmentStatus',
  // TODO #referentiel  status modifie n'existe pas dans cette enum du referentiel -> il faut voir la solution
  // utilise dans card-action-historique.component.ts
  MODIFIED = 'MODIFIED',
}
// TODO #referentiel - Old Enum: n'existait pas - AppointmentStatusEvent et AppointmentEventEnum etaient le meme enum

export enum AppointmentEventEnum {
  /**
   *  Planifié
   */
  PLANIFIE_AppointmentEvent = 'PLANIFIE-AppointmentEvent',
  /**
   *  Modifié
   */
  MODIFIE_AppointmentEvent = 'MODIFIE-AppointmentEvent',
  /**
   *  Annulé
   */
  ANNULE_AppointmentEvent = 'ANNULE-AppointmentEvent',
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {SearchGlobalService} from '../../../../shared/services/search-global.service';
import {CNSADialog, CNSAMenu, CNSASelect} from '@cnsa-fr/design-system';
import {LabelMenuListActionRdv, MenuListActionRdv} from './model/MenuListActionRdv';
import {AppointmentDto} from '../../../rendez-vous/models/appointment.dto';
import {ApaAgentDto} from '../../../demande/models/apa-agent.dto';
import {LocalStorageService} from 'src/app/modules/shared/services/local-storage.service';
import {TableMessage} from '../../../../shared/messages/table-message';
import {BanetteSharingService} from '../../services/banette-sharing.service';
import {SpecialTreatmentTypeEnum} from '../../../../shared/enums/special-treatment-type.enum';
import {forkJoin, take} from 'rxjs';
import {RendezVousData} from '../../../rendez-vous/data/rendez-vous-data';
import {BannetteTypeEnum} from '../../enums/bannette-type.enum';
import {AssignedModaleTypeEnum} from '../../enums/assigned-modale-type.enum';
import {ReferentielData} from '../../../../shared/data/referentiel-data';
import {ProcessingTypeEnum} from '../../../../shared/enums/referentiel-requests.enum';
import {AgentTypeEnum} from '../../../../shared/enums/referentiel-person.enum';
import {AppointmentStatusEnum} from '../../../../shared/enums/referentiel-evaluation.enum';
import {RendezVousSearchPipe} from '../../../../shared/pipes/rendez-vous-search.pipe';
import {AppointmentWithTaskDto} from '../../../rendez-vous/models/Appointment-with-task.dto';

/**
 * Composant pour afficher un tableau de rendez-vous.
 */
@Component({
  selector: 'app-banette-tableau-rendez-vous',
  templateUrl: './banette-tableau-rendez-vous.component.html',
  styleUrls: ['./banette-tableau-rendez-vous.component.css'],
  providers: [RendezVousSearchPipe],
})
export class BanetteTableauRendezVousComponent implements OnInit, OnDestroy {
  /** Titre du tableau. */
  @Input() titre?: string;

  /** Liste des rendez-vous à afficher. */
  @Input() rendezvous?: AppointmentWithTaskDto[] = [];

  /** Liste des référents disponibles. */
  @Input() referents?: ApaAgentDto[];

  /** Liste des évaluateurs disponibles. */
  @Input() evaluateurs?: ApaAgentDto[];

  /** Événement émis lors de l'ouverture du dialogue de planification de rendez-vous. */
  @Output() openPlanRdvModal = new EventEmitter();
  /**
   * Le nombre de resultat de rechercher.
   */
  @Output() updateCountSearch = new EventEmitter<number>();
  @Output() resetCountSearch = new EventEmitter();
  /** Référence au dialogue. */
  @ViewChild('dialog') dialog?: ElementRef<CNSADialog>;

  /** Enumération pour les actions de menu dans le tableau de rendez-vous. */
  protected readonly LabelMenuListActionRdv = LabelMenuListActionRdv;

  /** Enumération pour les statuts de rendez-vous. */
  protected readonly AppointmentStatusEnum = AppointmentStatusEnum;

  /** Enumération pour les types de traitement spécial. */
  protected readonly ProcessingTypeEnum = ProcessingTypeEnum;
  protected readonly BannetteTypeEnum = BannetteTypeEnum;
  protected readonly AgentTypeEnum = AgentTypeEnum;
  protected readonly AssignedModaleTypeEnum = AssignedModaleTypeEnum;

  /** Message de la table. */
  public message = TableMessage;

  /** Liste des rdv modifies */
  private changedRdvs: AppointmentWithTaskDto[] = [];

  /** Liste des ids rdv modifies */
  private changedRdvsIds: string[] = [];

  protected readonly Boolean = Boolean;

  isDialogOpen = false;

  profilType?: AgentTypeEnum;
  currentIndex = 0;

  /** Référence au sélecteur pour le choix du référent. */
  @ViewChild('selectReferent')
  readonly selectReferent?: ElementRef<CNSASelect>;

  @ViewChild('selectEvaluateur')
  readonly selectEvaluateur?: ElementRef<CNSASelect>;

  /** Référence au menu des actions de rendez-vous. */
  @ViewChild('menuRdv')
  readonly menuRdv?: ElementRef<CNSAMenu>;

  @ViewChildren('item') items?: QueryList<ElementRef>;

  /** Nombre d'éléments à afficher dans le tableau. */
  itemsToShow = 10;

  /** Actions du menu pour les rendez-vous. */
  listActionRdvs: MenuListActionRdv[] = [
    {
      label: LabelMenuListActionRdv.MODIFIER_LE_RENDEZ_VOUS,
      link: '',
      action: (rdv) => {
        // TODO Implement open modifier popup
      },
    },
    {
      label: LabelMenuListActionRdv.ANNULER_LE_RENDEZ_VOUS,
      link: '',
      action: (rdv) => {
        // TODO Implement open annuler rdv
      },
    },
    {
      label: LabelMenuListActionRdv.EDITER_LE_SMS_DE_RAPPEL,
      link: '',
      action: (rdv) => {
        // TODO Implement open editer sms
      },
    },
    {
      label: LabelMenuListActionRdv.EDITER_L_EMAIL_DE_RAPPEL,
      link: '',
      action: (rdv) => {
        // TODO Implement open editer email
      },
    },
    {
      label: LabelMenuListActionRdv.MODIFIER_LA_CARACTERISATION,
      link: '',
      action: (rdv) => {
        // TODO Implement open modifier caracterisation
        if (rdv.apaRequestSpecialTreatment.specialTreatmentType === SpecialTreatmentTypeEnum.EMERGENCY_APA_PLAN) return;
      },
    },
  ];

  /** Colonnes du tableau. */
  columns: string[] = [
    'Dossier',
    'Ville / CP',
    'Référent Administratif',
    'Évaluateur',
    'Dossier complet',
    'Date du rendez-vous',
  ];

  /** Indique si toutes les lignes du tableau sont sélectionnées. */
  selectedAll = false;

  /** Liste des éléments sélectionnés dans le tableau. */
  selectedData?: AppointmentWithTaskDto[] = [];

  /** Position et valeur de tri pour les colonnes. */
  positionValue = {
    position: 0,
    value: false,
  };

  /** Valeur de filtrage. */
  filterValue = '';
  personnes = [];
  groupes = [];

  /** Liste des rendez-vous de visites. */
  visites: AppointmentDto[] = [];
  /**
   * Le nombre de resultat de rechercher.
   */
  public countSearch = 0;

  rdvToUpdate: AppointmentDto | undefined;

  @Output() updateData = new EventEmitter();

  /**
   * Constructeur du composant.
   *
   * @param {BanetteSharingService} banetteSharingService Service de partage de données pour la banette.
   * @param {SearchGlobalService} searchServcice Service de recherche globale.
   * @param {LocalStorageService} localStorageService Service de gestion du stockage local.
   * @param rendezvousService
   */
  constructor(
    private banetteSharingService: BanetteSharingService,
    private searchServcice: SearchGlobalService,
    private localStorageService: LocalStorageService,
    private rendezvousService: RendezVousData,
    private rendezVousSearchPipe: RendezVousSearchPipe,
    private referentielService: ReferentielData
  ) {
  }

  /**
   * Méthode d'initialisation du composant.
   */
  ngOnInit() {
    this.searchServcice.resetSearchValue();
    this.searchServcice.getSearchValue().subscribe((value) => {
      this.filterValue = value;
      /**
       * Counter de le nombre de rechechers
       */
      if (this.rendezvous) {
        this.countSearch = this.filterForfaits(this.rendezvous, this.filterValue).length;
        this.updateCountSearch.emit(this.countSearch);
      }
    });
    this.resetCountSearch.emit();
    this.banetteSharingService.getSelectedElements().subscribe((data) => {
      this.selectedData = data as AppointmentWithTaskDto[];
    });
  }

  /**
   * Méthode appelée lors de la destruction du composant.
   */
  ngOnDestroy(): void {
    this.banetteSharingService.setSelectedElements([]);
  }

  /**
   * Augmente le nombre d'éléments à afficher dans le tableau
   */
  addMoreItemsToShow() {
    this.itemsToShow += 10; // Incrementa la cantidad de elementos a mostrar
    if (this.items) {
      this.items.changes.subscribe((r) => {
        this.items?.toArray()[this.items?.length - 1].nativeElement.focus();
      });
      // this.items[this.items.length].nativeElement.focus();
    }
  }

  /**
   * Sélectionner toutes les lignes du tableau
   */
  selectAllRows() {
    if (this.selectedData?.length == this.rendezvous?.length) {
      this.selectedData = [];
      this.selectedAll = false;
    } else {
      this.selectedData = this.rendezvous?.slice();
      this.selectedAll = true;
    }

    this.shareSelectedRows(this.selectedData!);
  }

  /**
   * Fonction permettant de sélectionner une ligne dans la colonne
   *
   * @param {number} index L'indice de la ligne à sélectionner
   */
  selectRow(index: number) {
    if (this.rendezvous) {
      if (this.selectedData?.includes(this.rendezvous?.[index])) {
        this.selectedData?.splice(this.selectedData?.indexOf(this.rendezvous?.[index]), 1);
        this.selectedAll = false;
      } else {
        this.selectedData?.push(this.rendezvous?.[index]);
        this.selectedAll = this.selectedData?.length === this.rendezvous?.length;
      }
    }

    this.shareSelectedRows(this.selectedData!);
  }

  /**
   * Vérifier si un élément est sélectionné
   *
   * @param {number} index L'indice de l'élément à vérifier
   * @returns {boolean} True si l'élément est sélectionné sinon False.
   */
  isSelected(index: number) {
    return this.rendezvous ? this.selectedData?.includes(this.rendezvous?.[index]) : false;
  }

  /**
   * Sauvegarde les elements sélectionnés dans le service de partage des banettes
   *
   * @param {AppointmentDto[]} data Données des lignes sélectionnées.
   */
  shareSelectedRows(data: AppointmentDto[] | AppointmentWithTaskDto[]) {
    this.banetteSharingService.setSelectedElements(data);
  }

  /**
   * Trie le tableau de rendez-vous en fonction de la colonne sélectionnée.
   *
   * @param {number} index L'indice de la colonne sélectionnée.
   */
  sortTableau(index: number) {
    if (this.positionValue.position === index && this.positionValue.value) {
      this.positionValue.value = false;
      this.sortBy(index, 'desc');
    } else {
      this.sortBy(index, 'asc');
      this.positionValue.position = index;
      this.positionValue.value = true;
    }
  }

  /**
   * Trie les données de la liste de rendez-vous en fonction de l'index de colonne et de l'ordre de tri spécifié.
   *
   * @param {number} index L'index de la colonne sur laquelle effectuer le tri.
   * @param {string} order L'ordre de tri ('asc' pour croissant, 'desc' pour décroissant).
   */
  sortBy(index: number, order: string) {
    switch (index) {
      case 0:
        order.toLowerCase().includes('asc')
          ? this.rendezvous?.sort((a, b) =>
            this.acsendingSort(
              a.appointment.requesterSituation.requesterIdentity.lastName,
              b.appointment.requesterSituation.requesterIdentity.lastName
            )
          )
          : this.rendezvous?.sort((a, b) =>
            this.descendingSort(
              a.appointment.requesterSituation.requesterIdentity.lastName,
              b.appointment.requesterSituation.requesterIdentity.lastName
            )
          );
        break;
      case 1:
        order.toLowerCase().includes('asc')
          ? this.rendezvous?.sort((a, b) =>
            this.acsendingSort(
              a.appointment.requesterSituation.requesterCoordinates.currentResidenceAddress?.placeName ?? '',
              b.appointment.requesterSituation.requesterCoordinates.currentResidenceAddress?.placeName ?? ''
            )
          )
          : this.rendezvous?.sort((a, b) =>
            this.descendingSort(
              a.appointment.requesterSituation.requesterCoordinates.currentResidenceAddress?.placeName ?? '',
              b.appointment.requesterSituation.requesterCoordinates.currentResidenceAddress?.placeName ?? ''
            )
          );
        break;
      case 2:
        order.toLowerCase().includes('asc')
          ? this.rendezvous?.sort((a: any, b: any) =>
            this.acsendingSort(
              a.appointment.requesterSituation.requesterIdentity.administrativeReferent?.lastName ?? '',
              b.appointment.requesterSituation.requesterIdentity.administrativeReferent?.lastName ?? ''
            )
          )
          : this.rendezvous?.sort((a: any, b: any) =>
            this.descendingSort(
              a.appointment.requesterSituation.requesterIdentity.administrativeReferent?.lastName ?? '',
              b.appointment.requesterSituation.requesterIdentity.administrativeReferent?.lastName ?? ''
            )
          );
        break;
      case 3:
        order.toLowerCase().includes('asc')
          ? this.rendezvous?.sort((a: any, b: any) =>
          this.acsendingSort(
            a.appointment.evaluator?.lastName ?? '',
            b.appointment.evaluator?.lastName ?? ''
          )
        )
        : this.rendezvous?.sort((a: any, b: any) =>
          this.descendingSort(
            a.appointment.evaluator?.lastName ?? '',
            b.appointment.evaluator?.lastName ?? ''
          )
        );
        break;
      case 4:
        order.toLowerCase().includes('asc')
          ? this.rendezvous?.sort((a, b) =>
            a.appointment.apaRequestCompletionDate > b.appointment.apaRequestCompletionDate ? 1 : -1
          )
          : this.rendezvous?.sort((a, b) =>
            a.appointment.apaRequestCompletionDate < b.appointment.apaRequestCompletionDate ? 1 : -1
          );
        break;
      case 5:
        order.toLowerCase().includes('asc')
          ? this.rendezvous?.sort((a, b) => (a.appointment.appointmentDate > b.appointment.appointmentDate ? 1 : -1))
          : this.rendezvous?.sort((a, b) => (a.appointment.appointmentDate < b.appointment.appointmentDate ? 1 : -1));
    }
  }

  /**
   * Trie le tableau par ordre ascendant en utilisant la méthode localeCompare.
   *
   * @param {string} a Première valeur à comparer.
   * @param {string} b Deuxième valeur à comparer.
   * @returns {number} Résultat de la comparaison.
   */
  acsendingSort(a: string, b: string): number {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }

  /**
   * Trie le tableau par ordre descendant en utilisant la méthode localeCompare.
   *
   * @param {string} a Première valeur à comparer.
   * @param {string} b Deuxième valeur à comparer.
   * @returns {number} Résultat de la comparaison.
   */
  descendingSort(a: string, b: string): number {
    return b.toLowerCase().localeCompare(a.toLowerCase());
  }

  /**
   * Fonction permettant de modifier le référent administratif pour un courrier spécifique.
   *
   * @param {any} event L'événement de changement de valeur du sélecteur.
   * @param {any} index L'indice du rendez-vous dans la liste.
   */
  changeReferent(event: any, index: any) {
    if (event.target.value && event.target.value.trim()) {
      this.referents!.forEach((ref) => {
        if (ref.userId && ref.userId === event.target.value && this.rendezvous) {
          this.rendezvous[index].appointment.requesterSituation.requesterIdentity.administrativeReferent = ref;
          this.rendezvous[index].appointment.requesterSituation.requesterIdentity.administrativeReferent!.agentId =
            ref.username;
          const idindex = this.changedRdvsIds.indexOf(this.rendezvous[index].appointment.appointmentId) ?? -1;
          if (idindex !== -1) this.changedRdvsIds[idindex] = this.rendezvous[index].appointment.appointmentId;
          else this.changedRdvsIds.push(this.rendezvous[index].appointment.appointmentId);
        }
      });
    } else {
      delete this.rendezvous![index].appointment.requesterSituation.requesterIdentity.administrativeReferent;
    }
    this.onRDVTableChange();
  }

  /**
   * Fonction permettant de modifier l'évaluateur pour un courrier spécifique.
   *
   * @param {any} event L'événement de changement de valeur de l'évaluateur.
   * @param {any} index L'indice du renddz-vous dans la liste.
   */
  changeEvaluateur(event: any, index: any) {
    if (event.target.value !== null && event.target.value !== '') {
      this.evaluateurs!.forEach((ref) => {
        if (ref.userId && ref.userId === event.target.value && this.rendezvous) {
          this.rendezvous[index].appointment.evaluator = ref;
          this.rendezvous[index].appointment.evaluator!.agentId = ref.username;
          const idindex = this.changedRdvsIds.indexOf(this.rendezvous[index].appointment.appointmentId) ?? -1;
          if (idindex !== -1) this.changedRdvsIds[idindex] = this.rendezvous[index].appointment.appointmentId;
          else this.changedRdvsIds.push(this.rendezvous[index].appointment.appointmentId);
        }
      });
    }
    this.onRDVTableChange();
  }

  //TODO will be implemented when the modal SFD E2-UC07 : Affectation d’un dossier
  /**
   * Méthode pour ouvrir le modal d'affectation d'un dossier au référent.
   *
   * @param {MouseEvent} $event L'événement de clic de la souris.
   * @param {Rendezvous} item L'objet Rendezvous associé au modal.
   */
  openModalAffectationDossierReferent($event: MouseEvent, item: AppointmentDto) {
    this.selectReferent?.nativeElement.hide();
  }

  //TODO will be implemented when the modal SFD E2-UC07 : Affectation d’un dossier

  /**
   * Méthode pour ouvrir le modal d'affectation d'un dossier à l'évaluateur.
   *
   * @param {MouseEvent} $event L'événement de clic de la souris.
   * @param {number} i
   */
  openModalAffectationDossierEvaluateurs($event: MouseEvent, i: number) {
    this.selectEvaluateur?.nativeElement.hide();
  }

  selectDefaultAction($event: any) {
    //TODO will be implemented with the form
  }

  /**
   * Ouvre le modal de planification de visite pour un rendez-vous donné.
   *
   * @param {Rendezvous} rdv Rendez-vous pour lequel le modal doit être ouvert.
   */
  onPlanVisit(rdv: AppointmentWithTaskDto) {
    this.localStorageService.setObject('rendezvous', rdv);
    this.openPlanRdvModal.emit();
  }

  /**
   * Fonction qui permet de vérifier si un rendez-vous a été modifié ou supprimé
   * et qui le supprime du tableau des rendez-vous modifiés
   *
   * @returns {void}
   */
  createListOfRdvToUpdate() {
    this.changedRdvs = this.changedRdvsIds
      .map((rdvId) => this.rendezvous?.find((rdv) => rdv.appointment.appointmentId === rdvId))
      .filter((rdv) => rdv !== undefined) as AppointmentWithTaskDto[];
  }

  /**
   * Fonction qui MAJ les rendez-vous modifiés à chaque changement
   *
   * @returns {void}
   */
  onRDVTableChange(): void {
    this.createListOfRdvToUpdate();
    forkJoin(this.changedRdvs.map((r) => this.rendezvousService.updateRendezvous(r.appointment, r.taskList[0].id)))
      .pipe(take(1))
      .subscribe({
        next: () => {
          console.log('Les rendez-vous ont été mis à jour avec succès.');
        },
        error: (err) => {
          console.error('Erreur de mise à jour du rendez-vous:', err);
        },
      });
  }

  /**
   * Cette fonction calcule la différence en jours entre la date passée en argument et la date actuelle.
   * Elle retourne une chaîne de caractères indiquant la différence en jours sous la forme "J+X" (pour les dates futures),
   * "J-X" (pour les dates passées), ou "0" (pour la date actuelle).
   *
   * @param {string} dateString - La date au format de chaîne de caractères à comparer.
   * @returns {string} - La différence en jours sous forme de chaîne.
   */
  getDayDifference(dateString: string): string {
    if (!dateString) return '';
    const currentDate = new Date();
    const targetDate = new Date(dateString);
    const differenceInMilliseconds = targetDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    if (differenceInDays === 0 || differenceInDays === -1 || differenceInDays === 1) {
      return 'J';
    } else if (differenceInDays < 0) {
      return `J+${Math.abs(differenceInDays)}`;
    } else {
      return `J-${differenceInDays}`;
    }
  }

  onClickOpenAssignmentModale(profilType: AgentTypeEnum, index: number) {
    this.currentIndex = index;
    this.profilType = profilType;
    if (!this.rendezvous) {
      return;
    }
    this.rdvToUpdate = this.rendezvous[this.currentIndex].appointment;
    this.isDialogOpen = true;
  }

  closeAssignedPersonModale(refreshData?: boolean) {
    if (refreshData) {
      this.updateData.emit();
    }
    this.isDialogOpen = false;
  }

  updateSelectedPerson(person: ApaAgentDto) {
    if (!this.rendezvous) {
      return;
    }

    const indexId = this.changedRdvsIds.indexOf(this.rendezvous[this.currentIndex].appointment.appointmentId) ?? -1;

    if (this.profilType === AgentTypeEnum.ADMINISTRATIF_AgentType) {
      this.rendezvous[this.currentIndex].appointment.requesterSituation.requesterIdentity.administrativeReferent =
        person;
      if (indexId !== -1) this.changedRdvsIds[indexId] = this.rendezvous[this.currentIndex].appointment.appointmentId;
      else this.changedRdvsIds.push(this.rendezvous[this.currentIndex].appointment.appointmentId);
    }
    if (this.profilType === AgentTypeEnum.EVALUATEUR_AgentType) {
      this.rendezvous[this.currentIndex].appointment.evaluator = person;
      if (indexId !== -1) {
        this.changedRdvsIds[indexId] = this.rendezvous[this.currentIndex].appointment.appointmentId;
      } else {
        this.changedRdvsIds.push(this.rendezvous[this.currentIndex].appointment.appointmentId);
      }
    }
    this.onRDVTableChange();
  }

  findLabelEnum(codeEnum: string) {
    return this.referentielService.mapEnumItemCodeToItemLabel(codeEnum);
  }

  /* *
   * Méthode de calcul du nombre de rechechers
   * */
  filterForfaits(rendezvous: AppointmentWithTaskDto[], filterValue: string): AppointmentWithTaskDto[] {
    return this.rendezVousSearchPipe.transform(rendezvous, filterValue);
  }
}

<cnsa-dialog classname="dialog-overview" label="Filtres" [open]="showDialog" #dialog size="large">
  <div class="div-filtres">
    <div class="div-filtres-general">
      <h3 class="sub-title-filtres">Caractérisation</h3>
      <div class="div-filtres-checkbox">
        <cnsa-checkbox
          size="medium"
          value="{{ ProcessingTypeEnum.APA_URGENCE_ProcessingType }}"
          (cnsa-change)="changeTypificationDemande($event)"
          name="urgence"
          [checked]="typifications.includes(ProcessingTypeEnum.APA_URGENCE_ProcessingType)"
          >Forfait APA en urgence</cnsa-checkbox
        >

        <cnsa-checkbox
          size="medium"
          value="{{ ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType }}"
          (cnsa-change)="changeTypificationDemande($event)"
          name="procedure_acceleree"
          [checked]="typifications.includes(ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType)"
          >Procédure accélérée</cnsa-checkbox
        >

        <cnsa-checkbox
          size="medium"
          value="{{ ProcessingTypeEnum.NO }}"
          (cnsa-change)="changeTypificationDemande($event)"
          name="aucune"
          [checked]="typifications.includes(ProcessingTypeEnum.NO)"
          >Aucune</cnsa-checkbox
        >
      </div>
    </div>

    <div class="div-filtres-general">
      <h3 class="sub-title-filtres">Nature de la demande</h3>
      <div class="div-filtres-naturedemande">
        <cnsa-checkbox
          size="medium"
          value="{{ TypeOfProcessEnum.PREMIERE_DEMANDE_APA_TypeOfProcess }}"
          (cnsa-change)="changeTypeDemande($event)"
          [checked]="requestTypes.includes(TypeOfProcessEnum.PREMIERE_DEMANDE_APA_TypeOfProcess)"
          class="filtres-checkbox" /><label class="filtres-checkbox-label">1ère demande</label>
        <cnsa-checkbox
          size="medium"
          value="{{ TypeOfProcessEnum.NOUVELLE_DEMANDE_APA_TypeOfProcess }}"
          (cnsa-change)="changeTypeDemande($event)"
          [checked]="requestTypes.includes(TypeOfProcessEnum.NOUVELLE_DEMANDE_APA_TypeOfProcess)"
          >Nouvelle demande</cnsa-checkbox
        >
        <cnsa-checkbox
          size="medium"
          value="{{ TypeOfProcessEnum.REVISION_PERSONNE_TypeOfProcess }}"
          (cnsa-change)="changeTypeDemande($event)"
          [checked]="requestTypes.includes(TypeOfProcessEnum.REVISION_PERSONNE_TypeOfProcess)"
          >Révision à la demande de la personne</cnsa-checkbox
        >
        <cnsa-checkbox
          size="medium"
          value="{{ TypeOfProcessEnum.RELAIS_HOSPITALISATION_TypeOfProcess }}"
          (cnsa-change)="changeTypeDemande($event)"
          [checked]="requestTypes.includes(TypeOfProcessEnum.RELAIS_HOSPITALISATION_TypeOfProcess)"
          >Module relais en cas d'hospitalisation</cnsa-checkbox
        >
      </div>
    </div>

    <div class="div-filtres-select">
      <h3 class="sub-title-filtres">Référent administratif</h3>
      <cnsa-button variant="secondary" (click)="onClickSelectReferent($event)">
       {{ this.selectedReferent && this.selectedReferent.lastName && this.selectedReferent.firstName ? (this.selectedReferent?.lastName | uppercase)+ ' ' + (this.selectedReferent?.firstName | titlecase) : 'Sélectionner'}}
      </cnsa-button>
    </div>

    <div class="div-filtres-select">
      <h3 class="sub-title-filtres">Évaluateur</h3>
      <cnsa-button variant="secondary" (click)="onClickSelectEvaluator($event)">
        {{ this.selectedEvaluator && this.selectedEvaluator.lastName && this.selectedEvaluator.firstName ? (this.selectedEvaluator?.lastName | uppercase)+ ' ' + (this.selectedEvaluator?.firstName | titlecase) : 'Sélectionner'}}
      </cnsa-button>
    </div>

    <div class="div-filtres-general">
      <h3 class="sub-title-filtres">Type de rendez-vous</h3>
      <div class="div-filtres-checkbox">
        <cnsa-checkbox
          size="medium"
          value="{{ EvaluationModalityEnum.ENTRETIEN_TEL_EvaluationModality }}"
          (cnsa-change)="changeTypeVisit($event)"
          [checked]="types.includes(EvaluationModalityEnum.ENTRETIEN_TEL_EvaluationModality)"
          >Téléphone</cnsa-checkbox
        >
        <cnsa-checkbox
          size="medium"
          value="{{ EvaluationModalityEnum.VISITE_DOMICILE_EvaluationModality }}"
          (cnsa-change)="changeTypeVisit($event)"
          [checked]="types.includes(EvaluationModalityEnum.VISITE_DOMICILE_EvaluationModality)"
          >Visite</cnsa-checkbox
        >
      </div>
    </div>

    <div class="div-filtres-general">
      <h3 class="sub-title-filtres">Statut du rendez-vous</h3>
      <div class="div-filtres-checkbox">
        <cnsa-checkbox
          size="medium"
          value="{{ AppointmentStatusEnum.A_PLANIFIER_AppointmentStatus }}"
          (cnsa-change)="changeStatusVisit($event)"
          [checked]="visitStatus.includes(AppointmentStatusEnum.A_PLANIFIER_AppointmentStatus)"
          >Rendez-vous à planifier</cnsa-checkbox
        >
        <cnsa-checkbox
          size="medium"
          value="{{ AppointmentStatusEnum.PLANIFIE_AppointmentStatus }}"
          (cnsa-change)="changeStatusVisit($event)"
          [checked]="visitStatus.includes(AppointmentStatusEnum.PLANIFIE_AppointmentStatus)"
          >Rendez-vous planifié</cnsa-checkbox
        >
      </div>
    </div>

    <div class="div-filtres-date">
      <h3 class="sub-title-filtres">Date de complétude du dossier</h3>
      <div class="div-filtres-date-radios">
        <input
          type="radio"
          value="periode"
          [checked]="dateForm === 'periode'"
          (change)="changeDateForm($event)"
          name="periode"
          class="circle" /><label>Période</label>
        <input
          type="radio"
          value="precise"
          [checked]="dateForm === 'precise'"
          (change)="changeDateForm($event)"
          name="precise"
          class="circle" /><label>Date précise</label>
      </div>

      <ng-container *ngIf="dateForm === 'periode'; else preciseBlock">
        <div class="div-filtres-date-2inputs">
          <div class="div-filtres-date-inputs">
            <cnsa-input
              label="Date de début"
              help-text="Format attend : JJ/MM/AAAAA"
              type="date"
              value="filter.apaRequestMinCompletionDate"
              [(ngModel)]="filter.apaRequestMinCompletionDate"
              ngDefaultControl />
          </div>

          <div class="div-filtres-date-inputs">
            <cnsa-input
              label="Date de fin"
              help-text="Format attendu : JJ/MM/AAAA"
              type="date"
              value="filter.apaRequestMaxCompletionDate"
              [(ngModel)]="filter.apaRequestMaxCompletionDate"
              ngDefaultControl />
          </div>
        </div>
      </ng-container>
      <ng-template #preciseBlock>
        <div class="div-filtres-date-inputs">
          <cnsa-input
            label="Date"
            help-text="Format attendu : JJ/MM/AAAAA"
            type="date"
            value="filter.apaRequestExactCompletionDate"
            [(ngModel)]="filter.apaRequestExactCompletionDate"
            ngDefaultControl />
        </div>
      </ng-template>
    </div>
  </div>

  <cnsa-button slot="footer" variant="navigation" type="reset" (click)="resetFilter()"
    >Réinitialiser les filtres</cnsa-button
  >
  <cnsa-button slot="footer" variant="primary" type="submit" (click)="filterRDV()">Filtrer</cnsa-button>
</cnsa-dialog>

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BanetteRoutingModule } from './banette-routing.module';
import { SiApaBanetteGeneriquePageComponent } from './si-apa-banette-generique-page/si-apa-banette-generique-page.component';
import { BanetteHeaderTitreComponent } from './components/banette-header-titre/banette-header-titre.component';
import { BanetteHeaderBarreActionsComponent } from './components/banette-header-barre-actions/banette-header-barre-actions.component';
import { BanetteHeaderRechercheComponent } from './components/banette-header-recherche/banette-header-recherche.component';
import { BanetteTableauDemandesComponent } from './components/banette-tableau-demandes/banette-tableau-demandes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BanetteTableauCorbeilleComponent } from './components/banette-tableau-corbeille/banette-tableau-corbeille.component';
import { BanetteTableauRendezVousComponent } from './components/banette-tableau-rendez-vous/banette-tableau-rendez-vous.component';
import { BanetteTableauBoiteComponent } from './components/banette-tableau-boite/banette-tableau-boite.component';
import { BanetteTableauCourriersComponent } from './components/banette-tableau-courriers/banette-tableau-courriers.component';
import { BanetteModaleAssignerPersonneComponent } from './components/bannete-modale-assigner-personne/banette-modale-assigner-personne.component';
import { GestionCorbeilleMockService } from '../corbeille/mocks/gestion-corbeille-mock.service';
import { GestionCorbeilleService } from '../corbeille/services/gestion-corbeille.service';
import { BanetteFiltresCorbeilleComponent } from './components/banette-filtres-corbeille/banette-filtres-corbeille.component';
import { GestionCourrierService } from '../courrier/services/gestion-courrier.service';
import { GestionCourriersEntrantsMockService } from '../courrier/mocks/gestion-courriers-entrants-mock.service';
import { BanetteFiltresDemandeComponent } from './components/banette-filtres-demande/banette-filtres-demande.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { BanetteFiltresBoiteComponent } from './components/banette-filtres-boite/banette-filtres-boite.component';
import { BanetteFiltresRdvComponent } from './components/banette-filtres-rdv/banette-filtres-rdv.component';
import { BanetteFiltresCourriersComponent } from './components/banette-filtres-courriers/banette-filtres-courriers.component';
import { BanetteForfaitsApaEnUrgenceComponent } from './components/banette-tableau-frofaits-apa-en-urgence/banette-forfaits-apa-en-urgence.component';
import { GroupManagementService } from '../management-group/service/group-management.service';
import { environment } from 'src/environments/environment';
import { PersonnesGroupesData } from './data/personnes-groupes.data';
import { PersonnesGroupesService } from './services/personnes-groupes.service';
import { PersonnesGroupesMockService } from './mocks/personnes-groupes-mock.service';
import { CorbeilleSearchPipe } from '../../shared/pipes/corbeille-search.pipe';

/**
 * Liste des fournisseurs de données.
 */
const DATA_PROVIDERS = [
  {
    provide: PersonnesGroupesData,
    useClass: environment.production ? PersonnesGroupesService : PersonnesGroupesMockService,
  },
];
@NgModule({
  declarations: [
    SiApaBanetteGeneriquePageComponent,
    BanetteHeaderTitreComponent,
    BanetteHeaderBarreActionsComponent,
    BanetteHeaderRechercheComponent,
    BanetteTableauDemandesComponent,
    BanetteTableauCorbeilleComponent,
    BanetteTableauRendezVousComponent,
    BanetteTableauBoiteComponent,
    BanetteTableauCourriersComponent,
    BanetteModaleAssignerPersonneComponent,
    BanetteFiltresCorbeilleComponent,
    BanetteFiltresDemandeComponent,
    BanetteFiltresBoiteComponent,
    BanetteFiltresRdvComponent,
    BanetteForfaitsApaEnUrgenceComponent,
    BanetteFiltresCourriersComponent,
  ],
  imports: [CommonModule, BanetteRoutingModule, FormsModule, ReactiveFormsModule, RouterModule, SharedModule],
  providers: [
    DATA_PROVIDERS,
    GestionCorbeilleService,
    GestionCorbeilleMockService,
    GestionCourrierService,
    GestionCourriersEntrantsMockService,
    GroupManagementService,
    PersonnesGroupesService,
    CorbeilleSearchPipe,
  ],
  exports: [
    SiApaBanetteGeneriquePageComponent,
    BanetteHeaderRechercheComponent,
    BanetteModaleAssignerPersonneComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BanetteModule {}

import { UserRole } from '../enums/user-role.enum';
import { UserDto } from '../models/user.dto';

export const mockAutonomieUser: UserDto = {
  workEmailAddress: 'mockAutonomieUser@apa.com',
  workPhoneNumber: '0402030506',
  profile: UserRole.AUTONOMIE,
  userId: '123',
  firstName: 'Nabil',
  lastName: 'ITOUHLATI',
  departmentName: 'serviceAutonomie',
  departmentNumber: 'CNSA-547',
};

import { Injectable } from '@angular/core';

/**
 * Service pour générer un fichier CSV.
 */
@Injectable({
  providedIn: 'root',
})
export class GenerateCsvService {
  /**
   * Génère un fichier CSV.
   *
   * @param {string} fileNameWithoutEnding Le nom du fichier sans la fin.
   * @param {string} columns Les colonnes.
   * @param {string[]} rowList La liste des lignes.
   */
  generateCsv(fileNameWithoutEnding: string, columns: string, rowList: string[]) {
    const tableToCheckSperator = [];
    tableToCheckSperator.push(columns);

    if (this.checkFormatSeparator(rowList) && this.checkFormatSeparator(tableToCheckSperator)) {
      this.generateFile(fileNameWithoutEnding, columns, rowList);
    } else {
      console.error("The csv separator has the wrong format. Replace it with ','");
    }
  }

  /**
   * Génère un fichier.
   *
   * @param {string} fileNameWithoutEnding Le nom du fichier sans la fin.
   * @param {string} columns Les colonnes.
   * @param {string[]} rowList La liste des lignes.
   */
  generateFile(fileNameWithoutEnding: string, columns: string, rowList: string[]) {
    const csv = rowList;

    csv.unshift(columns);
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob(['\uFEFF' + csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileNameWithoutEnding + '.csv';
    a.click();

    window.URL.revokeObjectURL(url);
    a.remove();
  }

  /**
   * Vérifie le format du separateur.
   *
   * @param {string} fileNameWithoutEnding Le nom du fichier sans la fin.
   * @returns {boolean} True si le format est valide sinon False.
   */
  checkFormatSeparator(list: string[]): boolean {
    let formatIsValid = true;

    list.forEach((row) => {
      if (row.split(',').length === 1) {
        formatIsValid = false;
      }
    });
    return formatIsValid;
  }
}

import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BannetteTypeEnum } from '../enums/bannette-type.enum';
import { OutgoingMailDto } from '../../courrier/models/outgoing-mail.dto';
import { formatDate } from '@angular/common';
import { IncomingMailDto } from '../../corbeille/models/incoming-mail.dto';
import { AppointmentDto } from '../../rendez-vous/models/appointment.dto';
import { ApaRequestDto } from '../../demande/models/apa-request.dto';
import { ReferentielData } from '../../../shared/data/referentiel-data';

/**
 * Service pour la création de lignes CSV et d'en-têtes en fonction du type de bannette.
 */
@Injectable({
  providedIn: 'root',
})
export class PrepareStatementCsvService {
  /**
   * Énumération des types de bannette.
   */
  public bannetteTypeEnum = BannetteTypeEnum;

  /**
   * Construit le service avec le code de localisation.
   *
   * @param {string} locale Le code de localisation.
   * @param referentielService
   */
  constructor(@Inject(LOCALE_ID) private locale: string, private referentielService: ReferentielData) {}

  /**
   * Crée une liste de lignes CSV en fonction des données et du type de bannette.
   *
   * @param {any[]} data Les données à convertir en lignes CSV.
   * @param {string} bannetteType Le type de bannette pour lequel générer les lignes CSV.
   * @returns {string[]} Une liste de lignes CSV.
   */
  createCsvRowList(data: any[], bannetteType: string): string[] {
    const csvRowList: string[] = [];

    switch (bannetteType) {
      case this.bannetteTypeEnum.BOITE:
        data.forEach((element: OutgoingMailDto) => csvRowList.push(this.createBoiteObjectToCsv(element)));
        break;
      case this.bannetteTypeEnum.COURRIERS:
        data.forEach((element: IncomingMailDto) => csvRowList.push(this.createCourrierObjectToCsv(element)));
        break;
      case this.bannetteTypeEnum.CORBEILLE:
        data.forEach((element: IncomingMailDto) => csvRowList.push(this.createCorbeilleObjectToCsv(element)));
        break;
      case this.bannetteTypeEnum.DEMANDE:
        data.forEach((element: ApaRequestDto) => csvRowList.push(this.createDemandeObjectToCsv(element)));
        break;
      case this.bannetteTypeEnum.RENDEZVOUS:
        data.forEach((element: AppointmentDto) => csvRowList.push(this.createRendezVousObjectToCsv(element)));
        break;
      default:
        console.error("Générateur de CSV : Le type de bannette n'est pas reconnu pour écrire les données du csv");
    }
    return csvRowList;
  }

  /**
   * Crée une ligne CSV à partir des données d'un courrier sortant.
   *
   * @param {OutgoingMailDto} sendedMail Le courrier sortant.
   * @returns {string} La ligne CSV correspondante.
   */
  createBoiteObjectToCsv(sendedMail: OutgoingMailDto): string {
    return (
      sendedMail.personSituation.personIdentity.firstName +
      ` ` +
      sendedMail.personSituation.personIdentity.lastName +
      ` ` +
      sendedMail.personSituation.personIdentity.nir +
      `,` +
      sendedMail.personSituation.personCoordinates.residenceAddress!.placeName +
      ` ` +
      sendedMail.personSituation.personCoordinates.residenceAddress!.zipCode +
      `,` +
      this.findLabelEnum(sendedMail.mailTypeCode) +
      `,` +
      sendedMail.personSituation.personIdentity.administrativeReferent?.firstName +
      ` ` +
      sendedMail.personSituation.personIdentity.administrativeReferent?.lastName +
      `,` +
      formatDate(sendedMail.generationDate.toString(), 'dd/MM/yyyy hh:mm', this.locale)
    );
  }

  /**
   * Crée une ligne CSV à partir des données d'un courrier entrant.
   *
   * @param {IncommingMail} incommingMail Le courrier entrant.
   * @returns {string} La ligne CSV correspondante.
   */
  createCourrierObjectToCsv(incommingMail: IncomingMailDto): string {
    return (
      incommingMail.mailName +
      ` ` +
      incommingMail.documents.length +
      `document(s),` +
      this.findLabelEnum(incommingMail.mailOriginCode) +
      `,` +
      this.findLabelEnum(incommingMail.mailTypeCode) +
      `,` +
      (incommingMail.mailApaPerson.administrativeReferent?.firstName ?? '') +
      ` ` +
      (incommingMail.mailApaPerson.administrativeReferent?.lastName ?? '') +
      `,` +
      incommingMail.mailDepositDate
    );
  }

  /**
   * Crée une ligne CSV à partir des données d'une demande APA.
   *
   * @param {ApaRequestDto} demande La demande APA.
   * @returns {string} La ligne CSV correspondante.
   */
  createDemandeObjectToCsv(demande: ApaRequestDto): string {
    return (
      demande.requesterSituation.personIdentity.firstName +
      ` ` +
      demande.requesterSituation.personIdentity.lastName +
      demande.requesterSituation.personIdentity.nir +
      `,` +
      (demande.requestType ? this.referentielService.mapEnumItemCodeToItemLabel(demande.requestType) : '') +
      `,` +
      this.findLabelEnum(demande.requestStatus!.value) +
      `,` +
      demande.visitEvaluator?.firstName +
      ` ` +
      demande.visitEvaluator?.lastName +
      `,` +
      demande.initialisationDateTime
    );
  }

  /**
   * Crée une ligne CSV à partir des données d'un courrier dans la corbeille.
   *
   * @param {IncommingMail} incommingMail Le courrier dans la corbeille.
   * @returns {string} La ligne CSV correspondante.
   */
  createCorbeilleObjectToCsv(incommingMail: IncomingMailDto) {
    return (
      incommingMail.mailName +
      ` ` +
      incommingMail.documents.length +
      `document(s),` +
      incommingMail.mailApaPerson.useName +
      ` ` +
      incommingMail.mailApaPerson.nir +
      `,` +
      incommingMail.deleteBy?.firstName +
      ` ` +
      incommingMail.deleteBy?.lastName +
      `,` +
      incommingMail.deletionDatetime
    );
  }

  /**
   * Crée une ligne CSV à partir des données d'un rendez-vous.
   *
   * @param {Rendezvous} rendezVous Le rendez-vous.
   * @returns {string} La ligne CSV correspondante.
   */
  createRendezVousObjectToCsv(rendezVous: AppointmentDto) {
    return (
      rendezVous.requesterSituation.requesterIdentity.firstName +
      ` ` +
      rendezVous.requesterSituation.requesterIdentity.lastName +
      ` ` +
      rendezVous.requesterSituation.requesterIdentity.nir +
      ` ` +
      rendezVous.apaRequestType +
      `,` +
      rendezVous.requesterSituation.requesterCoordinates.currentResidenceAddress.placeName +
      ` ` +
      +rendezVous.requesterSituation.requesterCoordinates.currentResidenceAddress.zipCode +
      `,` +
      rendezVous.requesterSituation.requesterIdentity.administrativeReferent?.firstName +
      ` ` +
      rendezVous.requesterSituation.requesterIdentity.administrativeReferent?.lastName +
      `,` +
      rendezVous.evaluator.firstName +
      ` ` +
      rendezVous.evaluator.lastName +
      `,` +
      rendezVous.apaRequestCompletionDate +
      `,` +
      rendezVous.appointmentDate +
      ` ` +
      rendezVous.startHour +
      ` ` +
      rendezVous.endHour
    );
  }

  /**
   * Crée un en-tête CSV en fonction du type de bannette.
   *
   * @param {string} bannetteType Le type de bannette pour lequel générer l'en-tête CSV.
   * @returns {string} L'en-tête CSV.
   */
  createCsvHeader(bannetteType: string): string {
    let columns = '';
    switch (bannetteType) {
      case this.bannetteTypeEnum.BOITE:
        columns = 'Dossier,Lieu de résidence,Type de courrier,Référent Administratif,Géneré le';
        break;
      case this.bannetteTypeEnum.COURRIERS:
        columns = "Nom,Provenance,Type de courrier,Référent Administratif,Date d'import";
        break;
      case this.bannetteTypeEnum.CORBEILLE:
        columns = 'Nom,Dossier,Supprimé par,Supprimé le';
        break;
      case this.bannetteTypeEnum.DEMANDE:
        columns = 'Dossier,Nature de la demande,Statut,Référent Administratif,Date de dépôt';
        break;
      case this.bannetteTypeEnum.RENDEZVOUS:
        columns = 'Dossier,Ville/CP,Référent Administratif,Evaluateur,Dossier complet,Date du rendez-vous';
        break;
      default:
        console.error("Générateur de CSV : Le type de bannette n'est pas reconnu pour écrire le nom des colonnes");
    }
    return columns;
  }

  findLabelEnum(codeEnum: string) {
    return this.referentielService.mapEnumItemCodeToItemLabel(codeEnum);
  }
}

import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IncomingMailsPaginatedSearchResultDto } from '../../corbeille/models/Incoming-mails-paginated-search-result.dto';
import { environment as envDev } from '../../../../../environments/environment';
import { environment as envProd } from '../../../../../environments/environment.prod';
import { PAGE_SIZE } from '../../../shared/utils/globalConstants';
import { IncomingMailPaginatedSearchQueryDto } from '../models/incoming-mail-paginated-search-query.dto';
import { ApaAgentDto } from '../../demande/models/apa-agent.dto';
import { GestionCourriersEntrantsData } from '../data/gestion-courriers-entrants.data';
import { IncomingMailDto } from '../../corbeille/models/incoming-mail.dto';
import { ReferentUpdateRequestDto } from 'src/app/modules/shared/models/referent-update-request.dto';
import { IncomingMailUpdateRequestDto } from '../../corbeille/models/incoming-mail-update-request.dto';

/**
 * Service de gestion des courriers.
 *
 * Ce service étend GestionCourriersEntrantsData et gère la récupération des courriers entrants.
 */
@Injectable({
  providedIn: 'root',
})
export class GestionCourrierService extends GestionCourriersEntrantsData {
  /**
   * Ensemble de référents (ApaAgentDto) pour le service.
   */
  referents: Set<ApaAgentDto> = new Set<ApaAgentDto>();

  /**
   * Subject pour stocker la date de mise à jour des courriers.
   */
  private courrierUpdateDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());

  private siapaCourrierInterRestApi;
  private endpoint;

  /**
   * Constructeur du service GestionCourrierService.
   *
   * @param httpClient - Instance d'HttpClient pour les requêtes HTTP.
   */
  constructor(private httpClient: HttpClient) {
    super();
    if (isDevMode()) {
      this.endpoint = envDev.endPointIncomingMails;
      this.siapaCourrierInterRestApi = envDev.siapaCourrierInterRestApi + this.endpoint;
    } else {
      this.endpoint = envProd.endPointIncomingMails;
      this.siapaCourrierInterRestApi = envProd.siapaCourrierInterRestApi + this.endpoint;
    }
  }

  /**
   * BehaviorSubject pour stocker la réponse des courriers entrants paginés.
   */
  response: BehaviorSubject<IncomingMailsPaginatedSearchResultDto> =
    new BehaviorSubject<IncomingMailsPaginatedSearchResultDto>({
      _links: undefined,
      pageContent: [],
      pageNumber: 0,
      pageSize: PAGE_SIZE,
      totalNumberOfElements: 0,
      totalNumberOfPages: 0,
    });

  //TODO: Create filter interface when filter is ready
  //TODO: Create response interface when response is ready
  //TODO: Check back-front connection when back is ready
  /**
   * Récupère les courriers entrants.
   *
   * @param {IncomingMailPaginatedSearchQueryDto} filter - (Facultatif) Filtre pour la récupération des courriers.
   * @returns {Observable<IncomingMailsPaginatedSearchResultDto>} Un Observable contenant la réponse de gestion des boîtes de réception.
   * @author Carlos Poveda
   */
  getCourriersEntrants(
    filter?: IncomingMailPaginatedSearchQueryDto
  ): Observable<IncomingMailsPaginatedSearchResultDto> {
    const requestUrl = this.siapaCourrierInterRestApi + '/search';
    if (!filter) {
      filter = {
        pageNumber: 0,
        pageSize: PAGE_SIZE,
      };
    }
    this.httpClient.post<IncomingMailsPaginatedSearchResultDto>(requestUrl, filter).subscribe((data) => {
      this.response.next(data);
      this.courrierUpdateDate.next(new Date());
    });
    return this.response.asObservable();
  }

  /**
   * Mis à jour du courrier entrant.
   *
   * @param courrier
   */
  updateCourrier(courrier: IncomingMailDto): Observable<IncomingMailDto> {
    const requestUrl = this.siapaCourrierInterRestApi + '/' + courrier.mailId;
    this.httpClient.put<IncomingMailUpdateRequestDto>(requestUrl, courrier).subscribe((data) => {
      courrier = data;
      this.getCourriersEntrants();
    });
    return of(courrier);
  }

  updateIncomingMailReferentAdmin(updateInfo: ReferentUpdateRequestDto): Observable<any> {
    const requestUrl = this.siapaCourrierInterRestApi + '/referent-admin';
    return this.httpClient.put<ReferentUpdateRequestDto>(requestUrl, updateInfo);
  }

  // Shared courrier data between components

  /**
   * Récupère la date de mise à jour des courriers.
   *
   * @returns {Observable<Date>} La date de mise à jour des courriers.
   */
  getCourrierUpdateDate(): Observable<Date> {
    return this.courrierUpdateDate.asObservable();
  }

  /**
   * Supprime un courrier entrant avec l'IDs spécifié.
   *
   * @param ids - IDs du courrier entrant à supprimer.
   */
  deleteCourriers(ids: string[]): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const params = new HttpParams().appendAll({ incomingMailIds: ids });
    return this.httpClient.delete<void>(this.siapaCourrierInterRestApi, { params, headers });
  }
}

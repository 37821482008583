<!-- TODO: Ajouter aria-sort= "ascending" ou "descending" sur les headers des colonnes lorsqu'elles sont triées -->
<div class="container">
  <cnsa-visually-hidden id="banette-helper"
    >, les entêtes de colonnes avec bouton peuvent être triés.
  </cnsa-visually-hidden>
  <table class="banette" aria-describedby="banette-title banette-helper">
    <thead class="banette__header">
      <tr>
        <th scope="col">
          <div class="banette__header-cell">
            <cnsa-checkbox
              label="Sélectionner toutes les lignes"
              [(ngModel)]="selectedAll"
              ngDefaultControl
              (cnsa-change)="selectAllRows()"></cnsa-checkbox>
          </div>
        </th>
        <th></th>
        <th scope="col" *ngFor="let column of columns; let x = index">
          <button class="banette__header-cell banette__header-cell--button" (click)="sortRows(x)">
            {{ column }}
            <cnsa-icon
              name="unfold_more"
              class="banette__sort-icon"
              aria-hidden="true"
              *ngIf="column !== ''"></cnsa-icon>
          </button>
        </th>
        <th></th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngIf="corbeille">
        <tr
          *ngFor="let item of corbeille?.slice(0, itemsToShow); let i = index"
          [ngClass]="isSelected(i) ? 'selected-background' : ''">
          <td>
            <cnsa-checkbox [checked]="isSelected(i)" (cnsa-change)="selectRow(i)"></cnsa-checkbox>
          </td>
          <td>
            <ng-container *ngIf="item.folder; else elseBlock">
              <cnsa-icon name="folder" class="icon-folder" *ngIf="item.folder" (click)="selecTedItem(item)"></cnsa-icon>
            </ng-container>
            <ng-template #elseBlock>
              <cnsa-icon name="article" class="icon-article" (click)="selecTedItem(item)"></cnsa-icon>
            </ng-template>
            <cnsa-dialog
              *ngIf="itemPop === item && item.folder"
              classname="dialog-overview"
              label="{{ item.lineName }}"
              [open]="showPopup"
              size="large">
              <div class="container">
                <table aria-label="Documents">
                  <thead class="visually-hidden">
                    <tr>
                      <th scope="col">Nom</th>
                      <th scope="col">Format</th>
                      <th scope="col">Type</th>
                      <th scope="col">Poids</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let doc of itemPop?.evidence">
                      <td class="cell-content">{{ mapEvidenceProperty(doc.evidenceProperty).filename }}</td>
                      <td>{{ mapEvidenceProperty(doc.evidenceProperty).extension }}</td>
                      <td>
                        <ng-template [ngIf]="doc.linkEvidenceType.length > 1">
                          <cnsa-tag variant="blue-100" *ngFor="let type of doc.linkEvidenceType"
                            >{{ findLabelEnum(type.evidenceTypeCode?.toString()) }}
                          </cnsa-tag>
                        </ng-template>
                      </td>
                      <!--<td>{{ doc.document?.contentLength }} Mo</td>-->
                      <td>
                        <cnsa-icon name="more_vert" class="icon-sort"></cnsa-icon>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </cnsa-dialog>
          </td>
          <td>
            <div class="cell-content">
              <strong>
                {{ mapEvidenceProperty(item.evidence[0].evidenceProperty).filename }}
              </strong>
              <p class="cell-content__secondary">
                {{ item.folder ? 'Afficher les ' + item.evidence.length + ' documents' : '' }}
              </p>
              <p class="cell-content__secondary">
                {{ mapEvidenceProperty(item.evidence[0].evidenceProperty).extension }}
              </p>
            </div>
          </td>
          <td class="cell-content">
            <!-- <p class="folder-name">
              {{ item?.deleterUserName ?? '' | uppercase }}
            </p> -->
            <!-- ne remonte pas  -->
            <!--          <a href="#" class="cell-content__secondary">
            {{ item.mailApaPerson && item.mailApaPerson.nir ? item.mailApaPerson.nir : '' }}
          </a>-->
          </td>

          <td>            
            {{formatName(item?.deleterUserName)}}
          </td>
          <td>
            <cnsa-icon name="calendar_today"></cnsa-icon>
            {{ item?.deleteDate ? (item.deleteDate | date : 'dd/MM/yy') : '' }}
          </td>
          <td>
            <cnsa-button size="small" variant="tertiary">Télécharger</cnsa-button>
          </td>
          <td>
            <cnsa-button size="small" variant="danger" (click)="deleteDoc(item.evidence[0].evidenceId)"
              >Supprimer</cnsa-button
            >
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <span class="table-label" *ngIf="corbeille && corbeille?.slice(0, itemsToShow)?.length === 0">
    <p class="bold-text">{{ message.noResult }}</p>
    <br />
    <p>{{ message.emptySearch }}</p>
  </span>
  <div class="container-button" *ngIf="corbeille">
    <cnsa-button
      *ngIf="corbeille.length > itemsToShow && corbeille.length > itemsToShow"
      variant="primary"
      (click)="addMoreItemsToShow()">
      <cnsa-icon name="downloading" slot="prefix"></cnsa-icon>
      Afficher plus de résultats
    </cnsa-button>
  </div>
</div>

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CNSADialog } from '@cnsa-fr/design-system';
import { FilterDemande } from '../../../demande/models/response-gestion-demande.dto';
import { ApaAgentDto } from '../../../demande/models/apa-agent.dto';
import { PAGE_SIZE } from '../../../../shared/utils/globalConstants';
import { GestionDemandesData } from '../../../demande/data/gestion-demandes.data';
import { ProfileTypeEnum } from '../../enums/profile-type.enum';
import { searchApaAgentData } from '../../../../shared/data/search-apa-agent.data';
import {
  DemandStatusEnum,
  ProcessingTypeEnum,
  TypeOfProcessEnum,
} from '../../../../shared/enums/referentiel-requests.enum';
import { ReferentielData } from '../../../../shared/data/referentiel-data';
import { AgentTypeEnum } from '../../../../shared/enums/referentiel-person.enum';

/**
 * Composant pour afficher les filtres des demandes et les utiliser pour filtrer les demandes.
 */
@Component({
  selector: 'app-banette-filtres-demande',
  templateUrl: './banette-filtres-demande.component.html',
  styleUrls: ['./banette-filtres-demande.component.css'],
})
export class BanetteFiltresDemandeComponent implements OnChanges {
  /**
   * Titre du composant (optionnel).
   */
  @Input() titre?: string;

  /**
   * Indicateur pour afficher ou masquer la boîte de dialogue.
   */
  @Input() showDialog = false;

  /**
   * Indicateur pour filtrer par plan APA d'urgence.
   */
  @Input() isEmergencyApaPlan = false;

  /**
   * Indicateur pour filtrer par procédure accélérée.
   */
  @Input() isAcceleratedProcedure = false;
  @Input() selectedPerson: ApaAgentDto | undefined = undefined;

  /**
   * Liste des référents APA disponibles.
   */
  @Input() referents?: ApaAgentDto[];

  /**
   * Événement émis lorsque la boîte de dialogue est fermée.
   */
  @Output() closeDialog = new EventEmitter();

  /**
   * Événement émis lorsque le nombre de filtres sélectionnés.
   */
  @Output() checkedCounterChange = new EventEmitter<number | boolean>();

  @Output() updateModale = new EventEmitter<boolean>();
  @Output() updateProfilName = new EventEmitter<AgentTypeEnum>();
  /**
   * Référence au dialogue (si utilisée dans le modèle).
   */
  @ViewChild('dialog') dialog?: ElementRef<CNSADialog>;

  /**
   * Référence à la sélection des référent administratif
   */
  @ViewChild('selectReferent') selectReferent?: any;

  /**
   * Filtre actuel pour les demandes.
   */
  filterDemandes: FilterDemande = {
    administrativeReferent: this.selectedPerson,
    pageNumber: 0,
    pageSize: PAGE_SIZE,
    specialTreatment: [],
    requestType: [],
  };

  /**
   * counter pour le nombre de filtres sélectionnés.
   */
  checkedCounter = 0;
  filterCount = 0;
  /**
   * Enumération des types de demandes APA.
   */
  TypeOfProcessEnum = TypeOfProcessEnum;

  /**
   * Enumération des types de traitement spécial.
   */
  ProcessingTypeEnum = ProcessingTypeEnum;
  /**
   * Enumération des statuts des demandes APA.
   */
  DemandStatusEnum = DemandStatusEnum;
  /**
   * Crée une instance du composant BanetteFiltresDemandeComponent.
   *
   * @param {searchApaAgentData} searchApaApgent Le service pour rechercher les agents APA.
   * @param {GestionDemandesData} gestionDemandesData Le service pour gérer les demandes.
   */
  constructor(private gestionDemandesData: GestionDemandesData, private referentielService: ReferentielData) {}

  /**
   * Méthode appelée lorsque les entrées de ce composant changent.
   *
   * @param {SimpleChanges} { isEmergencyApaPlan, isAcceleratedProcedure } Les changements observés.
   */
  ngOnChanges({ isEmergencyApaPlan, isAcceleratedProcedure }: SimpleChanges): void {
    // Emergency apa plan special treatment change
    if (isEmergencyApaPlan && !isEmergencyApaPlan.firstChange) {
      this.updateSpecialTreatmentSearch(this.isEmergencyApaPlan, ProcessingTypeEnum.APA_URGENCE_ProcessingType);
    }
    // Accelerated procedure special treatment change
    if (isAcceleratedProcedure && !isAcceleratedProcedure.firstChange) {
      this.updateSpecialTreatmentSearch(
        this.isAcceleratedProcedure,
        ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType
      );
    }
  }

  /**
   * Méthode pour mettre à jour les filtres de recherche en fonction du type de traitement spécial.
   *
   * @param {boolean} type Le type de filtre (vrai ou faux).
   * @param {ProcessingTypeEnum} specialTreatmentType Le type de traitement spécial.
   */
  private updateSpecialTreatmentSearch(type: boolean, specialTreatmentType: ProcessingTypeEnum) {
    setTimeout(() => {
      // if type is true add it to search filter
      if (type) {
        this.filterDemandes.specialTreatment.push(specialTreatmentType);
      } else {
        // if type is false remove it from search filter
        this.filterDemandes.specialTreatment = this.filterDemandes.specialTreatment.filter(
          (specialTreatmentFilter) => specialTreatmentFilter !== specialTreatmentType
        );
      }
      this.filtrerDemande();
    });
  }

  /**
   * Bascule l'affichage de la boîte de dialogue.
   * Cache la boîte de dialogue en émettant un événement closeDialog.
   */
  toggleDialog() {
    this.showDialog = false;
    this.closeDialog.emit();
  }

  /**
   * Méthode pour gérer le changement de caractérisation d'une demande.
   *
   * @param {Event} event L'événement de changement déclenché par l'utilisateur.
   */
  changeCaracterisationDemande(event: Event) {
    const target = event.target as HTMLInputElement;
    const specialTreatmentType = target.value as ProcessingTypeEnum;
    if (this.filterDemandes.specialTreatment.includes(specialTreatmentType)) {
      const index = this.filterDemandes.specialTreatment.indexOf(specialTreatmentType);
      if (index > -1) {
        this.filterDemandes.specialTreatment.splice(index, 1);
      }
    } else {
      if (!this.filterDemandes.specialTreatment) {
        this.filterDemandes.specialTreatment = [];
      }
      this.filterDemandes.specialTreatment.push(specialTreatmentType);
    }
  }

  /**
   * Méthode pour gérer le changement du statut d'une demande.
   *
   * @param {Event} event L'événement de changement déclenché par l'utilisateur.
   */
  changeDemandeStatus(event: Event) {
    const target = event.target as HTMLInputElement;
    if (this.filterDemandes.statusValue?.includes(target.value)) {
      const index = this.filterDemandes.statusValue.indexOf(target.value);
      if (index > -1) {
        this.filterDemandes.statusValue.splice(index, 1);
      }
    } else {
      if (!this.filterDemandes.statusValue) {
        this.filterDemandes.statusValue = [];
      }
      this.filterDemandes.statusValue?.push(target.value);
    }
  }

  /**
   * Méthode pour gérer le changement de la nature d'une demande.
   *
   * @param {Event} event L'événement de changement déclenché par l'utilisateur.
   */
  changeDemandeNature(event: Event) {
    const target = event.target as HTMLInputElement;
    if (this.filterDemandes.requestType?.includes(target.value)) {
      const index = this.filterDemandes.requestType.indexOf(target.value);
      if (index > -1) {
        this.filterDemandes.requestType.splice(index, 1);
      }
    } else {
      if (!this.filterDemandes.requestType) {
        this.filterDemandes.requestType = [];
      }
      this.filterDemandes.requestType?.push(target.value);
    }
  }

  /**
   * Méthode pour réinitialiser les filtres des demandes.
   * Appelle le service gestionDemandesData pour récupérer toutes les demandes sans filtres.
   */
  resetFiltersDemande() {
    const defaultRequestFilter: FilterDemande = {
      pageNumber: 0,
      pageSize: PAGE_SIZE,
      specialTreatment: [],
      statusValue: [DemandStatusEnum.INCOMPLETE_DemandStatus, DemandStatusEnum.DEPOSEE_DemandStatus],
    };
    this.gestionDemandesData.getDemandes(defaultRequestFilter).subscribe();
    this.filterDemandes = {
      pageNumber: 0,
      pageSize: PAGE_SIZE,
      specialTreatment: [],
    };
    this.checkedCounter = 0;
    this.checkedCounterChange.emit(this.checkedCounter);
    this.selectReferent.nativeElement.value = '';
    this.selectedPerson = undefined;
  }

  /**
   * Filtre les demandes en fonction des filtres actuels.
   * Appelle le service gestionDemandesData pour récupérer les demandes filtrées.
   */
  filtrerDemande() {
    if (this.selectedPerson) {
      this.filterDemandes.administrativeReferent = this.selectedPerson;
    }

    /**
     * Pour effectuer la recherche de demandes en utilisant les filtres actuels.
     * Appelle le service gestionDemandesData pour récupérer les demandes filtrées.
     */
    if(this.filterDemandes.statusValue==undefined || this.filterDemandes.statusValue.length === 0){
      const defaultRequestFilter = JSON.parse(JSON.stringify(this.filterDemandes));
      defaultRequestFilter.statusValue = [DemandStatusEnum.INCOMPLETE_DemandStatus, DemandStatusEnum.DEPOSEE_DemandStatus];
      this.gestionDemandesData.getDemandes(defaultRequestFilter).subscribe();
    }else{
      this.gestionDemandesData.getDemandes(this.filterDemandes).subscribe();
    } 

    this.filterCount = 0;
    if (this.filterDemandes.requestType) {
      this.filterCount += this.filterDemandes.requestType.length;
    }
    if (this.filterDemandes.specialTreatment) {
      this.filterCount += this.filterDemandes.specialTreatment.length;
    }
    if (this.filterDemandes.statusValue) {
      this.filterCount += this.filterDemandes.statusValue.length;
    }
    this.checkedCounter = this.filterCount;
    this.checkedCounterChange.emit(this.checkedCounter); // "counter"pour envoyer les informations au composant père avec le nombre de filtres sélectionnés.

    this.toggleDialog();
  }

  onClickSelect(): void {
    // event.preventDefault();
    this.updateModale.emit(true);
    this.updateProfilName.emit(AgentTypeEnum.ADMINISTRATIF_AgentType);
  }

  findLabelEnum(codeEnum: string) {
    return this.referentielService.mapEnumItemCodeToItemLabel(codeEnum);
  }
}

import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { DocumentManagementModule } from './modules/pages/document-management/document-management.module';

import '@cnsa-fr/design-system/dist/components/alert/alert.js';
import '@cnsa-fr/design-system/dist/components/badge/badge.js';
import '@cnsa-fr/design-system/dist/components/button/button.js';
import '@cnsa-fr/design-system/dist/components/card/card.js';
import '@cnsa-fr/design-system/dist/components/checkbox/checkbox.js';
import '@cnsa-fr/design-system/dist/components/dialog/dialog.js';
import '@cnsa-fr/design-system/dist/components/divider/divider.js';
import '@cnsa-fr/design-system/dist/components/drawer/drawer.js';
import '@cnsa-fr/design-system/dist/components/dropdown/dropdown.js';
import '@cnsa-fr/design-system/dist/components/icon/icon.js';
import '@cnsa-fr/design-system/dist/components/input/input.js';
import '@cnsa-fr/design-system/dist/components/menu-item/menu-item.js';
import '@cnsa-fr/design-system/dist/components/menu/menu.js';
import '@cnsa-fr/design-system/dist/components/option/option.js';
import '@cnsa-fr/design-system/dist/components/radio-button/radio-button.js';
import '@cnsa-fr/design-system/dist/components/radio-group/radio-group.js';
import '@cnsa-fr/design-system/dist/components/radio/radio.js';
import '@cnsa-fr/design-system/dist/components/select/select.js';
import '@cnsa-fr/design-system/dist/components/tag/tag.js';
import '@cnsa-fr/design-system/dist/components/tab/tab.js';
import '@cnsa-fr/design-system/dist/components/tab-panel/tab-panel.js';
import '@cnsa-fr/design-system/dist/components/tab-group/tab-group.js';
import '@cnsa-fr/design-system/dist/components/tree/tree.js';
import '@cnsa-fr/design-system/dist/components/tree-item/tree-item.js';
import '@cnsa-fr/design-system/dist/components/textarea/textarea.js';
import '@cnsa-fr/design-system/dist/components/tooltip/tooltip.js';
import '@cnsa-fr/design-system/dist/components/visually-hidden/visually-hidden.js';
import '@cnsa-fr/design-system/dist/components/checkbox-button/checkbox-button.js';
import '@cnsa-fr/design-system/dist/components/checkbox-group/checkbox-group.js';
import '@cnsa-fr/design-system/dist/components/input-mask/input-mask.js';
import { HttpProcessTechnicalInterceptor } from './modules/core/interceptors/HttpProcessTechnicalInterceptor';
import { AuthInterceptor } from './modules/core/interceptors/AuthInterceptor';
import { initProdEnv } from '../environments/environment.prod';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HttpClientModule,
    CommonModule,
    DocumentManagementModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpProcessTechnicalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: () => initProdEnv, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

<!-- TODO: Ajouter aria-sort= "ascending" ou "descending" sur les headers des colonnes lorsqu'elles sont triées -->
<div class="container">
  <cnsa-visually-hidden id="banette-helper"
    >, les entêtes de colonnes avec bouton peuvent être triés.</cnsa-visually-hidden
  >
  <table class="banette" aria-describedby="banette-title banette-helper">
    <thead class="banette__header">
      <tr>
        <th scope="col">
          <div class="banette__header-cell">
            <cnsa-checkbox
              label="Sélectionner toutes les lignes"
              [(ngModel)]="selectedAll"
              ngDefaultControl
              (cnsa-change)="selectAllRows()"></cnsa-checkbox>
          </div>
        </th>
        <th scope="col" *ngFor="let column of columns; let x = index">
          <button class="banette__header-cell banette__header-cell--button" (click)="sortRows(x)">
            {{ column }}
            <cnsa-icon
              name="unfold_more"
              class="banette__sort-icon"
              aria-hidden="true"
              *ngIf="column !== ''"></cnsa-icon>
          </button>
        </th>
        <th></th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngIf="rendezvous">
        <tr
          *ngFor="let item of rendezvous.slice(0, itemsToShow) | forfaitApaEnUrgenceSearch : filterValue; let i = index"
          [ngClass]="isSelected(i) ? 'selected-background' : ''">
          <td>
            <cnsa-checkbox [checked]="isSelected(i)" (cnsa-change)="selectRow(i)"></cnsa-checkbox>
          </td>
          <td>
            <div class="container-dossier">
              <div
                *ngIf="
                  item.apaRequestSpecialTreatment.specialTreatmentType === ProcessingTypeEnum.APA_URGENCE_ProcessingType
                ">
                <span style="color: red"> <cnsa-icon name="priority_high"></cnsa-icon></span>
              </div>
              <div
                *ngIf="
                  item.apaRequestSpecialTreatment.specialTreatmentType ===
                  ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType
                ">
                <cnsa-icon name="timer"></cnsa-icon>
              </div>
              <div class="cell-content">
                <a class="folder-name" routerLink="/" routerLinkActive="active" skipLocationChange="true"
                  >{{ item.requesterSituation.requesterIdentity.lastName }}
                  {{ item.requesterSituation.requesterIdentity.firstName }}</a
                >
                <div class="cell-content__secondary underline-none">
                  <!-- a changer : par le lien de la page de consultation (Pas encore crée) -->
                  <a href="#" class="cell-content__secondary">
                    {{
                      item.requesterSituation.requesterIdentity.nir ? item.requesterSituation.requesterIdentity.nir : ''
                    }}
                  </a>
                  <p>{{ item.apaRequestType }}</p>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <p class="cell-content__primary">
                {{ item.requesterSituation.requesterCoordinates.currentResidenceAddress.placeName }}
              </p>
              <p class="cell-content__secondary underline-none">
                {{ item.requesterSituation.requesterCoordinates.currentResidenceAddress.zipCode }}
              </p>
            </div>
          </td>
          <td>
            <cnsa-dropdown hoist="" caret="" name="typeCourrier" id="typeCourrier">
              <cnsa-button slot="trigger" caret="" variant="secondary" size="small">{{
                item.requesterSituation.requesterIdentity.administrativeReferent!.agentId
                  ? (item.requesterSituation.requesterIdentity.administrativeReferent!.lastName | uppercase) +
                    ' ' +
                    item.requesterSituation.requesterIdentity.administrativeReferent!.firstName
                  : 'Sélectionner'
              }}</cnsa-button>
              <cnsa-menu *ngFor="let referent of referents">
                <cnsa-menu-item (click)="changeReferent(referent, i)">{{
                  (referent.lastName | uppercase) + ' ' + referent.firstName
                }}</cnsa-menu-item>
              </cnsa-menu>
            </cnsa-dropdown>
          </td>
          <td>
            <cnsa-button
              name="evaluateurs"
              id="evaluateurs"
              title="Evaluateur"
              slot="trigger"
              variant="secondary"
              size="small">
              {{
                item.evaluator
                  ? (item.evaluator!.lastName | uppercase) + ' ' + item.evaluator.firstName
                  : 'Sélectionner'
              }}
            </cnsa-button>
          </td>
          <td>
            <div class="cell-content">
              <p class="cell-content__primary">
                {{ item.apaRequestCompletionDate | date : 'yyyy-MM-dd' | dateAdd : 30 | date : 'yyyy-MM-dd' }}
              </p>
              <p class="cell-content__secondary">
                J -{{
                  subtractDates(
                    item.apaRequestCompletionDate | date : 'yyyy-MM-dd' | dateAdd : 30 | date : 'yyyy-MM-dd'
                  )
                }}
              </p>
            </div>
          </td>
          <td>
            <ng-container>
              <cnsa-button variant="tertiary" size="small">Valider</cnsa-button>
            </ng-container>
            <ng-container class="cell-content">
              <cnsa-button variant="tertiary" size="small" style="margin-left: 10px">Modifier</cnsa-button>
            </ng-container>
          </td>
          <td>
            <cnsa-dropdown placement="left-end">
              <cnsa-icon-button name="more_vert" label="Actions sur le commentaire" slot="trigger"></cnsa-icon-button>
              <cnsa-menu (cnsa-select)="selectDefaultAction($event)">
                <ng-container *ngFor="let actionRdv of listActionRdvs">
                  <cnsa-menu-item [value]="actionRdv.label">
                    <div
                      *ngIf="
                        item.apaRequestSpecialTreatment.specialTreatmentType ===
                          ProcessingTypeEnum.APA_URGENCE_ProcessingType &&
                          actionRdv.label === LabelMenuListActionRdv.MODIFIER_LA_CARACTERISATION;
                        then thenBlock;
                        else elseBlock
                      "></div>
                    <ng-template #thenBlock>
                      <cnsa-tooltip
                        placement="bottom-start"
                        hoist="true"
                        content="La modification de la caractérisation n'est plus possible car le forfait APA en urgence et le déblocage du versement ont été validés par un évaluateur">
                        <span class="modifaction_disabled">{{ actionRdv.label }}</span>
                        <cnsa-icon class="icon-button-modify" name="info" label="info"></cnsa-icon>
                      </cnsa-tooltip>
                    </ng-template>
                    <ng-template #elseBlock>
                      <p [routerLink]="[actionRdv.link]" routerLinkActive="active" skipLocationChange="true">
                        {{ actionRdv.label }}
                      </p>
                    </ng-template>
                  </cnsa-menu-item>
                </ng-container>
              </cnsa-menu>
            </cnsa-dropdown>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>

  <div class="container-button">
    <cnsa-button variant="primary" (click)="addMoreItemsToShow()">
      <cnsa-icon name="downloading" slot="prefix"></cnsa-icon>
      Afficher plus de résultats
    </cnsa-button>
  </div>
</div>

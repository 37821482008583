import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterDemande, ResponseDataDemandeDto } from '../models/response-gestion-demande.dto';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment as envDev } from '../../../../../environments/environment';
import { environment as envProd } from '../../../../../environments/environment.prod';
import { ApaAgentDto } from '../models/apa-agent.dto';
import { PAGE_SIZE } from '../../../shared/utils/globalConstants';
import { GestionDemandesData } from '../data/gestion-demandes.data';
import { PageContentDto } from '../models/page-content.dto';
import {ApaRequestDto} from "../models/apa-request.dto";

/**
 * Service pour gérer les demandes APA.
 */
@Injectable({
  providedIn: 'root',
})
export class GestionDemandeService extends GestionDemandesData {

  private endPoint;
  private siapaDemandeInterRestApi;

  /**
   * Crée une instance du service GestionDemandeService.
   *
   * @param httpClient Le service HttpClient utilisé pour effectuer les appels HTTP.
   */
  constructor(private httpClient: HttpClient) {
    super();
    if (isDevMode()) {
      this.endPoint = envDev.endPointApaRequests;
      this.siapaDemandeInterRestApi = envDev.siapaDemandeInterRestApi + this.endPoint;
    } else {
      this.endPoint = envProd.endPointApaRequests;
      this.siapaDemandeInterRestApi = envProd.siapaDemandeInterRestApi + this.endPoint;
    }
  }

  /**
   * Observable contenant la réponse de la demande.
   */
  response: BehaviorSubject<ResponseDataDemandeDto> = new BehaviorSubject<ResponseDataDemandeDto>({
    _links: undefined,
    pageContent: [],
    pageNumber: 0,
    pageSize: PAGE_SIZE,
    totalNumberOfElements: 0,
    totalNumberOfPages: 0,
  });

  /**
   * Ensemble des référents pour les agents APA  utilisé pour stocker les informations sur les agents APA associés aux demandes.
   */
  referents: Set<ApaAgentDto> = new Set<ApaAgentDto>();

  /**
   * Correction des filtres de demande pour supprimer les valeurs vides ou inutiles.
   *
   * @param {FilterDemande} filter Le filtre de demande à corriger.
   */
  fixFilter(filter: FilterDemande) {
    if (filter.requestType?.length === 0) {
      delete filter.requestType;
    }
    if (filter.administrativeReferent?.agentId === '') {
      delete filter.administrativeReferent;
    }
    if (filter.statusValue?.length === 0) {
      delete filter.statusValue;
    }
  }

  //TODO: Check the response of the back
  //TODO: Check back-front communication
  /**
   * Récupère les demandes.
   *
   * @param {FilterDemande} filter - (Facultatif) Filtre pour la récupération des demandes.
   * @returns {Observable<ResponseDataDemande>} Un Observable contenant la réponse de gestion desdemandes.
   * @author Carlos Poveda
   */
  getDemandes(filter?: FilterDemande): Observable<ResponseDataDemandeDto> {
    const requestUrl = this.siapaDemandeInterRestApi + '/search';
    if (!filter) {
      filter = {
        pageNumber: 0,
        pageSize: PAGE_SIZE,
        specialTreatment: [],
      };
    } else {
      this.fixFilter(filter);
    }
    this.httpClient.post<ResponseDataDemandeDto>(requestUrl, filter).subscribe((data) => {
      this.response.next(data);
    });
    return this.response.asObservable();
  }

  /**
   * Mise à jour d'une demande
   *
   * @param ApaRequestDto demande à mettre à jour
   * @return Observable<ApaRequestDto> Demande mise à jour
   */
  updateDemande(demande: PageContentDto): Observable<PageContentDto> {
    const requestUrl =
      this.siapaDemandeInterRestApi +
      '/' +
      demande.apaRequest.requestId +
      (demande.taskList[0].id ? '?taskId=' + demande.taskList[0].id : '');
    this.httpClient.put<PageContentDto>(requestUrl, demande).subscribe((data) => {
      demande = data;
    });
    return of(demande);
  }

  referentsAdmin: ApaAgentDto[] = [];

  optionsReferentsAdmin: string[] = [];

  ajouterReferentAdmin(personne: ApaAgentDto) {
    this.referentsAdmin.push(personne); // Add the personne to the referentsAdmin array
    return this.referentsAdmin; // Return the updated referentsAdmin array
  }

  personneAssigneeHandler(personne: ApaAgentDto) {
    // Ajoutez la personne sélectionnée à la liste des referents admin
    const referent = {
      nom: personne.firstName,
      prenom: personne.lastName, // Ajoutez la propriété prenom ici avec une valeur appropriée si nécessaire
    };
    this.referentsAdmin.push(personne);
  }

  getDemande(id: string): Observable<ApaRequestDto> {
    const requestUrl = this.siapaDemandeInterRestApi + '/' + id;
    return this.httpClient.get<ApaRequestDto>(requestUrl);
  }
}

<footer role="contentinfo">
  <div class="footer-left-region">
    <img src="assets/logo_SI-APA_Signature_Couleur.svg" alt="CNSA SI APA" />
    <img src="assets/cnsa_spda_cmjn.svg" alt="Service public de l'autonomie." />
  </div>

  <div class="footer-right-region">
    <ul class="footer-menu">
      <li>Accessibilité</li>
      <li>Plan du site</li>
      <li>Conditions d'utilisation et services</li>
      <li>Mention légales</li>
      <li>Gestion des cookies</li>
    </ul>

    <p class="copyright">Copyright © 2023, tous droits reservés</p>
  </div>
</footer>

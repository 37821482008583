/**
 * Énumération des types possibles en la sélection du profil dans la banette header barre action.
 */
// TODO #referentiel à supprimer quand debouchonnage referentiel fini

export enum ProfileTypeEnum {
  REFERENT_ADMIN = 'REFERENT_ADMIN',
  EVALUATOR = 'EVALUATOR',
}

/**
 * Énumération des types possibles en la sélection du profil dans la banette header barre action
 */
export const ProfileTypeEnumTranslation = {
  [ProfileTypeEnum.REFERENT_ADMIN]: 'Référent Admin',
  [ProfileTypeEnum.EVALUATOR]: 'Évaluateur',
};

import { inject, Injectable, isDevMode } from '@angular/core';
import { environment as envDev } from '../../../../environments/environment';
import { environment as envProd } from '../../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { EnumItemDto, EnumItemDtoList } from '../../shared/models/enum-item.dto';
import { RootRequestInterface } from '../../shared/interfaces/root-request.interface';
import { RequestReferentielData } from '../data/RequestReferentielData';
import { StructureDto } from '../../shared/models/structure.dto';
import { DepartmentDtoList, DepartmentsDto } from '../../shared/models/departmentsDto';
import { CountryDto, CountryDtoList } from '../../shared/models/countries.dto';
import { Localities } from '../../shared/models/localities';

/**
 * Toute les méthodes sont documenté directement dans la classe abstraite {@link RequestReferentielData}
 * @see RequestReferentielData
 */
@Injectable({
  providedIn: 'root',
})
export class RequestReferentiel implements RequestReferentielData {
  /**
   * L'URL de l'API pour le référentiel socle
   */
  private readonly endPointEnumItem;
  /**
   * Lien complet vers l'API SIAPA pour le réfrentiel socle.
   */
  private readonly siapaReferentielEnumtiemSocleRestApi: string;
  /**
   * Effectue des requêtes HTTP.
   * @private
   */
  private http: HttpClient = inject(HttpClient);

  private readonly endPointStructures: string;
  private readonly endPointDepartments: string;
  private readonly endPointCountries: string;
  private readonly siapaReferentielStructuresSocleRestApi: string;
  private readonly siapaReferentielDepartmentsRestApi: string;
  private readonly siapaReferentielCountriesRestApi: string;
  private readonly endPointLocalities: string;
  private readonly siapaReferentielLocalitiesApi: string;

  constructor() {
    if (isDevMode()) {
      this.endPointEnumItem = envDev.endPointEnumItem;
      this.endPointStructures = envDev.endPointStructures;
      this.endPointDepartments = envDev.endPointDepartments;
      this.endPointCountries = envDev.endPointCountries;
      this.endPointLocalities = envDev.endPointLocalities;
      this.siapaReferentielEnumtiemSocleRestApi = envDev.siapaReferentielSocleRestApi + this.endPointEnumItem;
      this.siapaReferentielStructuresSocleRestApi = envDev.siapaReferentielSocleRestApi + this.endPointStructures;
      this.siapaReferentielDepartmentsRestApi = envDev.siapaReferentielSocleRestApi + this.endPointDepartments;
      this.siapaReferentielCountriesRestApi = envDev.siapaReferentielSocleRestApi + this.endPointCountries;
      this.siapaReferentielLocalitiesApi = envDev.siapaReferentielSocleRestApi + this.endPointLocalities;
    } else {
      this.endPointEnumItem = envProd.endPointEnumItem;
      this.endPointStructures = envProd.endPointStructures;
      this.endPointDepartments = envProd.endPointDepartments;
      this.endPointCountries = envProd.endPointCountries;
      this.endPointLocalities = envProd.endPointLocalities;
      this.siapaReferentielEnumtiemSocleRestApi = envProd.siapaReferentielSocleRestApi + this.endPointEnumItem;
      this.siapaReferentielStructuresSocleRestApi = envProd.siapaReferentielSocleRestApi + this.endPointStructures;
      this.siapaReferentielDepartmentsRestApi = envProd.siapaReferentielSocleRestApi + this.endPointDepartments;
      this.siapaReferentielCountriesRestApi = envProd.siapaReferentielSocleRestApi + this.endPointCountries;
      this.siapaReferentielLocalitiesApi = envProd.siapaReferentielSocleRestApi + this.endPointLocalities;
    }
  }

  getAllEnumItemSocle(): Observable<EnumItemDto[] | undefined> {
    return this.http
      .get<RootRequestInterface<EnumItemDtoList>>(`${this.siapaReferentielEnumtiemSocleRestApi}`)
      .pipe(
        map((rootEnumItemDto: RootRequestInterface<EnumItemDtoList>) => rootEnumItemDto._embedded?.enumItemDtoList)
      );
  }

  getStructureSocleByCode(code: string): Observable<StructureDto> | undefined {
    return this.http.get<StructureDto>(`${this.siapaReferentielStructuresSocleRestApi}/${code}`);
  }

  getDepartmentByInseeCode(inseeCode: string): Observable<DepartmentsDto | undefined> {
    return this.http.get<DepartmentsDto>(`${this.siapaReferentielDepartmentsRestApi}/${inseeCode}`);
  }

  getALLDepartments(): Observable<DepartmentsDto[] | undefined> {
    return this.http
      .get<RootRequestInterface<DepartmentDtoList>>(`${this.siapaReferentielDepartmentsRestApi}`)
      .pipe(map((rootDto: RootRequestInterface<DepartmentDtoList>) => rootDto._embedded?.departmentDtoList));
  }

  getALLCountries(): Observable<CountryDto[] | undefined> {
    return this.http
      .get<RootRequestInterface<CountryDtoList>>(`${this.siapaReferentielCountriesRestApi}`)
      .pipe(map((rootDto: RootRequestInterface<CountryDtoList>) => rootDto._embedded?.countryDtoList));
  }

  getCountryByIsoCode(iso3Code: string): Observable<CountryDto | undefined> {
    return this.http.get<CountryDto>(`${this.siapaReferentielCountriesRestApi}/${iso3Code}`);
  }

  getLocalitiesByInseeCode(inseeCode: string): Observable<Localities | undefined> {
    return this.http.get<Localities>(`${this.siapaReferentielLocalitiesApi}/${inseeCode}`);
  }
}

import { Component, inject } from '@angular/core';
import { NotificationService } from '../../../shared/services/notification.service';

/**
 * Composant principale: notification de l'application
 */
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationComponent {
  /**
   * Injection du service de notification
   */
  notificationService: NotificationService = inject(NotificationService);
}

import { Observable } from 'rxjs';
import { IncomingMailsPaginatedSearchResultDto } from '../models/Incoming-mails-paginated-search-result.dto';
import { IncomingMailPaginatedSearchQueryDto } from '../../courrier/models/incoming-mail-paginated-search-query.dto';
import { EvidenceDto } from '../../../shared/models/evidence.dto';
import { EvidenceLineDto, ListEvidenceLine } from '../../../shared/models/evidenceLineDto';

/**
 * Interface pour la gestion de la corbeille
 *
 * @export
 * @abstract
 * @class GestionCorbeilleData
 */
export abstract class GestionCorbeilleData {
  /**
   * Récupération des données de la corbeille
   *
   * @abstract
   * @param filter - {IncomingMailPaginatedSearchQueryDto}
   * @returns {Observable<EvidenceDto[]>}
   */
  abstract getCorbeille(filter?: IncomingMailPaginatedSearchQueryDto): Observable<ListEvidenceLine>;

  /**
   * Suppression d'un document
   *
   * @abstract
   * @param id
   * @returns {void}
   */
  abstract deleteDoc(id: string): void;
}

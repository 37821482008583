/**
 * Énumération des path de la route pages routing
 */
export enum PagesRouteEnum {
  DEMANDE = 'demande',
  RENDEZ_VOUS = 'rendez-vous',
  COURRIER = 'courrier',
  CORBEILLE = 'corbeille',
  CARD = 'card',
  DOCUMENT_VIEWER = 'document-viewer',
  GESTION_DES_GROUPES = 'gestion-des-groupes',
}

<div class="outer-container">
  <div class="inner-container">
    <section class="header-container">
      <app-banette-header-titre [titre]="titre"></app-banette-header-titre>
      <app-banette-header-barre-actions
        class="header-container__barre"
        [bannetteType]="table"
        [isDialogOpen]="isDialogOpen"
        (updateDialog)="updateDialog($event)"
        (updateData)="refresh()"></app-banette-header-barre-actions>
      <app-banette-header-recherche
        class="header-container__recherche"
        [titre]="titre"
        [count]="count"
        [countSearch]="countSearch"
        [showCountSearch]="showCountSearch"
        [bannetteType]="table"
        [data]="data"
        [evaluateurs]="evaluateurs"
        [referents]="referents"
        [import]="import"
        [export]="export"></app-banette-header-recherche>
    </section>

    <section>
      <ng-container *ngIf="table === banetteType.DEMANDE">
        <app-banette-tableau-demandes
          [referents]="referents"
          [demandes]="data"
          (updateCountSearch)="updateCountSearch($event)"
          (resetCountSearch)="resetCountSearch()"
          (updateData)="refresh()"></app-banette-tableau-demandes>
      </ng-container>

      <ng-container *ngIf="table === banetteType.RENDEZVOUS">
        <app-banette-tableau-rendez-vous
          [titre]="titre"
          [rendezvous]="data"
          [referents]="referents"
          [evaluateurs]="evaluateurs"
          (openPlanRdvModal)="openPlanRdvModal()"
          (updateCountSearch)="updateCountSearch($event)"
          (resetCountSearch)="resetCountSearch()"
          (updateData)="refresh()"></app-banette-tableau-rendez-vous>
      </ng-container>

      <ng-container *ngIf="table === banetteType.BOITE">
        <app-banette-tableau-boite
          [titre]="titre"
          [boite]="data"
          (updateCountSearch)="updateCountSearch($event)"
          (resetCountSearch)="resetCountSearch()"></app-banette-tableau-boite>
      </ng-container>

      <ng-container *ngIf="table === banetteType.CORBEILLE">
        <app-banette-tableau-corbeille
          [titre]="titre"
          [corbeille]="data"
          (updateCountSearch)="updateCountSearch($event)"
          (resetCountSearch)="resetCountSearch()"></app-banette-tableau-corbeille>
      </ng-container>

      <ng-container *ngIf="table === banetteType.COURRIERS">
        <app-banette-tableau-courriers
          [titre]="titre"
          [courriers]="data"
          [referents]="referents"
          (updateCountSearch)="updateCountSearch($event)"
          (resetCountSearch)="resetCountSearch()"
          (updateData)="refresh()"></app-banette-tableau-courriers>
      </ng-container>

      <ng-container *ngIf="table === banetteType.FORFAITSAPAENURGENCE">
        <app-banette-forfaits-apa-en-urgence
          [referents]="referents"
          [rendezvous]="data"
          [evaluateurs]="evaluateurs"
          (updateCountSearch)="updateCountSearch($event)"
          (resetCountSearch)="resetCountSearch()"></app-banette-forfaits-apa-en-urgence>
      </ng-container>
    </section>
  </div>
</div>

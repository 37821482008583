<div class="container">
  <nav aria-label="Fil d'ariane">
    <ol
      class="router"
      *ngIf="titre === 'Gestion des rendez-vous' || titre === 'Courriers entrants'; else corbeilleRooting">
      <li>
        <a class="router__link" routerLink="" routerLinkActive="active" skipLocationChange="true">Accueil</a>
      </li>
      <li>
        <a [href]="" class="router__current" aria-current="page"><span aria-hidden="true">&gt;</span> {{ titre }}</a>
      </li>
    </ol>
    <ng-template #corbeilleRooting>
      <ol class="router">
        <li>
          <a class="router__link" routerLink="" routerLinkActive="active" skipLocationChange="true">Tableau de bord</a>
        </li>
        <li>
          <a [href]="" class="router__current" aria-current="page"><span aria-hidden="true">&gt;</span>{{ titre }}</a>
        </li>
      </ol>
    </ng-template>
  </nav>

  <h1 id="banette-title" class="banette-title">
    <cnsa-icon
      name="move_to_inbox"
      class="banette-title__icon"
      *ngIf="titre?.includes('Boite') || titre === 'Courriers entrants'"></cnsa-icon>
    <cnsa-icon name="delete" class="banette-title__icon" *ngIf="titre === 'Corbeille'"></cnsa-icon>
    <cnsa-icon name="pending_actions" class="banette-title__icon" *ngIf="titre === 'Demandes en cours'"></cnsa-icon>
    <cnsa-icon
      name="priority_high"
      class="banette-title__icon"
      *ngIf="titre === 'Gestion des forfaits APA en urgence'"></cnsa-icon>
    <cnsa-icon
      name="perm_contact_calendar"
      class="banette-title__icon"
      *ngIf="titre === 'Gestion des rendez-vous'"></cnsa-icon>
    {{ titre }}
  </h1>

  <ng-container *ngIf="titre === 'Corbeille'">
    <p>Les documents supprimés sont defnitivement effacés aprés 30 jours.</p>
  </ng-container>

  <ng-container *ngIf="titre?.includes('Boite') || titre === 'Courriers entrants'">
    <p>Dernière actualisation: {{ lastUpdateDate | date : "dd/MM/yyyy 'à' HH'h'mm" }}</p>
  </ng-container>
</div>

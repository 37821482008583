import { DocumentTypeEnum } from '../../pages/demande/enums/document-type.enum';
// TODO #referentiel à supprimer quand debouchonnage referentiel fini
/**
 * Objet associatif permettant de traduire les valeurs de l'énumération `DocumentTypeEnum` en chaînes de caractères qui représentent le type du document.
 */
export const DocumentTypeTranslation: { [key in DocumentTypeEnum]: string } = {
  [DocumentTypeEnum.BANK_DETAILS]: 'RIB',
  [DocumentTypeEnum.NOTICE_TAXATION_OR_NON_TAXATION_OF_APPLICANT]: "Avis d'imposition ou non-imposition du demandeur",
  [DocumentTypeEnum.NOTICE_TAXATION_OR_NON_TAXATION_OF_SPOUSE]: "Avis d'imposition ou non-imposition du conjoint",
  [DocumentTypeEnum.FORM]: 'Formulaire',
  [DocumentTypeEnum.IDENTITY_DOCUMENT]: "Justificatif d'identité",
  [DocumentTypeEnum.MEDICAL_CERTIFICATE]: 'Certificat médical',
  [DocumentTypeEnum.NOTICE_PROPERTY_TAX]: 'Avis de taxe foncière',
  [DocumentTypeEnum.PROTECTIVE_MEASURE_DECISION]: 'Décision mesure de protection',
  [DocumentTypeEnum.OTHER]: 'Autre',
};

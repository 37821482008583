// TODO #referentiel - Old Enum: StorageTypeEnum(storage-type.enum.tsJ)
export enum StorageTypeEnum {
  /**
   *   Interne
   */
  INTERNE_StorageType = 'INTERNE-StorageType',
  /**
   *   GED
   */
  GED_StorageType = 'GED-StorageType',
  /**
   *   Classothèque
   */
  CLASSOTHEQUE_StorageType = 'CLASSOTHEQUE-StorageType',
}

// TODO #referentiel - Old Enum: DocumentStatusEnum
export enum EvidenceStatusEnum {
  /**
   *   Disponible
   */
  DISPONIBLE_EvidenceStatus = 'DISPONIBLE-EvidenceStatus',
  /**
   *   Archivé
   */
  ARCHIVE_EvidenceStatus = 'ARCHIVE-EvidenceStatus',
  /**
   *   Supprimé
   */
  SUPPRIME_EvidenceStatus = 'SUPPRIME-EvidenceStatus',
}

// TODO #referentiel - Old Enum: DocumentTypeEnum
export enum EvidenceTypeEnum {
  /**
   * EvidenceType	Formulaire de demande APA
   */
  DEMANDE_EvidenceType = 'DEMANDE-EvidenceType',
  /**
   * EvidenceType	Courrier de demande de révision
   */
  REVISION_EvidenceType = 'REVISION-EvidenceType',
  /**
   * Courrier de module relais en cas d'hospitalisation
   */
  HOSPITALISATION_EvidenceType = 'HOSPITALISATION-EvidenceType',
  /**
   * Attestation d'hospitalisation
   */
  ATTESTATION_EvidenceType = 'ATTESTATION-EvidenceType',
  /**
   * EvidenceType	RIB
   */
  RIB_EvidenceType = 'RIB-EvidenceType',
  /**
   * Justificatif d'identité
   */
  IDENTITE_EvidenceType = 'IDENTITE-EvidenceType',
  /**
   *   Avis d'imposition ou de non imposition demandeur
   */
  IMPOSITION_DEMANDEUR_EvidenceType = 'IMPOSITION_DEMANDEUR-EvidenceType',
  /**
   Avis d'imposition ou de non imposition conjoint
   *
   */
  IMPOSITION_CONJOINT_EvidenceType = 'IMPOSITION_CONJOINT-EvidenceType',
  /**
   * EvidenceType	Avis de taxe foncière
   */
  TAXE_FONCIERE_EvidenceType = 'TAXE_FONCIERE-EvidenceType',
  /**
   * EvidenceType	Certificat médical
   */
  MEDICAL_EvidenceType = 'MEDICAL-EvidenceType',
  /**
   * EvidenceType	Action sociale interministérielle (TS DAA)
   */
  ACTION_SOCIALE_EvidenceType = 'ACTION_SOCIALE-EvidenceType',
  /**
   * EvidenceType	Notification action sociale (TS DAA)
   */
  NOTIFICATION_SOCIALE_EvidenceType = 'NOTIFICATION_SOCIALE-EvidenceType',
  /**
   * EvidenceType	Jugement tutelles ou curatelle (= représentation TS DAA)
   */
  JUGEMENT_EvidenceType = 'JUGEMENT-EvidenceType',
  /**
   * EvidenceType	Décision d'attribution de l'APA
   */
  DECISION_ATTRIBUTION_APA_EvidenceType = 'DECISION_ATTRIBUTION_APA-EvidenceType',
  /**
   * EvidenceType	Photocopie de carte(s) attribuée(s)
   */
  PHOTOCOPIE_CARTES_EvidenceType = 'PHOTOCOPIE_CARTES-EvidenceType',
  /**
   * EvidenceType	Autre
   */
  AUTRE_EvidenceType = 'AUTRE-EvidenceType',
  /**
   * EvidenceType	Dossier individu
   */
  DOSSIER_INDIVIDU_EvidenceType = 'DOSSIER_INDIVIDU-EvidenceType',
  /**
   * EvidenceType	Dossier de demande
   */
  DOSSIER_DEMANDE_EvidenceType = 'DOSSIER_DEMANDE-EvidenceType',
}

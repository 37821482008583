import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { OutgoingMailsPaginatedSearchResultDto } from '../models/outgoing-mails-paginated-search-result.dto';
import { environment as envDev } from '../../../../../environments/environment';
import { environment as envProd } from '../../../../../environments/environment.prod';
import { ApaAgentDto } from '../../demande/models/apa-agent.dto';
import { PAGE_SIZE } from '../../../shared/utils/globalConstants';
import { GestionBoiteData } from '../data/gestion-boite.data';
import { OutgoingMailsPaginatedSearchQueryDto } from '../models/outgoing-mails-paginated-search-query.dto';

/**
 * Service de gestion de la boîte d'envoi.
 *
 * Ce service étend GestionBoiteData et gère la récupération des courriers à envoyer.
 */
@Injectable({
  providedIn: 'root',
})
export class GestionBoiteEnvoiService extends GestionBoiteData {
  private siapaCourrierInterRestApi;
  private endpoint;

  /**
   * Ensemble de référents (ApaAgentDto) pour le service.
   */
  referents: Set<ApaAgentDto> = new Set<ApaAgentDto>();

  /**
   * Constructeur du service GestionBoiteEnvoiService.
   *
   * @param {HttpClient} httpClient - Instance d'HttpClient pour les requêtes HTTP.
   */
  constructor(private httpClient: HttpClient) {
    super();
    if (isDevMode()) {
      this.endpoint = envDev.endPointOutgoingMails;
      this.siapaCourrierInterRestApi = envDev.siapaCourrierInterRestApi + this.endpoint;
    } else {
      this.endpoint = envProd.endPointOutgoingMails;
      this.siapaCourrierInterRestApi = envProd.siapaCourrierInterRestApi + this.endpoint;
    }
  }

  /**
   * BehaviorSubject pour stocker la réponse des courriers sortants paginés.
   */
  response: BehaviorSubject<OutgoingMailsPaginatedSearchResultDto> =
    new BehaviorSubject<OutgoingMailsPaginatedSearchResultDto>({
      _links: undefined,
      pageContent: [],
      pageNumber: 0,
      pageSize: PAGE_SIZE,
      totalNumberOfElements: 0,
      totalNumberOfPages: 0,
    });

  /**
   * Récupère les courriers à envoyer.
   *
   * @param {OutgoingMailsPaginatedSearchQueryDto} filter Filtre de recherche optionnel pour les courriers.
   * @returns {Observable<ResponseGestionBoite>} Un objet Observable contenant la réponse de gestion de la boîte aux lettres.
   * @author Carlos Poveda
   */
  getMailsToSend(filter?: OutgoingMailsPaginatedSearchQueryDto): Observable<OutgoingMailsPaginatedSearchResultDto> {
    const requestUrl = this.siapaCourrierInterRestApi + '/search';
    if (!filter) {
      filter = {
        pageNumber: 0,
        pageSize: PAGE_SIZE,
      };
    }

    return this.httpClient.post<OutgoingMailsPaginatedSearchResultDto>(requestUrl, filter).pipe(
      switchMap((data) => {
        this.response.next(data);
        return this.response.asObservable();
      })
    );
  }

  //@TODO Dès que l'api back /pdf est prete il faudra mettre le retour de l'api dans un blob
  downloadGeneratedPdfFromMail(idMail: string) {
    this.httpClient.get(this.siapaCourrierInterRestApi + '/' + idMail + '/pdf');
  }
}

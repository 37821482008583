import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';
import { BanetteModule } from '../pages/banette/banette.module';
import { LoginComponent } from './auth/login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SideDialogComponent } from './components/side-dialog/side-dialog.component';
import { SkipToContentComponent } from './components/skip-to-content/skip-to-content.component';
import { CoreRoutingModule } from './core-routing.module';
import { SearchService } from './mock/search.service';
import { NotificationComponent } from './components/notification/notification.component';
import { UserData } from '../pages/home/data/user.data';
import { ApaUserServiceMock } from '../pages/home/mocks/user.mock.service';
import { RequestReferentielData } from './data/RequestReferentielData';
import { environment } from '../../../environments/environment';
import { ReferentielData } from '../shared/data/referentiel-data';
import { ReferentielService } from '../shared/services/referentiel.service';
import { RequestReferentiel } from './service/request-referentiel.service';
import { RequestReferentielDataMockService } from './mock/request-referentiel-data-mock.service';

@NgModule({
  declarations: [
    LoginComponent,
    PageNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    SkipToContentComponent,
    MenuComponent,
    SideDialogComponent,
    SearchBarComponent,
    NotificationComponent,
  ],
  imports: [CommonModule, CoreRoutingModule, ReactiveFormsModule, BanetteModule],
  exports: [HeaderComponent, FooterComponent, SkipToContentComponent, SearchBarComponent, NotificationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    SearchService,
    { provide: UserData, useClass: ApaUserServiceMock },
    {
      provide: RequestReferentielData,
      useClass: environment.production ? RequestReferentiel : RequestReferentielDataMockService,
    },
    {
      provide: ReferentielData,
      useClass: ReferentielService,
    },
  ],
})
export class CoreModule {}

import { RootRequestInterface } from '../interfaces/root-request.interface';
import { DepartmentDtoList } from '../models/departmentsDto';

export const DepartmentsMock: RootRequestInterface<DepartmentDtoList> = {
  _embedded: {
    departmentDtoList: [
      {
        inseeCode: '01',
        label: 'Ain',
        localities: [
          {
            inseeCode: '01001',
            label: "L'Abergement-Clémenciat",
          },
          {
            inseeCode: '01002',
            label: "L'Abergement-de-Varey",
          },
          {
            inseeCode: '01004',
            label: 'Ambérieu-en-Bugey',
          },
          {
            inseeCode: '01005',
            label: 'Ambérieux-en-Dombes',
          },
          {
            inseeCode: '01006',
            label: 'Ambléon',
          },
          {
            inseeCode: '01007',
            label: 'Ambronay',
          },
          {
            inseeCode: '01008',
            label: 'Ambutrix',
          },
          {
            inseeCode: '01009',
            label: 'Andert-et-Condon',
          },
          {
            inseeCode: '01010',
            label: 'Anglefort',
          },
          {
            inseeCode: '01011',
            label: 'Apremont',
          },
          {
            inseeCode: '01012',
            label: 'Aranc',
          },
          {
            inseeCode: '01013',
            label: 'Arandas',
          },
          {
            inseeCode: '01014',
            label: 'Arbent',
          },
          {
            inseeCode: '01015',
            label: 'Arboys en Bugey',
          },
        ],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/01',
          },
        },
      },
      {
        inseeCode: '02',
        label: 'Aisne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/02',
          },
        },
      },
      {
        inseeCode: '03',
        label: 'Allier',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/03',
          },
        },
      },
      {
        inseeCode: '04',
        label: 'Alpes-de-Haute-Provence',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/04',
          },
        },
      },
      {
        inseeCode: '05',
        label: 'Hautes-Alpes',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/05',
          },
        },
      },
      {
        inseeCode: '06',
        label: 'Alpes-Maritimes',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/06',
          },
        },
      },
      {
        inseeCode: '07',
        label: 'Ardèche',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/07',
          },
        },
      },
      {
        inseeCode: '08',
        label: 'Ardennes',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/08',
          },
        },
      },
      {
        inseeCode: '09',
        label: 'Ariège',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/09',
          },
        },
      },
      {
        inseeCode: '10',
        label: 'Aube',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/10',
          },
        },
      },
      {
        inseeCode: '11',
        label: 'Aude',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/11',
          },
        },
      },
      {
        inseeCode: '12',
        label: 'Aveyron',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/12',
          },
        },
      },
      {
        inseeCode: '13',
        label: 'Bouches-du-Rhône',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/13',
          },
        },
      },
      {
        inseeCode: '14',
        label: 'Calvados',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/14',
          },
        },
      },
      {
        inseeCode: '15',
        label: 'Cantal',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/15',
          },
        },
      },
      {
        inseeCode: '16',
        label: 'Charente',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/16',
          },
        },
      },
      {
        inseeCode: '17',
        label: 'Charente-Maritime',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/17',
          },
        },
      },
      {
        inseeCode: '18',
        label: 'Cher',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/18',
          },
        },
      },
      {
        inseeCode: '19',
        label: 'Corrèze',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/19',
          },
        },
      },
      {
        inseeCode: '21',
        label: "Côte-d'Or",
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/21',
          },
        },
      },
      {
        inseeCode: '22',
        label: "Côtes-d'Armor",
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/22',
          },
        },
      },
      {
        inseeCode: '23',
        label: 'Creuse',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/23',
          },
        },
      },
      {
        inseeCode: '24',
        label: 'Dordogne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/24',
          },
        },
      },
      {
        inseeCode: '25',
        label: 'Doubs',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/25',
          },
        },
      },
      {
        inseeCode: '26',
        label: 'Drôme',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/26',
          },
        },
      },
      {
        inseeCode: '27',
        label: 'Eure',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/27',
          },
        },
      },
      {
        inseeCode: '28',
        label: 'Eure-et-Loir',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/28',
          },
        },
      },
      {
        inseeCode: '29',
        label: 'Finistère',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/29',
          },
        },
      },
      {
        inseeCode: '2A',
        label: 'Corse-du-Sud',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/2A',
          },
        },
      },
      {
        inseeCode: '2B',
        label: 'Haute-Corse',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/2B',
          },
        },
      },
      {
        inseeCode: '30',
        label: 'Gard',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/30',
          },
        },
      },
      {
        inseeCode: '31',
        label: 'Haute-Garonne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/31',
          },
        },
      },
      {
        inseeCode: '32',
        label: 'Gers',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/32',
          },
        },
      },
      {
        inseeCode: '33',
        label: 'Gironde',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/33',
          },
        },
      },
      {
        inseeCode: '34',
        label: 'Hérault',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/34',
          },
        },
      },
      {
        inseeCode: '35',
        label: 'Ille-et-Vilaine',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/35',
          },
        },
      },
      {
        inseeCode: '36',
        label: 'Indre',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/36',
          },
        },
      },
      {
        inseeCode: '37',
        label: 'Indre-et-Loire',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/37',
          },
        },
      },
      {
        inseeCode: '38',
        label: 'Isère',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/38',
          },
        },
      },
      {
        inseeCode: '39',
        label: 'Jura',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/39',
          },
        },
      },
      {
        inseeCode: '40',
        label: 'Landes',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/40',
          },
        },
      },
      {
        inseeCode: '41',
        label: 'Loir-et-Cher',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/41',
          },
        },
      },
      {
        inseeCode: '42',
        label: 'Loire',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/42',
          },
        },
      },
      {
        inseeCode: '43',
        label: 'Haute-Loire',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/43',
          },
        },
      },
      {
        inseeCode: '44',
        label: 'Loire-Atlantique',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/44',
          },
        },
      },
      {
        inseeCode: '45',
        label: 'Loiret',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/45',
          },
        },
      },
      {
        inseeCode: '46',
        label: 'Lot',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/46',
          },
        },
      },
      {
        inseeCode: '47',
        label: 'Lot-et-Garonne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/47',
          },
        },
      },
      {
        inseeCode: '48',
        label: 'Lozère',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/48',
          },
        },
      },
      {
        inseeCode: '49',
        label: 'Maine-et-Loire',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/49',
          },
        },
      },
      {
        inseeCode: '50',
        label: 'Manche',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/50',
          },
        },
      },
      {
        inseeCode: '51',
        label: 'Marne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/51',
          },
        },
      },
      {
        inseeCode: '52',
        label: 'Haute-Marne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/52',
          },
        },
      },
      {
        inseeCode: '53',
        label: 'Mayenne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/53',
          },
        },
      },
      {
        inseeCode: '54',
        label: 'Meurthe-et-Moselle',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/54',
          },
        },
      },
      {
        inseeCode: '55',
        label: 'Meuse',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/55',
          },
        },
      },
      {
        inseeCode: '56',
        label: 'Morbihan',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/56',
          },
        },
      },
      {
        inseeCode: '57',
        label: 'Moselle',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/57',
          },
        },
      },
      {
        inseeCode: '58',
        label: 'Nièvre',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/58',
          },
        },
      },
      {
        inseeCode: '59',
        label: 'Nord',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/59',
          },
        },
      },
      {
        inseeCode: '60',
        label: 'Oise',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/60',
          },
        },
      },
      {
        inseeCode: '61',
        label: 'Orne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/61',
          },
        },
      },
      {
        inseeCode: '62',
        label: 'Pas-de-Calais',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/62',
          },
        },
      },
      {
        inseeCode: '63',
        label: 'Puy-de-Dôme',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/63',
          },
        },
      },
      {
        inseeCode: '64',
        label: 'Pyrénées-Atlantiques',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/64',
          },
        },
      },
      {
        inseeCode: '65',
        label: 'Hautes-Pyrénées',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/65',
          },
        },
      },
      {
        inseeCode: '66',
        label: 'Pyrénées-Orientales',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/66',
          },
        },
      },
      {
        inseeCode: '67',
        label: 'Bas-Rhin',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/67',
          },
        },
      },
      {
        inseeCode: '68',
        label: 'Haut-Rhin',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/68',
          },
        },
      },
      {
        inseeCode: '69',
        label: 'Rhône',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/69',
          },
        },
      },
      {
        inseeCode: '70',
        label: 'Haute-Saône',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/70',
          },
        },
      },
      {
        inseeCode: '71',
        label: 'Saône-et-Loire',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/71',
          },
        },
      },
      {
        inseeCode: '72',
        label: 'Sarthe',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/72',
          },
        },
      },
      {
        inseeCode: '73',
        label: 'Savoie',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/73',
          },
        },
      },
      {
        inseeCode: '74',
        label: 'Haute-Savoie',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/74',
          },
        },
      },
      {
        inseeCode: '75',
        label: 'Paris',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/75',
          },
        },
      },
      {
        inseeCode: '76',
        label: 'Seine-Maritime',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/76',
          },
        },
      },
      {
        inseeCode: '77',
        label: 'Seine-et-Marne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/77',
          },
        },
      },
      {
        inseeCode: '78',
        label: 'Yvelines',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/78',
          },
        },
      },
      {
        inseeCode: '79',
        label: 'Deux-Sèvres',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/79',
          },
        },
      },
      {
        inseeCode: '80',
        label: 'Somme',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/80',
          },
        },
      },
      {
        inseeCode: '81',
        label: 'Tarn',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/81',
          },
        },
      },
      {
        inseeCode: '82',
        label: 'Tarn-et-Garonne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/82',
          },
        },
      },
      {
        inseeCode: '83',
        label: 'Var',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/83',
          },
        },
      },
      {
        inseeCode: '84',
        label: 'Vaucluse',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/84',
          },
        },
      },
      {
        inseeCode: '85',
        label: 'Vendée',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/85',
          },
        },
      },
      {
        inseeCode: '86',
        label: 'Vienne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/86',
          },
        },
      },
      {
        inseeCode: '87',
        label: 'Haute-Vienne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/87',
          },
        },
      },
      {
        inseeCode: '88',
        label: 'Vosges',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/88',
          },
        },
      },
      {
        inseeCode: '89',
        label: 'Yonne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/89',
          },
        },
      },
      {
        inseeCode: '90',
        label: 'Territoire de Belfort',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/90',
          },
        },
      },
      {
        inseeCode: '91',
        label: 'Essonne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/91',
          },
        },
      },
      {
        inseeCode: '92',
        label: 'Hauts-de-Seine',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/92',
          },
        },
      },
      {
        inseeCode: '93',
        label: 'Seine-Saint-Denis',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/93',
          },
        },
      },
      {
        inseeCode: '94',
        label: 'Val-de-Marne',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/94',
          },
        },
      },
      {
        inseeCode: '95',
        label: "Val-d'Oise",
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/95',
          },
        },
      },
      {
        inseeCode: '971',
        label: 'Guadeloupe',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/971',
          },
        },
      },
      {
        inseeCode: '972',
        label: 'Martinique',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/972',
          },
        },
      },
      {
        inseeCode: '973',
        label: 'Guyane',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/973',
          },
        },
      },
      {
        inseeCode: '974',
        label: 'La Réunion',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/974',
          },
        },
      },
      {
        inseeCode: '976',
        label: 'Mayotte',
        localities: [],
        _links: {
          self: {
            href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/departments/976',
          },
        },
      },
    ],
  },
  _links: {
    self: {
      href: 'http://siapa-capg-dev2-dck-001.corp.capgemini.com/services/siapa-referentiel-socle-rest/api/v1/countries',
    },
  },
};

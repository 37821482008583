import { UserRole } from '../enums/user-role.enum';
import { UserDto } from '../models/user.dto';

export const mockCourrierUser: UserDto = {
  userId: '123',
  profile: UserRole.COURRIER,
  firstName: 'Dardouri',
  lastName: 'Hamza',
  departmentName: 'serviceCourrier',
  departmentNumber: 'CNSA-547',
};

import { ApaAgentDto } from '../../../pages/demande/models/apa-agent.dto';

/**
 * Informations de l'utilisateur connecté
 */
export class AuthenticatedUserInfo {
  /**
   * Identifiant Keycloak de l'utilisateur connecté
   */
  public userId: string;

  /**
   * Username
   */
  public username: string;

  /**
   * Rôles dans Keycloak
   */
  public profiles: string[];

  /**
   * Prénom
   */
  public firstName?: string;

  /**
   * Nom de famille
   */
  public lastName?: string;

  /**
   * Nom de famille
   */
  public email?: string;

  /**
   * Numéro du département du conseil départemental auquel est rattaché l'utilisateur.
   */
  public departmentNumber?: string;

  /**
   * Nom du département du conseil départemental auquel est rattaché l'utilisateur.
   */
  public departmentName?: string;

  /**
   * Constructeur
   * @param userId Identifiant Keycloak de l'utilisateur connecté
   * @param username Username
   * @param profiles
   */
  constructor(userId: string, username: string, profiles: string[]) {
    this.userId = userId;
    this.username = username;
    this.profiles = profiles;
  }
}
export function toApaAgentDto(authUser: AuthenticatedUserInfo): ApaAgentDto {
  return {
    userId: authUser.userId,
    agentId: authUser.userId,
    firstName: authUser.firstName ?? '',
    lastName: authUser.lastName ?? '',
    departmentNumber: authUser.departmentNumber ?? '',
    departmentName: authUser.departmentName ?? '',
    workEmailAddress: authUser.email ?? '',
    workPhoneNumber: '',
  };
}

<!-- TODO: Ajouter aria-sort= "ascending" ou "descending" sur les headers des colonnes lorsqu'elles sont triées -->
<div class="container">
  <cnsa-visually-hidden id="banette-helper"
    >, les entêtes de colonnes avec bouton peuvent être triés.</cnsa-visually-hidden
  >
  <table (change)="onRDVTableChange()" class="banette" aria-describedby="banette-title banette-helper">
    <thead class="banette__header">
      <tr>
        <th scope="col">
          <div class="banette__header-cell">
            <cnsa-checkbox
              label="Sélectionner toutes les lignes"
              size="small"
              [checked]="selectedAll"
              ngDefaultControl
              (cnsa-change)="selectAllRows()"></cnsa-checkbox>
          </div>
        </th>
        <th></th>
        <th scope="col" *ngFor="let column of columns; let x = index">
          <button class="banette__header-cell banette__header-cell--button" (click)="sortTableau(x)">
            {{ column }}
            <cnsa-icon
              name="unfold_more"
              class="banette__sort-icon"
              aria-hidden="true"
              *ngIf="column !== ''"></cnsa-icon>
          </button>
        </th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngIf="rendezvous">
        <tr
          *ngFor="let item of rendezvous.slice(0, itemsToShow) | rendezVousSearch : filterValue; let i = index"
          [ngClass]="isSelected(i) ? 'selected-background' : ''">
          <td>
            <cnsa-checkbox
              size="small"
              [checked]="isSelected(i)"
              ngDefaultControl
              (cnsa-change)="selectRow(i)"></cnsa-checkbox>
          </td>
          <td>
            <ng-container *ngIf="item.appointment.apaRequestSpecialTreatment">
              <span class="red-color">
                <cnsa-icon
                  name="priority_high"
                  *ngIf="
                    item.appointment.apaRequestSpecialTreatment.specialTreatmentType ===
                    ProcessingTypeEnum.APA_URGENCE_ProcessingType
                  "></cnsa-icon
              ></span>

              <cnsa-icon
                name="timer"
                *ngIf="
                  item.appointment.apaRequestSpecialTreatment.specialTreatmentType ===
                  ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType
                "></cnsa-icon>
            </ng-container>
          </td>
          <td>
            <div class="cell-content">
              <a class="folder-name"
                >{{ item.appointment.requesterSituation.requesterIdentity.lastName | uppercase }}
                {{ item.appointment.requesterSituation.requesterIdentity.firstName }}</a
              >
              <div class="cell-content__secondary underline-none">
                <!-- a changer : par le lien de la page de consultation (Pas encore crée) -->
                <a href="#" class="cell-content__secondary">
                  {{
                    item.appointment.requesterSituation.requesterIdentity.nir
                      ? item.appointment.requesterSituation.requesterIdentity.nir
                      : ''
                  }}
                </a>
                <p>{{ findLabelEnum(item.appointment.apaRequestType) }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="cell-content">
              <p class="cell-content__primary">
                {{
                  item.appointment.requesterSituation &&
                  item.appointment.requesterSituation.requesterCoordinates &&
                  item.appointment.requesterSituation.requesterCoordinates.currentResidenceAddress &&
                  item.appointment.requesterSituation.requesterCoordinates.currentResidenceAddress.placeName
                    ? item.appointment.requesterSituation.requesterCoordinates.currentResidenceAddress.placeName
                    : ''
                }}
              </p>
              <p class="cell-content__secondary underline-none">
                {{
                  item.appointment.requesterSituation &&
                  item.appointment.requesterSituation.requesterCoordinates &&
                  item.appointment.requesterSituation.requesterCoordinates.currentResidenceAddress &&
                  item.appointment.requesterSituation.requesterCoordinates.currentResidenceAddress.zipCode
                    ? item.appointment.requesterSituation.requesterCoordinates.currentResidenceAddress.zipCode
                    : ''
                }}
              </p>
            </div>
          </td>
          <td>
            <cnsa-button
              name="referents"
              id="referents"
              (click)="onClickOpenAssignmentModale(AgentTypeEnum.ADMINISTRATIF_AgentType, i)"
              title="Référent admin."
              slot="trigger"
              variant="secondary"
              size="small">
              {{
                item.appointment.requesterSituation.requesterIdentity.administrativeReferent
                  ? (item.appointment.requesterSituation.requesterIdentity.administrativeReferent!.lastName
                      | uppercase) +
                    ' ' +
                    item.appointment.requesterSituation.requesterIdentity.administrativeReferent!.firstName
                  : 'Sélectionner'
              }}
            </cnsa-button>
          </td>
          <td>
            <cnsa-button
              name="evaluateurs"
              id="evaluateurs"
              (click)="onClickOpenAssignmentModale(AgentTypeEnum.EVALUATEUR_AgentType, i)"
              title="Référent admin."
              slot="trigger"
              variant="secondary"
              size="small">
              {{
                item.appointment.evaluator
                  ? (item.appointment.evaluator.lastName | uppercase) + ' ' + item.appointment.evaluator.firstName
                  : 'Sélectionner'
              }}
            </cnsa-button>
          </td>
          <td>
            <div class="cell-content">
              <p class="cell-content__primary">{{ item.appointment.apaRequestCompletionDate | date : 'dd/MM/yyyy' }}</p>
              <p class="cell-content__secondary">{{ getDayDifference(item.appointment.apaRequestCompletionDate) }}</p>
            </div>
          </td>
          <td>
            <ng-container
              *ngIf="
                item.appointment.appointmentStatus === AppointmentStatusEnum.A_PLANIFIER_AppointmentStatus;
                else elseBlock
              ">
              <cnsa-button variant="tertiary" size="small" (click)="onPlanVisit(item)"
                >Planifier le Rendez-vous</cnsa-button
              >
            </ng-container>
            <ng-template #elseBlock>
              <ng-container
                class="cell-content"
                *ngIf="
                  item.appointment.appointmentStatus === AppointmentStatusEnum.ANNULE_AppointmentStatus;
                  else elsePlanified
                ">
                <p>
                  <del>{{ item.appointment.appointmentDate | date : 'dd/MM/yyyy' }} </del>
                </p>
                <cnsa-button variant="tertiary" size="small" (click)="onPlanVisit(item)">Replanifier </cnsa-button>
              </ng-container>
              <ng-template #elsePlanified>
                <ng-container
                  *ngIf="item.appointment.appointmentStatus === AppointmentStatusEnum.PLANIFIE_AppointmentStatus">
                  <div class="cell-content">
                    <p class="cell-content__primary">{{ item.appointment.appointmentDate | date : 'dd/MM/yyyy' }}</p>
                    <p class="cell-content__secondary">
                      {{ item.appointment.startHour.substring(0, 5) }} - {{ item.appointment.endHour.substring(0, 5) }}
                    </p>
                  </div>
                </ng-container>
              </ng-template>
            </ng-template>
          </td>
          <td>
            <cnsa-dropdown placement="left-end">
              <cnsa-icon-button name="more_vert" label="Actions sur le commentaire" slot="trigger"></cnsa-icon-button>
              <cnsa-menu (cnsa-select)="selectDefaultAction($event)">
                <ng-container *ngFor="let actionRdv of listActionRdvs">
                  <ng-container
                    *ngIf="
                      actionRdv.label === LabelMenuListActionRdv.MODIFIER_LA_CARACTERISATION
                      then thenBlock;
                      else elseBlock
                    "></ng-container>

                  <ng-template #thenBlock>
                    <cnsa-menu-item [value]="actionRdv.label" disabled (click)="actionRdv.action(item.appointment)">
                      <cnsa-tooltip
                        placement="bottom-start"
                        hoist="true"
                        content="La modification de la caractérisation n'est plus possible car le forfait APA en urgence et le déblocage du versement ont été validés par un évaluateur">
                        <span class="modifaction_disabled">{{ actionRdv.label }}</span>
                        <cnsa-icon class="icon-button-modify" name="info" label="info"></cnsa-icon>
                      </cnsa-tooltip>
                    </cnsa-menu-item>
                  </ng-template>

                  <ng-template #elseBlock>
                    <cnsa-menu-item [value]="actionRdv.label" (click)="actionRdv.action(item.appointment)" *ngIf="!(item.appointment.appointmentStatus === AppointmentStatusEnum.A_PLANIFIER_AppointmentStatus || item.appointment.appointmentStatus === AppointmentStatusEnum.ANNULE_AppointmentStatus)">
                      <p routerLinkActive="active" skipLomatCardAvatar="true">
                        {{ actionRdv.label }}
                      </p>
                    </cnsa-menu-item>
                  </ng-template>
                </ng-container>
              </cnsa-menu>
            </cnsa-dropdown>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <span
    class="table-label"
    *ngIf="rendezvous && (rendezvous.slice(0, itemsToShow) | rendezVousSearch : filterValue).length === 0">
    <p class="bold-text">{{ message.noResult }}</p>
    <br />
    <p>{{ message.emptySearch }}</p>
  </span>

  <div class="container-button" *ngIf="rendezvous">
    <cnsa-button
      *ngIf="rendezvous.length > itemsToShow && (rendezvous | rendezVousSearch : filterValue).length > itemsToShow"
      variant="primary"
      (click)="addMoreItemsToShow()">
      <cnsa-icon name="downloading" slot="prefix"></cnsa-icon>
      Afficher plus de résultats
    </cnsa-button>
  </div>
  <app-bannete-modale-assigner-personne
    *ngIf="isDialogOpen && profilType"
    [nameProfile]="profilType"
    [banetteType]="BannetteTypeEnum.RENDEZVOUS"
    [dialogIsOpen]="isDialogOpen"
    [dataToChangeRefAdmin]="rdvToUpdate"
    (closeModal)="closeAssignedPersonModale($event)"
    (updatePerson)="updateSelectedPerson($event)">
  </app-bannete-modale-assigner-personne>
</div>

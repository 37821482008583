<cnsa-menu>
  <cnsa-menu-item>
    <cnsa-button class="menu-button" variant="navigation" (click)="handleClick(routes.Home)">
      <cnsa-icon name="dashboard" slot="prefix"></cnsa-icon>
      Accueil
    </cnsa-button>
  </cnsa-menu-item>

  <ng-template [ngIf]="authService.isAuthorize(rolesAccessPages[PagesRouteEnum.COURRIER])">
    <cnsa-menu-item>
      <cnsa-button class="menu-button" variant="navigation" (click)="handleClick(routes.IncomingMailBanner)">
        <cnsa-icon name="outbox" slot="prefix" variant="navigation"></cnsa-icon>
        Courriers entrants
      </cnsa-button>
    </cnsa-menu-item>
  </ng-template>

  <ng-template [ngIf]="authService.isAuthorize(rolesAccessPages[PagesRouteEnum.DEMANDE])">
    <cnsa-menu-item>
      <cnsa-button class="menu-button" variant="navigation" (click)="handleClick(routes.ApaRequestBanner)">
        <cnsa-icon name="pending_actions" slot="prefix"></cnsa-icon>
        Demandes en cours
      </cnsa-button>
    </cnsa-menu-item>
  </ng-template>

  <ng-template [ngIf]="authService.isAuthorize(rolesAccessPages[PagesRouteEnum.RENDEZ_VOUS])">
    <cnsa-menu-item>
      <cnsa-button class="menu-button" variant="navigation" (click)="handleClick(routes.ForfaitsApaUrgence)">
        <cnsa-icon name="perm_contact_calendar" slot="prefix"></cnsa-icon>
        Forfaits APA en urgence
      </cnsa-button>
    </cnsa-menu-item>
  </ng-template>

  <ng-template [ngIf]="authService.isAuthorize(rolesAccessPages[PagesRouteEnum.RENDEZ_VOUS])">
    <cnsa-menu-item>
      <cnsa-button class="menu-button" variant="navigation" (click)="handleClick(routes.AppointmentBanner)">
        <cnsa-icon name="perm_contact_calendar" slot="prefix"></cnsa-icon>
        Rendez-vous
      </cnsa-button>
    </cnsa-menu-item>
  </ng-template>
  <!--TODO add when the route wiil be know-->
  <!--
    <ng-template [ngIf]="authService.isAuthorize(rolesAccessPages[PagesRouteEnum.COURRIER])">
-->
  <cnsa-menu-item>
    <cnsa-button class="menu-button" variant="navigation" (click)="handleClick(routes.error)">
      <cnsa-icon name="network_check" slot="prefix"></cnsa-icon>
      Évaluations
    </cnsa-button>
  </cnsa-menu-item>
  <!--</ng-template>-->
  <!--TODO add when the route wiil be know-->
  <!-- <ng-template [ngIf]="authService.isAuthorize(rolesAccessPages[PagesRouteEnum.COURRIER])">-->
  <cnsa-menu-item>
    <cnsa-button class="menu-button" variant="navigation" (click)="handleClick(routes.error)">
      <cnsa-icon name="file_present" slot="prefix"></cnsa-icon>
      Propositions
    </cnsa-button>
  </cnsa-menu-item>
  <!--    </ng-template>-->
  <!--TODO add when the route wiil be know-->
  <!--<ng-template [ngIf]="authService.isAuthorize(rolesAccessPages[PagesRouteEnum.COURRIER])">-->
  <cnsa-menu-item>
    <cnsa-button class="menu-button" variant="navigation" (click)="handleClick(routes.error)">
      <cnsa-icon name="gavel" slot="prefix"></cnsa-icon>
      Décisions
    </cnsa-button>
  </cnsa-menu-item>
  <!--</ng-template>-->

  <ng-template [ngIf]="authService.isAuthorize(rolesAccessPages[PagesRouteEnum.COURRIER])">
    <cnsa-menu-item>
      <cnsa-button class="menu-button" variant="navigation" (click)="handleClick(routes.MailOutbox)">
        <cnsa-icon name="send" slot="prefix"></cnsa-icon>
        Boîte d'envoi
      </cnsa-button>
    </cnsa-menu-item>
  </ng-template>

  <ng-template [ngIf]="authService.isAuthorize(rolesAccessPages[PagesRouteEnum.GESTION_DES_GROUPES])">
    <cnsa-menu-item>
      <cnsa-button class="material-icons-outlined" variant="navigation" (click)="handleClick(routes.GestionDesGroupes)">
        <cnsa-icon name="settings" slot="prefix"></cnsa-icon>
        Paramétrages
      </cnsa-button>
    </cnsa-menu-item>
  </ng-template>

  <ng-template [ngIf]="authService.isAuthorize(rolesAccessPages[PagesRouteEnum.CORBEILLE])">
    <cnsa-menu-item>
      <cnsa-button class="menu-button" variant="navigation" (click)="handleClick(routes.trash)">
        <cnsa-icon name="delete" slot="prefix"></cnsa-icon>
        Corbeille
      </cnsa-button>
    </cnsa-menu-item>
  </ng-template>
</cnsa-menu>

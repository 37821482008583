import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CNSADialog } from '@cnsa-fr/design-system';
import { ApaAgentDto } from '../../../demande/models/apa-agent.dto';
import { PAGE_SIZE } from '../../../../shared/utils/globalConstants';
import { GestionCorbeilleData } from '../../../corbeille/data/gestion-corbeille.data';
import { ProfileTypeEnum } from '../../enums/profile-type.enum';
import { IncomingMailPaginatedSearchQueryDto } from '../../../courrier/models/incoming-mail-paginated-search-query.dto';
import { EvidencePaginatedSearchQueryDto } from '../../../corbeille/models/EvidencePaginatedSearchQueryDto';
import { EvidenceStatusEnum } from '../../../../shared/enums/referentiel-evidences.enum';

/**
 * Composant pour afficher les filtres de la corbeille et les utiliser pour filtrer les courriers dans la corbeille.
 */
@Component({
  selector: 'app-banette-filtres-corbeille',
  templateUrl: './banette-filtres-corbeille.component.html',
  styleUrls: ['./banette-filtres-corbeille.component.css'],
})
export class BanetteFiltresCorbeilleComponent {
  /**
   * Titre du composant (optionnel).
   */
  @Input() titre?: string;

  /**
   * Indicateur pour afficher ou masquer la boîte de dialogue.
   */
  @Input() showDialog!: boolean;

  /**
   * Liste des référents APA disponibles.
   */
  @Input() referents?: ApaAgentDto[];

  /**
   * Événement émis lorsque la boîte de dialogue est fermée.
   */
  @Output() closeDialog = new EventEmitter();

  @Input() selectedPerson: ApaAgentDto | undefined = undefined;

  /**
   * Événement émis lorsque le nombre de filtres sélectionnés.
   */
  @Output() checkedCounterChange = new EventEmitter<number>();

  /**
   * Référence au dialogue (si utilisée dans le modèle).
   */
  @ViewChild('dialog') dialog?: ElementRef<CNSADialog>;

  @Output() updateModale = new EventEmitter<boolean>();

  @Output() updateProfilName = new EventEmitter<ProfileTypeEnum>();

  @ViewChild('selectRef') selectRef: any;
  /**
   * Indicateur pour filtrer par dossier ou non.
   */
  isFolder = '';

  /**
   * Filtre actuel pour la corbeille.
   */
  filterCorbeille: EvidencePaginatedSearchQueryDto = {
    pageNumber: 0,
    pageSize: PAGE_SIZE,
    statusCodeList: [EvidenceStatusEnum.SUPPRIME_EvidenceStatus],
  };

  /**
   * counter pour le nombre de filtres sélectionnés.
   */
  checkedCounter = 0;

  /**
   * Crée une instance du composant BanetteFiltresCorbeilleComponent.
   *
   * @param {GestionCorbeilleData} gestionCorbeilleData Le service pour gérer la corbeille.
   */
  constructor(private gestionCorbeilleData: GestionCorbeilleData) {}

  /**
   * Fonction permettant de changer le référent qui a supprimé le courrier
   *
   * @param event
   */
  //TODO: Do when API (deleteBy) is ready
  changeReferent(event: Event) {
    const target = event.target as HTMLInputElement;
    //  this.filterCorbeille.referentId = target.value;
  }

  /**
   * Change l'indicateur pour filtrer par dossier ou non en fonction de l'événement fourni.
   *
   * @param event L'événement déclenché par le changement de l'indicateur de dossier.
   */
  changeIsFolder(event: any) {
    this.isFolder = event.target.checked ? event.target.value : '';
  }

  /**
   * Bascule l'affichage de la boîte de dialogue.
   * Cache la boîte de dialogue en émettant un événement closeDialog.
   */
  toggleDialog() {
    this.showDialog = false;
    this.closeDialog.emit();
  }

  /**
   * Filtre les courriers dans la corbeille en fonction du filtre actuel.
   * Appelle le service gestionCorbeilleData pour récupérer les courriers filtrés.
   */
  filtrerCorbeille() {
    /*    if (this.isFolder === 'true') {
      this.filterCorbeille.isFolder = true;
    } else if (this.isFolder === 'false') this.filterCorbeille.isFolder = false;
    else delete this.filterCorbeille.isFolder;*/

    this.gestionCorbeilleData.getCorbeille(this.filterCorbeille).subscribe();
    this.toggleDialog();

    /*    this.checkedCounter =
      (this.isFolder ? 1 : 0) +
      (this.filterCorbeille.deletionDatetime ? 1 : 0) +
      (this.filterCorbeille.administrativeReferent?.agentId ? 1 : 0);*/
    this.checkedCounterChange.emit(this.checkedCounter); // "counter"pour envoyer les informations au composant père avec le nombre de filtres sélectionnés.
  }

  /**
   * Réinitialise les filtres de la corbeille.
   * Appelle le service gestionCorbeilleData pour récupérer tous les courriers sans filtres.
   */
  resetFiltersCorbeille() {
    this.gestionCorbeilleData.getCorbeille().subscribe();
    this.filterCorbeille = {
      pageNumber: 0,
      pageSize: PAGE_SIZE,
      deletionDate: '',
      statusCodeList: [EvidenceStatusEnum.SUPPRIME_EvidenceStatus],
    };
    this.isFolder = '';
    this.checkedCounter = 0;
    this.selectedPerson = undefined;
    this.checkedCounterChange.emit(this.checkedCounter); // "counter"pour envoyer les informations au composant père avec le nombre de filtres sélectionnés.
  }

  onClickSelect(event: Event) {
    event.preventDefault();
    this.updateModale.emit(true);
    this.updateProfilName.emit(ProfileTypeEnum.REFERENT_ADMIN);
  }
}

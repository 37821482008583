import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

/**
 * Service de recherche
 */
@Injectable()
export class SearchService {
  // for now we use a Map for cache perhaps depends on data we can use ng2-cache-service or ngx-cache
  /**
   * Map de la cache de recherche
   */
  private cache: Map<string, string[]> = new Map();

  /**
   * Données de recherche simulées
   */
  private mockData = ['Result 1', 'Result 2', 'Result 3', 'Result 4', 'Result 5', 'Result 6'];

  /**
   * Fonction de recherche
   * Si les résultats sont disponibles dans la cache, on les retourne
   * Sinon, on fait une recherche et on cache les résultats
   *
   * @param query Chaîne de requête
   * @returns Observable de la liste des résultats
   */
  search(query: string): Observable<string[]> {
    if (this.cache.has(query)) {
      // Return cached results if available
      return of(this.cache.get(query)!);
    } else {
      // Perform search and cache the results
      const results = this.performSearch(query);
      this.cache.set(query, results);
      return of(results);
    }
  }

  // TODO add a HTTP request to backend search service
  /**
   * Fonction qui fait une recherche dans les données simulées
   *
   * @param query Chaîne de requête
   * @returns Liste des résultats
   */
  private performSearch(query: string): string[] {
    return this.mockData.filter((data) => data.includes(query));
  }
}

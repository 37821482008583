import { ApaUserGroupProfile } from '../models/apa-user-group-profile';
import { GroupDto } from '../models/group.dto';
import { UserImplements } from '../models/user.model';
import { UserRole } from '../../home/enums/user-role.enum';

const user1: UserImplements = {
  userId: '1',
  profile: UserRole.AUTONOMIE,
  firstName: 'Carole',
  lastName: 'laSympathique',
  departmentNumber: '84000',
  departmentName: '8400',
};

const user2: UserImplements = {
  userId: '4',
  profile: UserRole.AUTONOMIE,
  firstName: 'Marie',
  lastName: 'Madeleine',
  departmentNumber: '4000',
  departmentName: '400',
};

export const group2: GroupDto = {
  groupId: '2',
  groupName: 'B',
  description: 'desc2',
  profile: ApaUserGroupProfile.EVALUATION,
  attributedZipCodes: ['100000', '81000'],
  members: [user1, user2],
};

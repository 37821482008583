import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment as envDev } from '../../../../../environments/environment';
import { environment as envProd } from '../../../../../environments/environment.prod';
import { AttachedDocumentDto } from '../../../shared/models/attached-document.dto';

import { takeUntil } from 'rxjs/operators';
import { EvidenceCreation, EvidenceCreationRequest } from '../../../shared/models/evidence-creation-request.dto';
import { EvidenceUpdateRequestDto } from 'src/app/modules/shared/models/evidence-update-request.dto';
import { EvidenceDto } from 'src/app/modules/shared/models/evidence.dto';
import { CnsaFile } from '../models/cnsa-file.model';
import { DocumentModel } from '../models/document.model';
import { DocumentStatusEnum } from '../../../shared/enums/document-status.enum';
import { AttachedDocumentTypeEnum } from '../../demande/enums/attached-document-type.enum';
import { EvidenceTypeEnum } from '../../../shared/enums/referentiel-evidences.enum';

/**
 * Service de gestion des documents.
 */
@Injectable({
  providedIn: 'root',
})
export class GestionDocumentService {
  /**
   * URL de base de l'API Siapa Piece Socle.
   */
  private siapaPieceSocleRestApiURL;

  /**
   * Endpoint pour une fonctionnalité spécifique de l'API.
   */
  private endPoint;

  /**
   * La liste des documents.
   */
  private _documents: EvidenceDto[] = [];

  private documentsSubject = new BehaviorSubject<EvidenceDto[]>([]);

  /**
   * Construit le service de gestion des documents.
   *
   * Initialise les URL de l'API en fonction de l'environnement.
   *
   * @param {HttpClient} httpClient Le client HTTP pour effectuer des requêtes.
   */
  constructor(private httpClient: HttpClient) {
    if (isDevMode()) {
      this.endPoint = envDev.endPointEvidences;
      this.siapaPieceSocleRestApiURL = envDev.siapaPieceSocleRestApi + this.endPoint;
    } else {
      this.endPoint = envProd.endPointEvidences;
      this.siapaPieceSocleRestApiURL = envProd.siapaPieceSocleRestApi + this.endPoint;
    }
    console.log('URL siapaDmandeInterRestApi -- ', this.siapaPieceSocleRestApiURL);
  }

  /**
   * Télécharge le fichier vers le serveur.
   *
   * @param cancelUpload$
   * @param evidence
   * @param file
   * @returns {Observable<EvidenceDto[]>} Un Observable contenant la réponse de l'API.
   */
  uploadFile(
    cancelUpload$: Subject<void>,
    evidence: EvidenceCreationRequest,
    file: CnsaFile
  ): Observable<EvidenceDto[]> {
    const formData: FormData = EvidenceCreation.toUploadFile(evidence, file);
    //si on met un hhttp header https://stackoverflow.com/questions/36005436/the-request-was-rejected-because-no-multipart-boundary-was-found-in-springboot
    return this.httpClient.post<EvidenceDto[]>(this.siapaPieceSocleRestApiURL, formData).pipe(takeUntil(cancelUpload$));
  }

  /**
   * Met à jour les balises d'un fichier.
   *
   * @param {string} evidenceId L'identifiant du fichier.
   * @param {string} structureCode L'identifiant de la structure.
   * @param {any} keys Les balises à mettre à jour.
   * @returns {Observable<EvidenceDto>} Un Observable contenant la réponse de l'API.
   */
  updateEvidence(evidenceId: string, structureCode: string, keys: EvidenceUpdateRequestDto): Observable<any> {
    return this.httpClient.patch<EvidenceDto>(
      `${this.siapaPieceSocleRestApiURL}/${evidenceId}?structureCode=${structureCode}`,
      keys
    );
  }

  /**
   * suppression d'une pièce.
   *
   * @param {string} evidenceId L'identifiant du fichier.
   * @param {string} structureCode L'identifiant de la structure.
   * @returns Void
   */
  deleteEvidence(evidenceId: string, structureCode: string) {
    this.httpClient
      .delete(`${this.siapaPieceSocleRestApiURL}/${evidenceId}?structureCode=${structureCode}`)
      .subscribe();
  }

  /**
   * Récupération de documents par id
   *
   * @param evidenceIds
   * @param structureCode
   */
  getEvidences(evidenceIds: string[], structureCode: string): Observable<EvidenceDto[]> {
    let httpParams = new HttpParams().set('structureCode', structureCode);
    evidenceIds.forEach((id) => (httpParams = httpParams.append('evidenceIds', id)));
    return this.httpClient.get<EvidenceDto[]>(`${this.siapaPieceSocleRestApiURL}`, { params: httpParams });
  }

  /**
   * Getter pour la liste de documents.
   *
   * @returns {EvidenceDto[]} La liste des documents.
   */
  get documents(): EvidenceDto[] {
    return this._documents;
  }

  /**
   * Setter pour la liste de documents.
   *
   * @param {EvidenceDto[]} value La liste des documents.
   */
  set documents(value: EvidenceDto[]) {
    this._documents = value;
    this.documentsSubject.next(this._documents);
  }

  /**
   * Mappe les documents  EvidenceDto[] joints en objets de type AttachedDocumentDto.
   *
   * @returns Un tableau d'objets AttachedDocumentDto représentant les documents joints.
   */
  mapEvidenceDtoToAttachedDocuments(): AttachedDocumentDto[] {
    const attachedDocuments: AttachedDocumentDto[] = [];
    let docTypes: EvidenceTypeEnum[] = [];
    if (this.documents && this.documents.length) {
      this.documents.forEach((doc) => {
        docTypes = [];
        doc.linkEvidenceType.forEach((value) => {
          docTypes.push(value.evidenceTypeCode as EvidenceTypeEnum);
        });
        //doc.linkEvidenceType.map(value => {  }
        attachedDocuments.push({
          url: doc.url,
          attachedDocumentId: doc?.evidenceId ?? '',
          storageType: doc.storageTypeCode,
          types: docTypes,
          reference: doc.reference,
          document: {
            status: DocumentStatusEnum.AVAILABLE,
            documentId: doc.evidenceId ?? '',
            documentName:
              doc.evidenceProperty.find((property) => property.propertyKey == 'filename')?.propertyValue ?? '',
          },
        });
      });
    }
    return attachedDocuments;
  }

  getDocumentsAsSubject() {
    return this.documentsSubject.asObservable();
  }

  /**
   * Mappe les documents joints en objets de type DocumentModel.
   *
   * @returns Un tableau d'objets DocumentModel représentant les documents joints.
   */
  mapDocumentsModels(attachedDocuments: AttachedDocumentDto[]): DocumentModel[] {
    const documents = attachedDocuments.map((doc) => {
      return {
        name: doc?.document?.documentName ?? '',
        path: doc.url,
        imagePath: doc.url?.replace('preview', 'thumbnail'),
        pieceId: doc?.document?.documentId ?? '',
      };
    });
    return documents;
  }

  fetchThumbnail(url:string) {
    return this.httpClient.get(url, { responseType: 'blob' });
  }
}

/**
 * Constante représentant le mode "réel" pour les bannières.
 */
export const REAL_MODE = 'real';

/**
 * Constante représentant le mode "simulé" pour les bannières.
 */
export const MOCK_MODE = 'mock';

/**
 * Constante représentant la taille de pagination par défaut pour les listes.
 */
export const PAGE_SIZE = 100;

/**
 * URL de l'image fictive utilisée comme espace réservé lorsqu'aucune prévisualisation n'est disponible.
 */
export const FAKE_IMAGE_PALCEHOLDER = 'https://fakeimg.pl/300x200?text=No+preview+available';

/**
 * Texte fictif utilisé pour représenter un chemin fictif.
 */
export const FAKE_PATH_TEXT = 'fake-path';

/**
 * Modèle d'expression régulière pour valider le nom des classes de bibliothèque.
 *
 * Cette expression régulière permet uniquement des caractères alphanumériques (majuscules et minuscules) et des chiffres.
 */
export const CLASS_LIBRARY_REGEX_PATTER = '^[a-zA-Z0-9]*$';

/**
 * Âge minimum requis pour être éligible à l'APA à domicile.
 */
export const MINIMUM_AGE_FOR_HOME_APA = 60;

/**
 * Le format de date utilisé
 */
export const DATE_FORMAT = 'yyyy-MM-dd';

/**
 * Local utilisé dans pour les dates
 */
export const DATE_LOCAL_US = 'en-US';

/**
 * Pays de residence accepté
 */
export const DEFAULT_COUNTRY_NAME = 'France';

/**
 * Decompte en nombre de jours
 */
export const COUNTDOWN_IN_DAYS = 10;

/**
 * mapping des messages d erreurs issus du µ siapa-demande-metier
 * class/fichier ApaRequestConstants
 */
export const sectionMessageMap = new Map<string, string>([
  ['Décision de justice relative à la mise sous protection manquante', 'section9'],
  ['Avis de taxe foncière manquant', 'section9'],
  ['Certificat Médical manquant', 'section9'],
  ['Décision d’attribution de l’APA manquante', 'section9'],
  ["Avis d'imposition ou de non-imposition du conjoint manquant", 'section9'],
  ["La présence d'une mesure de protection du demandeur n'est pas renseignée", 'section2'],
  ['La mesure de protection est non-renseignée', 'section2'],
  ["Le statut de la résidence du demandeur n'est pas renseigné", 'section6'],
  ["Le formulaire de demande n'est pas signé", 'section8'],
  ['Formulaire manquant', 'section9'],
  ["Justificatif d'identité manquant", 'section9'],
  ["Avis d'imposition ou non-imposition du demandeur manquant", 'section9'],
  ['RIB manquant', 'section9'],
  // demande CMI
  ['Le demandeur ne bénéficie pas de l’APA', 'demande CMI'],
  ['Section Carte Mobilité et Inclusion à compléter', 'demande CMI'],
  ['Certificat Médical manquant', 'demande CMI'],
  ['Décision d’attribution de l’APA manquante', 'demande CMI'],
  ['Photocopie carte(s) attribuée(s) manquante(s)', 'demande CMI'],
  ['Aucuns documents n’ont été importés dans le formulaire', 'demande CMI'],
  // Module relais en cas d'hospitalisation
  ['Le demandeur ne bénéficie pas de l’APA', "Module relais en cas d'hospitalisation"],
  ['Les informations de la demande sont incomplètes', "Module relais en cas d'hospitalisation"],
  ['Courrier de module relais en cas d’hospitalisation manquant', "Module relais en cas d'hospitalisation"],
  ['Document attestant de l’hospitalisation manquant', "Module relais en cas d'hospitalisation"],
  ['Type d’hospitalisation à compléter', "Module relais en cas d'hospitalisation"],
  ['Aidant indispensable non sélectionné', "Module relais en cas d'hospitalisation"],
]);

/**
 * Code ISO RÉPUBLIQUE FRANÇAISE
 */
export const ISO3_CODE_FRANCE = 'FRA';

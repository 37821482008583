import { Pipe, PipeTransform } from '@angular/core';
import { OutgoingMailDto } from '../../pages/courrier/models/outgoing-mail.dto';
import { ReferentielData } from '../data/referentiel-data';

/**
 * Pipe personnalisée pour filtrer une liste d'objets OutgoingMailDto en fonction d'une valeur de filtre.
 */
@Pipe({
  name: 'boiteSearch',
})
export class BoiteSearchPipe implements PipeTransform {
  constructor(private referentielService: ReferentielData) {}
  /**
   * Applique le filtre sur la liste d'e-mails envoyés en fonction de la valeur de filtrage fournie.
   *
   * @param {OutgoingMailDto[]} mailList - La liste d'e-mails envoyés à filtrer.
   * @param {string} filterValue - La valeur de filtre à appliquer.
   * @returns {OutgoingMailDto[]} Une nouvelle liste d'e-mails envoyés filtrée en fonction du filtre.
   */
  transform(mailList: OutgoingMailDto[], filterValue: string): OutgoingMailDto[] {
    if (!mailList || !filterValue) {
      return mailList; // Retourne la liste non filtrée si la liste ou la valeur de filtrage est vide
    }

    filterValue = filterValue.toLowerCase(); // Convertit la valeur de filtrage en minuscules

    return mailList.filter((mail: OutgoingMailDto) => {
      //const recipientsNumber = mail..toString().includes(filterValue);
      const firstName = mail.receiverSituation.personIdentity.firstName.toLowerCase().includes(filterValue);
      const lastName = mail.receiverSituation.personIdentity.lastName.toLowerCase().includes(filterValue);
      const nir = mail.receiverSituation.personIdentity.nir
        ? mail.receiverSituation.personIdentity.nir.toLowerCase().includes(filterValue)
        : null;
      const placeName = mail.receiverSituation.personCoordinates
        .residenceAddress!.placeName.toLowerCase()
        .includes(filterValue);
      const zipCode = mail.receiverSituation.personCoordinates
        .residenceAddress!.zipCode.toLowerCase()
        .includes(filterValue);
      const adminstrativeReferentFirstName = mail.receiverSituation.personIdentity.administrativeReferent?.firstName
        .toLowerCase()
        .includes(filterValue);
      const adminstrativeReferentlastName = mail.receiverSituation.personIdentity.administrativeReferent?.lastName
        .toLowerCase()
        .includes(filterValue);
      const generatedDate = mail.generationDate.getDate().toString().toLowerCase().includes(filterValue);
      const houres = mail.generationDate.getHours().toString().toLowerCase().includes(filterValue);
      const mailType = this.findLabelEnum(mail.mailTypeCode) ?? ''.toLowerCase().includes(filterValue);

      return (
        firstName ||
        lastName ||
        nir ||
        placeName ||
        zipCode ||
        mailType ||
        adminstrativeReferentFirstName ||
        adminstrativeReferentlastName ||
        generatedDate ||
        houres
      );
    });
  }
  findLabelEnum(codeEnum: string) {
    return this.referentielService.mapEnumItemCodeToItemLabel(codeEnum);
  }
}

import { inject, Injectable } from '@angular/core';
import { ReferentielData } from '../data/referentiel-data';
import { EnumItemDto } from '../models/enum-item.dto';
import { LocalStorageService } from './local-storage.service';
import { RequestReferentielData } from '../../core/data/RequestReferentielData';
import { StructureDto } from '../models/structure.dto';
import { map, Observable, of, tap } from 'rxjs';
import { DepartmentsDto } from '../models/departmentsDto';
import { CountryDto } from '../models/countries.dto';
import { Localities } from '../models/localities';

/**
 * Service de gestion des référentiels.
 *
 * Ce service permet de récupérer les différents référentiels depuis l'API et de les stocker dans le stockage local. Il permet également de récupérer les différents référentiels depuis le stockage local et de les mapper en objets EnumItemDto.
 *
 * Toute les méthodes sont documenté directement dans la classe abstraite {@link ReferentielData}
 * @see ReferentielData
 */
@Injectable({
  providedIn: 'root',
})
export class ReferentielService implements ReferentielData {
  ENUM_ITEM_STORAGE = 'referentiel-enum_item';
  DEPARTMENT_STORAGE = 'referentiel-department';
  COUNTRY_STORAGE = 'referentiel-country';
  /**
   * Service de gestion des enum items.
   */
  requestReferentiel: Readonly<RequestReferentielData> = inject(RequestReferentielData);

  /**
   * Service de gestion du stockage local.
   */
  localStorageService: Readonly<LocalStorageService> = inject(LocalStorageService);

  saveReferentielEnumItemInLocalStorage(): void {
    this.requestReferentiel.getAllEnumItemSocle().subscribe((value) => {
      if (value) this.localStorageService.setObject(this.ENUM_ITEM_STORAGE, value);
    });
  }

  mapEnumItemCodeToItemLabel(code: string | undefined): string | undefined {
    return code ? this.retrieveItemCode(code)?.enumItemLabel : undefined;
  }

  getReferentielsEnumItemByType(type: string): EnumItemDto[] | undefined {
    return this.retrieveItemsType(type);
  }

  getReferentielByCode(code: string): EnumItemDto | undefined {
    return this.retrieveItemCode(code);
  }

  structureByCode(structureCode: string): Observable<StructureDto> | undefined {
    return this.requestReferentiel.getStructureSocleByCode(structureCode);
  }

  allDepartments(): Observable<DepartmentsDto[] | undefined> {
    const departmentsLocalstorage = this.localStorageService.getObject<DepartmentsDto[]>(this.DEPARTMENT_STORAGE);
    if (departmentsLocalstorage) return of(departmentsLocalstorage);
    return this.requestReferentiel.getALLDepartments().pipe(
      tap((value) => {
        if (value) this.localStorageService.setObject<DepartmentsDto[]>(this.DEPARTMENT_STORAGE, value);
      })
    );
  }

  departmentByInseeCode(inseeCode: string): Observable<DepartmentsDto | undefined> {
    return this.allDepartments().pipe(map((value) => value?.find((department) => department.inseeCode === inseeCode)));
  }

  allCountries(): Observable<CountryDto[] | undefined> {
    const countryLocalStorage = this.localStorageService.getObject<CountryDto[]>(this.COUNTRY_STORAGE);
    if (countryLocalStorage) return of(countryLocalStorage);
    return this.requestReferentiel.getALLCountries().pipe(
      tap((value) => {
        const sortResult = value?.sort((a, b) => a.label.localeCompare(b.label));
        if (sortResult) this.localStorageService.setObject<CountryDto[]>(this.COUNTRY_STORAGE, sortResult);
      })
    );
  }

  countryByIsoCode(iso3Code: string): Observable<CountryDto | undefined> {
    return this.allCountries().pipe(map((value) => value?.find((country) => country.iso3Code === iso3Code)));
  }

  localitiesByInseeCode(inseeCode: string): Observable<Localities | undefined> {
    return this.requestReferentiel.getLocalitiesByInseeCode(inseeCode);
  }

  /**
   *
   * @param code Identifiant unique de l'énumération
   * @returns EnumItemDto ou undefined
   * @private
   */
  private retrieveItemCode(code: string): EnumItemDto | undefined {
    const getRef = this.localStorageService.getObject<EnumItemDto[]>(this.ENUM_ITEM_STORAGE);
    let itemCode;
    if (getRef) {
      itemCode = getRef.find((referentiel) => code === referentiel.enumItemCode);
      return itemCode;
    }
    return undefined;
  }

  /**
   *
   * @param code Type de l'énumération
   * @returns EnumItemDto ou undefined
   * @private
   */
  private retrieveItemsType(code: string): EnumItemDto[] | undefined {
    const getRef = this.localStorageService.getObject<EnumItemDto[]>(this.ENUM_ITEM_STORAGE);
    let itemType;
    if (getRef) {
      itemType = getRef.filter((referentiel) => code === referentiel.enumItemType);
      return itemType;
    }
    return undefined;
  }
}

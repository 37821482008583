import { Component } from '@angular/core';

/**
 * Composant principale: bouton d'ouverture du menu
 */
@Component({
  selector: 'app-side-dialog',
  templateUrl: './side-dialog.component.html',
  styleUrls: ['./side-dialog.component.scss'],
})
export class SideDialogComponent {
  /**
   * Indique si le dialog est ouvert ou non
   */
  isOpen = false;

  /**
   * Ouvre ou ferme le dialog
   */
  handleDrawer(): void {
    this.isOpen = !this.isOpen;
  }

  /**
   * Ferme le dialog
   */
  onDrawerClose(): void {
    this.isOpen = false;
  }
}

<cnsa-dialog classname="dialog-overview" label="Filtrer" [open]="showDialog" #dialog size="small">
  <ng-container *ngIf="titre === 'Corbeille'">
    <div class="div-filtres">
      <div class="div-filtres-general">
        <h3 class="sub-title-filtres">Type de fichier</h3>
        <div class="div-filtres-checkbox">
          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeIsFolder($event)"
            [checked]="isFolder === 'false'"
            value="false"
            >Document</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeIsFolder($event)"
            [checked]="isFolder === 'true'"
            value="true"
            >Dossier</cnsa-checkbox
          >
        </div>
      </div>

      <div class="div-filtres-select">
        <h3 class="sub-title-filtres">Référent ayant supprimé</h3>
        <cnsa-select (mousedown)="onClickSelect($event)" [value]="selectedPerson ? selectedPerson.userId : ''">
          <cnsa-option value="" disabled selected>Sélectionner</cnsa-option>
          <cnsa-option [value]="selectedPerson?.userId">
            {{ selectedPerson?.firstName }} {{ selectedPerson?.lastName }}
          </cnsa-option>
        </cnsa-select>
      </div>

      <div class="div-filtres-date-inputs">
        <h3 class="sub-title-filtres">Date de suppression</h3>
        <cnsa-input type="date" [(ngModel)]="filterCorbeille.deletionDate" help-text="Format attendu: JJ/MM/AAAA" />
      </div>
    </div>

    <cnsa-button slot="footer" variant="navigation" (click)="resetFiltersCorbeille()"
      >Réinitialiser les filtres</cnsa-button
    >
    <cnsa-button slot="footer" (click)="filtrerCorbeille()">Filtrer</cnsa-button>
  </ng-container>
</cnsa-dialog>

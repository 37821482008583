<cnsa-dialog classname="dialog-overview" label="Filtrer" [open]="showDialog" #dialog size="large">
  <ng-container *ngIf="titre === 'Courriers entrants'">
    <div class="div-filtres">
      <div class="div-filtres-general">
        <h3 class="sub-title-filtres">Caractérisation</h3>
        <div class="div-filtres-checkbox">
          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeCaracterisationCourrier($event)"
            value="{{ ProcessingTypeEnum.APA_URGENCE_ProcessingType }}"
            [checked]="typifications.includes(ProcessingTypeEnum.APA_URGENCE_ProcessingType)"
            >Forfait APA en urgence</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeCaracterisationCourrier($event)"
            value="{{ ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType }}"
            [checked]="typifications.includes(ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType)"
            >Procédure accélérée</cnsa-checkbox
          >
          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeCaracterisationCourrier($event)"
            value="{{ ProcessingTypeEnum.NO }}"
            [checked]="typifications.includes(ProcessingTypeEnum.NO)"
            >Aucune caractérisation</cnsa-checkbox
          >
        </div>
      </div>

      <div class="div-filtres-general">
        <h3 class="sub-title-filtres">Provenance</h3>
        <div class="div-filtres-checkbox">
          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeProvenanceCourrier($event)"
            [checked]="origins.includes(CommunicationChannelEnum.TS_CommunicationChannel)"
            value="{{ CommunicationChannelEnum.TS_CommunicationChannel }}"
            >TS DAA</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeProvenanceCourrier($event)"
            [checked]="origins.includes(CommunicationChannelEnum.UNKNOWN)"
            value="{{ CommunicationChannelEnum.UNKNOWN }}"
            >Non-renseignée</cnsa-checkbox
          >
        </div>
      </div>

      <div class="div-filtres-general">
        <h3 class="sub-title-filtres">Type de courrier</h3>
        <div class="div-filtres-naturedemande">
          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeTypeCourrier($event)"
            value="{{ TypeOfProcessEnum.PREMIERE_DEMANDE_APA_TypeOfProcess }}"
            [checked]="types.includes(TypeOfProcessEnum.PREMIERE_DEMANDE_APA_TypeOfProcess)"
            >1ère demande</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeTypeCourrier($event)"
            value="{{ TypeOfProcessEnum.NOUVELLE_DEMANDE_APA_TypeOfProcess }}"
            [checked]="types.includes(TypeOfProcessEnum.NOUVELLE_DEMANDE_APA_TypeOfProcess)"
            >Nouvelle demande</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeTypeCourrier($event)"
            value="{{ TypeOfProcessEnum.REVISION_PERSONNE_TypeOfProcess }}"
            [checked]="types.includes(TypeOfProcessEnum.REVISION_PERSONNE_TypeOfProcess)"
            >Révision à la demande de la personne</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeTypeCourrier($event)"
            value="{{ TypeOfProcessEnum.RELAIS_HOSPITALISATION_TypeOfProcess }}"
            [checked]="types.includes(TypeOfProcessEnum.RELAIS_HOSPITALISATION_TypeOfProcess)"
            >Module relais en cas d'hospitalisation</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeTypeCourrier($event)"
            value="{{ TypeOfProcessEnum.PREMIERE_DEMANDE_CMI_TypeOfProcess }}"
            [checked]="types.includes(TypeOfProcessEnum.PREMIERE_DEMANDE_CMI_TypeOfProcess)"
            >Demande de CMI</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeTypeCourrier($event)"
            value="{{ TypeOfProcessEnum.RENOUVELLEMENT_CMI_TypeOfProcess }}"
            [checked]="types.includes(TypeOfProcessEnum.RENOUVELLEMENT_CMI_TypeOfProcess)"
            >Renouvellement de CMI</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeTypeCourrier($event)"
            value="{{ TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess }}"
            [checked]="types.includes(TypeOfProcessEnum.PIECES_COMPL_TypeOfProcess)"
            >Pièces complémentaires</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeTypeCourrier($event)"
            value="{{ TypeOfProcessEnum.UNKNOWN }}"
            [checked]="types.includes(TypeOfProcessEnum.UNKNOWN)"
            >Non-renseigné</cnsa-checkbox
          >
        </div>
      </div>

      <div class="div-filtres-select">
        <h3 class="sub-title-filtres">Référent administratif</h3>
        <cnsa-button (click)="onClickSelect()" variant="secondary">Sélectionner</cnsa-button>
      </div>

      <div class="div-filtres-date">
        <!-- <cnsa-radio-group label="Date de complétude du dossier" horizontal> -->
        <!-- <cnsa-radio value="1" size="small" formControlName="periode" name="date" (onclick)="time('p')">Période</cnsa-radio> -->
        <!-- <cnsa-radio value="2" size="small" formControlName="precise" name="date" (onclick)="time('d')">Date précise</cnsa-radio> -->
        <!-- </cnsa-radio-group> -->
        <h3 class="sub-title-filtres">Date d'import</h3>
        <div class="div-filtres-date-radios">
          <input
            type="radio"
            value="periode"
            [checked]="dateForm === 'periode'"
            (change)="changeDateForm($event)"
            name="periode"
            class="circle" /><label>Période</label>
          <input
            type="radio"
            value="precise"
            [checked]="dateForm === 'precise'"
            (change)="changeDateForm($event)"
            name="precise"
            class="circle" /><label>Date précise</label>
        </div>

        <ng-container *ngIf="dateForm === 'periode'; else preciseBlock">
          <div class="div-filtres-date-2inputs">
            <div class="div-filtres-date-inputs">
              <label class="sub-title-filtres-date">Date de début</label>
              <cnsa-input
                type="date"
                [(ngModel)]="filterCourriers.mailMinImportDate"
                ngDefaultControl
                (change)="changeDateForm($event)"
                help-text="Format attendu: JJ/MM/AAAA" />
            </div>

            <div class="div-filtres-date-inputs">
              <label class="sub-title-filtres-date">Date de fin</label>
              <cnsa-input
                type="date"
                [(ngModel)]="filterCourriers.mailMaxImportDate"
                ngDefaultControl
                (change)="changeDateForm($event)"
                help-text="Format attendu: JJ/MM/AAAA" />
            </div>
          </div>
        </ng-container>
        <ng-template #preciseBlock>
          <div class="div-filtres-date-inputs">
            <label class="sub-title-filtres-date">Date</label>
            <cnsa-input
              type="date"
              [(ngModel)]="filterCourriers.mailExactImportDate"
              ngDefaultControl
              help-text="Format attendu: JJ/MM/AAAA" />
          </div>
        </ng-template>
      </div>
    </div>

    <cnsa-button slot="footer" variant="navigation" (click)="resetFiltersCourriers()"
      >Réinitialiser les filtres</cnsa-button
    >
    <cnsa-button slot="footer" (click)="filtrerCourrier()">Filtrer</cnsa-button>
  </ng-container>
</cnsa-dialog>

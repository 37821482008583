/**
 * Profils disponibles pour les groupes APA.
 */
export enum ApaUserGroupProfile {
  ADMINISTRATIVE = 'ADMINISTRATIVE',
  EVALUATION = 'EVALUATION',
  EMS = 'EMS',
  EFFECTIVENESS_AND_PAYMENT = 'EFFECTIVENESS_AND_PAYMENT',
}

/**
 * Liste des profils de groupe avec leurs étiquettes et valeurs énumérées correspondantes.
 */
export const GroupProfileList = {
  administratif: { label: 'Administratif', enum: ApaUserGroupProfile.ADMINISTRATIVE },
  evaluation: { label: 'Evaluation', enum: ApaUserGroupProfile.EVALUATION },
  ems: { label: 'EMS', enum: ApaUserGroupProfile.EMS },
  effectiveness_and_payment: { label: 'Effectivité et paiement', enum: ApaUserGroupProfile.EFFECTIVENESS_AND_PAYMENT },
};

/**
 * Obtient l'étiquette du profil de groupe en fonction de la valeur énumérée.
 *
 * @param {ApaUserGroupProfile | null} profileEnum - La valeur énumérée du profil de groupe.
 * @returns {string} L'étiquette du profil de groupe correspondant.
 */
export function getGroupProfileLabel(profileEnum: ApaUserGroupProfile | null): string {
  const groupProfilList = GroupProfileList;
  let label = '';

  if (groupProfilList.administratif.enum === profileEnum) {
    label = groupProfilList.administratif.label;
  } else if (groupProfilList.effectiveness_and_payment.enum === profileEnum) {
    label = groupProfilList.effectiveness_and_payment.label;
  } else if (groupProfilList.ems.enum === profileEnum) {
    label = groupProfilList.ems.label;
  } else {
    label = groupProfilList.evaluation.label;
  }
  return label;
}

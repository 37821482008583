import { Observable } from 'rxjs';
import { EnumItemDto } from '../../shared/models/enum-item.dto';
import { StructureDto } from '../../shared/models/structure.dto';
import { DepartmentsDto } from '../../shared/models/departmentsDto';
import { CountryDto } from '../../shared/models/countries.dto';
import { Localities } from '../../shared/models/localities';

/**
 * Classe abstraite fournissant des méthodes pour traiter les demandes.
 */
export abstract class RequestReferentielData {
  /**
   * Retourne la liste de tous les EnumItem du référentiels socle
   *
   * @abstract
   * @method getAllEnumItemSocle
   * @returns {Observable<EnumItemDto[]>} Un observable émettant la liste des EnumItem référentiels socle
   */

  abstract getAllEnumItemSocle(): Observable<EnumItemDto[] | undefined>;

  /**
   * Récupère la structure du référentiel socle pour un code donné.
   * @param {string} code Le code du référentiel socle.
   * @returns {Observable<StructureDto>} Un Observable qui émet la structure du référentiel socle, ou undefined si le référentiel socle n'existe pas.
   *
   */
  abstract getStructureSocleByCode(code: string): Observable<StructureDto> | undefined;

  /**
   * Récupère tous les départments du référentiel socle .
   * vérifie dans {@link LocalStorageService} l'existance de la clef {@link DEPARTMENT_STORAGE}
   * si existance retourne la liste des départements , sinon lance request
   * et stocke dans LocalStorageService
   * @returns {Observable<DepartmentsDto>} Un Observable qui renvoie un DepartmentsDto du référentiel socle, ou undefined si le référentiel socle n'existe pas.
   *
   */
  abstract getALLDepartments(): Observable<DepartmentsDto[] | undefined>;

  /**
   * Récupère le départment du référentiel socle pour un code donné.
   * @param {string} inseeCode Le code insee du departement.
   * @returns {Observable<DepartmentsDto>} Un Observable qui émet la structure du référentiel socle, ou undefined si le référentiel socle n'existe pas.
   *
   */
  abstract getDepartmentByInseeCode(inseeCode: string): Observable<DepartmentsDto | undefined>;

  /**
   * Récupère tous les pays du référentiel socle .
   * vérifie dans {@link LocalStorageService} l'existance de la clef {@link COUNTRY_STORAGE}
   * si existance retourne la liste sinon lance request
   * et stocke dans LocalStorageService
   * @returns {Observable<CountryDto>} Un Observable qui renvoie la liste des pays du référentiel socle, ou undefined si le référentiel socle n'existe pas.
   *
   */
  abstract getALLCountries(): Observable<CountryDto[] | undefined>;

  /**
   * Récupère le pay du référentiel socle pour un code donné.
   * @param {string} iso3Code Le code du pays référentiel socle.
   * @returns {Observable<CountryDto>} Un Observable qui émet le pay du référentiel socle, ou undefined si le référentiel socle n'existe pas.
   *
   */
  abstract getCountryByIsoCode(iso3Code: string): Observable<CountryDto | undefined>;

  /**
   * Récupère la commune du référentiel socle pour le code le code insee donée
   * @param {string} inseeCode Le code insee de la commune.
   * @returns {Observable<Localities>} Un Observable qui émet la commune, ou undefined si la commune socle n'existe pas.
   *
   */
  abstract getLocalitiesByInseeCode(inseeCode: string): Observable<Localities | undefined>;
}

import { Pipe, PipeTransform } from '@angular/core';
import { AppointmentDto } from '../../pages/rendez-vous/models/appointment.dto';

/**
 * Pipe personnalisée pour filtrer une liste de rendez-vous en fonction de la valeur de filtrage fournie.
 */
@Pipe({
  name: 'forfaitApaEnUrgenceSearch',
})
export class ForfaitApaEnUrgenceSearchPipe implements PipeTransform {
  /**
   * Ajouter un nombre de jours à une date donnée et renvoie la nouvelle date sous forme de chaîne de caractères au format 'yyyy-MM-dd'.
   *
   * @param {string} date La date d'origine au format 'yyyy-MM-dd'.
   * @param {number} days Le nombre de jours à ajouter.
   * @returns {string} Une nouvelle date sous forme de chaîne de caractères au format 'yyyy-MM-dd' après l'ajout des jours spécifiés.
   */
  private addDaysToDate(date: string, days: number): string {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return date;
    }

    const result = new Date(parsedDate);
    result.setDate(result.getDate() + days);
    return result.toISOString().substring(0, 10);
  }

  /**
   * Filtrer une liste de rendez-vous en fonction de la valeur de filtrage fournie.
   *
   * @param {AppointmentDto[]} rendezvous La liste de rendez-vous à filtrer.
   * @param {string} filterValue La valeur de filtrage utilisée pour sélectionner les rendez-vous.
   * @returns {AppointmentDto[]} Une nouvelle liste de rendez-vous filtrée en fonction de la valeur de filtrage.
   */
  transform(rendezvous: AppointmentDto[], filterValue: string): AppointmentDto[] {
    if (!rendezvous || !filterValue) {
      return rendezvous; // Retourne la liste non filtrée si la liste ou la valeur de filtrage est vide
    }

    filterValue = filterValue.toLowerCase(); // Convertit la valeur de filtrage en minuscules

    return rendezvous.filter((rdv: AppointmentDto) => {
      const lastName = rdv.requesterSituation.requesterIdentity.lastName.toLowerCase().includes(filterValue);
      const fistName = rdv.requesterSituation.requesterIdentity.firstName.toLowerCase().includes(filterValue);
      const nir = rdv.requesterSituation.requesterIdentity.nir
        ? rdv.requesterSituation.requesterIdentity.nir.toLowerCase().includes(filterValue)
        : null;
      const requestType = rdv.apaRequestType.toLowerCase().includes(filterValue);
      const placeName = rdv.requesterSituation.requesterCoordinates.currentResidenceAddress.placeName
        .toLowerCase()
        .includes(filterValue);
      const zipCode = rdv.requesterSituation.requesterCoordinates.currentResidenceAddress.zipCode
        .toLowerCase()
        .includes(filterValue);
      const dateComplet = this.addDaysToDate(rdv.apaRequestCompletionDate, 30).toLowerCase().includes(filterValue);
      const administrativeFirstName = rdv.requesterSituation.requesterIdentity.administrativeReferent?.firstName
        .toLowerCase()
        .includes(filterValue);
      const administrativelastName = rdv.requesterSituation.requesterIdentity.administrativeReferent?.lastName
        .toLowerCase()
        .includes(filterValue);
      const evaluatorFirstName = rdv.evaluator.firstName.toLowerCase().includes(filterValue);
      const evaluatorLastName = rdv.evaluator.lastName.toLowerCase().includes(filterValue);

      return (
        lastName ||
        fistName ||
        nir ||
        requestType ||
        placeName ||
        zipCode ||
        dateComplet ||
        administrativeFirstName ||
        administrativelastName ||
        evaluatorFirstName ||
        evaluatorLastName
      );
    });
  }
}

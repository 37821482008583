import { Component, ElementRef, ViewChild } from '@angular/core';
import { SearchService } from '../../mock/search.service';

/**
 * Composante principale : barre de recherche
 */
@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css'],
})
export class SearchBarComponent {
  /**
   * Référence vers l'élément input de recherche
   */
  @ViewChild('searchInput', { static: true }) searchInputRef!: ElementRef<HTMLInputElement>;

  /**
   * Chaîne de requête
   */
  searchQuery = '';

  /**
   * Liste des résultats de recherche
   */
  searchResults: string[] = [];

  constructor(private searchService: SearchService) {}

  /**
   * Fonction qui gère le changement de valeur de l'input de recherche
   * Si la valeur est vide, on vide la liste des résultats
   * Sinon, on fait une recherche
   *
   * @param value Valeur de l'input de recherche
   */
  onInputChange(value: string): void {
    this.searchQuery = value;
    const query = this.searchQuery.trim();

    if (query !== '') {
      this.searchService.search(query).subscribe((results) => {
        this.searchResults = results;
      });
    } else {
      this.searchResults = [];
    }
  }
}

import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

/**
 * Composant principale: login de l'utilisateur
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  /**
   * Constructeur du composant
   * @param fb Injection du FormBuilder
   */
  constructor(private fb: FormBuilder) {}

  /**
   * Formulaire de login de l'utilisateur
   */
  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });
}

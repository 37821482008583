import { Injectable, isDevMode } from '@angular/core';
import { ApaAgentDto } from '../../pages/demande/models/apa-agent.dto';
import { Observable, of, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApaUserProfileEnum } from '../enums/apa-user-profile.enum';
import { environment as envDev } from '../../../../environments/environment';
import { environment as envProd } from '../../../../environments/environment.prod';
import { searchApaAgentData } from '../data/search-apa-agent.data';

/**
 * Service qui permet de rechercher des agents APA.
 */
@Injectable({
  providedIn: 'root',
})
/**
 * Service pour la recherche des agents APA
 */
export class SearchApaAgentService extends searchApaAgentData {
  /**
   * L'URL de l'API pour les agents APA.
   */
  private endPoint;

  /**
   * L'URL de l'API REST interne SIAPA IAM pour les utilisateurs APA.
   */
  private siapaIamInterRestApi: string;

  /**
   * La liste des évaluateurs APA.
   */
  private apaEvaluators: ApaAgentDto[] = [];

  /**
   * La liste des référents APA.
   */
  private apaReferents: ApaAgentDto[] = [];

  constructor(private httpClient: HttpClient) {
    super();
    if (isDevMode()) {
      this.endPoint = envDev.endPointApaUsers;
      this.siapaIamInterRestApi = envDev.siapaIamInterRestApi + this.endPoint;
    } else {
      this.endPoint = envProd.endPointApaUsers;
      this.siapaIamInterRestApi = envProd.siapaIamInterRestApi + this.endPoint;
    }
  }

  /**
   * Recherche la liste des agents.
   *
   * @param {string} profile Le profil des agents recherchés.
   * @returns {Observable<ApaAgentDto[]>} La liste des agents s'ils existent sinon une liste vide est retournée.
   */
  searchApaAgents(profile: string): Observable<ApaAgentDto[]> {
    switch (profile) {
      case ApaUserProfileEnum.ADMINISTRATIVE:
        if (this.apaReferents.length === 0) {
          return this.httpClient
            .get(this.siapaIamInterRestApi, { params: { userProfile: ApaUserProfileEnum.ADMINISTRATIVE } })
            .pipe(
              map((data: any) => {
                this.apaReferents = data._embedded.apaUserDtoList;
                return this.apaReferents;
              })
            );
        } else {
          return of(this.apaReferents);
        }

      case ApaUserProfileEnum.EVALUATION:
        if (this.apaEvaluators.length === 0) {
          return this.httpClient
            .get(this.siapaIamInterRestApi, { params: { userProfile: ApaUserProfileEnum.EVALUATION } })
            .pipe(
              map((data: any) => {
                this.apaEvaluators = data._embedded.apaUserDtoList;
                return this.apaEvaluators;
              })
            );
        } else {
          return of(this.apaEvaluators);
        }

      default:
        return of([]);
    }
  }

  /**
   * Renvoie la liste des référents.
   *
   * @param {string[]} ids Les identifiants des référents.
   * @returns {Observable<ApaAgentDto[]>} La liste des référents.
   */
  getListVisibleReferents(ids: string[]): Observable<ApaAgentDto[]> {
    return this.searchApaAgents(ApaUserProfileEnum.ADMINISTRATIVE).pipe(
      map((apaReferents: ApaAgentDto[]) => {
        if (ids.length > 0) {
          return apaReferents.filter((referent) => ids.includes(referent.userId!));
        }
        return apaReferents;
      })
    );
  }

  /**
   * Renvoie la liste des évaluateurs visibles.
   *
   * @param {string[]} ids Les identifiants des évaluateurs.
   * @returns {Observable<ApaAgentDto[]>} La liste des évaluateurs.
   */
  getListVisibleEvaluators(ids: string[]): Observable<ApaAgentDto[]> {
    return this.searchApaAgents(ApaUserProfileEnum.EVALUATION).pipe(
      map((apaEvaluators: ApaAgentDto[]) => {
        if (ids.length > 0) {
          return apaEvaluators.filter((evaluator) => ids.includes(evaluator.userId!));
        }
        return apaEvaluators;
      })
    );
  }
}

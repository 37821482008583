/**
 * Énumération des types de demande APA.
 */
// TODO #referentiel à supprimer quand debouchonnage referentiel fini -> nouveaux fichier referentiel-requests.enum.ts
export enum ApaRequestType {
  /**
   * 1ère Demande APA
   */
  FIRST_REQUEST = 'FIRST_REQUEST',
  /**
   * Nouvelle demande APA
   */
  NEW_REQUEST = 'NEW_REQUEST',
  /**
   * Révision à la demande de la personne
   */
  REQUEST_REVISION = 'REQUEST_REVISION',
  /**
   * Renouvellement CMI
   */
  CMI_RENEWAL_REQUEST = 'CMI_RENEWAL_REQUEST',
  /**
   * Pièces complémentaires
   */
  ADDITIONAL_DOCUMENTS_REQUEST = 'ADDITIONAL_DOCUMENTS_REQUEST',
  /**
   * Demande de CMI
   */
  CMI_REQUEST = 'CMI_REQUEST',
  /**
   * Module relais en cas d'hospitalisation
   */
  HOSPITALIZATION_MODULE_REQUEST = 'HOSPITALIZATION_MODULE_REQUEST',
}

/**
 * Traductions des types de demande de l'énumération `ApaRequestType`.
 */
export const ApaRequestTypeTranslation: { [key in ApaRequestType]: string } = {
  [ApaRequestType.FIRST_REQUEST]: '1ère demande APA',
  [ApaRequestType.NEW_REQUEST]: 'Nouvelle demande APA',
  [ApaRequestType.ADDITIONAL_DOCUMENTS_REQUEST]: 'Pièces complémentaires',
  [ApaRequestType.REQUEST_REVISION]: 'Révision à la demande de la personne',
  [ApaRequestType.HOSPITALIZATION_MODULE_REQUEST]: "Module relais en cas d'hospitalisation",
  [ApaRequestType.CMI_REQUEST]: 'demande CMI',
  [ApaRequestType.CMI_RENEWAL_REQUEST]: 'renouvellement CMI',
};

import { Pipe, PipeTransform } from '@angular/core';
import { IncomingMailDto } from '../../pages/corbeille/models/incoming-mail.dto';
import { ReferentielData } from '../data/referentiel-data';

/**
 * Pipe personnalisée pour filtrer une liste d'e-mails reçus en fonction d'une valeur de filtre.
 */
@Pipe({
  name: 'courriersEntrantsSearch',
})
export class CourriersEntrantsSearchPipe implements PipeTransform {
  constructor(private referentielService: ReferentielData) {}
  /**
   * Filtrer une liste d'e-mails reçus en fonction de la valeur de filtrage fournie.
   *
   * @param {IncomingMailDto[]} mailList La liste d'e-mails reçus à filtrer.
   * @param {string} filterValue La valeur de filtrage utilisée pour sélectionner les e-mails.
   * @returns {IncomingMailDto[]} Une nouvelle liste d'e-mails reçus filtrée en fonction de la valeur de filtrage.
   */
  transform(mailList: IncomingMailDto[], filterValue: string): IncomingMailDto[] {
    if (!mailList || !filterValue) {
      return mailList; // Retourne la liste non filtrée si la liste ou la valeur de filtrage est vide
    }

    filterValue = filterValue.toLowerCase(); // Convertit la valeur de filtrage en minuscules

    return mailList.filter((mail: IncomingMailDto) => {
      const mailName = (mail.mailName ?? '').toLowerCase().includes(filterValue);
      const mailOrigin = (mail.mailOriginCode ?? '').toLowerCase().includes(filterValue);
      const administrativeFirstName = (mail.mailApaPerson.administrativeReferent?.firstName ?? '')
        .toLowerCase()
        .includes(filterValue);
      const administrativelastName = (mail.mailApaPerson.administrativeReferent?.lastName ?? '')
        .toLowerCase()
        .includes(filterValue);
      const mailImportDate = (mail.originCreationDate ?? '').toLowerCase().includes(filterValue);
      const mailType =
        (this.referentielService.mapEnumItemCodeToItemLabel(mail.mailTypeCode) ?? '').toLowerCase().includes(filterValue);

      return mailName || mailOrigin || mailType || administrativeFirstName || administrativelastName || mailImportDate;
    });
  }
}

export enum ReferentielRequestsEnum {
  TYPE_OF_PROCESS = 'TypeOfProcess',
  INPUT_CHANNEL = 'InputChannel',
  PROCESSING_TYPE = 'ProcessingType',
  CRITICALITY_REASON = 'CriticalityReason',
}

// TODO #referentiel - Old Enum: ApaRequestType
export enum TypeOfProcessEnum {
  /**
   *   Première demande APA à domicile
   */
  PREMIERE_DEMANDE_APA_TypeOfProcess = 'PREMIERE_DEMANDE_APA-TypeOfProcess',
  /**
   *   Nouvelle demande APA à domicile
   */
  NOUVELLE_DEMANDE_APA_TypeOfProcess = 'NOUVELLE_DEMANDE_APA-TypeOfProcess',
  /**
   *   Révision à la demande de la personne
   */
  REVISION_PERSONNE_TypeOfProcess = 'REVISION_PERSONNE-TypeOfProcess',
  /**
   *   Module relais en cas d'hospitalisation
   */
  RELAIS_HOSPITALISATION_TypeOfProcess = 'RELAIS_HOSPITALISATION-TypeOfProcess',
  /**
   *   Demande CMI
   */
  PREMIERE_DEMANDE_CMI_TypeOfProcess = 'PREMIERE_DEMANDE_CMI-TypeOfProcess',
  /**
   *   Renouvellement CMI
   */
  RENOUVELLEMENT_CMI_TypeOfProcess = 'RENOUVELLEMENT_CMI-TypeOfProcess',
  /**
   *   Pièces complémentaires
   */
  PIECES_COMPL_TypeOfProcess = 'PIECES_COMPL-TypeOfProcess',
  /**
   *   Il n'existe pas dand le référentiel mais il est utilisé coté front
   */
  UNKNOWN = 'UNKNOWN',
}

export enum TypeOfDemandEnum {
  /**
   *   APA à domicile
   */
  APA_D_TypeOfDemand = 'APA_D-TypeOfDemand',
  /**
   *   Relais hospitalisation
   */
  RELAIS_HOSPIT_TypeOfDemand = 'RELAIS_HOSPIT-TypeOfDemand',
  /**
   *   CMI
   */
  CMI_TypeOfDemand = 'CMI-TypeOfDemand',
}

export enum NatureOfDemandEnum {
  /**
   *   Première demande
   */
  PREMIERE_NatureOfDemand = 'PREMIERE-NatureOfDemand',
  /**
   *   Nouvelle demande
   */
  NOUVELLE_NatureOfDemand = 'NOUVELLE-NatureOfDemand',
  /**
   *   Révision
   */
  REVISION_NatureOfDemand = 'REVISION-NatureOfDemand',
  /**
   *   Renouvellement
   */
  RENOUVELLEMENT_NatureOfDemand = 'RENOUVELLEMENT-NatureOfDemand',
}

// TODO #referentiel - Old Enum: CriticalityReasonEnum
export enum CriticalityReasonEnum {
  /**
   *   Sortie d'hospitalisation
   */
  HOSPITALISATION_CriticalityReason = 'HOSPITALISATION-CriticalityReason',
  /**
   *   Dégradation de l'état de santé ou de l'autonomie
   */
  DEGRADATION_CriticalityReason = 'DEGRADATION-CriticalityReason',
  /**
   *   Soins palliatifs
   */
  PALIATIFS_CriticalityReason = 'PALIATIFS-CriticalityReason',
  /**
   *   Rupture brutale d'aidant ou entourage (décès…)
   */
  RUPTURE_CriticalityReason = 'RUPTURE-CriticalityReason',
  /**
   *   Absence d'aidant/isolement social
   */
  ISOLEMENT_CriticalityReason = 'ISOLEMENT-CriticalityReason',
  /**
   *   Besoin alimentaire
   */
  ALIMENTAIRE_CriticalityReason = 'ALIMENTAIRE-CriticalityReason',
  /**
   *   Mise en danger de la personne
   */
  DANGER_CriticalityReason = 'DANGER-CriticalityReason',
  /**
   *   Autre
   */
  AUTRE_CriticalityReason = 'AUTRE-CriticalityReason',
}

/**
 * Énumération représentant différents types de traitements spéciaux des demandes APA.
 */
// TODO #referentiel - Old Enum: SpecialTreatmentTypeEnum
export enum ProcessingTypeEnum {
  /**
   *   Forfait APA en urgence
   */
  APA_URGENCE_ProcessingType = 'APA_URGENCE-ProcessingType',
  /**
   *   Procédure accélérée
   */
  PROCEDURE_ACCELEREE_ProcessingType = 'PROCEDURE_ACCELEREE-ProcessingType',
  // TODO #referentiel à supprimer quand debouchonnage referentiel fini
  NO = 'NO',
}

// TODO #referentiel - Old Enum: ApaRequestStatusValueEnum
export enum DemandStatusEnum {
  /**
   *   Déposée
   */
  DEPOSEE_DemandStatus = 'DEPOSEE-DemandStatus',
  /**
   *   Complète
   */
  COMPLETE_DemandStatus = 'COMPLETE-DemandStatus',
  /**
   *   En attente de complétude
   */
  INCOMPLETE_DemandStatus = 'INCOMPLETE-DemandStatus',
  /**
   *   A rejeter administrativement
   */
  REJETEE_DemandStatus = 'REJETEE-DemandStatus',
  /**
   *   En cours d'évaluation
   */
  EVALUATION_EN_COURS_DemandStatus = 'EVALUATION_EN_COURS-DemandStatus',
  /**
   *   Evaluée
   */
  EVALUEE_DemandStatus = 'EVALUEE-DemandStatus',
  /**
   *   Décidée
   */
  DECIDEE_DemandStatus = 'DECIDEE-DemandStatus',
  /**
   *   Clôturée
   */
  CLOTUREE_DemandStatus = 'CLOTUREE-DemandStatus',
}

// TODO #referentiel - Old Enum: SignatoryEnum
export enum SignatoryTypeEnum {
  /**
   *   Demandeur
   */
  DEMANDEUR_SignatoryType = 'DEMANDEUR-SignatoryType',
  /**
   *   Représentant légal
   */
  REPRESENTANT_LEGAL_SignatoryType = 'REPRESENTANT_LEGAL-SignatoryType',
}

// TODO #referentiel - Old Enum: IncurProtectionMeasureEnum
export enum AnswerFormEnum {
  /**
   *   Oui, la mesure a été prononcée
   */
  OUI_MESURE_PRONONCEE_AnswerForm = 'OUI_MESURE_PRONONCEE-AnswerForm',
  /**
   *   Non, mais une demande est en cours
   */
  NON_DEMANDE_EN_COURS_AnswerForm = 'NON_DEMANDE_EN_COURS-AnswerForm',
  /**
   *   Oui
   */
  OUI_AnswerForm = 'OUI-AnswerForm',
  /**
   *   Non
   */
  NON_AnswerForm = 'NON-AnswerForm',
  /**
   *   Non renseigné
   */
  NON_RENSEIGNE_AnswerForm = 'NON_RENSEIGNE-AnswerForm',
}

// TODO #referentiel - New Enum
export enum QuestionFormEnum {
  /**
   *   Le demandeur fait-il l'objet d'un mesure de protection ?
   */
  MESURE_PROTECTION_DEMANDEUR_QuestionForm = 'MESURE_PROTECTION_DEMANDEUR-QuestionForm',
  /**
   *   Le demandeur a-t-il émis le souhait d'une demande ou renouvellement de CMI ?
   */
  DEMANDE_RENOUVELLEMENT_CMI_EMIS_QuestionForm = 'DEMANDE_RENOUVELLEMENT_CMI_EMIS-QuestionForm',
  /**
   *   La signature est-elle présente ?
   */
  PRESENCE_SIGNATURE_QuestionForm = 'PRESENCE_SIGNATURE-QuestionForm',
  /**
   *   S'agit-il d'une demande de renouvellement ou remplacement d'une carte européenne de stationnement ?
   */
  DEMANDE_RENOUVELLEMENT_STATIONNEMENT_QuestionForm = 'DEMANDE_RENOUVELLEMENT_STATIONNEMENT-QuestionForm',
  /**
   *   Souhaitez-vous rejeter la demande module relais en cas d'hospitalisation ?
   */
  REJET_MODULE_RELAIS_HOSPITALISATION_QuestionForm = 'REJET_MODULE_RELAIS_HOSPITALISATION-QuestionForm',
}

export enum FormSectionEnum {
  /**
   *  Situation du demandeur
   */
  SITUATION_DEMANDEUR_FormSection = 'SITUATION_DEMANDEUR-FormSection',
  /**
   *   Coordonnées
   */
  COORDONNEES_FormSection = 'COORDONNEES-FormSection',
  /**
   *   Situation familiale
   */
  SITUATION_FAMILIALE_FormSection = 'SITUATION_FAMILIALE-FormSection',
  /**
   *  Mesure de protection du demandeur
   */
  MESURE_PROTECTION_FormSection = 'MESURE_PROTECTION-FormSection',
  /**
   *   Personne proche à contacter
   */
  PROCHE_A_CONTACTER_FormSection = 'PROCHE_A_CONTACTER-FormSection',
  /**
   *   Identification de l'organisme compétent
   */
  ORGANISME_COMPETENT_FormSection = 'ORGANISME_COMPETENT-FormSection',
  /**
   *   Autres prestations de soutien à l'autonomie
   */
  AUTRES_PRESTATIONS_SOUTIEN_FormSection = 'AUTRES_PRESTATIONS_SOUTIEN-FormSection',
  /**
   *   Ressources et Patrimoine du demandeur et de son conjoint (marié, pacsé, concubin)
   */
  RESSOURCES_ET_PATRIMOINE_FormSection = 'RESSOURCES_ET_PATRIMOINE-FormSection',
  /**
   *  Bien(s) immobilier(s)
   */
  BIENS_IMMOBILIERS_FormSection = 'BIENS_IMMOBILIERS-FormSection',
  /**
   *   Bien(s) mobilier(s)
   */
  BIENS_MOBILIERS_FormSection = 'BIENS_MOBILIERS-FormSection',
  /**
   *   Relevé d'identité bancaire
   */
  RIB_FormSection = 'RIB-FormSection',
  /**
   *   Carte mobilité inclusion
   */
  CMI_FormSection = 'CMI_FormSection',
  /**
   *   Signature
   */
  SIGNATURE_FormSection = 'SIGNATURE-FormSection',
  /**
   *   Hospitalisation
   */
  HOSPITALISATION_FormSection = 'HOSPITALISATION-FormSection',
}

// TODO #referentiel - Old Enum: HospitalisationTypeEnum
export enum TypeOfHospitalizationEnum {
  /**
   *   Programmée
   */
  PROGRAMMEE_TypeOfHospitalization = 'PROGRAMMEE-TypeOfHospitalization',
  /**
   *   Non programmée
   */
  NON_PROGRAMMEE_TypeOfHospitalization = 'NON_PROGRAMMEE-TypeOfHospitalization',
}

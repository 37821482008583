/**
 * Enumération représentant le type d'un courrier entrant.
 */
// TODO #referentiel à supprimer quand debouchonnage referentiel fini -> nouveaux fichier referentiel-requests.enum.ts

export enum IncomingMailTypeEnum {
  /**
   * Première demande.
   */
  FIRST_REQUEST = 'FIRST_REQUEST',
  /**
   * Nouvelle demande.
   */
  NEW_REQUEST = 'NEW_REQUEST',
  /**
   * Demande de révision.
   */
  REQUEST_REVISION = 'REQUEST_REVISION',
  /**
   * Demande de module hospitalisation.
   */
  HOSPITALIZATION_MODULE_REQUEST = 'HOSPITALIZATION_MODULE_REQUEST',
  /**
   * Demande de CMI.
   */
  CMI_REQUEST = 'CMI_REQUEST',
  /**
   * Demande de renouvellement CMI.
   */
  CMI_RENEWAL_REQUEST = 'CMI_RENEWAL_REQUEST',
  /**
   * Demande de pièces complémentaires.
   */
  ADDITIONAL_DOCUMENTS_REQUEST = 'ADDITIONAL_DOCUMENTS_REQUEST',
  /**
   * Type de demande non renseigné
   */
  UNKNOWN = 'UNKNOWN',
}

/**
 * Traductions pour l'énumération IncomingMailTypeEnum.
 */
export const IncomingMailTypeEnumTranslation: { [key in IncomingMailTypeEnum]: string } = {
  [IncomingMailTypeEnum.FIRST_REQUEST]: '1ère demande APA',
  [IncomingMailTypeEnum.NEW_REQUEST]: 'Nouvelle demande APA',
  [IncomingMailTypeEnum.REQUEST_REVISION]: 'Révision à la demande de la personne',
  [IncomingMailTypeEnum.HOSPITALIZATION_MODULE_REQUEST]: "Module relais en cas d'hospitalisation",
  [IncomingMailTypeEnum.CMI_REQUEST]: 'Demande de CMI',
  [IncomingMailTypeEnum.CMI_RENEWAL_REQUEST]: 'Renouvellement de CMI',
  [IncomingMailTypeEnum.ADDITIONAL_DOCUMENTS_REQUEST]: 'Pièces complémentaires',
  [IncomingMailTypeEnum.UNKNOWN]: 'Non-renseigné',
};

import { Pipe, PipeTransform } from '@angular/core';
import { ReferentielData } from '../data/referentiel-data';
import { PageContentDto } from '../../pages/demande/models/page-content.dto';

/**
 * Pipe personnalisée pour filtrer une liste de demandes APA en cours en fonction de la valeur de filtrage fournie.
 */
@Pipe({
  name: 'demandeEnCoursSearch',
})
export class DemandeEnCoursSearchPipe implements PipeTransform {
  constructor(private referentielService: ReferentielData) {}
  /**
   * Filtrer une liste de demandes APA en cours en fonction de la valeur de filtrage fournie.
   *
   * @param {PageContentDto[]} demandeEnCoursList La liste de demandes APA en cours à filtrer.
   * @param {string} filterValue La valeur de filtrage utilisée pour sélectionner les demandes APA.
   * @returns {PageContentDto[]} Une nouvelle liste de demandes APA filtrée en fonction de la valeur de filtrage.
   */
  transform(demandeEnCOursList: PageContentDto[], filterValue: string): PageContentDto[] {
    if (!demandeEnCOursList || !filterValue) {
      return demandeEnCOursList; // Retourne la liste non filtrée si la liste ou la valeur de filtrage est vide
    }

    filterValue = filterValue.toLowerCase(); // Convertit la valeur de filtrage en minuscules

    return demandeEnCOursList.filter((demande: PageContentDto) => {
      const requesterFirstName = demande.apaRequest.requesterSituation.personIdentity.firstName
        .toLowerCase()
        .includes(filterValue);
      const requesterLastName = demande.apaRequest.requesterSituation.personIdentity.lastName
        .toLowerCase()
        .includes(filterValue);
      const nir = demande.apaRequest.requesterSituation.personIdentity.nir
        ? demande.apaRequest.requesterSituation.personIdentity.nir.toLowerCase().includes(filterValue)
        : null;
      const initialisationDateTime = demande.apaRequest.initialisationDateTime
        ?.toString()
        .toLowerCase()
        .includes(filterValue);

      const requestType = (this.findLabelEnum(demande.apaRequest.requestType!) ?? '').toLowerCase().includes(filterValue);
      const requestStatusValue =
        (this.findLabelEnum(demande.apaRequest.requestStatus!.value) ?? '').toLowerCase().includes(filterValue);

      return (
        requestType || requestStatusValue || initialisationDateTime || requesterFirstName || requesterLastName || nir
      );
    });
  }

  findLabelEnum(codeEnum: string) {
    return this.referentielService.mapEnumItemCodeToItemLabel(codeEnum);
  }
}

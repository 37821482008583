import { Component, Input, OnInit, Output } from '@angular/core';
import { SearchGlobalService } from '../../../../shared/services/search-global.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrepareStatementCsvService } from '../../services/prepare-statement-csv.service';
import { GenerateCsvService } from 'src/app/modules/shared/services/generate-csv.service';
import { ApaAgentDto } from '../../../demande/models/apa-agent.dto';
import { ProfileTypeEnum } from '../../enums/profile-type.enum';
import { BannetteTypeEnum } from '../../enums/bannette-type.enum';
import { AssignedModaleTypeEnum } from '../../enums/assigned-modale-type.enum';
import { AgentTypeEnum } from '../../../../shared/enums/referentiel-person.enum';
import { ProcessingTypeEnum } from '../../../../shared/enums/referentiel-requests.enum';

/**
 * Composant responsable de la recherche de l'en-tête de la banette.
 * Affiche le champ de recherche, les filtres et les options d'import/export.
 */
@Component({
  selector: 'app-banette-header-recherche',
  templateUrl: './banette-header-recherche.component.html',
  styleUrls: ['./banette-header-recherche.component.css'],
})
export class BanetteHeaderRechercheComponent implements OnInit {
  protected readonly AssignedModaleTypeEnum = AssignedModaleTypeEnum;

  /** Le titre à afficher dans l'en-tête. */
  @Input() titre?: string;

  /** Le nombre d'éléments affichés dans la banette. */
  @Input() count?: number;
  /** Le nombre d'éléments affichés dans la banette  lorsque la rechercher */
  @Input() countSearch?: number;
  @Input() showCountSearch?: boolean = false;
  // /** Indique si l'option d'import est activée. */
  @Input() import?: boolean;

  /** Indique si l'option d'export est activée. */
  @Input() export?: boolean;

  /** Le type de la banette. */
  @Input() bannetteType?: BannetteTypeEnum;

  /** Les données affichées dans la banette. */
  @Input() data?: any[];
  isDialogOpen = false;

  // TODO #referentiel à supprimer ProfileTypeEnum quand debouchonnage referentiel fini et ou
  profilName?: ProfileTypeEnum | AgentTypeEnum;

  public selectedReferent: ApaAgentDto | undefined;
  public selectedEvaluator: ApaAgentDto | undefined;

  /** Liste des référents disponibles. */
  @Input() referents?: ApaAgentDto[];

  /** Liste des évaluateurs disponibles. */
  @Input() evaluateurs?: ApaAgentDto[];

  /** Indique si les filtres sont affichés. */
  public showFilters = false;

  /** Indique si le traitement d'urgence APA est activé. */
  public isEmergencyApaPlan = false;

  /** Indique si la procédure accélérée est activée. */
  public isAcceleratedProcedure = false;

  /** La valeur de recherche saisie par l'utilisateur. */
  public searchValue = '';

  /** Formulaire de recherche. */
  public searchForm!: FormGroup;

  /**
   * Le nombre de filtres sélectionnées.
   */
  public checkedCounter = 0;

  /**
   * Constructeur du composant.
   *
   * @param {FormBuilder} formBuilder Le constructeur de formulaire.
   * @param {SearchGlobalService} searchService Le service de recherche globale.
   * @param {PrepareStatementCsvService} prepareStatementCsvService Le service de préparation de fichier CSV.
   * @param {GenerateCsvService} generateCsvService Le service de génération de fichier CSV.
   */
  constructor(
    private formBuilder: FormBuilder,
    private searchService: SearchGlobalService,
    private prepareStatementCsvService: PrepareStatementCsvService,
    private generateCsvService: GenerateCsvService
  ) {}

  /**
   * Méthode pour ouvrir/fermer les filtres de recherche.
   */
  openDialog() {
    this.showFilters = !this.showFilters;
  }

  /**
   * Méthode pour fermer les filtres de recherche.
   */
  closeDialog(typifications?: string[]) {
    if (typifications) {
      this.isAcceleratedProcedure = typifications.includes(ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType);
      this.isEmergencyApaPlan = typifications.includes(ProcessingTypeEnum.APA_URGENCE_ProcessingType);
    }
    this.showFilters = false;
  }

  /**
   * Méthode d'initialisation du composant.
   * Crée le formulaire de recherche et écoute les changements de valeur.
   */
  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchValue: [''],
    });

    this.searchForm.valueChanges.subscribe((formValue) => {
      const searchValue = formValue.searchValue;
      this.searchService.setSearchValue(searchValue);
    });
  }
  /**
   * Méthode appelée lors du changement de l'état de la procédure accélérée.
   *
   * @param {Event} event L'événement de changement.
   */
  onAcceleratedProcedureChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    this.isAcceleratedProcedure = checkbox.checked;
  }

  /**
   * Méthode appelée lors du changement de l'état de la demande d'APA en urgence.
   *
   * @param {Event} event L'événement de changement.
   */
  onEmergencyApaPlanChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    this.isEmergencyApaPlan = checkbox.checked;
  }

  /**
   * Méthode pour générer un fichier CSV à partir des données affichées.
   */
  generateCsv() {
    let csvRowList: string[] = [];
    const fileNameWithoutEnding: string = `CNSA_bannette_` + this.bannetteType;
    let columns = '';
    if (this.data && this.bannetteType) {
      csvRowList = this.prepareStatementCsvService.createCsvRowList(this.data, this.bannetteType);
      columns = this.prepareStatementCsvService.createCsvHeader(this.bannetteType);
      this.generateCsvService.generateCsv(fileNameWithoutEnding, columns, csvRowList);
    }
  }
  updateCheckedCounter(value: number | boolean) {
    if (typeof value === 'boolean') {
      this.isEmergencyApaPlan = false;
      this.isAcceleratedProcedure = false;
    } else {
      this.checkedCounter = value;
    }
  }
  updateModale(value: boolean) {
    this.isDialogOpen = value;
  }
  closeAssignedPersonModale() {
    this.isDialogOpen = false;
  }
  // TODO #referentiel supprimer ProfileTypeEnum quand debouchonnage referentiel fini
  updateProfileName(event: ProfileTypeEnum | AgentTypeEnum) {
    this.profilName = event;
  }

  updateSelectedPerson(person: ApaAgentDto) {
    if (this.profilName === AgentTypeEnum.ADMINISTRATIF_AgentType) this.selectedReferent = person;
    if (this.profilName === AgentTypeEnum.EVALUATEUR_AgentType) this.selectedEvaluator = person;
  }
}

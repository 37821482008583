import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CNSADialog } from '@cnsa-fr/design-system';
import { ApaAgentDto } from '../../../demande/models/apa-agent.dto';
import { GestionBoiteData } from '../../../courrier/data/gestion-boite.data';
import { PAGE_SIZE } from '../../../../shared/utils/globalConstants';
import { OutgoingMailsPaginatedSearchQueryDto } from '../../../courrier/models/outgoing-mails-paginated-search-query.dto';
import { ProfileTypeEnum } from '../../enums/profile-type.enum';
import {
  MessageTypeEnum,
  TypeCourrierEnum,
  completeTypeCourrierSortant,
  imcompleteTypeCourrierSortant,
  rdvTypeCourrierSortant,
  rejetTypeCourrierSortant,
  relanceTypeCourrierSortant,
  visiteTypeCourrierSortant
} from '../../../../shared/enums/referentiel-communication.enum';

/**
 * Composant pour afficher les filtres de la boîte de messagerie et les utiliser pour filtrer les courriers sortants.
 */
@Component({
  selector: 'app-banette-filtres-boite',
  templateUrl: './banette-filtres-boite.component.html',
  styleUrls: ['./banette-filtres-boite.component.css'],
})
export class BanetteFiltresBoiteComponent {
  /**
   * Titre du composant (optionnel).
   */
  @Input() titre?: string;

  /**
   * Indicateur pour afficher ou masquer la boîte de dialogue.
   */
  @Input() showDialog!: boolean;

  @Input() selectedPerson: ApaAgentDto | undefined = undefined;

  /**
   * Liste des référents APA disponibles.
   */
  @Input() referents?: ApaAgentDto[];

  /**
   * Événement émis lorsque la boîte de dialogue est fermée.
   */
  @Output() closeDialog = new EventEmitter();

  /**
   * Événement émis lorsque le nombre de filtres sélectionnés.
   */
  @Output() checkedCounterChange = new EventEmitter<number>();

  @Output() updateModale = new EventEmitter<boolean>();
  @Output() updateProfilName = new EventEmitter<ProfileTypeEnum>();

  /**
   * Référence au dialogue (si utilisée dans le modèle).
   */
  @ViewChild('dialog') dialog?: ElementRef<CNSADialog>;
  @ViewChild('zipCodeListRef') zipCodeListRef?: ElementRef;
  @ViewChild('placeNameRef') placeNameRef?: ElementRef;

  /**
   * Filtre actuel pour les courriers sortants.
   */
  filter: OutgoingMailsPaginatedSearchQueryDto = {
    pageNumber: 0,
    pageSize: PAGE_SIZE,
    generationDate: undefined,
  };

  /**
   * Enumération des types de courriers sortants.
   */
  MessageTypeEnum = MessageTypeEnum;
  TypeCourrierEnum = TypeCourrierEnum;
  /**
   * Liste des villes disponibles pour le filtre.
   */
  villes: string[] = ['Paris', 'Lyon', 'Marseille', 'Toulouse', 'Bordeaux', 'Lille', 'Valence', 'ville'];

  /**
   * Liste des codes postaux disponibles pour le filtre.
   */
  codesPostal: string[] = ['75001', '8888', '7777', '9999', '1111', '0000', '3333', '2222'];

  /**
   * counter pour le nombre de filtres sélectionnés.
   */
  checkedCounter = 0;

  /**
   * Liste des  MessageTypes regroupés par typeCourrier pour le filtre.
   */
  completeTypeCourrierSortant = completeTypeCourrierSortant;
  imcompleteTypeCourrierSortant = imcompleteTypeCourrierSortant;
  relanceTypeCourrierSortant = relanceTypeCourrierSortant;
  rejetTypeCourrierSortant = rejetTypeCourrierSortant;
  rdvTypeCourrierSortant = rdvTypeCourrierSortant;
  visiteTypeCourrierSortant = visiteTypeCourrierSortant;

  /**
   * Crée une instance du composant BanetteFiltresBoiteComponent.
   * @param {GestionBoiteData} gestionBoiteData Le service pour gérer la boîte de messagerie.
   */
  constructor(private gestionBoiteData: GestionBoiteData) {}

  /**
   * Change le type de courrier sortant en fonction de l'événement fourni.
   *
   * @param {any} event L'événement déclenché par le changement de type de courrier.
   */
  changeMailType(event: Event) {
    if (!this.filter.mailTypeCodeList) {
      this.filter.mailTypeCodeList = [];
    }
    const target = event.target as HTMLInputElement;
    const selectedValues = target.value as unknown as MessageTypeEnum[];
    selectedValues.forEach((selectedValue) => {
      if (this.filter.mailTypeCodeList?.includes(selectedValue)) {
        this.filter.mailTypeCodeList = this.filter.mailTypeCodeList?.filter((type) => type !== selectedValue) || [];
      } else {
        this.filter.mailTypeCodeList?.push(selectedValue);
      }
    });
  }

  /**
   * Change le référent administratif en fonction de l'événement fourni.
   *
   * @param {any} event L'événement déclenché par le changement de référent administratif.
   */
  changeReferent(event: any) {
    this.filter.administrativeReferent = {
      agentId: event.target.value,
      departmentName: '',
      departmentNumber: '',
      firstName: '',
      lastName: '',
      userId: '',
      workEmailAddress: '',
      workPhoneNumber: '',
    };
  }

  /**
   * Filtre les courriers de la boîte de messagerie en fonction du filtre actuel.
   * Appelle le service gestionBoiteData pour récupérer les courriers filtrés.
   */

  filterBoite() {
    if (this.selectedPerson) {
      this.filter.administrativeReferent = this.selectedPerson;
    }
    this.gestionBoiteData.getMailsToSend(this.filter).subscribe();
    this.toggleDialog();

    // Calculer le compteur en fonction du nombre de filtres actifs
    let activeFiltersCount = 0;

    if (this.filter.mailTypeCodeList && this.filter.mailTypeCodeList.length > 0) {
      activeFiltersCount += this.filter.mailTypeCodeList.length;
    }

    if (this.filter.administrativeReferent && this.filter.administrativeReferent.agentId) {
      activeFiltersCount++;
    }

    if (this.filter.zipCodeList && this.filter.zipCodeList.length > 0) {
      activeFiltersCount++;
    }

    if (this.filter.placeNameList && this.filter.placeNameList.length > 0) {
      activeFiltersCount++;
    }

    if (this.filter.generationDate) {
      activeFiltersCount++;
    }

    this.checkedCounter = activeFiltersCount;
    this.checkedCounterChange.emit(this.checkedCounter);
  }

  /**
   * Réinitialise les filtres de la boîte de messagerie.
   * Appelle le service gestionBoiteData pour récupérer tous les courriers sans filtres.
   */
  resetFilters() {
    this.gestionBoiteData.getMailsToSend().subscribe();
    this.filter = {
      pageNumber: 0,
      pageSize: PAGE_SIZE,
    };

    if (this.zipCodeListRef) this.zipCodeListRef.nativeElement.value = '';

    if (this.placeNameRef) this.placeNameRef.nativeElement.value = '';
    this.checkedCounter = 0;
    this.selectedPerson = undefined;
    this.checkedCounterChange.emit(this.checkedCounter); // "counter"pour envoyer les informations au composant père avec le nombre de filtres sélectionnés.
  }

  /**
   * Bascule l'affichage de la boîte de dialogue.
   * Cache la boîte de dialogue en émettant un événement closeDialog.
   */
  toggleDialog() {
    this.showDialog = false;
    this.closeDialog.emit();
  }

  /**
   * Gère le changement de code postal.
   *
   * @param event L'événement associé au changement
   */
  changeZipCode(event: Event) {
    const selectedElements = event.target as HTMLSelectElement;
    if (!this.filter.zipCodeList) {
      this.filter.zipCodeList = [];
    }
    this.filter.zipCodeList = Array.from(selectedElements.selectedOptions).map((option) => option.value);
  }
  /**
   * Gère le changement de ville.
   *
   * @param event L'événement associé au changement
   */
  changePlaceName(event: Event) {
    const selectedElements = event.target as HTMLSelectElement;
    if (!this.filter.placeNameList) {
      this.filter.placeNameList = [];
    }
    this.filter.placeNameList = Array.from(selectedElements.selectedOptions).map((option) => option.value);
  }

  onClickSelect(): void {
    this.updateModale.emit(true);
    this.updateProfilName.emit(ProfileTypeEnum.REFERENT_ADMIN);
  }

  isChecked(type: string): boolean | undefined {
    switch (type) {
      case 'completeTypeCourrierSortant':
        return this.filter.mailTypeCodeList?.some((type) => this.completeTypeCourrierSortant.includes(type));
      case 'imcompleteTypeCourrierSortant':
        return this.filter.mailTypeCodeList?.some((type) => this.imcompleteTypeCourrierSortant.includes(type));
      case 'relanceTypeCourrierSortant':
        return this.filter.mailTypeCodeList?.some((type) => this.relanceTypeCourrierSortant.includes(type));
      case 'rejetTypeCourrierSortant':
        return this.filter.mailTypeCodeList?.some((type) => this.rejetTypeCourrierSortant.includes(type));
      case 'rdvTypeCourrierSortant':
        return this.filter.mailTypeCodeList?.some((type) => this.rdvTypeCourrierSortant.includes(type));
      case 'visiteTypeCourrierSortant':
        return this.filter.mailTypeCodeList?.some((type) => this.visiteTypeCourrierSortant.includes(type));
      default:
        return false;
    }
  }
}

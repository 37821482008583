<!-- TODO: Ajouter aria-sort= "ascending" ou "descending" sur les headers des colonnes lorsqu'elles sont triées -->
<div class="container">
  <cnsa-visually-hidden id="banette-helper"
    >, les entêtes de colonnes avec bouton peuvent être triés.</cnsa-visually-hidden
  >
  <table class="banette" aria-describedby="banette-title banette-helper">
    <thead class="banette__header">
      <tr>
        <th scope="col">
          <div class="banette__header-cell">
            <cnsa-checkbox
              label="Sélectionner toutes les lignes"
              size="small"
              [checked]="selectedAll"
              ngDefaultControl
              (cnsa-change)="selectAllRows()"></cnsa-checkbox>
          </div>
        </th>
        <th></th>
        <th scope="col" *ngFor="let column of columns; let x = index">
          <button class="banette__header-cell banette__header-cell--button" (click)="sortRows(x)">
            {{ column }}
            <cnsa-icon
              name="unfold_more"
              class="banette__sort-icon"
              aria-hidden="true"
              *ngIf="column !== ''"></cnsa-icon>
          </button>
        </th>
        <th></th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngIf="demandesValue">
        <tr
          *ngFor="
            let demande of (demandesValue | demandeEnCoursSearch : filterValue).slice(0, itemsToShow);
            let i = index
          "
          [ngClass]="isSelected(i) ? 'selected-background' : ''">
          <td>
            <cnsa-checkbox size="small" [checked]="isSelected(i)" (cnsa-change)="selectRow(i)"></cnsa-checkbox>
          </td>
          <td>
            <ng-container
              *ngIf="
                demande.apaRequest.specialTreatmentDetails &&
                demande.apaRequest.specialTreatmentDetails.specialTreatmentType !== SpecialTreatmentType.NO
              ">
              <span class="red-color">
                <cnsa-icon
                  name="priority_high"
                  *ngIf="
                    demande.apaRequest.specialTreatmentDetails.specialTreatmentType ===
                    SpecialTreatmentType.EMERGENCY_APA_PLAN
                  "></cnsa-icon
              ></span>

              <cnsa-icon
                name="timer"
                *ngIf="
                  demande.apaRequest.specialTreatmentDetails &&
                  demande.apaRequest.specialTreatmentDetails.specialTreatmentType ===
                    SpecialTreatmentType.ACCELERATED_PROCEDURE
                "></cnsa-icon>
            </ng-container>
          </td>
          <td>
            <div class="container-dossier">
              <div class="cell-content">
                <p class="cell-content__primary">
                  {{
                    demande.apaRequest.requesterSituation.personIdentity.lastName
                      ? demande.apaRequest.requesterSituation.personIdentity.lastName.toUpperCase()
                      : ''
                  }}
                  {{
                    demande.apaRequest.requesterSituation.personIdentity.firstName
                      ? demande.apaRequest.requesterSituation.personIdentity.firstName
                      : ''
                  }}
                </p>
                <a href="" (click)="openDemandeForm(demande); $event.preventDefault()" class="cell-content__secondary">
                  {{
                    demande.apaRequest.requesterSituation.personIdentity.nir
                      ? demande.apaRequest.requesterSituation.personIdentity.nir
                      : ''
                  }}
                </a>
              </div>
            </div>
          </td>
          <td>
            <div class="cell-content__secondary">
              {{ demande.apaRequest.requestType ? findLabelEnum(demande.apaRequest.requestType!) : '' }}
            </div>
          </td>
          <td>
            <ng-container *ngIf="demande.apaRequest.requestStatus?.value === RequestStatusValue.INCOMPLETE">
              <cnsa-tag variant="yellow-200">{{
                mapEnumRequestStatusToLabel(demande.apaRequest.requestStatus?.value!)
              }}</cnsa-tag>
            </ng-container>
            <ng-container
              *ngIf="
                demande.apaRequest.requestStatus?.value !== RequestStatusValue.COMPLETE &&
                demande.apaRequest.requestStatus?.value !== RequestStatusValue.INCOMPLETE
              ">
              <cnsa-tag variant="blue-200">{{
                mapEnumRequestStatusToLabel(demande.apaRequest.requestStatus?.value!)
              }}</cnsa-tag>
            </ng-container>
            <ng-container *ngIf="demande.apaRequest.requestStatus?.value === RequestStatusValue.COMPLETE">
              <cnsa-tag variant="green-200">{{
                mapEnumRequestStatusToLabel(demande.apaRequest.requestStatus?.value!)
              }}</cnsa-tag>
            </ng-container>
          </td>
          <td>
            <cnsa-button
              name="referents"
              id="referents"
              (click)="onClickOpenAssignmentModale(i)"
              title="Référent admin."
              slot="trigger"
              variant="secondary"
              size="small">
              {{
                demande.apaRequest.requesterSituation.personIdentity.administrativeReferent &&
                demande.apaRequest.requesterSituation.personIdentity.administrativeReferent.lastName &&
                demande.apaRequest.requesterSituation.personIdentity.administrativeReferent.firstName
                  ? (demande.apaRequest.requesterSituation.personIdentity.administrativeReferent!.lastName
                      | uppercase) +
                    ' ' +
                    demande.apaRequest.requesterSituation.personIdentity.administrativeReferent!.firstName
                  : 'Sélectionner'
              }}
            </cnsa-button>
          </td>
          <td>
            <div class="cell-content">
              <p>
                {{
                  demande.apaRequest.initialisationDateTime
                    ? (demande.apaRequest.initialisationDateTime | date : 'dd/MM/yy')
                    : ''
                }}
              </p>
              <div *ngIf="demande.apaRequest.daysLeft">
                <p *ngIf="demande.apaRequest.daysLeft > 0" class="cell-content__secondary">
                  J-{{ demande.apaRequest.daysLeft }}
                </p>
                <p *ngIf="demande.apaRequest.daysLeft < 0" style="color: red" class="cell-content__secondary">
                  J+{{ demande.apaRequest.daysLeft ? Math.abs(demande.apaRequest.daysLeft) : '' }}
                </p>
              </div>
              <div
                *ngIf="
                  !demande.apaRequest.daysLeft || (demande.apaRequest.daysLeft && demande.apaRequest.daysLeft === 0)
                ">
                <p class="cell-content__secondary">J</p>
              </div>
            </div>
          </td>
          <td>
            <cnsa-button (click)="openDemandeForm(demande)" variant="tertiary" size="small">Compléter</cnsa-button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <span class="table-label" *ngIf="isNoResults()">
    <p class="bold-text">{{ message.noResult }}</p>
    <br />
    <p>{{ message.emptySearch }}</p>
  </span>

  <div class="container-button" *ngIf="demandesValue">
    <cnsa-button *ngIf="canShowMoreResults()" variant="primary" (click)="addMoreItemsToShow()">
      <cnsa-icon name="downloading" slot="prefix"></cnsa-icon>
      Afficher plus de résultats
    </cnsa-button>
  </div>
  <app-bannete-modale-assigner-personne
    *ngIf="isDialogOpen"
    [nameProfile]="AgentTypeEnum.ADMINISTRATIF_AgentType"
    [banetteType]="BannetteTypeEnum.DEMANDE"
    [dialogIsOpen]="isDialogOpen"
    [dialogType]="AssignedModaleTypeEnum.ASSIGNMENT_UNITARY"
    [dataToChangeRefAdmin]="selectedDemande"
    (closeModal)="closeAssignedPersonModale($event)"
    (updatePerson)="updateSelectedPerson($event)">
  </app-bannete-modale-assigner-personne>
</div>

<cnsa-dialog classname="dialog-overview" label="Filtrer" [open]="showDialog" #dialog size="large">
  <ng-container>
    <div class="div-filtres">
      <div class="div-filtres-general">
        <h3 class="sub-title-filtres">Caractérisation</h3>
        <div class="div-filtres-checkbox">
          <cnsa-checkbox
            size="medium"
            value="{{ ProcessingTypeEnum.APA_URGENCE_ProcessingType }}"
            [checked]="filterDemandes.specialTreatment.includes(ProcessingTypeEnum.APA_URGENCE_ProcessingType)"
            (cnsa-change)="changeCaracterisationDemande($event)"
            >Forfait APA en urgence</cnsa-checkbox
          >
          <cnsa-checkbox
            size="medium"
            value="{{ ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType }}"
            [checked]="filterDemandes.specialTreatment.includes(ProcessingTypeEnum.PROCEDURE_ACCELEREE_ProcessingType)"
            (cnsa-change)="changeCaracterisationDemande($event)"
            >Procédure accélérée</cnsa-checkbox
          >
          <cnsa-checkbox
            size="medium"
            value="{{ ProcessingTypeEnum.NO }}"
            [checked]="filterDemandes.specialTreatment.includes(ProcessingTypeEnum.NO)"
            (cnsa-change)="changeCaracterisationDemande($event)"
            >Aucune caractérisation</cnsa-checkbox
          >
        </div>
      </div>

      <div class="div-filtres-general">
        <h3 class="sub-title-filtres">Statut</h3>
        <div class="div-filtres-checkbox">
          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeDemandeStatus($event)"
            value="{{ DemandStatusEnum.INCOMPLETE_DemandStatus }}"
            [checked]="filterDemandes.statusValue?.includes(DemandStatusEnum.INCOMPLETE_DemandStatus)"
            >Incomplet</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeDemandeStatus($event)"
            value="{{ DemandStatusEnum.DEPOSEE_DemandStatus }}"
            [checked]="filterDemandes.statusValue?.includes(DemandStatusEnum.DEPOSEE_DemandStatus)"
            >En cours</cnsa-checkbox
          >
        </div>
      </div>

      <div class="div-filtres-general">
        <h3 class="sub-title-filtres">Nature de la demande</h3>
        <div class="div-filtres-naturedemande">
          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeDemandeNature($event)"
            value="{{ TypeOfProcessEnum.PREMIERE_DEMANDE_APA_TypeOfProcess }}"
            [checked]="filterDemandes.requestType?.includes(TypeOfProcessEnum.PREMIERE_DEMANDE_APA_TypeOfProcess)"
            >{{ findLabelEnum(TypeOfProcessEnum.PREMIERE_DEMANDE_APA_TypeOfProcess) }}</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeDemandeNature($event)"
            value="{{ TypeOfProcessEnum.NOUVELLE_DEMANDE_APA_TypeOfProcess }}"
            [checked]="filterDemandes.requestType?.includes(TypeOfProcessEnum.NOUVELLE_DEMANDE_APA_TypeOfProcess)"
            >{{ findLabelEnum(TypeOfProcessEnum.NOUVELLE_DEMANDE_APA_TypeOfProcess) }}</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeDemandeNature($event)"
            value="{{ TypeOfProcessEnum.REVISION_PERSONNE_TypeOfProcess }}"
            [checked]="filterDemandes.requestType?.includes(TypeOfProcessEnum.REVISION_PERSONNE_TypeOfProcess)"
            >{{ findLabelEnum(TypeOfProcessEnum.REVISION_PERSONNE_TypeOfProcess) }}</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeDemandeNature($event)"
            value="{{ TypeOfProcessEnum.RELAIS_HOSPITALISATION_TypeOfProcess }}"
            [checked]="filterDemandes.requestType?.includes(TypeOfProcessEnum.RELAIS_HOSPITALISATION_TypeOfProcess)"
            >{{ findLabelEnum(TypeOfProcessEnum.RELAIS_HOSPITALISATION_TypeOfProcess) }}</cnsa-checkbox
          >

          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeDemandeNature($event)"
            value="{{ TypeOfProcessEnum.PREMIERE_DEMANDE_CMI_TypeOfProcess }}"
            [checked]="filterDemandes.requestType?.includes(TypeOfProcessEnum.PREMIERE_DEMANDE_CMI_TypeOfProcess)"
            >{{ findLabelEnum(TypeOfProcessEnum.PREMIERE_DEMANDE_CMI_TypeOfProcess) }}</cnsa-checkbox
          >
          <cnsa-checkbox
            size="medium"
            (cnsa-change)="changeDemandeNature($event)"
            value="{{ TypeOfProcessEnum.RENOUVELLEMENT_CMI_TypeOfProcess }}"
            [checked]="filterDemandes.requestType?.includes(TypeOfProcessEnum.RENOUVELLEMENT_CMI_TypeOfProcess)"
            >{{ findLabelEnum(TypeOfProcessEnum.RENOUVELLEMENT_CMI_TypeOfProcess) }}</cnsa-checkbox
          >
        </div>
      </div>

      <div class="div-filtres-select">
        <h3 class="sub-title-filtres">Référent administratif</h3>
        <cnsa-button (click)="onClickSelect()" variant="secondary">Sélectionner</cnsa-button>
      </div>

      <ng-container>
        <ng-container>
          <h3></h3>
        </ng-container>
      </ng-container>
    </div>

    <cnsa-button slot="footer" variant="navigation" (click)="resetFiltersDemande()"
      >Réinitialiser les filtres</cnsa-button
    >
    <cnsa-button slot="footer" (click)="filtrerDemande()">Filtrer</cnsa-button>
  </ng-container>
</cnsa-dialog>

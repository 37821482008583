/**
 * Énumération des types possibles pour les éléments de la banette.
 */
export enum BannetteTypeEnum {
  DEMANDE = 'demandes',
  RENDEZVOUS = 'rendezvous',
  BOITE = 'boite',
  CORBEILLE = 'corbeille',
  COURRIERS = 'courriers',
  FORFAITSAPAENURGENCE = 'forfaits apa en urgence',
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationRequest } from '../types/NotificationRequest.type';
import { NotificationRequestOptions } from '../interfaces/NotificationRequestOptions.interface';

/**
 * Service pour afficher des notifications en bas de la page.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  /**
   * Un sujet (Subject) utilisé pour émettre des notifications de type NotificationRequest ou null.
   *
   * @type {Subject<NotificationRequest | null>}
   */
  notificationRequested$: Subject<NotificationRequest | null> = new Subject<NotificationRequest | null>();

  /**
   * Permet de fermer la pop de notification
   */
  closeNotification() {
    this.notificationRequested$.next(null);
  }

  /**
   * Permet d'afficher une pop-up de notification en bas de page.
   *
   * @param title Message écrit en gras dans la notification.
   * @param options Les options possibles pour la notification. Des options sont par défaut.
   * Options par défaut :
   * <ul>
   *   <li>Duration : 5000 ms</li>
   *   <li>Variant : {@link NotificationVariant#INFO}</li>
   *   <li>Closable : true (si vrai, une croix permettant de fermer la pop-up par l'utilisateur est affichée)</li>
   *   <li>Message : message écrit en dessous du titre en caractères normaux</li>
   * </ul>
   */
  showNotification(title: string, options: Partial<NotificationRequestOptions>) {
    const request: NotificationRequest = new NotificationRequest(title, options);
    this.notificationRequested$.next(request);
  }
}
